var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.show,"append-to-body":true,"close-on-click-modal":false,"fullscreen":""},on:{"update:visible":function($event){_vm.show=$event}}},[_c('div',{staticClass:"dialog-wrap-full"},[(_vm.show)?_c('div',{staticClass:"flex-home"},[_c('el-col',{staticStyle:{"margin-bottom":"18px"},attrs:{"span":24}},[_c('el-button',{on:{"click":function($event){return _vm.$refs.AddSign.open({
              mainId: _vm.detail.mainId,
              merchantId: _vm.detail.merchantId
            })}}},[_vm._v("添加")])],1),_c('TableView',{ref:"vTable",attrs:{"labels":_vm.tableLabels,"searchList":_vm.searchList,"apiName":"GetMainSupplierInfoPageList","searchExt":{ merchantId: _vm.merchantId }}},[_c('el-table-column',{attrs:{"slot":"table-item","label":"操作","width":"220","fixed":"right"},slot:"table-item",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
            var $index = ref.$index;
return [(row.effectiveStatus != 2)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.invalidHanle(row)}}},[_vm._v("作废")]):_vm._e(),(row.effectiveStatus == 2)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteHandle(row)}}},[_vm._v("删除")]):_vm._e(),(row.signType == 2 && row.effectiveStatus == 1 && row.signState == 2 && row.archivedStatus != 1)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.archiveHandle(row)}}},[_vm._v("归档")]):_vm._e(),(row.signType == 2 && row.signState == 2 && row.archivedStatus != 1)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.uploadContractHandle({
                  id: row.id,
                  signType: row.signType,
                  contractName: row.contractUrl,
                  contractName: row.contractTemplateName,
                  againUpload: 1
                })}}},[_vm._v("重新上传")]):_vm._e(),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.downContract(row)}}},[_vm._v("下载合同")]),(row.signType == 2 && row.signState == 1)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.uploadContractHandle({ id: row.id, signType: row.signType })}}},[_vm._v("上传合同")]):_vm._e(),(row.signState == 1)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.copyLink(row.signUrl)}}},[_vm._v("复制链接")]):_vm._e()]}}],null,false,468229338)})],1)],1):_vm._e()]),_c('UploadContract',{ref:"UploadContract",on:{"uploaded":function($event){return _vm.$refs.vTable.getTableList()}}}),_c('AddSign',{ref:"AddSign",on:{"updateList":function($event){return _vm.$refs.vTable.refreshTable()}}}),_c('button',{ref:"CopyBtn",staticClass:"copyBtn",attrs:{"data-clipboard-text":_vm.copyText}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }