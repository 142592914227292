<template>
  <el-dialog title="审核" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" custom-class="dialog-full-hasfoot" fullscreen>
    <!-- 内容区域 -->
    <div class="dialog-wrap-full" v-if="show">
      <div class="flex-home">
        <el-descriptions :title="'结算单信息【结算单号：' + detail.planNo + '】'" :column="2" :labelStyle="{ width: '120px', textAlign: 'right' }">
          <el-descriptions-item label="所属任务">{{ detail.taskName || '无' }}</el-descriptions-item>
          <el-descriptions-item label="任务编号">{{ detail.taskNo || '无' }}</el-descriptions-item>
          <el-descriptions-item label="人数">{{ detail.totalPeopleNum || '0' }}</el-descriptions-item>
          <el-descriptions-item label="付款通道">{{ detail.paymentChannel || '无' }}</el-descriptions-item>
          <el-descriptions-item label="提交金额"
            ><span v-format="'#,##0.00'">{{ detail.issueAmount || 0 }}</span> 元</el-descriptions-item
          >
          <el-descriptions-item label="服务费"
            ><span v-format="'#,##0.00'">{{ detail.serviceFee || 0 }}</span> 元</el-descriptions-item
          >
          <el-descriptions-item label="差额服务费" :span="2"
            ><span v-format="'#,##0.00'">{{ detail.serviceDiffFee || 0 }}</span> 元</el-descriptions-item
          >
          <el-descriptions-item label="个税"
            ><span v-format="'#,##0.00'">{{ detail.individualTax || 0 }}</span> 元</el-descriptions-item
          >
          <el-descriptions-item label="增值税"
            ><span v-format="'#,##0.00'">{{ detail.addedTax || 0 }}</span> 元</el-descriptions-item
          >
          <el-descriptions-item label="税金及附加"
            ><span v-format="'#,##0.00'">{{ detail.surchargesTax || 0 }}</span> 元</el-descriptions-item
          >
          <el-descriptions-item label="实发金额"
            ><span v-format="'#,##0.00'">{{ detail.finalPayingAmount || 0 }}</span> 元</el-descriptions-item
          >
          <el-descriptions-item label="发放税源地">{{ detail.supplierName + '【' + detail.supplierCode + '】' }}</el-descriptions-item>
          <el-descriptions-item label="发放通道">{{
            detail.channelName ? detail.supplierName + ' - ' + detail.channelName : '无'
          }}</el-descriptions-item>
        </el-descriptions>
        <el-divider></el-divider>
        <el-descriptions title="商户信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right' }">
          <el-descriptions-item label="商户名称">{{ detail.merchantName || '无' }}</el-descriptions-item>
          <el-descriptions-item label="商户编号">{{ detail.memberCode || '无' }}</el-descriptions-item>
          <el-descriptions-item label="认证企业">{{ detail.mainName || '无' }}</el-descriptions-item>
          <el-descriptions-item label="签约类型"
            ><span v-html="formatStatusHasColor(kvContractMode, detail.contractMode)"></span
          ></el-descriptions-item>
        </el-descriptions>
        <el-divider></el-divider>
        <div class="el-descriptions__header">
          <div class="el-descriptions__title">结算明细</div>
          <div class="el-descriptions__extra"></div>
        </div>
        <TableView ref="vTable" :labels="tableLabels" apiName="GetInidvidualPlanByDetailPageList" :searchExt="{ PlanNo: id }"></TableView>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="submit(false)" :loading="btnloadSubmit">审核不通过</el-button>
      <el-button type="primary" @click="submit(true)" :loading="btnloadSubmit">审核通过</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { formatStatus, formatStatusHasColor, kvContractMode } from '@/configs/formatters'
import { tableWorkPlanWorkDetail } from '@/configs/tables'
export default {
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      supplierOptions: [],
      detail: {},
      tableLabels: tableWorkPlanWorkDetail,
      kvContractMode
    }
  },
  methods: {
    formatStatusHasColor,
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
      this.signRate = {}
    },
    open(obj) {
      this.clear()
      if (obj) {
        // 编辑
        this.id = obj.planNo
        this.detail = obj
        this.channelName = obj.channelName || ''
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    },
    // 提交审核
    async submit(audit) {
      const params = {
        memberCode: this.detail.memberCode,
        planNo: this.detail.planNo,
        supplierId: this.detail.supplierId
      }
      if (audit) {
        if (!this.detail.channelName) return this.$message.error('没有发放通道信息，不能审核通过')
        this.btnloadSubmit = true
        const res = await this.$api.AuditPassInidvidualPlan(params).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('结算单审核通过')
        this.$emit('submit')
        this.show = false
      } else {
        // 不通过弹窗aduitFailReason
        const { value } = await this.$prompt('请输入审核不通过理由', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^\S{1,200}$/,
          inputType: 'textarea',
          inputPlaceholder: '必填，请输入审核不通过理由，不超过200字',
          inputErrorMessage: '必填，请输入审核不通过理由，不超过200字'
        })
        params.aduitFailReason = value
        this.btnloadSubmit = true
        const res = await this.$api.AuditNotPassInidvidualPlan(params).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('结算单审核不通过')
        this.$emit('submit')
        this.show = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contract-info {
  display: flex;
  align-items: center;
  padding-right: 100px;
  &-item {
    flex: 1;
  }
  .arrow {
    position: relative;
    flex-shrink: 0;
    width: 100px;
    height: 6px;
    background: #c0c4cc;
    margin: 0 100px 0 20px;
    &::after {
      content: '';
      display: block;
      border-left: 10px solid #c0c4cc;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      position: absolute;
      left: 100%;
      top: 50%;
      margin-top: -8px;
    }
  }
}
::v-deep .el-divider {
  flex-shrink: 0;
}
</style>
