var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":"添加","visible":_vm.show,"append-to-body":true,"close-on-click-modal":false,"width":"850px"},on:{"update:visible":function($event){_vm.show=$event}}},[_c('div',{staticClass:"dialog-wrap"},[_c('FormView',{ref:"FormView",attrs:{"labels":_vm.labels,"formConfig":{ labelWidth: '150px' }},on:{"onInputChange":_vm.onInputChange},scopedSlots:_vm._u([{key:"contractTemplateId",fn:function(ref){
var form = ref.form;
var idx = ref.idx;
return [_c('el-col',[_c('el-form-item',{attrs:{"label":"选择模板","prop":"contractTemplateId","rules":[{ required: true, message: '请选择模板并填写', trigger: 'blur' }]}},[_c('div',{staticClass:"fillTemplate"},[_c('el-select',{staticStyle:{"width":"300px"},attrs:{"placeholder":"请选择"},on:{"change":_vm.changeContractTemplateId},model:{value:(form.contractTemplateId),callback:function ($$v) {_vm.$set(form, "contractTemplateId", $$v)},expression:"form.contractTemplateId"}},_vm._l((_vm.tempOptions),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.templateCode + ' ' + item.templateName,"value":item.id}})}),1),_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.openContractTemplate}},[_vm._v("填充模板")])],1)])],1)]}},{key:"signatoryDtos",fn:function(ref){
var form = ref.form;
var idx = ref.idx;
return [_c('el-col',[_c('el-form-item',{staticClass:"is-required",attrs:{"label":"盖章关键字"}},[_c('el-table',{attrs:{"data":_vm.keywordList}},[_c('el-table-column',{attrs:{"prop":"keyword","label":"关键字","width":"120"}}),_c('el-table-column',{attrs:{"prop":"signatoryType","label":"签约方类型","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
var $index = ref.$index;
return [_vm._v(_vm._s(_vm.formatStatus(_vm.stampOptions, row.signatoryType)))]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"signatoryName","label":"签约方","width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
var $index = ref.$index;
return [(row.signatoryType == 1 || row.signatoryType == 2)?_c('el-input',{attrs:{"disabled":"","placeholder":row.signatoryType == 1 ? '商户主体' : '创客'}}):_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":""},on:{"change":function($event){return _vm.onSignatoryChange($event, row)}},model:{value:(row.signatoryId),callback:function ($$v) {_vm.$set(row, "signatoryId", $$v)},expression:"row.signatoryId"}},_vm._l((_vm.signatoryOptions),function(item){return _c('el-option',{key:item.mainId,staticStyle:{"width":"300px"},attrs:{"label":item.mainCode + ' ' + item.mainName,"value":item.mainId}})}),1)]}}],null,true)}),_c('el-table-column',{attrs:{"label":"授权状态","width":"80","prop":"authorState"}})],1)],1)],1)]}},{key:"supplierId",fn:function(ref){
var form = ref.form;
var idx = ref.idx;
return [_c('el-col',[_c('el-form-item',{attrs:{"label":"签约税源地","prop":"supplierId","rules":[{ required: true, message: '请选择签约税源地', trigger: 'blur' }]}},[_c('el-select',{style:({ width: '300px' }),attrs:{"filterable":"","remote":"","placeholder":"请输入税源地名称","remote-method":_vm.getSupplierOptions,"loading":_vm.loading},on:{"change":_vm.setSupplierNameValue},model:{value:(form.supplierId),callback:function ($$v) {_vm.$set(form, "supplierId", $$v)},expression:"form.supplierId"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.id,attrs:{"label":("(" + (item.supplierCode) + ") " + (item.supplierName)),"value":item.id}})}),1)],1)],1)]}}])})],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.show = false}}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.btnloadSubmit},on:{"click":_vm.limtButton}},[_vm._v("确定")])],1),_c('FillMain',{ref:"FillMain",on:{"submit":_vm.updateTemplateDetail}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }