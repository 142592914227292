<template>
  <el-dialog title="配置管理" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" custom-class="dialog-full-hasfoot" fullscreen>
    <!-- 内容区域 -->
    <div class="dialog-wrap-full" v-if="show">
      <el-form :model="form" ref="vForm" :rules="rules" label-width="180px" labelSuffix="：">
        <el-divider content-position="left">基础配置</el-divider>
        <el-form-item label="服务税源地" required>{{ detail.supplierName }}（{{ detail.supplierCode }}）</el-form-item>
        <el-form-item label="费率类型" prop="isPiecewise">
          <el-radio-group v-model="form.isPiecewise">
            <el-radio :label="0">固定费率</el-radio>
            <el-radio :label="1">阶梯费率</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="服务费率" prop="serviceTariffing" v-if="form.isPiecewise == 0">
          <el-input-number :controls="false" :min="0" :precision="2" :max="100" v-model="form.serviceTariffing" />
          %
        </el-form-item>
        <el-form-item label="成本费率" prop="costServiceTariffing" v-if="form.isPiecewise == 0">
          <el-input-number :controls="false" :min="0" :precision="2" :max="100" v-model="form.costServiceTariffing" />
          %
        </el-form-item>
        <el-form-item v-if="form.isPiecewise == 1">
          <el-button plain type="primary" icon="el-icon-plus" @click="addTariffing()" style="margin-bottom: 12px">添加</el-button>
          <!-- 阶梯税率表格 -->
          <el-table ref="merchantTariffingListTable" :data="merchantTariffingList" border>
            <el-table-column label="发放金额">
              <template v-slot="{ row, $index }">
                <el-input-number :controls="false" :precision="0" disabled :value="issueAmountBefore($index)" />
                -
                <el-input-number :controls="false" :min="issueAmountBefore($index)" :max="1000000000000" :precision="0" v-model="row.issueAmount" />
              </template>
            </el-table-column>
            <el-table-column label="服务费率">
              <template v-slot="{ row }">
                <el-input-number :controls="false" :min="0" :precision="2" :max="100" v-model="row.serviceTariffing" />
                %
              </template>
            </el-table-column>
            <el-table-column label="成本费率">
              <template v-slot="{ row }">
                <el-input-number :controls="false" :min="0" :precision="2" :max="100" v-model="row.costServiceTariffing" />
                %
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template v-slot="{ row, $index }">
                <el-button type="text" class="el-button-error" @click="deleteTariffing(row, $index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-divider content-position="left">电签设置</el-divider>
        <el-form-item label="签约类型" prop="contractMode">
          <el-radio-group v-model="form.contractMode" @change="changeContractMode">
            <el-radio :label="20">活体电签</el-radio>
            <el-radio :label="30">快捷签约</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="电签协议模板" prop="templateValue">
          <el-select v-model="form.templateValue" placeholder="请选择" style="width: 300px" @change="changeValue">
            <el-option
              v-for="item in (form.contractMode == 30 ? TempOptions[1] && TempOptions[1].list : TempOptions[0] && TempOptions[0].list) || []"
              :key="item.contractTemplateCode"
              :label="item.contractTemplateName"
              :value="item.contractTemplateId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="签约率" prop="signRate">
          <el-input-number :controls="false" :min="0" :precision="0" :max="100" v-model="form.signRate" />
          %
        </el-form-item>
        <el-form-item label="签约校验" prop="isSigned">
          <el-radio-group v-model="form.isSigned">
            <el-radio :label="1">关闭</el-radio>
            <el-radio :label="0"
              >开启&nbsp;<el-tooltip effect="dark" content="开启后，结算单导入时，只能导入已签约的创客"
                ><i class="el-icon-warning-outline"></i></el-tooltip
            ></el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-divider content-position="left">充值账户</el-divider>  -->
        <el-form-item v-if="false">
          <el-button plain type="primary" icon="el-icon-plus" @click="$refs.AddAccount.open()" style="margin-bottom: 12px">添加</el-button>
          <!-- 充值账户表格 -->
          <el-table ref="accountListTable" :data="accountList" border>
            <el-table-column label="账户名称" prop="accountName"></el-table-column>
            <el-table-column label="开户银行" prop="accountBank"></el-table-column>
            <el-table-column label="银行账户" prop="accountNumber"></el-table-column>
            <el-table-column
              label="账户类型"
              prop="accountType"
              :formatter="
                (row, column, cellValue, index) => {
                  return formatStatus(kvAccountType, cellValue)
                }
              "
            >
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template v-slot="{ row, $index }">
                <el-button type="text" @click="$refs.AddAccount.open(row, $index)">编辑</el-button>
                <el-button type="text" class="el-button-error" @click="deleteAccount($index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-divider content-position="left">票税设置</el-divider>

        <el-form-item label="第三方开票服务" prop="isThirdInvoice">
          <el-radio-group @change="changeHm" v-model="form.isThirdInvoice">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="2">开启，仅获取税目</el-radio>
            <el-radio :label="1">开启，并使用第三方开票服务</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="appKey" prop="hmCode" v-if="form.isThirdInvoice">
          <el-input style="width: 350px" v-model="form.hmCode" />
        </el-form-item>
        <el-form-item label="secretKey" prop="hmSecretKey" v-if="form.isThirdInvoice">
          <el-input style="width: 350px" v-model="form.hmSecretKey" />
        </el-form-item>
        <el-form-item label="publicKey" prop="hmPublicKey" v-if="form.isThirdInvoice">
          <el-input style="width: 350px; margin-right: 20px" v-model="form.hmPublicKey" />
        </el-form-item>
        <el-form-item label="mainstayId" prop="mainstayId" v-if="form.isThirdInvoice">
          <el-input style="width: 350px; margin-right: 20px" v-model="form.mainstayId" />
        </el-form-item>
        <!-- 调试 -->
        <el-form-item prop="checkHmTaxItemsId" :error="hmTaxError" v-if="form.isThirdInvoice" label="可开票税目" class="is-required">
          <el-popover placement="bottom-start" width="600" trigger="manual" v-model="showPopver">
            <div slot="reference">
              <el-button type="primary" @click="getHmMerchant">添加</el-button>
            </div>
            <div class="tax-pop">
              <div class="text">税目列表</div>
              <el-tree
                :data="hmTaxItemsLevelList"
                show-checkbox
                default-expand-all
                node-key="childrenId"
                :default-checked-keys="checkHmTaxItemsId"
                ref="taxHmAllTree"
                @check="getCheckChange"
              >
                <span class="custom-tree-node" slot-scope="{ data }">
                  <span class="label">{{ data.label || '' }}</span
                  >&nbsp;
                  <span class="label-num">{{ data.taxCode || '' }}</span>
                </span>
              </el-tree>
            </div>
            <div style="text-align: right; margin: 0">
              <el-button type="text" @click="showPopver = false">取消</el-button>
              <el-button type="primary" @click="getHmTree">确定</el-button>
            </div>
          </el-popover>
          <el-tree
            style="width: 400px"
            v-if="checkHmTaxItemsLevelList.length > 0"
            :data="checkHmTaxItemsLevelList"
            default-expand-all
            node-key="value"
            ref="taxHmTree"
          >
            <span class="custom-tree-node" slot-scope="{ data }">
              <span class="label">{{ data.label || '' }}</span
              >&nbsp;
              <span class="label-num">{{ data.taxCode || '' }}</span>
            </span>
          </el-tree>
        </el-form-item>
        <el-form-item v-if="form.isThirdInvoice" label="第三方同步订单" prop="openThirdOrderSync">
          <el-radio-group v-model="form.openThirdOrderSync">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="!form.isThirdInvoice" label="可开票税目" class="is-required" :error="taxError">
          <el-popover placement="bottom-start" width="600" trigger="manual" v-model="showTaxPopver">
            <div slot="reference">
              <el-button type="primary" @click="showTaxPopver = true">添加</el-button>
            </div>
            <div class="tax-pop">
              <div class="text">税目列表</div>
              <el-tree
                :data="taxItemsLevelList"
                show-checkbox
                default-expand-all
                :default-checked-keys="checkTaxItemsId"
                node-key="value"
                ref="taxAllTree"
              >
                <span class="custom-tree-node" slot-scope="{ data }">
                  <span class="label">{{ data.title || '' }}</span
                  >&nbsp;
                  <span class="label-num">{{ data.taxCode || '' }}</span>
                </span>
              </el-tree>
            </div>
            <div style="text-align: right; margin: 0">
              <el-button type="text" @click="showTaxPopver = false">取消</el-button>
              <el-button type="primary" @click="getTaxTree">确定</el-button>
            </div>
          </el-popover>
          <el-tree style="width: 400px" v-if="merchantTaxList.length > 0" :data="merchantTaxList" default-expand-all node-key="value" ref="taxTree">
            <span class="custom-tree-node" slot-scope="{ data }">
              <span class="label">{{ data.title || '' }}</span
              >&nbsp;
              <span class="label-num">{{ data.taxCode || '' }}</span>
            </span>
          </el-tree>
        </el-form-item>
        <el-form-item label="发票附件" prop="isUploadBillPic">
          <el-radio-group v-model="form.isUploadBillPic">
            <el-radio :label="1">必传</el-radio>
            <el-radio :label="0">非必传</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-divider content-position="left">额度管控</el-divider>
        <el-form-item label="额度控制" prop="isControl">
          <el-radio-group v-model="form.isControl">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
          <span style="margin-left: 30px; font-size: 12px" v-if="form.isControl == 1">以下两项，至少一项不为0，0表示不开启此项限制：</span>
        </el-form-item>
        <el-form-item v-if="form.isControl == 1" prop="sigleLimit">
          单笔发放金额不得超过
          <el-input-number :controls="false" :min="0" :precision="2" v-model="form.sigleLimit" />
          元
        </el-form-item>
        <el-form-item v-if="form.isControl == 1" prop="monthLimit">
          每人每月发放金额不得超过
          <el-input-number :controls="false" :min="0" :precision="2" v-model="form.monthLimit" />
          元
        </el-form-item>
        <el-divider content-position="left">要素验证</el-divider>
        <el-form-item label="创客信息校验" prop="verifyPeople">
          <el-select @change="changeUser(form.verifyPeople)" multiple v-model="form.verifyPeople" placeholder="请选创客信息验证" style="width: 350px">
            <el-option
              v-for="item in peopleOptions"
              :key="item.value"
              :value="item.value"
              :label="item.label"
              @click.native="selectUser(item.value)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="结算单校验（支付宝）" prop="verifyAlipay">
          <el-select @change="changeAlipay(form.verifyAlipay)" multiple v-model="form.verifyAlipay" placeholder="请选支付宝验证" style="width: 350px">
            <el-option
              v-for="item in alipayOptions"
              :key="item.value"
              :value="item.value"
              :label="item.label"
              @click.native="selectAlipay(item.value)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="结算单校验（银行卡）" prop="verifyBank">
          <el-select @change="changeBank(form.verifyBank)" multiple v-model="form.verifyBank" placeholder="请选择银行卡验证" style="width: 350px">
            <el-option
              v-for="item in bankOptions"
              :key="item.value"
              :value="item.value"
              :label="item.label"
              @click.native="selectBank(item.value)"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="支付宝验证" prop="verifyAlipay">
          <el-select v-model="form.verifyAlipay" placeholder="请选支付宝验证" style="width: 350px">
            <el-option label="关闭" :value="0"></el-option>
            <el-option label="支付宝二要素(支付宝账号+姓名)" :value="1"></el-option>
            <el-option label="支付宝三要素(支付宝账号+姓名+身份证号码)" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="微信验证" prop="verifyWechat">
          <el-select v-model="form.verifyWechat" placeholder="请选择微信验证" style="width: 350px">
            <el-option label="关闭" :value="0"></el-option>
            <el-option label="微信二要素(微信账号+姓名)" :value="1"></el-option>
            <el-option label="微信三要素(微信账号+姓名+身份证号码)" :value="2"></el-option>
          </el-select>
        </el-form-item> -->
        <el-divider content-position="left">两单一物</el-divider>
        <el-form-item label="两单一物生成规则" prop="isDeliverable">
          <el-radio-group v-model="form.isDeliverable">
            <el-radio :label="0">系统设置</el-radio>
            <el-radio :label="1">自定义</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="金额范围" v-if="form.isDeliverable" prop="isDeliverableAmountLimit">
          <el-radio-group v-model="form.isDeliverableAmountLimit">
            <el-radio :label="0">不限制</el-radio>
            <el-radio :label="1">限制</el-radio>
          </el-radio-group>
          <div style="display: inline-block; margin-left: 30px" v-if="form.isDeliverableAmountLimit">
            <el-form-item style="display: inline-block; margin-bottom: 0" prop="deliverableMinAmount"
              ><el-input-number :controls="false" :min="0" :precision="2" v-model="form.deliverableMinAmount"
            /></el-form-item>
            <span> - </span>
            <el-form-item style="display: inline-block; margin-bottom: 0" prop="deliverableMaxAmount"
              ><el-input-number :controls="false" :min="0" :precision="2" v-model="form.deliverableMaxAmount"
            /></el-form-item>
          </div>
        </el-form-item>
        <el-form-item label="抽成占比" v-if="form.isDeliverable" prop="deliverableCountLimit"
          ><el-input-number :controls="false" :min="0" :precision="1" :max="100" v-model="form.deliverableCountLimit" /> %</el-form-item
        >
        <el-divider content-position="left">其他配置</el-divider>
        <el-form-item label="API接口上传" prop="isAudit">
          <el-radio-group v-model="form.isAudit">
            <el-radio :label="1">自动提交</el-radio>
            <el-radio :label="2">自动审核</el-radio>
            <el-radio :label="0">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="自动发送创客签约短信" prop="isAutoSend">
          <el-radio-group v-model="form.isAutoSend">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="风控通知短信">
          <el-radio-group v-model="form.isRiskNotification">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="自动发放">
          <el-radio-group v-model="form.autoGrant">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="任务挂接" prop="taskStrategy">
          <el-radio-group v-model="form.taskStrategy">
            <el-radio :label="0">自动</el-radio>
            <el-radio :label="1">固定</el-radio>
          </el-radio-group>
          <div class="el-form--inline" inline style="display: inline-block; margin-left: 15px">
            <el-form-item prop="fixedTaskNumber" v-if="form.taskStrategy == 1">
              <el-input inline-message v-model="form.fixedTaskNumber" />
            </el-form-item>
          </div>
        </el-form-item> -->
      </el-form>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">保 存</el-button>
    </span>
    <!-- 弹窗 -->
    <AddAccount ref="AddAccount" @submit="accountSave"></AddAccount>
  </el-dialog>
</template>

<script>
import { formatStatus, kvAccountType } from '@/configs/formatters'
import { isEmpty } from '@/utils'
import AddAccount from './addaccount'
export default {
  components: { AddAccount },
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      detail: {},
      form: {},
      rules: {
        contractMode: [{ required: true, message: '请选择签约类型', trigger: 'change' }],
        templateValue: [{ required: true, message: '请选择电签协议模板', trigger: 'change' }],
        signRate: [
          {
            required: true,
            message: '请输入签约率·',
            trigger: ['change', 'blur']
          }
        ],
        isSigned: [{ required: true, message: '请选择是否签约校验', trigger: 'change' }],
        isPiecewise: [{ required: true, message: '请选择费率类型·', trigger: 'change' }],
        serviceTariffing: [
          {
            required: true,
            message: '请输入服务费率·',
            trigger: ['change', 'blur']
          }
        ],
        costServiceTariffing: [
          {
            required: true,
            message: '请输入服务费率·',
            trigger: ['change', 'blur']
          }
        ],
        isUploadBillPic: [
          {
            required: true,
            message: '请选择是否必传发票附件',
            trigger: 'change'
          }
        ],
        openThirdOrderSync: [
          {
            required: true,
            message: '请选择是否同步第三方订单',
            trigger: 'change'
          }
        ],
        isControl: [{ required: true, message: '请选择是否额度管控', trigger: 'change' }],
        verifyCitizen: [{ required: true, message: '请选择身份证验证', trigger: 'change' }],
        verifyBank: [{ required: true, message: '请选择银行卡验证', trigger: 'change' }],
        verifyAlipay: [{ required: true, message: '请选支付宝验证', trigger: 'change' }],
        verifyPeople: [{ required: true, message: '请选创客信息验证', trigger: 'change' }],
        verifyWechat: [{ required: true, message: '请选择微信验证', trigger: 'change' }],
        isAudit: [
          {
            required: true,
            message: '请选择API接口上传方式',
            trigger: 'change'
          }
        ],
        taskStrategy: [{ required: true, message: '请选择任务挂接方式', trigger: 'change' }],
        fixedTaskNumber: [
          {
            required: true,
            message: '请填写挂接任务的任务编号',
            trigger: 'blur'
          }
        ],
        hmSecretKey: [{ required: true, message: '请填写secretKey', trigger: 'blur' }],
        hmPublicKey: [{ required: true, message: '请填写publicKey', trigger: 'blur' }],
        mainstayId: [{ required: true, message: '请填写mainstayId', trigger: 'blur' }],
        hmCode: [{ required: true, message: '请填写appKey', trigger: 'blur' }],
        isDeliverable: [{ required: true, message: '请选择两单一物生成规则', trigger: 'change' }],
        isDeliverableAmountLimit: [{ required: true, message: '请选择金额范围', trigger: 'change' }],
        deliverableMinAmount: [{ required: true, message: '请填写最小金额', trigger: 'blur' }],
        deliverableMaxAmount: [
          { required: true, message: '请填写最大金额', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value < this.form.deliverableMinAmount) {
                callback(new Error('需要大于最小金额'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        deliverableCountLimit: [{ required: true, message: '请填写抽取占比', trigger: 'blur' }]
      },
      defultSelect: {}, // 默认选中项
      TempOptions: [], // 选择列表
      merchantTariffingList: [], // 阶梯税率列表
      deledTariffingIds: [], // 删除的阶梯费率ids
      accountList: [], // 充值帐户列表
      merchantTaxList: [], // 税目列表
      taxItemsLevelList: [], // 所有税目列表
      taxItemsFlat: [], // 拉平后的所有税目列表
      checkTaxItemsId: [],
      showTaxPopver: false,
      hmTaxItemsLevelList: [], // 盒马税目列表
      hmTaxItemsFlat: [], // 拉平后的盒马税目列表
      kvAccountType,
      checkHmTaxItemsLevelList: [], //选中的盒马税目列表
      end: null,
      taxError: '',
      hmTaxError: '',
      isGetHmMerchant: false,
      showPopver: false,
      checkHmTaxItemsId: [],
      peopleOptions: [
        { value: '0', label: '关闭' },
        { value: '1', label: '身份证二要素(姓名+身份证号码)' },
        { value: '2', label: '手机号三要素(姓名+身份证号码+手机号)' },
        { value: '3', label: '银行卡三要素(姓名+身份证号+银行卡号)' },
        {
          value: '4',
          label: '银行卡四要素（姓名+身份证号+银行卡号+预留手机号）'
        }
      ],
      bankOptions: [
        { value: '0', label: '关闭' },
        { value: '1', label: '身份证二要素(姓名+身份证号码)' },
        { value: '2', label: '手机号三要素(姓名+身份证号码+手机号)' },
        { value: '3', label: '银行卡三要素(姓名+身份证号+银行卡号)' },
        {
          value: '4',
          label: '银行卡四要素（姓名+身份证号+银行卡号+预留手机号）'
        }
      ],
      alipayOptions: [
        { value: '0', label: '关闭' },
        { value: '1', label: '身份证二要素(姓名+身份证号码)' },
        { value: '2', label: '手机号三要素(姓名+身份证号码+手机号)' }
      ]
    }
  },
  watch: {
    merchantTaxList(val) {
      if (val && val.length) this.taxError = ''
      else this.taxError = '请选择可开票税目'
    },
    checkHmTaxItemsLevelList(val) {
      if (val && val.length) this.hmTaxError = ''
      else this.hmTaxError = '请选择可开票税目'
    }
  },
  methods: {
    formatStatus,
    changeMode(e) {
      switch (e) {
        case 20:
          this.form.electronicRate = 100
          break
        case 10:
          this.form.electronicRate = 0
        default:
          break
      }
    },
    selectUser(e) {
      if (this.form.verifyPeople.includes('0') && e != '0') {
        this.form.verifyPeople = this.form.verifyPeople.filter((item) => {
          return item !== '0'
        })
        if (this.form.verifyPeople.length < 1) {
          this.form.verifyPeople.push(e)
        }
      }
    },
    selectBank(e) {
      if (this.form.verifyBank.includes('0') && e != '0') {
        this.form.verifyBank = this.form.verifyBank.filter((item) => {
          return item !== '0'
        })
        if (this.form.verifyBank.length < 1) {
          this.form.verifyBank.push(e)
        }
      }
    },
    selectAlipay(e) {
      if (this.form.verifyAlipay.includes('0') && e != '0') {
        this.form.verifyAlipay = this.form.verifyAlipay.filter((item) => {
          return item !== '0'
        })
        if (this.form.verifyAlipay.length < 1) {
          this.form.verifyAlipay.push(e)
        }
      }
    },

    changeBank(e) {
      if (e.includes('0')) {
        this.form.verifyBank = ['0']
      }
    },
    changeUser(e) {
      if (e.includes('0')) {
        this.form.verifyPeople = ['0']
      }
    },
    changeAlipay(e) {
      if (e.includes('0')) {
        this.form.verifyAlipay = ['0']
      }
    },
    //获取可开票税目
    async getHmMerchant() {
      if (this.form.hmCode == null) {
        this.$message.warning('请填写appKey')
        return false
      }
      if (this.form.hmSecretKey == null) {
        this.$message.warning('请填写secretKey')
        return false
      }
      if (this.form.hmPublicKey == null) {
        this.$message.warning('请填写publicKey')
        return false
      }
      if (this.form.mainstayId == null) {
        this.$message.warning('请填写mainstayId')
        return false
      }
      this.showPopver = true
      let res = await this.$api.QueryMerchantHmTaxCode({
        merchantId: this.detail.merchantId,
        hmCode: this.form.hmCode,
        hmSecretKey: this.form.hmSecretKey,
        hmPublicKey: this.form.hmPublicKey,
        mainstayId: this.form.mainstayId
      })
      if (res) {
        this.getTaxByHmcode(this.form.hmCode, this.form.mainstayId)
        // this.getTaxItemsLevelList()
      }
    },
    //获取盒马税目列表
    async getTaxByHmcode(hmCode, mainstayId) {
      const res = await this.$api.GetTaxByHmcode({ hmCode, mainstayId })
      let v = res.list.map((item) => {
        return {
          label: item.parentTaxName,
          hmCode: item.hmCode,
          childrenId: item.childrenId,
          children: [
            {
              taxCode: item.taxCode,
              childrenId: item.childrenId,
              label: item.childrenTaxName
            }
          ]
        }
      })
      this.hmTaxItemsLevelList = v
      // this.hmTtaxItemsFlat = res.list.flatMap((item) => [item, ...item.children])
    },
    // 数据清空
    clear() {
      this.id = null
      this.defultSelect = {}
      this.form = {}
      this.deledTariffingIds = []
      this.detail = {}
      this.checkHmTaxItemsLevelList = []
      this.hmTaxItemsLevelList = []
      this.checkHmTaxItemsId = []
      this.checkTaxItemsId = []
      this.showTaxPopver = false
      this.showPopver = false
    },

    open(obj) {
      this.clear()
      if (obj && obj.merchantId) {
        // 编辑
        this.id = obj.merchantId
        this.getDetail()
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    },
    // 获取配置详情
    async getDetail() {
      const res = await this.$api.GetMerchantInfo({ merchantId: this.id })
      res.verifyAlipay = res.verifyAlipay.split('|')
      res.verifyBank = res.verifyBank.split('|')
      res.verifyPeople = res.verifyPeople.split('|')
      const { merchantSecret, mainId, mainCode, mainName, mobile, peopleTemplateCode, peopleTemplateId, peopleTemplateName, ...resform } = res
      // res.verifyAlipay = "0|1|2";
      this.detail = res
      if (res) {
        let templateValue = undefined
        const result = await this.$api.GetTemplateListBySignType()
        const idx = resform.contractMode == 30 ? 1 : 0
        if (result && result.list[idx].list.some((item) => item.contractTemplateId == peopleTemplateId)) {
          this.defultSelect = {
            peopleTemplateCode,
            peopleTemplateId,
            peopleTemplateName
          }
          templateValue = peopleTemplateName
        }
        this.TempOptions = result.list
        this.form = Object.assign({ templateValue, isRiskNotification: 0 }, resform)
        if (this.form.isThirdInvoice == 1 || this.form.isThirdInvoice == 2) {
          await this.getTaxByHmcode(this.form.hmCode, this.form.mainstayId)
          this.getHmMerchantTaxList()
        }
      }
      this.getMerchantTariffingList()
      // this.getMerchantAccount()
      this.getTaxItemsLevelList()
      this.$nextTick(() => {
        this.$refs.vForm.clearValidate()
      })
    },

    // 获取阶梯税率信息
    async getMerchantTariffingList() {
      const res = await this.$api.GetMerchantTariffingList({
        merchantId: this.id
      })
      this.merchantTariffingList = res.list
    },
    // 获取充值帐户信息
    async getMerchantAccount() {
      const res = await this.$api.GetMerchantAccount({ merchantId: this.id })
      this.accountList = res.list
    },
    // 获取税目信息
    async getMerchantTaxList() {
      const res = await this.$api.GetMerchantTaxList({ merchantId: this.id })
      // 节点转换为tags显示用
      this.checkTaxItemsId = res.list
      const parentsId = new Set()
      const merchantTaxItemList = res.list.map((item) => {
        const findIt = this.taxItemsFlat.find((it) => {
          return it.value == item
        })
        if (findIt) {
          parentsId.add(findIt.parent_value)
          return findIt
        }
      })
      // 父
      const merchantTaxList = this.taxItemsLevelList
        .filter((item) => {
          return parentsId.has(item.value)
        })
        .map((item) => {
          return Object.assign({}, item, { children: [] })
        })
      // 子塞入父
      merchantTaxItemList.map((item) => {
        if (!item || !item.parent_value) return
        const me = merchantTaxList.find((it) => {
          return it.value == item.parent_value
        })
        if (me) me.children.push(item)
      })
      this.merchantTaxList = merchantTaxList
    },
    //获取盒马税目信息
    async getHmMerchantTaxList() {
      const res = await this.$api.GetMerchantTaxList({ merchantId: this.id })
      this.checkHmTaxItemsId = res.list
      let v = this.hmTaxItemsLevelList.map((item) => {
        const findIt = this.checkHmTaxItemsId.find((it) => {
          return it == item.childrenId
        })
        if (findIt) {
          return item
        }
      })
      this.checkHmTaxItemsLevelList = v.filter((item) => {
        return item != undefined
      })
      this.checkHmTaxItemsId = this.checkHmTaxItemsLevelList.map((item) => {
        return item.childrenId
      })
    },
    // 获取全部税目列表
    async getTaxItemsLevelList() {
      const res = await this.$api.GetTaxItemsLevelList()
      this.taxItemsLevelList = res.list.map((item) => {
        if (item.children)
          item.children = item.children.map((it) => {
            it.parent_value = item.value
            return it
          })
        return item
      })
      this.taxItemsFlat = res.list.flatMap((item) => [item, ...item.children])
      this.getMerchantTaxList()
    },
    // 获取当前阶梯前置发放金额
    issueAmountBefore(idx) {
      if (idx == 0) return 0
      return this.merchantTariffingList[idx - 1].issueAmount
    },
    // 添加阶梯税率信息
    addTariffing() {
      this.merchantTariffingList.push({
        serviceTariffing: 0,
        costServiceTariffing: 0
      })
    },
    // 删除阶梯税率信息
    deleteTariffing(item, idx) {
      if (item.id) this.deledTariffingIds.push(item.id)
      this.merchantTariffingList.splice(idx, 1)
    },
    // 校验阶梯税率列表
    validateFieldTariffing() {
      this.$refs.vForm.validateField()
    },
    // 删除充值账户
    deleteAccount(idx) {
      this.accountList.splice(idx, 1)
    },
    // 充值账户编辑返回事件
    accountSave(response, idx) {
      if (idx === null) {
        // 新建
        this.accountList.push(response)
      } else {
        // 编辑
        this.$set(this.accountList, idx, response)
      }
    },
    getTaxTree() {
      const allList = this.$refs.taxAllTree.getCheckedNodes(false, true)
      // 父
      const merchantTaxList = allList
        .filter((item) => {
          return 'children' in item
        })
        .map((item) => {
          return Object.assign({}, item, { children: [] })
        })
      // 子塞入父
      allList.map((item) => {
        if (!item.parent_value) return
        const me = merchantTaxList.find((it) => {
          return it.value == item.parent_value
        })
        if (me) me.children.push(item)
      })
      this.merchantTaxList = merchantTaxList
      this.checkTaxItemsId = this.$refs.taxAllTree.getCheckedKeys(true)
      this.showTaxPopver = false
    },
    // 获取盒马树选中节点
    getCheckChange(item, obj) {
      this.chooseHm = obj
    },
    getHmTree() {
      this.checkHmTaxItemsLevelList = this.chooseHm.checkedNodes.filter((item) => {
        return 'children' in item
      })
      this.checkHmTaxItemsId = this.checkHmTaxItemsLevelList.map((item) => {
        return item.childrenId
      })
      this.showPopver = false
    },
    // contractMode改变
    changeContractMode(val) {
      this.$set(this.form, 'templateValue', undefined)
      this.defultSelect = {}
    },
    // 更新选中项
    changeValue(val) {
      if (!val) return (this.defultSelect = {})
      const idx = this.form.contractMode == 30 ? 1 : 0
      let data = this.TempOptions[idx].list
        .map((item) => {
          if (item.contractTemplateId == val) {
            return {
              peopleTemplateCode: item.contractTemplateCode,
              peopleTemplateId: item.contractTemplateId,
              peopleTemplateName: item.contractTemplateName
            }
          }
        })
        .filter((f) => f)
      this.defultSelect = data[0]
    },
    // 提交
    async submit() {
      this.$refs.vForm.validate(async (valid) => {
        if (valid) {
          // 附加判断
          // 阶梯费率的判断
          if (this.form.isPiecewise == 1) {
            if (!this.merchantTariffingList.length) return this.$message.error(`请至少输入1项阶梯费率`)
            for (let idx in this.merchantTariffingList) {
              const index = parseInt(idx)
              const item = this.merchantTariffingList[index]
              // 判断空值
              if (isEmpty(item.issueAmount) || isEmpty(item.serviceTariffing) || isEmpty(item.costServiceTariffing)) {
                return this.$message.error(`请将第${index + 1}项阶梯费率输入完整`)
              }
              // 判断阶梯
              if (index != 0 && item.issueAmount <= this.merchantTariffingList[index - 1].issueAmount) {
                return this.$message.error(`第${index + 1}项阶梯费率的发放金额不能小于上一项发放金额`)
              }
            }
          }
          // 判断税目
          if ((!this.checkTaxItemsId || !this.checkTaxItemsId.length) && !this.form.isThirdInvoice) return (this.taxError = '请选择可开票税目')
          if ((!this.checkHmTaxItemsId || !this.checkHmTaxItemsId.length) && this.form.isThirdInvoice) return (this.hmTaxError = '请选择可开票税目')
          // ==================
          // 通用常量
          const { merchantId, merchantName, memberCode } = this.detail
          this.btnloadSubmit = true
          // 处理阶梯税率
          const saveMerchantTariffing = {
            merchantTariffingList: this.merchantTariffingList.map((item) => {
              return Object.assign({ merchantId, merchantName, memberCode }, item)
            }),
            ids: this.deledTariffingIds,
            merchantId
          }
          // 处理充值帐户
          const saveMerchantAccount = {
            // merchantId,
            // merchantAccountList: this.accountList
          }
          // 处理税目
          const saveMerchantTax = {
            merchantId,
            merchantTaxList: this.form.isThirdInvoice
              ? this.checkHmTaxItemsId.map((item) => {
                  return { taxId: item }
                })
              : this.checkTaxItemsId.map((item) => {
                  return { taxId: item }
                })
          }
          const params = Object.assign({}, this.form, this.defultSelect, {
            saveMerchantTariffing,
            saveMerchantAccount,
            saveMerchantTax
          })
          params.verifyAlipay = params.verifyAlipay.join('|')
          params.verifyPeople = params.verifyPeople.join('|')
          params.verifyBank = params.verifyBank.join('|')
          const res = await this.$api.SaveMerchantConfig(params).finally(() => {
            this.btnloadSubmit = false
          })
          this.$message.success('配置成功')
          this.$emit('submit')
          this.show = false
        }
      })
    },
    //关闭开票服务
    changeHm() {
      // this.form.hmCode = 'lx1236cdb24b0692feb5'
      // this.form.hmSecretKey = 'bb3877e7c38e4c914644519891aef2b4'
      // this.form.hmPublicKey =
      //   'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBALCEHuvT8WOgDOGdC1DlRLlOVYDrBYuPFEy6L+XRprMkAFOLwBygDbbWzzZUfiMrRL1NtIxa8AbNHvTLOnxDt0sCAwEAAQ=='
      if (!this.form.isThirdInvoice) {
        this.form.hmCode = null
        this.form.hmSecretKey = null
        this.form.hmPublicKey = null
        this.form.mainstayId = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tax-box {
  width: 100%;
  padding: 5px 15px;
  height: auto;
  min-height: 120px;
}
.tax-pop {
  height: 500px;
  overflow: auto;
  .label {
    margin-right: 10px;
  }
  .label-num {
    // color: #236df4;
  }
}
</style>
