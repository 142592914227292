<template>
  <el-dialog title="查看" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
    <!-- 内容区域 -->
    <el-descriptions title="基本信息" border :column="2" :labelStyle="{ width: '120px', textAlign: 'right' }" :contentStyle="{ width: '34%' }">
      <el-descriptions-item label="创客姓名">{{ detail.userName || '--' }}<WpView v-if="detail.userName" :id="id" :type="2" /></el-descriptions-item>
      <el-descriptions-item label="手机号">{{ detail.mobile || '--' }}<WpView v-if="detail.mobile" :id="id" :type="2" /></el-descriptions-item>
      <el-descriptions-item label="证件号">{{ detail.idCard || '--' }}<WpView v-if="detail.idCard" :id="id" :type="2" /></el-descriptions-item>
      <el-descriptions-item label="所属商户">{{ detail.merchantName || '--' }}</el-descriptions-item>
      <el-descriptions-item label="添加方式">{{ detail.GenerationType | resultStr }}</el-descriptions-item>
      <el-descriptions-item label="添加时间">{{ detail.createTime || '--' }}</el-descriptions-item>
      <el-descriptions-item label="拉黑原因">{{ detail.blackReason || '--' }}</el-descriptions-item>
      <el-descriptions-item label="描述">{{ detail.remark || '--' }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      title="解除信息"
      border
      :column="2"
      :labelStyle="{ width: '120px', textAlign: 'right' }"
      :contentStyle="{ width: '34%' }"
      style="margin-top: 20px"
    >
      <el-descriptions-item label="身份证正面">
        <ImageViewer :path="detail.idFontPhoto"></ImageViewer>
      </el-descriptions-item>
      <el-descriptions-item label="身份证反面">
        <ImageViewer :path="detail.idBackPhoto"></ImageViewer>
      </el-descriptions-item>
      <el-descriptions-item label="证明文件">
        <ImageViewer :path="detail.attachPhoto"></ImageViewer>
      </el-descriptions-item>
      <el-descriptions-item label="备注">{{ detail.lostRemark || '无' }}</el-descriptions-item>
      <el-descriptions-item label="操作人"> {{ detail.lostPerson || '无' }} </el-descriptions-item>
      <el-descriptions-item label="失效时间"> {{ detail.lostTime || '无' }} </el-descriptions-item>
    </el-descriptions>
  </el-dialog>
</template>

<script>
import { formatStatus, kvOrganizationType, kvIsEntrust, kvIsAuthentication } from '@/configs/formatters'
import ImageViewer from '@/components/tableView/imageViewer'
import WpView from '@/components/wpView'
export default {
  components: { ImageViewer, WpView },
  data() {
    return {
      id: null,
      show: false,
      detail: {},
      kvOrganizationType,
      kvIsEntrust,
      kvIsAuthentication
    }
  },
  filters: {
    resultStr(val) {
      return statusStr.get(val)
    }
  },
  methods: {
    formatStatus,
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    async open(obj) {
      this.clear()
      if (obj && obj.merchantId) {
        // 编辑
        this.id = obj.id
        await this.getDetail()
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    },
    async getDetail() {
      const res = await this.$api.GetSystemBlackInfo({
        id: this.id
      })
      this.detail = res
      this.detail.attachPhoto = res.attachList[0]?.fileUrl
    },
    seeFileViewer() {}
  }
}

const statusStr = new Map([
  [0, '手动添加'],
  [1, '自动添加'],
  [null, '--'],
  [undefined, '--']
])
</script>
