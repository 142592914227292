<template>
  <el-dialog :title="title" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="1000px">
    <!-- 内容区域 -->
    <div class="flex-home" style="height: 500px" v-if="show">
      <TableView
        ref="vTable"
        :labels="tableLabels"
        apiName="GetSupplierInfoCodeName"
        :searchExt="{ mainId: id }"
        :noPage="true"
        :selection="true"
        @selection-change="selChange"
        :selConfig="{ selectable }"
      >
      </TableView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { tableSelectPark } from '@/configs/tables'
export default {
  data() {
    return {
      btnloadSubmit: false,
      tableLabels: tableSelectPark,
      show: false,
      id: null,
      title: '选择',
      overList: [],
      multipleSelection: []
    }
  },
  methods: {
    selChange(val) {
      this.multipleSelection = val
    },
    clear() {
      this.id = null
      this.overList = []
      this.multipleSelection = []
    },
    open(obj, list) {
      this.clear()
      if (obj && obj.id) {
        this.id = obj.id
        this.overList = list || []
        this.show = true
        // 编辑
      } else {
        this.$message.error('无效的信息')
      }
    },
    selectable(row, index) {
      const has = this.overList.find((item) => {
        return item.supplierId == row.supplierId
      })
      if (!has) return true
      return false
    },
    async submit() {
      if (!this.multipleSelection.length) return this.$message.warning('请先选择需要添加的税源地')
      this.btnloadSubmit = true
      const res = await this.$api.SaveMainSupplierInfo({
        mainId: this.id,
        SupplierIdList: this.multipleSelection.map((item) => {
          return item.supplierId
        })
      }).finally(() => {
        this.btnloadSubmit = false
      })
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
