<template>
  <el-dialog :title="(!idx ? '添加' : '编辑') + title" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="1000px">
    <FormView ref="vForm" :labels="fromLabels"> </FormView>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { formsMerchantAccount } from '@/configs/forms'
export default {
  name: 'AddAccount',
  data() {
    return {
      fromLabels: formsMerchantAccount,
      idx: null,
      show: false,
      title: '充值账户'
    }
  },
  methods: {
    open(obj = {}, idx = null) {
      this.idx = idx
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData(Object.assign(obj, { accountType: obj.accountType ? parseInt(obj.accountType) : null }))
      })
    },
    async submit() {
      const form = await this.$refs.vForm.getForm()
      this.$emit('submit', form, this.idx)
      this.show = false
    }
  }
}
</script>
