<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView @get="getOtherAmount" ref="vTable" :labels="tableLabels" apiName="GetInvoiceLogPageList" :searchList="searchList">
      <!-- <el-card slot="ext-table" shadow="never" style="margin-bottom: 18px; flex-shrink: 0">
        <el-descriptions :column="4" :colon="false" size="medium" style="margin-bottom: -10px">
          <el-descriptions-item label="已开票金额"><b v-format="'#,##0.00'">0</b> 元</el-descriptions-item>
          <el-descriptions-item label="开票中金额"><b v-format="'#,##0.00'">0</b> 元</el-descriptions-item>
        </el-descriptions>
      </el-card> -->
      <el-card slot="ext-table" style="margin-bottom: 18px">
        <div class="ext">
          <div v-for="(item, index) in cardItem" :key="index" class="ext-money">
            <div class="title-text">{{ item.label }}</div>
            <div>
              <span class="text" v-format="'#,##0.00'">{{ item.value }}</span>
            </div>
          </div>
        </div>
      </el-card>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="180" fixed="right">
        <template v-slot="{ row }">
          <el-button v-if="$hasAccess('001001')" type="text" @click="$refs.Detail.open(row)">查看</el-button>
          <el-button v-if="$hasAccess('001002') && row.billState == '开票中'" type="text" @click="$refs.Up.open(row)">上传发票</el-button>
          <el-button v-if="row.billState == '已开票' && row.distributionState == '未配送'" type="text" @click="$refs.Express.open(row)"
            >快递单号</el-button
          >
          <el-button v-if="row.billState == '开票失败'" type="text" @click="backTask(row.id)">退回</el-button>
        </template>
      </el-table-column>
    </TableView>
    <Detail ref="Detail" />
    <Up ref="Up" @close="$refs.vTable.refreshTable()" />
    <Express ref="Express" @submit="$refs.vTable.refreshTable()" />
  </div>
</template>

<script>
import { tableBillInfo } from '@/configs/tables'
import { searchBillInfo } from '@/configs/searches'
import Detail from './detail'
import Up from './up'
import Express from './express'
export default {
  components: { Detail, Up, Express },
  data() {
    return {
      tableLabels: tableBillInfo,
      searchList: searchBillInfo,
      cardItem: [
        { label: '开票中金额（元）', value: 0 },
        { label: '已开票金额（元）', value: 0 },
        { label: null, value: null }
      ]
    }
  },
  mounted() {
    this.GetSupplierList();
  },
  methods: {
    async getOtherAmount(params) {
      const res = await this.$api.GetInvoiceLogSummaryAmount(params)
      if (res) {
        res.list.map((item) => {
          switch (item.type) {
            case 10:
              this.cardItem[0].value = item.amount
              break
            case 20:
              this.cardItem[1].value = item.amount
              break
            default:
              break
          }
        })
      }
    },
    async GetSupplierList() {
      let supplierRes = await this.$api.GetSupplierListBySearch({ PageIndex: 1, PageSize: 999 })
      this.searchList[4].options = supplierRes.list.map((item) => {
        return {
          key: item.supplierId,
          label: item.supplierName
        }
      })
    },
    backTask(invoiceId) {
      this.$confirm('确定退回当前信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$api.ReturnInvoice({ invoiceId })
          this.$refs.vTable.refreshTable()
          this.$message({
            type: 'success',
            message: '退回成功'
          })
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消'
          // })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.ext {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  .title-text {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
  }
  .text {
    font-size: 26px;
    color: #333;
    font-weight: 600;
    margin-right: 10px;
  }
}
</style>
