<template>
  <el-dialog title="验证消息" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="600px">
    <el-form ref="vForm" :model="form" :rules="rules" :inline="true" @submit.native.prevent>
      <el-form-item prop="code" label="短信验证码： "><el-input v-model="form.code" placeholder="请输入验证码"></el-input></el-form-item>
      <el-form-item
        ><el-button type="primary" @click="sendVerifyCode" :disabled="vcCountdown != 0" style="width: 120px">{{
          vcCountdown != 0 ? vcCountdown + 's后再获取' : '获取验证码'
        }}</el-button></el-form-item
      >
    </el-form>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      detail: {},
      btnloadSubmit: false,
      phone: '',
      form: {},
      rules: {
        code: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }]
      }
    }
  },
  computed: {
    vcCountdown() {
      return this.$store.state.app.vcCountdown
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.form = {}
      this.btnloadSubmit = false
    },
    async open(obj) {
      this.clear()
      this.show = true
      this.form = {}
      
      this.detail = obj
    },
    // 获取验证吗
    async sendVerifyCode() {
      const res = await this.$api.SendVerifyCode({ verifyCodeType: 2 })
      this.$store.dispatch('app/vcDownStart', true)
    },
    submit() {
      this.$refs.vForm.validate(async (valid) => {
        if (valid) {
          this.btnloadSubmit = true
          const res = await this.$api.CheckVerifyCode({ VerifyCode: this.form.code, verifyCodeType: 2 }).finally(() => {
            this.btnloadSubmit = false
          })
          this.$store.commit('app/setWpVerify', this.form.code)
          this.smsShow = false
          if (res) {
            const ee = await this.$api.reauth({ id: this.detail.id })
            if (ee) {
              this.$message.success('该企业已重置为未认证状态，点击认证链接进行认证！')
              this.$emit('submit')
              this.show = false
            }
          }else{
            this.$message.error('验证失败')
          }
        }
      })
    },
    // // 提交
    // async submit() {
    //   // 结果更新
    //   const result = await this.$refs.vForm.validate()
    //   this.btnloadSubmit = true
    //   const params = await this.$refs.vForm.getForm()
    //   const res = await this.$api.AddBusinessMain(params).finally(() => {
    //     this.btnloadSubmit = false
    //   })
    //   if (res) {
    //     // this.btnloadSubmit = false
    //     this.$message.success('添加成功')
    //     this.$emit('submit')
    //     this.show = false
    //   }
    // }
  }
}
</script>
