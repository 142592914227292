<template>
  <div>
    <el-dialog title="查看" :visible.sync="show" :append-to-body="true" :before-close="handlerClose" width="800px">
      <div>
        <el-descriptions
          :contentStyle="{ width: '250px', textAlign: 'left' }"
          :labelStyle="{ width: '120px', textAlign: 'right' }"
          :column="2"
          border
          title="基本信息"
        >
          <el-descriptions-item label="商户编号">{{ detail.memberCode || '无' }}</el-descriptions-item>
          <el-descriptions-item label="商户名称">{{ detail.merchantName || '无' }} </el-descriptions-item>
          <el-descriptions-item label="企业主体">{{ detail.mainName || '无' }} </el-descriptions-item>
          <el-descriptions-item label="当前月份">{{ detail.currMonth || '无' }}</el-descriptions-item>
          <el-descriptions-item label="税源地">{{ detail.supplierName || '无' }}</el-descriptions-item>
          <el-descriptions-item label="业务确认单："
            ><span @click="downLoadWd" class="result-text">系统生成业务确认单.doc</span></el-descriptions-item
          >
        </el-descriptions>
        <el-descriptions
          :column="2"
          border
          title="上传概况"
          :contentStyle="{ width: '250px', textAlign: 'left' }"
          :labelStyle="{ width: '120px', textAlign: 'right' }"
          style="margin-top: 18px"
        >
          <!-- <template slot="extra">
            <el-button
              v-if="detail.uploadState == 1 && $hasAccess('001703')"
              @click=";(showReason = true), (backType = 1)"
              type="primary"
              size="small"
              >退回</el-button
            >
          </template> -->
          <!-- <el-descriptions-item label="上传时间">{{ detail.upLoadTime || '无' }}</el-descriptions-item>
          <el-descriptions-item label="上传人">{{ detail.uploader || '无' }}</el-descriptions-item> -->
          <el-descriptions-item label="交付物状态">{{ formatStatus(kvAuditStatus, detail.uploadState) }}</el-descriptions-item>
          <el-descriptions-item label="审核状态">{{ formatStatus(kvSearchAuditStatus, detail.auditState) }}</el-descriptions-item>
          <el-descriptions-item label="退回理由">{{ detail.backReason || '无' }}</el-descriptions-item>
          <el-descriptions-item label="上传文件">
            <template v-if="detail.confirmReceiptAttachs && detail.confirmReceiptAttachs.length > 0">
              <div v-for="(item, index) in detail.confirmReceiptAttachs" :key="index">
                <ImageViewer v-if="item.attachUrl && item.type != 'pdf'" :path="item.attachUrl" :name="'查看文件' + (index + 1)" />
                <span @click="viewPdf(item.attachUrl)" class="otherStyle" v-if="item.attachUrl && item.type == 'pdf'">查看文件{{ index + 1 }}</span>
              </div>
            </template>
            <span v-else>无</span>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
    <el-dialog width="30%" title="理由" :visible.sync="showReason" :append-to-body="true" :close-on-click-modal="false">
      <div>
        <el-input type="textarea" :rows="4" placeholder="请输入退回理由，不超过200字" v-model="reason"> </el-input>
      </div>
      <div slot="footer">
        <el-button @click="showReason = !showReason">取消</el-button>
        <el-button @click="reBack" type="primary">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formatStatus, kvAuditStatus, kvSearchAuditStatus } from '@/configs/formatters'
import ImageViewer from '@/components/tableView/imageViewer'
import WpView from '@/components/wpView'
import { downloadUrl, downloadDocx } from '@/utils'
export default {
  components: { ImageViewer, WpView },
  data() {
    return {
      show: false,
      showReason: false,
      id: null,
      detail: {
        attachList: []
      },
      reason: '',
      kvAuditStatus,
      kvSearchAuditStatus,
      isEdit: false,
      backType: 0,
      id: ''
    }
  },
  methods: {
    formatStatus,
    handlerClose() {
      this.show = false
      this.$emit('refresh')
    },
    viewPdf(e) {
      downloadUrl(e)
    },
    async open(obj) {
      this.reason = null
      this.id = obj.id
      const result = await this.$api.GetConfirmReceipts({ id: obj.id })
      result.confirmReceiptAttachs.map((item) => {
        let i = item.attachUrl.lastIndexOf('.')
        item.type = item.attachUrl.substr(i + 1)
      })
      this.detail = Object.assign({}, result)
      this.show = true
    },
    async downLoadWd() {
      await this.$api.ConfirmReceiptsTemplateCheck({ confirmReceiptsId: this.id })
      const result = await this.$api.DownloadConfirmReceiptsFile({ confirmReceiptsId: this.id })
      downloadDocx(result, '业务确认单模板')
    },
    async reBack() {
      if (this.reason == '' || !this.reason) {
        this.$message.error('请输入退回原因')
        return false
      }
      let res = await this.$api.ReturnConfirmReceipts({ id: this.id, BackReason: this.reason })
      if (res) {
        this.$message.success('退回成功')
        this.showReason = false
        this.open({ id: this.id })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.otherStyle {
  color: #3d78fa;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}
.result-text {
  color: #409eff;
  cursor: pointer;
}
</style>
