<template>
  <el-dialog title="快递单号" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="600px">
    <div v-if="show">
      <FormView ref="vForm" :labels="fromLabels"> </FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      fromLabels: [
        {
          cmp: 'select',
          label: '快递名称',
          prop: 'expressName',
          options: [
            { key: '顺丰快递', label: '顺丰快递' },
            { key: '中通快递', label: '中通快递' },
            { key: '圆通快递', label: '圆通快递' },
            { key: '韵达快递', label: '韵达快递' },
            { key: '申通快递', label: '申通快递' },
            { key: '京东快递', label: '京东快递' },
            { key: '内部邮寄', label: '内部邮寄' }
          ],
          itemConfig: { rules: [{ required: true, message: '请选择快递', trigger: 'change' }] }
        },
        {
          cmp: 'input',
          label: '快递单号',
          prop: 'expressNo',
          show: `form.expressName!='内部邮寄'`,
          itemConfig: { rules: [{ required: true, message: '请输入快递单号', trigger: 'input' }] }
        },
        {
          cmp: 'upload',
          label: '快递附件',
          prop: 'filePath',
          show: `form.expressName!='内部邮寄'`
          // itemConfig: { rules: [{ required: true, message: '请上传快递附件', trigger: 'change' }] }
        }
      ]
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = {}
    },
    open(obj) {
      this.clear()
      this.id = obj.id
      this.show = true
    },
    getName(val) {
      const pos = val.lastIndexOf('\/')
      return val.substring(pos + 1)
    },
    async submit() {
      const form = await this.$refs.vForm.getForm()
      this.btnloadSubmit = true
      await this.$api
        .SaveInvoiceExpress(Object.assign({ invoiceId: this.id }, form, { fileName: form.filePath ? this.getName(form.filePath) : null }))
        .finally(() => {
          this.btnloadSubmit = false
        })
      this.$message.success('上传成功')
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
