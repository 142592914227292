<template>
  <el-dialog :visible.sync="show" title="批量提现">
    <el-divider content-position="left">提现金额</el-divider>
    <el-form ref="vForm" :model="form" :rules="rules" labelSuffix="：" labelWidth="150px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="总金额"
            ><span v-format="'#,##0.00'">{{ withdrawnSumAmount || 0 }}</span></el-form-item
          >
        </el-col>
        <el-col :span="12">
          <el-form-item label="第三方单号" prop="thirdNumber"><el-input v-model="form.thirdNumber"></el-input></el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-divider content-position="left">提现明细</el-divider>

    <div v-if="show">
      <TableView
        ref="vTable"
        :labels="tableLabels"
        tableMinHeight="300px"
        :tableConfig="{ height: '300px' }"
        apiName="GetFPWorkPlanSumByMerchant"
        resChildName="fpWorkPlanSumByMerchants"
        :searchExt="{ withrawnKeyId: this.id }"
        @geted="getSumAmount"
      ></TableView>
    </div>

    <div slot="footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
const tableLabels = [
  { label: '商户号', prop: 'memberCode' },
  { label: '商户名称', prop: 'merchantName' },
  { label: '金额', prop: 'withdrawnAmount', cmp: 'money' }
]
export default {
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      tableLabels,
      withdrawnSumAmount: null,
      form: {},
      rules: {
        thirdNumber: [{ required: true, message: '请输入第三方单号', trigger: 'blur' }]
      }
    }
  },
  methods: {
    clear() {
      this.withdrawnSumAmount = null
      this.form = {}
    },
    open(id) {
      this.clear()
      if (id) {
        this.id = id
        this.show = true
        this.$nextTick(() => {
          this.$refs.vForm.clearValidate()
        })
      } else {
        this.$message.error('无效的信息')
      }
    },
    getSumAmount(res) {
      this.withdrawnSumAmount = res.withdrawnSumAmount
    },
    submit() {
      this.$refs.vForm.validate(async (valid) => {
        if (valid) {
          this.btnloadSubmit = true
          const res = await this.$api.SaveFPWorkPlanSumByMerchant({ withrawnKeyId: this.id, ...this.form }).finally(() => {
            this.btnloadSubmit = false
          })
          this.$message.success('配置成功')
          this.$emit('submit')
          this.show = false
        }
      })
    }
  }
}
</script>
