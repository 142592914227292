<template>
  <el-dialog :title="(!id ? '新增' : '编辑') + '角色'" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="500px">
    <!-- 内容区域 -->
    <FormView ref="vForm" :labels="fromLabels"></FormView>
    <div class="dialog-wrap" style="height: 220px" v-if="show">
      <el-tree ref="vTree" :props="treeProps" :data="tree" show-checkbox node-key="funCode"> </el-tree>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { formRole } from '@/configs/forms'
export default {
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      fromLabels: formRole,
      detail: {},
      treeProps: {
        label(data, node) {
          return data.funName || data.menuName
        },
        children: 'lists'
      },
      tree: []
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
      this.tree = []
    },
    open(obj) {
      this.clear()
      this.getList()
      if (obj && obj.id) {
        // 编辑
        const { id, roleName, roleDesc, isEenable } = obj
        this.id = obj.id
        this.detail = { roleId: id, roleName, roleDesc, isEenable }
        this.show = true
        this.getMeList()
      } else {
        // 添加
        this.show = true
      }
      this.$nextTick(() => {
        this.$refs.vForm.fillData(this.detail)
      })
    },
    async getList() {
      const res = await this.$api.GetMerchantMenuRoleFunList()
      const list = res.list || []
      // 处理树结构
      this.tree = this.handlerList(list)
    },
    handlerList(list = []) {
      return list.map((item) => {
        if (!item.funCode) item.funCode = item.menuCode
        if (item.lists && item.lists.length) {
          item.lists = this.handlerList(item.lists)
        }
        return item
      })
    },
    async getMeList() {
      const res = await this.$api.GetRoleMenuFunList({ roleId: this.id })
      const cheked = (res.list || []).map((item) => {
        // funCode和menuCode相同时，判断是否为子节点
        if (item.funCode == item.menuCode) {
          if (
            res.list.find((it) => {
              return it.menuCode == item.menuCode && it.funCode != it.menuCode
            })
          )
            return ''
        }
        if (item.funCode) return item.funCode
        return ''
      })
      this.$refs.vTree.setCheckedKeys(cheked)
    },
    // 提交
    async submit() {
      // 结果更新
      const params = await this.$refs.vForm.getForm()

      this.btnloadSubmit = true
      // 获取并处理树结构数据
      const treeList = this.$refs.vTree.getCheckedNodes(true)
      const systemRoleMenuFunEntities = treeList.map((item) => {
        const { funCode, menuCode } = item
        return {
          roleId: this.id || null,
          funCode,
          menuCode
        }
      })

      if (this.id) {
        // 编辑
        const res = await this.$api.EditMerchantSystemRole(Object.assign({ systemRoleMenuFunEntities }, params)).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('编辑成功')
      } else {
        // 添加
        const res = await this.$api.AddMerchantSystemRole(Object.assign({ systemRoleMenuFunEntities }, params)).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('添加成功')
      }
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
