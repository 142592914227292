<template>
  <el-dialog class="diaStyle" title="提成计算器" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="600px">
    <!-- 内容区域 -->
    <FormView  ref="vForm" :labels="formLabels" :formConfig="{ labelWidth: '150px' }">
      <!-- 下载模板 -->
      <template v-slot:downBtn>
        <div class="downBtn"><el-button size="medium" icon="el-icon-download" type="text" @click="downLoadXls">匹配商务关系模板</el-button></div>
        <!-- <el-col>
            <el-form-item label="模板">
             
            </el-form-item>
          </el-col> -->
        </template>
    </FormView>
    <span slot="footer">
      <el-button :loading="btnloadDownload" type="primary" @click="submit">导出结算数据</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { downloadExcel } from '@/utils'
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      loading: false,
      loadDownload: false,
      btnloadDownload: false,
      formLabels: [
        {
          cmp: 'upload',
          label: '匹配商务关系',
          prop: 'filePath',
          itemConfig: {
            rules: [{ required: true, message: '请导入文件', trigger: 'change' }]
          },
          suffixArray: ['xlsx'],
          size: 20
        },
        {
          cmp: 'dpicker',
          label: '导出月份',
          prop: 'month',
          config: { type: 'month', valueFormat: 'yyyyMM', style: { width: '300px' } },
          itemConfig: {
            rules: [{ required: true, message: '选择导出月份', trigger: 'blur' }]
          }
        },
        {
          cmp: 'slot',
          name: 'downBtn'
        }
      ],
      result: {}
    }
  },
  methods: {
    // 数据清空
    clear() {},
    open() {
      this.clear()
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData({})
      })
    },
    // 提交
    async submit() {
      const result = await this.$refs.vForm.validate()
      if (result) {
        const params = await this.$refs.vForm.getForm()
        console.log(params);
        this.btnloadDownload = true
        const res = await this.$api.ExportProfitShareCalculatorData(params).finally(() => {
          this.btnloadDownload = false
        })
        if (res.type == 'application/json') {
          // 将blob文件流转换成json
          const reader = new FileReader()
          reader.onload = (event) => {
            const error = JSON.parse(reader.result)
            this.$message.error(error.message || '导出错误')
          }
          reader.readAsText(res)
        } else {
          downloadExcel(res, `结算数据${params.month}`)
        }
      }
    },
    // 下载
    async downLoadXls(row) {
      if (this.loadDownload) {
        this.$message.warning('正在下载，请耐心等待')
        return
      }
      this.loadDownload = true
      const res = await this.$api.DownloadProfitShareTemplate().finally(() => {
        this.loadDownload = false
      })
      downloadExcel(res,'匹配商务关系模板')
    }
  }
}
</script>
<style lang="scss" scoped>
.diaStyle {
  // position: relative;
  .downBtn {
    position: absolute;
    top: -73px;
    right: 52px;
  }
}
</style>
