<template>
  <el-dialog title="查看" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="50%">
    <!-- 内容区域 -->

    <el-descriptions :column="2" size="medium" :colon="false" :labelStyle="{ flexShrink: 0 }" class="descriptions-all">
      <el-descriptions-item label="企业名称:">{{ detail.companyName }}</el-descriptions-item>
      <el-descriptions-item label="纳税人识别号:">{{ detail.taxIdentificationNumber }}</el-descriptions-item>
      <el-descriptions-item label="发票类型:">{{ taxStatus[detail.billType] }}</el-descriptions-item>
      <el-descriptions-item label=""></el-descriptions-item>
      <el-descriptions-item label="开票税目:">{{ detail.taxItemsName }}</el-descriptions-item>
      <el-descriptions-item label=""></el-descriptions-item>
      <el-descriptions-item label="开票金额（元）:">
        <span v-format="'#,##0.00'">{{ detail.invoiceAmount }}</span>
      </el-descriptions-item>
      <el-descriptions-item label=""></el-descriptions-item>
      <el-descriptions-item label="税源地:">{{ detail.supplierName }}</el-descriptions-item>
      <el-descriptions-item label=""></el-descriptions-item>
      <el-descriptions-item label="备注:">{{ detail.remarks }}</el-descriptions-item>
      <el-descriptions-item label=""></el-descriptions-item>
      <el-descriptions-item label="附件:">
        <ImageViewer v-if="detail.filePath" :path="detail.filePath" :name="detail.name" />
      </el-descriptions-item>
      <el-descriptions-item label=""></el-descriptions-item>
      <el-descriptions-item label="完结状态:">{{ endStatus[detail.billingState] }}</el-descriptions-item>
      <el-descriptions-item label=""></el-descriptions-item>
    </el-descriptions>
  </el-dialog>
</template>

<script>
import ImageViewer from '@/components/tableView/imageViewer'
export default {
  components: { ImageViewer },
  data() {
    return {
      id: null,
      show: false,
      detail: {},
      taxStatus: {
        1: '增值税专用发票',
        2: '增值税普通发票',
        3: '增值税专用电子发票',
        4: '增值税普通电子发票'
      },
      endStatus: {
        0: '未完结',
        1: '已完结'
      }
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    async open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 查看
        this.id = obj.id
        const res = await this.$api.getInvoiceRegisterInfo({ id: this.id })
        this.detail = res
        this.detail.filePath = res.billFileList[0]?.filePath
        this.detail.name = res.billFileList[0]?.fileName
        this.detail.taxItemsName = obj.taxItemsName
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.descriptions-all {
  margin: 0 20px;
}
</style>
