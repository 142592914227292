var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":"税源地税目调整确认","visible":_vm.show,"append-to-body":true,"close-on-click-modal":false,"width":"800px"},on:{"update:visible":function($event){_vm.show=$event}}},[_c('div',[_c('p',[_vm._v("对比系统与表格中税源地税目，将进行以下调整，请确认")]),_c('el-table',{attrs:{"data":_vm.list,"height":"calc(70vh - 260px)"}},[_c('el-table-column',{attrs:{"label":"税源地"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_vm._v(" "+_vm._s(row.supplierName)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"税目"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_vm._v(" "+_vm._s(row.taxNameStr)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"原状态","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_vm._v(" "+_vm._s(row.beforeState)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"改后状态","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_vm._v(" "+_vm._s(row.afterState)+" ")]}}])})],1)],1),_c('span',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.show = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.btnloadSubmit},on:{"click":_vm.submit}},[_vm._v("确 定")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }