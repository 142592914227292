<template>
  <el-dialog title="修改密码" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="500px">
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 220px">
      <FormView ref="vForm" :labels="fromLabels" :formConfig="{ labelWidth: '120px' }" />
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { formUpdateOperationUserPwd } from '@/configs/forms'
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      fromLabels: formUpdateOperationUserPwd
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.btnloadSubmit = false
    },
    open(obj) {
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData({})
      })
    },
    // 提交
    async submit() {
      // 结果更新
      const { newPwd, newPwd2, wornPwd } = await this.$refs.vForm.getForm()
      if (newPwd != newPwd2) return this.$message.error('确认新密码和新密码不一致，请重新输入')
      this.btnloadSubmit = true
      const res = this.$api.UpdateOperationUserPwd({ newPwd, wornPwd }).finally(() => {
        this.btnloadSubmit = false
      })
      this.$message.success('密码修改成功')
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
