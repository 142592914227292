<template>
  <el-dialog title="补发" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="1000px">
    <!-- 内容区域 -->
    <div v-if="show" class="dialog-wrap">
      <FormView ref="vForm" :labels="fromLabels" :formConfig="{ labelWidth: '200px' }"> </FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
const fromLabels = [
  {
    cmp: 'txt',
    label: '订单号',
    prop: 'customerOrderNo'
  },
  {
    cmp: 'txt',
    label: '姓名',
    prop: 'userName'
  },
  {
    cmp: 'txt',
    label: '身份证号',
    prop: 'idCard'
  },
  {
    cmp: 'input',
    label: '银行卡号',
    prop: 'payeeAccountNumber',
    itemConfig: {
      rules: [{ required: true, message: '请输入银行卡号', trigger: 'blur' }]
    },
    config: { maxlength: 50 }
  },
  {
    cmp: 'mountFee',
    label: '税后金额',
    prop: 'afterTaxAmount'
  },
  {
    cmp: 'select',
    label: '补发方式',
    prop: 'repPayMode',
    options: [{ key: 'Y', label: '超网补发' }],
    itemConfig: {
      rules: [{ required: true, message: '请选择补发方式', trigger: 'change' }]
    }
  },
  {
    cmp: 'input',
    label: '银行名称',
    prop: 'bankName',
    config: { maxlength: 20 }
  },
  {
    cmp: 'input',
    label: '开户省',
    prop: 'bankAddressProvince',
    config: { maxlength: 10 }
  },
  {
    cmp: 'input',
    label: '开户市',
    prop: 'bankAddressCity',
    config: { maxlength: 10 }
  }
]
export default {
  data() {
    return {
      id: null,
      show: false,
      btnloadSubmit: false,
      fromLabels
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.detail = {}
    },
    open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 编辑
        this.id = obj.id
        const { customerOrderNo, userName, idCard, afterTaxAmount, accountNumber } = obj
        this.show = true
        this.$nextTick(() => {
          this.$refs.vForm.fillData({ customerOrderNo, userName, idCard, afterTaxAmount, payeeAccountNumber: accountNumber, repPayMode: 'Y' })
        })
      } else {
        this.$message.error('无效的信息')
      }
    },
    async submit() {
      const form = await this.$refs.vForm.getForm()
      this.btnloadSubmit = true
      const { customerOrderNo, userName, idCard, afterTaxAmount, ...params } = form
      const res = await this.$api.WorkDetailRepaySubmit({ workDetailId: this.id, ...params }).finally(() => {
        this.btnloadSubmit = false
      })
      this.$message.success('补发成功')
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
