<template>
  <el-dialog :title="auditState ? '审核' : '添加'" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" top="8vh" width="1200px">
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 450px">
      <FormView ref="vForm" :labels="fromLabels" :formConfig="{ labelWidth: '200px' }"></FormView>
    </div>
    <div slot="footer" v-if="auditState" style="display: flex; justify-content: space-evenly; padding: 10px 0">
      <el-button @click="failed" :loading="btnloadSubmit">审核不通过</el-button>
      <el-button type="primary" @click="approved" :loading="btnloadSubmit">审核通过</el-button>
    </div>
    <span slot="footer" v-else>
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { formPeolple } from '@/configs/forms'
import { mapState } from 'vuex'
export default {
  props: {},
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      auditState: false,
      fromLabels: formPeolple,
      form: {}
    }
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId
    })
  },
  mounted() {},
  methods: {
    // 数据清空
    clear() {
      this.form = {}
      this.auditState = false
    },
    async open(obj) {
      this.clear()
      if (obj && obj.id) this.getDetail(obj.id)
      this.getMerchantOptions()
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData(this.form)
      })
    },

    // label字段添加
    async getMerchantOptions() {
      let v = []
      let result = await this.$api.GetMerchantInfoCodeAndName()
      if (result.list.length < 0) {
        return false
      }
      result.list.map((item) => {
        v.push({
          key: item.merchantId,
          label: `【${item.memberCode}】${item.merchantName}`
        })
      })
      this.fromLabels.forEach((item) => {
        if (item.prop === 'mermchantId') {
          item.options = v
        }
      })
    },

    // 获取详情
    async getDetail(id) {
      const res = await this.$api.GetSystemWhiteInfo({ id })
      this.form = Object.assign({ mermchantId: res.merchantId }, res)
      this.auditState = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData(this.form)
      })
    },
    // 审核通过
    approved() {
      this.btnloadSubmit = true
      const { id } = this.form
      const userId = this.userId
      this.$api
        .AuditSystemWhite({ id, userId, auditState: 2 })
        .then((res) => {
          this.show = false
          this.$message.success('操作成功')
          this.$emit('submit')
        }).catch(err => {
          if(err.message.indexOf('非待审核')>=0) {
            this.show = false
            this.$emit('submit')
          }
        }).finally(() => {
          this.btnloadSubmit = false
        })
    },
    // 审核不通过
    failed() {
      this.$prompt('', '理由', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入审核不通过理由，不超过200字',
        inputType: 'textarea',
        inputErrorMessage: '请输入审核未通过的理由且不高于200字',

        inputValidator: (value) => {
          if (value.length != 0 && value.length <= 200) {
            return true
          }
          return false
        }
      })
        .then(({ value }) => {
          this.btnloadSubmit = true
          const { id } = this.form
          const userId = this.userId
          this.$api
            .AuditSystemWhite({ id, userId, auditState: 3, refuse: value })
            .then((res) => {
              this.show = false
              this.$message.success('操作成功')
              this.$emit('submit')
            }).catch(err => {
              if(err.message.indexOf('非待审核')>=0) {
                this.show = false
                this.$emit('submit')
              }
            }).finally(() => {
              this.btnloadSubmit = false
            })
        })
        .catch(() => {})
    },

    // 提交
    async submit() {
      // 结果更新
      const result = await this.$refs.vForm.validate()
      if (result) {
        this.form = await this.$refs.vForm.getForm()
        this.btnloadSubmit = true
        const userId = this.userId
        let res = await this.$api.AddSystemWhite(Object.assign({ userId }, this.form)).finally(() => {
          this.btnloadSubmit = false
        })
        if (res) {
          this.$message.success('添加成功')
          this.$emit('submit')
          this.show = false
        }
      }
    }
  }
}
</script>
