/* eslint-disable space-before-function-paren */
let countdownTimer
export default {
  namespaced: true,
  state: {
    sideFlag: false,
    crumb: '',
    vcCountdown: 0,
    wpVerifyCode: ''
  },
  mutations: {
    showSide(state) {
      state.sideFlag = true
    },
    hideSide(state) {
      state.sideFlag = false
    },
    setCrumb(state, t) {
      state.crumb = t
    },
    // 倒计时
    setVcCountdown(state, refresh) {
      if (refresh) {
        state.vcCountdown = 60
      } else {
        state.vcCountdown = state.vcCountdown - 1
      }
      if (state.vcCountdown == 0) {
        clearInterval(countdownTimer)
      }
    },
    setWpVerify(state, code) {
      if (code) {
        state.wpVerifyCode = code
      } else {
        state.wpVerifyCode = ''
      }
    }
  },
  actions: {
    // 倒计时开始
    vcDownStart: ({ commit }) => {
      commit('setVcCountdown', true)
      countdownTimer = setInterval(() => {
        commit('setVcCountdown')
      }, 1000)
    }
  }
}
