<template>
  <div>
    <!-- 文本 -->
    <div class="cardItem" v-if="isType('text')">
      <el-card shadow="never">
        <el-form ref="ruleForm" class="form" label-position="left">
          <el-form-item :label="prop.parameterName" label-width="180px">
            <el-input :type="prop.parameterName == '敏感词' ? 'textarea' : ''" v-model="formValue" style="width: 400px"></el-input>
            <slot name="suffix" :data="prop.parameterCode"></slot>
          </el-form-item>
        </el-form>
        <div style="margin: 18px 0">
          <el-button v-if="$hasAccess('002200')" type="primary" @click="submit(prop.parameterCode)">保存</el-button>
        </div>
      </el-card>
    </div>
    <!-- 数字 -->
    <div class="cardItem" v-if="isType('num')">
      <el-card shadow="never">
        <el-form ref="ruleForm" class="form" label-position="left">
          <el-form-item
            :rules="[{ required: true, message: `请输入${prop.parameterName}`, trigger: 'blur' }]"
            :label="prop.parameterName"
            label-width="200px"
          >
            <el-input-number :min="0" :max="99999999.99" v-model="formValue" :controls="false"></el-input-number>
            <slot name="suffix" :data="prop.parameterCode"></slot>
          </el-form-item>
        </el-form>
        <div style="margin: 18px 0">
          <el-button v-if="$hasAccess('002200')" type="primary" @click="submit(prop.parameterCode)">保存</el-button>
        </div>
      </el-card>
    </div>
    <!-- 单选 -->
    <div class="cardItem" v-if="isType('radio')">
      <el-card shadow="never">
        <el-form class="form" label-position="left">
          <el-form-item :label="prop.parameterName" label-width="120px">
            <el-switch v-model="formValue" :width="50"></el-switch>
          </el-form-item>
        </el-form>
        <div style="margin: 18px 0">
          <el-button v-if="$hasAccess('002200')" type="primary" @click="submit(prop.parameterCode)">保存</el-button>
        </div>
      </el-card>
    </div>
    <!-- 列表(纯数字) -->
    <div class="cardItem" v-if="isType('list')">
      <el-card shadow="never">
        <el-form class="form" label-position="left">
          <el-form-item :label="prop.parameterName" label-width="120px">
            <el-input-number
              :min="1"
              :max="99999999"
              @change="onInput($event, 1)"
              :precision="0"
              v-model="formValue[0]"
              :controls="false"
            ></el-input-number>
            -
            <el-input-number
              :min="1"
              :max="99999999"
              @change="onInput($event, 2)"
              :precision="0"
              v-model="formValue[1]"
              :controls="false"
            ></el-input-number>
            <slot name="suffix" :data="prop.parameterCode"></slot>
          </el-form-item>
        </el-form>
        <div style="margin: 18px 0">
          <el-button v-if="$hasAccess('002200')" type="primary" @click="submit(prop.parameterCode)">保存</el-button>
        </div>
      </el-card>
    </div>
    <!-- 多选 -->
    <div class="cardItem" v-if="isType('multiple')">
      <el-card shadow="never">
        <el-form class="form" label-position="left">
          <el-form-item :label="prop.parameterName" label-width="150px">
            <el-checkbox-group v-model="formValue">
              <el-checkbox v-for="item in prop.parameterItems" :label="item" :key="item">{{ item }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <div style="margin: 18px 0">
          <el-button v-if="$hasAccess('002200')" type="primary" @click="submit(prop.parameterCode)">保存</el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
export default {
  // 组件名称
  name: 'systemItem',
  props: {
    type: String,
    prop: Object
  },
  // 组件状态值
  data() {
    return {}
  },
  // 计算属性
  computed: {
    // 展示对应组件
    isType() {
      return (val) => {
        return this.type == val
      }
    },
    // 同步对应数据
    formValue: {
      get() {
        switch (this.prop.parameterType) {
          case 'text': {
            return this.prop.parameterValue
          }
          case 'list': {
            return JSON.parse(this.prop.parameterValue)
          }
          case 'radio': {
            return this.prop.parameterValue == 0 ? false : true
          }
          case 'num': {
            return Number(this.prop.parameterValue)
          }
          case 'multiple': {
            return this.prop.parameterValue
          }
        }
      },
      set(val) {
        this.$emit('updateValue', { code: this.prop.parameterCode, value: val })
      }
    }
  },
  // 组件方法
  methods: {
    submit(code) {
      this.$emit('submit', { code })
    },
    // 数组数据更新
    onInput(val, idx) {
      this.$emit('updateValue', { code: this.prop.parameterCode, value: val, idx })
    }
  }
}
</script>

<style lang="scss" scoped>
.cardItem {
  margin-bottom: 10px;
}
</style>
