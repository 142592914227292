<template>
  <el-dialog :title="(!id ? '新增' : '编辑') + title" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false">
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 550px">
      <FormView ref="vForm" :formConfig="{ labelWidth: '140px' }" :labels="fromLabels"> </FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit(false)" :loading="btnloadSubmit">保存草稿</el-button>
      <el-button type="primary" @click="submit(true)" :loading="btnloadSubmit">发 布</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { formNotice } from '@/configs/forms'

export default {
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      title: '公告',
      fromLabels: formNotice,
      detail: {}
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    async open(obj) {
      this.clear()
      this.getMerchantOptions()
      this.id = null
      if (obj && obj.id) {
        // 编辑
        this.id = obj.id
        await this.getDetail()
        //
      } else {
        // 新建
      }
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData(this.detail)
      })
    },
    async getDetail() {
      const res = await this.$api.GetMessageById({ id: this.id })
      if (res.attach) {
        res.attachmentUrl = res.attach.attachmentUrl
      }
      if (res.issueLevel == 2 && res.merchantInfoDtos && res.merchantInfoDtos.length) {
        res.merchantList = res.merchantInfoDtos.map((item) => {
          return item.merchantId
        })
      }
      this.detail = res
    },
    async getMerchantOptions() {
      let v = []
      let result = await this.$api.GetMerchantInfoCodeAndName()
      if (result.list.length < 0) {
        return false
      }
      result.list.map((item) => {
        v.push({
          key: item.merchantId,
          label: `【${item.memberCode}】${item.merchantName}`
        })
      })
      this.fromLabels.map((item) => {
        if (item.prop === 'merchantList') {
          item.options = v
        }
      })
    },
    // 获取文件名称
    getName(val) {
      let pos = val.lastIndexOf('\/')
      return val.substring(pos + 1)
    },
    // 提交
    async submit(publish) {
      // 结果更新
      const params = await this.$refs.vForm.getForm()
      this.btnloadSubmit = true
      // 处理参数
      if (params.issueLevel) {
      } else {
        params.merchantList = null
      }
      if (params.issueMode == 1) {
        params.issueTime = null
      }
      if (params.attachmentUrl) {
        params.attachmentName = this.getName(params.attachmentUrl)
      }
      if (publish && params.issueMode == 1) {
        // 发布
        params.issueState = 2
      } else if (publish && params.issueMode == 2) {
        // 延时发布
        params.issueState = 1
      } else {
        params.issueState = 0
      }
      if (this.id) {
        // 编辑
        const res = await this.$api.UpdateMessage(params).finally(() => {
          this.btnloadSubmit = false
        })
      } else {
        // 添加
        const res = await this.$api.AddMessage(params).finally(() => {
          this.btnloadSubmit = false
        })
      }
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
