<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetConfirmReceiptsPageList" :searchList="searchList">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item">
        <el-button plain type="primary" icon="el-icon-download" @click="download" :loading="btnloadDownload">导出</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="150" fixed="right">
        <template v-slot="{ row }">
          <el-button v-if="$hasAccess('001704')" type="text" @click="$refs.Detail.open(row)">查看</el-button>
          <el-button v-if="$hasAccess('001704') && row.confirmState == 1" type="text" @click="$refs.Audit.open(row)">审核</el-button>
          <el-button v-if="$hasAccess('001702') && row.confirmState == 2" type="text" @click="downloadAchFile(row)">下载</el-button>
          <!-- <el-button
            v-if="$hasAccess('001705') && row.auditState == 1 && row.auditState != 2 && row.auditState != 3"
            type="text"
            @click="$refs.Audit.open(row)"
            >业务确认单审核</el-button
          > -->
          <!-- <el-button v-if="$hasAccess('001704')" type="text" @click="reCreat(row)">重新生成</el-button> -->
          <!-- <el-button v-if="$hasAccess('001701')" type="text" @click="previewAchFile(row)">预览</el-button>
          <el-button v-if="$hasAccess('001703 ')" type="text" @click="reBack(row)">退回</el-button> -->
        </template>
      </el-table-column>
    </TableView>
    <el-image-viewer
      v-if="showViewer"
      :zIndex="9999"
      :url-list="viewerlList"
      :on-close="
        () => {
          showViewer = false
        }
      "
    />
    <Detail @refresh="asyncRefreshTable" ref="Detail" />
    <Audit @refresh="asyncRefreshTable" ref="Audit" />
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { tableBusiness } from '@/configs/tables'
import { searchBusiness } from '@/configs/searches'
import { downloadUrl, downloadExcel } from '@/utils'
import Detail from './newDetail'
import Audit from './newAudit'
export default {
  components: { ElImageViewer, Detail, Audit },
  data() {
    return {
      btnloadDownload: false,
      viewerlList: [],
      showViewer: false,
      tableLabels: tableBusiness,
      searchList: searchBusiness
    }
  },
  mounted() {
    this.getOptions()
  },
  methods: {
    // 表格延迟刷新
    asyncRefreshTable() {
      setTimeout(() => {
        this.$refs.vTable.refreshTable()
      }, 0)
    },
    async download() {
      this.btnloadDownload = true
      const params = this.$refs.vTable.getParams()
      const res = await this.$api.ExportConfirmReceipts(params).finally(() => {
        this.btnloadDownload = false
      })
      if (res.type == 'application/json') {
        // 将blob文件流转换成json
        const reader = new FileReader()
        reader.onload = (event) => {
          const error = JSON.parse(reader.result)
          this.$message.error(error.message || '导出错误')
        }
        reader.readAsText(res)
      } else {
        downloadExcel(res, `业务确认单`)
      }
    },
    async getOptions() {
      const res = await this.$api.GetSupplierInfoCodeName()
      this.searchList[2].options = res.list.map((item) => {
        return {
          key: item.supplierName,
          label: item.supplierName
        }
      })
    },
    updateDialogTemplate() {
      this.$refs.vTable.refreshTable()
    },
    previewAchFile(e) {
      let urlList = this.filterType(e.confirmReceiptAttachs, 1)
      if (urlList.length < 1) {
        this.$message.error('没有附件')
        return false
      }
      this.viewerlList = urlList.map((item) => {
        return item.attachUrl
      })
      this.showViewer = true
    },
    downloadAchFile(e) {
      let urlList = e.confirmReceiptAttachs
      if (urlList.length < 1) {
        this.$message.error('没有附件')
        return false
      }
      downloadUrl(urlList[0].attachUrl, urlList[0].attachName || '业务确认单')
      // urlList.map((item) => {
      // })
    },
    filterType(e, type) {
      //type:1:业务确认单,
      let v = e.filter((item) => {
        return item.attachType == type
      })
      return v
    },
    async reBack(e) {
      this.$confirm('确定退回吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let res = await this.$api.ReturnConfirmReceipts({ id: e.id })
          if (res) {
            this.$message.success('退回成功')
            this.updateDialogTemplate()
          }
        })
        .catch(() => {})
    },
    async reCreat(e) {
      this.$confirm('重新生成业务确认单会删除该条数据, 确定重新生成？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let res = await this.$api.AfreshGenerateConfirmReceipts({ confirmReceiptId: e.id })
          if (res) {
            this.$message.success('业务确认单重新生成中，请稍后刷新')
            this.updateDialogTemplate()
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.deleteColor {
  color: #f56c6c;
}
</style>
