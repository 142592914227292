<template>
  <div>
    <el-dialog :title="title" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false">
      <div class="dialog-wrap">
        <FormView @onInputChange="onInputChange" ref="form" v-if="show" :labels="labels" :slotIdxList="[4, 5]" :formConfig="{ labelWidth: '140px' }">
          <template v-slot:form-items="{ idx, form }">
            <el-col v-if="idx == 4 ">
              <div style="margin-left: 140px" v-if="form.templateType != 3 && form.templateSignType != 30">
                <el-button type="primary" @click="addTemplate" >添加</el-button>
              </div>
              <!-- 企业签约 -->
              <el-form-item label="模板字段" v-if="form.templateType == 1 && form.templateSignType == 20" >
                <el-table :data="form.fieldDtos" :cell-style="tdStyle">
                  <el-table-column label="字段编号" prop="templateCode">
                    <template slot-scope="{ row, $index }">
                      <el-form-item
                        :prop="'fieldDtos.' + $index + '.templateCode'"
                        :rules="[{ required: true, message: '请填写模板字段编号', trigger: 'blur' }]"
                      >
                        <el-input v-model="row.templateCode" spellcheck ='false'></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="字段名称" prop="templateName">
                    <template slot-scope="{ row, $index }">
                      <el-form-item
                        :prop="'fieldDtos.' + $index + '.templateName'"
                        :rules="[{ required: true, message: '请填写模板字段名称', trigger: 'blur' }]"
                      >
                        <el-input v-model="row.templateName" spellcheck ='false'></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="{ row, $index }">
                      <el-button type="text" style="color: red" @click="removeItem(row, $index)" >删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
              <!-- 个人签约 -->
              <el-form-item label="模板字段" v-if="form.templateType == 3 ">
                <el-table :data="form.templateFields" :cell-style="tdStyle">
                  <el-table-column label="字段编号" prop="templateCode">
                    <template slot-scope="{ row, $index }">
                      <el-form-item
                        :prop="'templateFields.' + $index + '.templateCode'"
                        :rules="[{ required: true, message: '请填写模板字段编号', trigger: 'blur' }]"
                      >
                        <el-input v-model="row.templateCode" spellcheck ='false' :disabled="true"></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="字段名称" prop="templateName">
                    <template slot-scope="{ row, $index }">
                      <el-form-item
                        :prop="'templateFields.' + $index + '.templateName'"
                        :rules="[{ required: true, message: '请填写模板字段名称', trigger: 'blur' }]"
                      >
                        <el-input v-model="row.templateName" spellcheck ='false' :disabled="true"></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="{ row, $index }">
                      <el-button type="text" style="color: red" @click="newRemoveItem(row, $index, form)"  v-if="( $refs.form.getFormItem('templateSignType') == 20 && row.orderNo!=2) || ( $refs.form.getFormItem('templateSignType') == 30 && row.orderNo!=0 && row.orderNo!=2)">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-col>
            <!-- <el-col v-if="idx == 4 && form.templateSignType == 20"> 逻辑修改
              <div style="margin-left: 140px">
                <el-button type="primary" @click="addTemplate">添加</el-button>
              </div>
              <el-form-item label="模板字段">
                <el-table :data="form.fieldDtos" :cell-style="tdStyle">
                  <el-table-column label="字段编号" prop="templateCode">
                    <template slot-scope="{ row, $index }">
                      <el-form-item
                        :prop="'fieldDtos.' + $index + '.templateCode'"
                        :rules="[{ required: true, message: '请填写模板字段编号', trigger: 'blur' }]"
                      >
                        <el-input v-model="row.templateCode"></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="字段名称" prop="templateName">
                    <template slot-scope="{ row, $index }">
                      <el-form-item
                        :prop="'fieldDtos.' + $index + '.templateName'"
                        :rules="[{ required: true, message: '请填写模板字段名称', trigger: 'blur' }]"
                      >
                        <el-input v-model="row.templateName"></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="{ row, $index }">
                      <el-button type="text" style="color: red" @click="removeItem(row, $index)">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-col> -->
            <el-col v-if="idx == 5 && form.templateSignType == 20">
              <div style="margin-left: 140px">
                <el-button type="primary" @click="addKeyword">添加</el-button>
              </div>
              <el-form-item label="盖章关键字" :rules="[{ required: true }]">
                <el-table :data="form.keywordDtos" :cell-style="tdStyle">
                  <el-table-column label="关键字" prop="keyword">
                    <template slot-scope="{ row, $index }">
                      <el-form-item
                        :prop="'keywordDtos.' + $index + '.keyword'"
                        :rules="[{ required: true, message: '请填写关键字', trigger: 'blur' }]"
                      >
                        <el-input v-model="row.keyword"></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="签约方类型" prop="signatoryType">
                    <template slot-scope="{ row, $index }">
                      <el-form-item
                        :prop="'keywordDtos.' + $index + '.signatoryType'"
                        :rules="[{ required: true, message: '请选择签约方类型', trigger: 'blur' }]"
                      >
                        <el-select @change="changeSignType" v-model="row.signatoryType" placeholder="请选择签约方类型">
                          <el-option v-for="item in stampOptions" :key="item.id" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="{ row, $index }">
                      <el-button type="text" style="color: red" @click="removeKeyword(row, $index)">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-col>
          </template>
        </FormView>
      </div>
      <div slot="footer">
        <el-button @click="show = false">取消</el-button>
        <el-button type="primary" @click="submit" :loading="btnloadSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formContract } from '@/configs/forms.js'
import { tableWhiteList } from '../../configs/tables'
import { deepClone } from '@/utils'

let templateFields = []
export default {
  data() {
    return {
      btnloadSubmit: false,
      title: '查看',
      show: false,
      labels: deepClone(formContract),
      detail: {
        fieldDtos: [],
        keywordDtos: [],
        templateFields:deepClone(templateFields)
      },
      stampOptions: [
        { label: '商户', value: 1 },
        // { label: '创客', value: 2 },
        { label: '税源地', value: 3 },
        { label: '其他', value: 99 }
      ]
    }
  },
  methods: {
    // 数据初始化
    clear() {
      this.detail = { fieldDtos: [], keywordDtos: [], templateFields: [] };
    },
    async open(row) {
      this.clear()
      if (row) {
        // 模板类型和签约方式禁用
        this.labels.find((item) =>item.prop == 'templateType').config.disabled = true;
        this.labels.find((item) =>item.prop == 'templateSignType').config.disabled = true;
        if(row.templateSignType === 30) {
          this.labels.find((item) =>item.prop == 'templateType').options[0].disabled = true;
        } else {
          this.labels.find((item) =>item.prop == 'templateType').options[0].disabled = false;
        }
        // this.detail.templateFields = deepClone(templateFields)
        //模板字段回显
        const res = await this.$api.GetEditByContractTemplateFieldList({ templateId: row && row.id })
        //盖章字段回显
        const keyRes = await this.$api.GetContractTemplateKeywordList({ templateId: row && row.id })
        this.detail.fieldDtos = res.list
          .map((item) => {
            return { templateName: item.fieldName, templateCode: item.fieldCode, orderNo: item.orderNo }
          })
          .sort((x, y) => x.orderNo - y.orderNo)
        this.detail.keywordDtos = keyRes.list
          .map((item) => {
            return { keyword: item.keyword, signatoryType: item.signatoryType, orderNo: item.orderNo }
          })
          .sort((x, y) => x.orderNo - y.orderNo)
        this.onInputChange(row.templateType, 'templateType', false)
        this.detail = Object.assign({}, row, this.detail, { templateRemark: row.remark })
        this.title = '编辑'
      } else {
        // 模板类型和签约方式解除禁用
        const res = await this.$api.WorkerContractTemplateFields();
        templateFields = res.list;
        this.labels.find((item) =>item.prop == 'templateType').config.disabled = false;
        this.labels.find((item) =>item.prop == 'templateSignType').config.disabled = false;
        this.detail.templateFields = deepClone(templateFields)
        this.labels = deepClone(formContract);
        this.detail = Object.assign({ templateSignType: 20, templateType: 1 }, this.detail)
        this.onInputChange(1, 'templateType', false)
        this.title = '添加'
      }
      this.show = true
      this.$nextTick(() => {
        this.updateDetail()
      })
    },
    newRemoveItem(row,index, f) {
      this.detail.templateFields.splice(index, 1)
    },
    // 删除模板字段列表项
    removeItem(row, index) {
      this.detail.fieldDtos.splice(index, 1)
      // this.updateDetail()
    },
    // 删除盖章列表项
    removeKeyword(row, index) {
      this.detail.keywordDtos.splice(index, 1)
      // this.updateDetail()
    },
    // 新增模板字段列表项
    addTemplate() {
      this.detail.fieldDtos.push({ templateName: '', templateCode: '', orderNo: this.detail.fieldDtos.length })
      // this.updateDetail()
    },
    // 新增盖章字段列表项
    addKeyword() {
      this.detail.keywordDtos.push({ keyword: '', signatoryType: null, orderNo: this.detail.keywordDtos.length })
      // this.updateDetail()
    },
    // 更新模板表格
    updateDetail() {
      this.$refs.form.upData(this.detail)
    },
    // 提交新增模板字段
    async submit() {
      const formRes = await this.$refs.form.getForm()
      // && formRes.templateType && formRes.templateType !== 3
      // 签约逻辑判断 处理数据
      if(formRes.templateType == 3) {
        formRes.fieldDtos = [];
        formRes.fieldDtos = formRes.templateFields;
      }
      delete formRes.templateFields;
      if (formRes && formRes.templateSignType == 20) {
        // if (!formRes.fieldDtos || formRes.fieldDtos.length == 0) {
        //   this.$message.warning('请至少填写一条模板字段')
        //   return
        // }
        if (!formRes.keywordDtos || formRes.keywordDtos.length == 0) {
          this.$message.warning('请至少填写一条盖章关键字')
          return
        }
        if (formRes.keywordDtos) {
          // 有且只能存在一个商户/创客
          let list = formRes.keywordDtos.filter((item) => {
            return item.signatoryType == 1 || item.signatoryType == 2
          })
          if (list.length > 1) {
            this.$message.warning('商户/个体户类、创客类关键字只能设置一个，且这两个类型只能设置一个')
            return
          }
        }
      }
      // 提交请求
      let result = null
      let message = ''
      this.btnloadSubmit = true
      if (this.detail.id) {
        const { id, remark, created, ...rest } = formRes
        result = await this.$api.PushContractModifyItem(Object.assign(rest, { templateId: id })).finally(() => {
          this.btnloadSubmit = false
        })
        message = '修改成功'
      } else {
        result = await this.$api.PushContractAddItem(formRes).finally(() => {
          this.btnloadSubmit = false
        })
        message = '添加成功'
      }
      if (result) {
        this.$emit('updateList')
        this.$message.success(message)
        this.show = false
      }
    },
    // 添加模板效验后出现样式变化,这里进行调整
    tdStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0 || columnIndex == 1) {
        return 'text-align:center;padding-bottom:0px;padding-top:14px'
      }
    },
    // 表单输入变更
    onInputChange(val, key, isChange = true) {
      // 切换模板类型
      if (key == 'templateType') {
        switch (val) {
          case 1:
            this.stampOptions = [
              { label: '商户', value: 1 },
              { label: '税源地', value: 3 },
              { label: '其他', value: 99 }
            ]
            break
          case 3:
            this.stampOptions = [
              { label: '创客', value: 2 },
              { label: '税源地', value: 3 },
              { label: '其他', value: 99 }
            ]
            break
          default:
            break
        }
        //判断是否为初次加载
        if (isChange) {
          this.detail.keywordDtos.map((item) => {
            item.signatoryType = null
          })
        }
        return
      };
      if(key == 'templateSignType') {
        let templateFieldsCode = this.detail.templateFields.map(n=> {return n.templateCode})
        deepClone(templateFields).forEach((element,keys) => {
          if(templateFieldsCode.indexOf(element.templateCode) < 0){
            this.detail.templateFields.splice(keys,0,element)
          }
        });
      }
      if(key === 'templateSignType' && val === 30) {
        this.labels.find((item) =>item.prop == 'templateType').options[0].disabled = true;
        this.$refs.form.upData({ templateType: 3 })
      } else {
        this.labels.find((item) =>item.prop == 'templateType').options[0].disabled = false;
      }
    },
    //切换签约类型
    changeSignType() {
      // 有且只能存在一个商户/创客
      this.detail.keywordDtos.map((item) => {
        if (this.detail.templateType == 1) {
        } else if (this.detail.templateType == 3) {
        }
      })
    }
  }
}
</script>
