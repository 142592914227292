<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetReceiptPlanPageList">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item-left">
        <el-button plain type="primary" v-if="$hasAccess('001301')" @click="$refs.Edit.open()">批量上传</el-button>
        <el-button plain icon="el-icon-refresh" @click="$refs.vTable.refreshTable()">刷新</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="80" fixed="right">
        <template v-slot="{ row }">
          <el-button type="text" @click="$refs.Detail.open(row, false)">查看</el-button>
        </template>
      </el-table-column>
    </TableView>
    <Detail ref="Detail" />
    <Edit @submit="asyncRefreshTable" ref="Edit" />
  </div>
</template>

<script>
import Detail from './detail'
import Edit from './edit'
import { formatStatus } from '@/configs/formatters'
const tableLabels = [
  { label: '导入时间', prop: 'importTime', config: { width: 180 } },
  {
    label: '回单提供方',
    prop: 'providerName'
  },
  {
    label: '导入数量',
    prop: 'planCount'
  },
  {
    label: '匹配状态',
    prop: 'mappingState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '未匹配' },
          { key: 1, label: '匹配中' },
          { key: 2, label: '匹配完成' }
        ],
        cellValue
      )
    }
  },
  {
    label: '成功',
    prop: 'mappingSuccessCount'
  },
  {
    label: '失败',
    prop: 'mappingFailCount'
  }
]
export default {
  components: { Detail, Edit },
  data() {
    return {
      tableLabels: tableLabels
    }
  },
  methods: {
    // 表格延迟刷新
    asyncRefreshTable() {
      setTimeout(() => {
        this.$refs.vTable.refreshTable()
      }, 0)
    }
  }
}
</script>
