<template>
  <el-dialog title="对接参数" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="700px">
    <el-form label-position="right" label-width="120px" label-suffix="：">
      <el-form-item label="接口秘钥">{{ detail.merchantSecret }}</el-form-item>
      <el-form-item label="订单回调地址">
        <div class="line">
          <div class="line-left">
            <span v-if="!edit">{{ detail.merchantApi || '无' }}</span>
            <el-input v-else v-model="form.merchantApi"></el-input>
          </div>
          <div class="line-right">
            <el-button v-if="!edit" type="text" @click="edit = true">编辑</el-button>
            <template v-else>
              <el-button
                type="text"
                @click="
                  edit = false
                  form.merchantApi = detail.merchantApi
                "
                >取消</el-button
              >
              <el-button type="text" @click="submit" :loading="btnloadSubmit">保存</el-button>
            </template>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      detail: {},
      form: {},
      edit: false
    }
  },
  methods: {
    clear() {
      this.form = {}
    },
    async open(obj) {
      if (obj && obj.merchantId) {
        const res = await this.$api.GetMerchantApiConfig({ merchantId: obj.merchantId })
        this.detail = Object.assign({ merchantId: obj.merchantId }, res)
        this.form = { merchantId: obj.merchantId, merchantApi: res.merchantApi }
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    },
    async submit() {
      this.btnloadSubmit = true
      const res = await this.$api.SaveMerchantApi(this.form).finally(() => {
        this.btnloadSubmit = false
      })
      this.edit = false
      this.$set(this.detail, 'merchantApi', this.form.merchantApi)
    }
  }
}
</script>

<style lang="scss" scoped>
.line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  &-left {
    flex: 1;
    margin-right: 50px;
  }
}
</style>
