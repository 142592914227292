<template>
  <el-dialog :title="!id ? '添加' : '编辑'" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="500px">
    <!-- 内容区域 -->
    <FormView ref="vForm" :labels="fromLabels" :slotIdxList="[0]">
      <template #form-items="{ idx, form }">
        <el-form-item v-if="id && idx === 0" label="用户编号" prop="userId">
          <el-input v-model="form.userCode" disabled></el-input>
        </el-form-item>
      </template>
    </FormView>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { formOperationUser } from '@/configs/forms'
export default {
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      fromLabels: formOperationUser,
      detail: {}
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    open(obj) {
      this.clear()
      this.getRoles()
      if (obj && obj.id) {
        // 编辑
        const { id, userName, loginName, mobile, roleId, userCode } = obj
        this.id = obj.id
        this.detail = { userId: id, userName, loginUser: loginName, mobile, roleId, userCode }
      } else {
        // 添加
      }
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData(this.detail)
      })
    },
    // 获取角色列表
    async getRoles() {
      const res = await this.$api.GetSystemRoleSelectList()
      const options = (res.list || []).map((item) => {
        return {
          label: item.roleName,
          key: item.roleId
        }
      })
      this.$set(this.fromLabels[3], 'options', options)
    },
    // 提交
    async submit() {
      // 结果更新
      const params = await this.$refs.vForm.getForm()
      this.btnloadSubmit = true
      if (this.id) {
        // 编辑
        const res = await this.$api.EditOperationUser(params).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('编辑成功')
      } else {
        // 添加
        const res = await this.$api.AddOperationUser(params).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('添加成功')
      }
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
