<template>
  <el-dialog
    title="上传发票"
    :visible.sync="show"
    :append-to-body="true"
    :close-on-click-modal="false"
    custom-class="dialog-full-hasfoot"
    fullscreen
    @close="$emit('close')"
  >
    <!-- 内容区域 -->
    <div class="dialog-wrap-full" v-if="show">
      <el-descriptions title="基本信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="审核状态">{{ detail.verifyState || '无' }}</el-descriptions-item>
        <el-descriptions-item label="审核时间">{{ detail.verifyTime || '无' }}</el-descriptions-item>
        <el-descriptions-item label="申请编号">{{ detail.applyNumber || '--' }}</el-descriptions-item>
        <el-descriptions-item label="申请时间">{{ detail.createTime || '--' }}</el-descriptions-item>
        <el-descriptions-item label="商户名称">{{ detail.merchantName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="商户号">{{ detail.memberCode || '无' }}</el-descriptions-item>
        <el-descriptions-item :span="1" label="认证企业">{{ detail.mainName || '无' }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="发票内容" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="发票类型">{{ detail.invoiceType || '无' }}</el-descriptions-item>
        <el-descriptions-item label="开票税目">{{ detail.invoiceItem || '无' }}</el-descriptions-item>
        <el-descriptions-item label="申请金额"
          ><span v-format="'#,##0.00'">{{ detail.applyAmount || 0 }}</span> 元</el-descriptions-item
        >
        <el-descriptions-item label="合并笔数"
          >{{ detail.invoicingSource != 2 ? detail.planCount || '无' : '无' }}
          <el-link
            v-if="detail.planCount && detail.invoicingSource != 2"
            type="primary"
            style="margin-left: 30px; margin-top: -2px"
            @click="$refs.Plan.open({ InvoiceId: id, PlanType: 1, detail })"
            >查看</el-link
          ></el-descriptions-item
        >
        <el-descriptions-item label="平账结算单"
          >{{ detail.planDSOCount || 0 }}
          <el-link
            v-if="detail.planDSOCount"
            type="primary"
            style="margin-left: 30px; margin-top: -2px"
            @click="$refs.Plan.open({ InvoiceId: id, PlanType: 2, detail })"
            >查看</el-link
          ></el-descriptions-item
        >
        <el-descriptions-item :span="1" label="备注">{{ detail.remark || '无' }}</el-descriptions-item>
        <el-descriptions-item label="退汇金额"
          ><span v-format="'#,##0.00'">{{ detail.returnAmount || 0 }}</span> 元</el-descriptions-item
        >
        <el-descriptions-item label="开票金额"
          ><span v-format="'#,##0.00'">{{ detail.invoiceAmount || 0 }}</span> 元</el-descriptions-item
        >
      </el-descriptions>
      <el-divider></el-divider>
      <div class="el-descriptions__header">
        <div class="el-descriptions__title">发票信息</div>
        <div class="el-descriptions__extra">
          <el-button plain type="primary" @click="downLoadFile">下载模板</el-button>
          <div style="display: inline-block; margin: 0 15px" v-if="$hasAccess('001004')">
            <ComponentFormImport
              :fileSuffix="['jpg', 'png', 'jpeg', 'pdf', 'zip', 'xlsx', 'rar']"
              :size="20"
              url="BillApply/ImportBill"
              button="批量导入"
              @uploaded="onUploaded"
            />
          </div>
          <el-button v-if="$hasAccess('001005')" plain type="primary" @click="$refs.Edit.open()">添加</el-button>
        </div>
      </div>
      <div style="margin-bottom: 18px">
        已上传发票金额：<span v-format="'#,##0.00'">{{ sum }}</span>
      </div>
      <el-table style="overflow: auto" ref="table" :data="list" border>
        <el-table-column type="index" label="#" width="65"></el-table-column>
        <el-table-column label="发票编号" prop="receiptNo"></el-table-column>
        <el-table-column label="发票金额" prop="receiptAmount">
          <template v-slot="{ row }"
            ><span v-format="'#,##0.00'">{{ row.receiptAmount || 0 }}</span></template
          >
        </el-table-column>
        <el-table-column label="发票图片">
          <template v-slot="{ row }">
            <!-- <ImageViewer :path="row.receiptFilePath" /> -->
            <ImageViewer v-if="row.receiptFilePath && ['jpg', 'jpeg', 'png'].includes(row.type)" :path="row.receiptFilePath" name="查看文件" />
            <!-- <span @click="viewPdf(row.receiptFilePath)" class="otherStyle" v-if="row.receiptFilePath && row.type == 'pdf'">查看文件{{ index + 1 }}</span> -->
            <span @click="viewPdf(row.receiptFilePath)" class="otherStyle" v-if="row.receiptFilePath && !['jpg', 'jpeg', 'png'].includes(row.type)"
              >查看文件</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template v-slot="{ row, $index }">
            <el-button type="text" @click="$refs.Edit.open($index, row)">编辑</el-button>
            <el-button type="text" class="el-button-error" @click="del($index, row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取消</el-button>
      <el-button v-if="$hasAccess('001007')" type="primary" @click="save" :loading="btnloadSave">保存</el-button>
      <el-button v-if="$hasAccess('001006')" type="primary" @click="submit" :loading="btnloadSubmit">开票完成</el-button>
    </span>
    <Plan ref="Plan" />
    <Edit ref="Edit" @submit="onEdit" />
  </el-dialog>
</template>

<script>
import { deepClone } from '@/utils'
import { tableBillReceipt } from '@/configs/tables'
import ImageViewer from '@/components/tableView/imageViewer'
import Plan from '../invoice/plan'
import Edit from './edit'
import ComponentFormImport from '@/components/formView/import'
import { downloadUrl, downloadDocx, downloadExcel } from '@/utils'
export default {
  components: { Plan, ImageViewer, Edit, ComponentFormImport },
  data() {
    return {
      btnloadSave: false,
      btnloadSubmit: false,
      id: null,
      show: false,
      tableLabels: tableBillReceipt,
      detail: {},
      oriList: [],
      list: []
    }
  },
  computed: {
    sum() {
      const sum = this.list.reduce((next, it) => {
        return Number(next) + (Number(it.receiptAmount) || 0)
      }, 0)
      return sum
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
      this.oriList = []
      this.list = []
    },
    open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 查看
        this.id = obj.id
        this.detail = obj
        this.show = true
        this.getList()
      } else {
        this.$message.error('无效的信息')
      }
    },
    // 获取列表
    async getList() {
      const res = await this.$api.GetInvoiceDetailReceiptPageList({ InvoiceId: this.id, PageSize: 999 })
      this.oriList = res.list
      this.list = deepClone(res.list)
    },
    // 编辑
    onEdit(res, idx) {
      // 判断重复
      const reOne = this.list.find((item, index) => {
        return index != idx && item.receiptNo == res.receiptNo
      })
      if (reOne) return this.$message.error('发票单号已重复，请重新' + (idx >= 0 ? '编辑' : '添加'))
      // 处理数据
      if (idx >= 0) {
        this.$set(this.list, idx, res)
      } else {
        this.list.unshift(res)
      }
      this.$message.success(idx >= 0 ? '编辑成功' : '添加成功')
    },
    // 删除
    del(idx) {
      this.list.splice(idx, 1)
    },
    // 批量导入
    onUploaded(res) {
      if (Number(res.successCount)) {
        res.successList.map((item) => {
          item.receiptNo = item.billNo
          item.receiptAmount = item.billMoney
        })
        let flag = false
        this.list = this.list.filter((item) => {
          let target = res.successList.find((ee) => ee.receiptNo == item.receiptNo)
          if (target) {
            flag = true
            return item.receiptNo != target.receiptNo
          }
        })
        if (flag) {
          this.$message.warning('当前导入的表格中存在重复编号，已做覆盖处理')
        }
        this.list = [...res.successList, ...this.list]
        setTimeout(() => {
          this.$message.success('成功导入' + res.successCount + '条')
        }, 3000)
      }
      if (Number(res.failCount)) this.$message.error('导入失败' + res.failCount + '条' + (res.failReason ? ',' + res.failReason : ''))
    },
    // 保存
    async save() {
      this.btnloadSave = true
      const res = await this.$api.SaveInvoice({ invoiceId: this.id, receiptList: this.list }).finally(() => {
        this.btnloadSave = false
      })
      this.$message.success('保存成功')
      this.getList()
    },
    // 开票完成
    async submit() {
      // 判断金额
      if (this.sum.toFixed(2) != this.detail.invoiceAmount.toFixed(2)) return this.$message.error('开票金额不等于申请金额')
      // 判断是否保存
      if (JSON.stringify(this.list) != JSON.stringify(this.oriList)) return this.$message.error('请先保存')
      this.btnloadSubmit = true
      const res = await this.$api.FinishInvoice({ invoiceId: this.id }).finally(() => {
        this.btnloadSubmit = false
      })
      this.$message.success('开票完成')
      this.show = false
    },
    async downLoadFile() {
      const result = await this.$api.DownloadBillApplyFile()
      downloadExcel(result, '发票导入模板')
    },
    viewPdf(e) {
      downloadUrl(e)
    }
  }
}
</script>
<style lang="scss" scoped>
.otherStyle {
  color: #3d78fa;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}
</style>
