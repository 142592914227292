<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <el-card shadow="never" style="height: 100%" :body-style="{ height: '100%' }">
      <div class="flex-home-btn">
        <el-button v-if="$hasAccess('001100')" type="primary" @click="add()">添加一级税目</el-button>
      </div>
      <div class="custom-tree-node flex-home-title">
        <div class="first-text text">税目名称</div>
        <div class="text">税目编号</div>
        <div class="btns text">操作</div>
      </div>
      <div class="warp">
        <el-tree :props="props" :load="loadNode" lazy>
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <el-tooltip :disabled="node.label.length < 7" class="item" effect="dark" :content="node.label" placement="top">
              <span class="label">{{ node.label }}</span>
            </el-tooltip>

            <span v-if="node.level == 2" class="label-num">{{ data.taxCode || '' }}</span>
            <span class="btns">
              <el-button v-if="$hasAccess('001101')" type="text" @click.stop="edit(data)">编辑</el-button>
              <el-button v-if="$hasAccess('001102') && !data.level" type="text" @click.stop="add(data)">添加二级税目</el-button>
              <el-button v-if="$hasAccess('001103')" type="text" class="el-button-error" @click.stop="del(node, data)">删除</el-button>
            </span>
          </span>
        </el-tree>
      </div>
    </el-card>
    <Edit ref="Edit" @submit="reloadTree" />
  </div>
</template>

<script>
import Edit from './edit'
export default {
  components: { Edit },
  data() {
    return {
      props: {
        label: 'name',
        isLeaf: 'leaf'
      },
      node: {},
      resolveFunc: function () {}
    }
  },
  mounted() {},
  methods: {
    // 动态加载节点
    async loadNode(node, resolve) {
      if (node.level === 0) {
        this.node = node
        this.resolveFunc = resolve
        // 一级
        const res = await this.$api.GetTaxItemsParentList()
        return resolve(res.list)
      }
      if (node.level === 1) {
        // 二级
        const res = await this.$api.GetTaxItemsPageList({
          parentId: node.data.id,
          pageSize: 100
        })
        return resolve(
          res.list
            .filter((item) => {
              return item.level == 2
            })
            .map((item) => {
              return Object.assign(item, {
                parentId: node.data.id,
                parentName: node.data.name,
                leaf: true
              })
            })
        )
      }
    },
    // 添加
    add(item) {
      this.$refs.Edit.open(item, true)
    },
    // 编辑
    edit(item) {
      this.$refs.Edit.open(item)
    },
    // 删除
    async del(node, item) {
      // 判断有没有2级税目
      if (!item.leaf && (!node.loaded || node.childNodes.length)) {
        return this.$message.error('请先删除当前税目下，所有的二级税目')
      }
      await this.$confirm(`确认删除当前税目吗？`, '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      const res = await this.$api.DeleteTaxItem({ childrenId: item.id })
      this.$message.success('税目已删除')
      this.reloadTree()
    },
    // 重载
    reloadTree() {
      this.node.childNodes = []
      this.loadNode(this.node, this.resolveFunc)
    }
  }
}
</script>

<style lang="scss" scoped>
.warp {
  height: 86%;
  overflow: auto;
}
.flex-home-btn {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}
.flex-home-title {
  background: #f6f7f9;
  height: 35px;
  margin-bottom: 10px;
  .text {
    font-size: 14px;
    color: #333;
  }
  .first-text {
    &:before {
      content: ' ';
      height: 1px;
      width: 24px;
      display: inline-block;
    }
  }
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .btns {
    width: 500px;
  }
  .label {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .label-num {
    text-align: left;
    width: 100px;
  }
}
</style>
