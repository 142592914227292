<template>
  <el-aside class="side-wrap">
    <div class="scroll-wrap">
      <el-menu class="side-menu" :default-active="activeMenu">
        <side-item v-for="route in routes" :key="route.path" :item="route" :base-path="route.path" />
      </el-menu>
    </div>
  </el-aside>
</template>

<script>
import SideItem from './sideItem'

export default {
  name: 'Side',
  components: { SideItem },
  computed: {
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    routes() {
      return this.$router.options.routes
    }
  }
}
</script>
<style lang="scss" scoped>
.scroll-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
