<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetIndividualPeoplePageList" :searchList="searchList">
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="120" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button type="text" v-if="row.signState == 0 && row.individualState == 3" @click="wxappSign(row)" :loading="btnloadDownload"
            >生成签约链接</el-button
          >
        </template>
      </el-table-column>
    </TableView>
    <!-- <WxappSign ref="WxappSign" /> -->
  </div>
</template>

<script>
import { formatStatus } from '@/configs/formatters'
// 常量设置
const options = [
  { key: 0, label: '待申请' },
  { key: 1, label: '待审核' },
  { key: 2, label: '注册中' },
  { key: 3, label: '经营中' },
  { key: 4, label: '已拒绝' }
]
const signState = [
  { key: 0, label: '未生成' },
  { key: 1, label: '未签约' },
  { key: 2, label: '已签约' }
]
const tableLabels = [
  { label: '个体户编号', prop: 'peopleNo' },
  { label: '姓名', prop: 'name' },
  { label: '身份证', prop: 'idCard' },
  { label: '手机号', prop: 'mobile' },
  {
    label: '个体户状态',
    prop: 'individualState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(options, cellValue)
    }
  },
  { label: '添加时间', prop: 'createTime' },
  { label: '个体户名称', prop: 'companyName' },
  { label: '开票类目', prop: 'invoiceName' },
  { label: '注册地', prop: 'registrationPlaceName' },
  {
    label: '签约状态',
    prop: 'signState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(signState, cellValue)
    }
  },
  { label: '完成注册时间', prop: 'finishTime' }
]
const searchList = [
  {
    cmp: 'input',
    label: '基础信息',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入姓名/身份证/手机号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '个体户状态',
    prop: 'IndividualState',
    options: options
  },
  {
    cmp: 'dtpickers',
    label: '添加时间',
    prop: ['StartCreateTime', 'EndCreateTime']
  },
  {
    cmp: 'dtpickers',
    label: '完成注册时间',
    prop: ['StartFinishTime', 'EndFinishTime']
  }
]
// import WxappSign from './wxappSign.vue'
export default {
  // components: { WxappSign },
  data() {
    return {
      btnloadDownload: false,
      tableLabels: tableLabels,
      searchList: searchList
    }
  },
  methods: {
    async wxappSign(item) {
      this.btnloadDownload = true
      const res = await this.$api.IndividualPeopleSign({ individualId: item.id }).finally(() => {
        this.btnloadDownload = false
      })
      this.$message.success('发送成功')
    }
  }
}
</script>
