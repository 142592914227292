<template>
  <el-dialog :title="title" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" top="8vh">
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 550px">
      <FormView ref="vForm" :formConfig="{ labelWidth: '160px' }" :labels="formLabels"> </FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit()" :loading="btnSubmit">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { regular } from '@/configs/validate'
const formLabels = [
  {
    sessionTitle: '基本信息',
    cmp: 'input',
    label: '运营商号',
    prop: '_no',
    config: { disabled: true, placeholder: '系统生成' }
  },
  {
    cmp: 'input',
    label: '运营商名称',
    prop: 'name',
    itemConfig: {
      rules: [{ required: true, message: '请输入运营商名称', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '社会统一信用代码',
    prop: 'creditCode',
    itemConfig: {
      rules: [{ required: true, message: '请输入社会统一信用代码', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '运营公司名称',
    prop: 'companyName',
    itemConfig: {
      rules: [{ required: true, message: '请输入运营公司名称', trigger: 'blur' }]
    }
  },
  {
    sessionTitle: '账号信息',
    cmp: 'input',
    label: '登录账号',
    prop: 'loginAccount',
    itemConfig: {
      rules: [{ required: true, message: '请输入登录账号', trigger: 'blur' }]
    }
  },
  {
    cmp: 'input',
    label: '手机号',
    prop: 'mobile',
    itemConfig: {
      rules: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        {
          pattern: regular.mobile,
          message: regular.mobileMsg,
          trigger: 'blur'
        }
      ]
    },
    config: { maxlength: 11 }
  },
  {
    sessionTitle: '业务信息',
    cmp: 'select',
    label: '服务税源地',
    prop: 'supplierIds',
    itemConfig: {
      rules: [{ required: true, message: '输入税源地名称搜索', trigger: 'change' }]
    },
    options: [],
    config: {
      filterable: true,
      remote: true,
      multiple: true,
      style: 'width: 100%'
    }
  }
]
export default {
  data() {
    return {
      btnSubmit: false,
      show: false,
      title: '添加运营商',
      formLabels: formLabels
    }
  },
  mounted() {
    // 处理formLabels
    this.formLabels.find((item) => item.prop == 'supplierIds').config.remoteMethod = (val) => {
      this.getSupplierList(val)
    }
    this.getSupplierList()
  },
  methods: {
    // 数据清空
    clear() {},
    async open() {
      this.clear()
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData({})
      })
    },
    // 获取税源地列表
    async getSupplierList(SupplierName) {
      const res = await this.$api.GetSupplierInfoByKeyWord({ SupplierName })
      // 处理数据结构并赋值
      this.formLabels.find((item) => item.prop == 'supplierIds').options = (res.list || []).map((item) => {
        return Object.assign(
          {
            label: item.supplierName + '【' + item.supplierCode + '】',
            key: item.id
          },
          item
        )
      })
    },
    async submit() {
      const form = await this.$refs.vForm.getForm()
      const res = await this.$api.AddTenant(form)
      this.$message.success('添加成功')
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
