<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetEmployeeInfoPageList" :searchList="searchList">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item">
        <el-button plain type="primary" icon="el-icon-plus" @click="$refs.Edit.open()">添加</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="120" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button type="text" @click="$refs.Edit.open(row)">编辑</el-button>
        </template>
      </el-table-column>
    </TableView>
    <Edit ref="Edit" @submit="$refs.vTable.refreshTable()" />
  </div>
</template>

<script>
import { formatStatus, kvEmployeeJobState } from '@/configs/formatters'
import Edit from './edit.vue'
const tableLabels = [
  { label: '工号', prop: 'employeeCode' },
  { label: '商务姓名', prop: 'employeeName' },
  { label: '手机号', prop: 'employeePhone' },
  {
    label: '状态',
    prop: 'jobState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvEmployeeJobState, cellValue)
    }
  }
]
const searchList = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入工号或商务姓名搜索',
      style: { width: '250px' }
    }
  }
]
export default {
  components: { Edit },
  data() {
    return {
      tableLabels,
      searchList
    }
  }
}
</script>
