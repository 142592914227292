<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetTenantPageList" :searchList="searchList" @onSwitch="onSwitch">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item-left">
        <el-button v-if="$hasAccess('007500')" plain type="primary" icon="el-icon-plus" @click="$refs.Edit.open()">添加</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <!-- <el-table-column slot="table-item" label="操作" width="120" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button type="text">重置密码</el-button>
        </template>
      </el-table-column> -->
    </TableView>
    <Edit ref="Edit" @submit="$refs.vTable.refreshTable()"></Edit>
  </div>
</template>

<script>
import Edit from './edit.vue'
const tableLabels = [
  { label: '运营商号', prop: 'serialNo' },
  { label: '运营商名称', prop: 'name' },
  { label: '运营公司名称', prop: 'companyName' },
  { label: '登录账号', prop: 'loginAccount' },
  { label: '服务税源地', prop: 'suppliersLabel' },
  { label: '创建时间', prop: 'createTime' },
  { label: '禁用/启用', prop: 'isEnable', cmp: 'switch', switchConfig: { activeValue: 1, inactiveValue: 0 } }
]
const searchList = [
  {
    cmp: 'input',
    prop: 'SearchText',
    config: {
      placeholder: '请输入运营商号/运营商名称/登录账号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '禁用/启用',
    prop: 'IsEnable',
    options: [
      { key: 0, label: '禁用' },
      { key: 1, label: '启用' }
    ]
  },
  {
    cmp: 'select',
    label: '税源地',
    prop: 'SupplierId',
    options: [],
    config: {
      filterable: true,
      remote: true,
      placeholder: '请选择税源地',
      style: { width: '300px' }
    }
  }
]
export default {
  components: { Edit },
  data() {
    return {
      tableLabels: tableLabels,
      searchList: searchList
    }
  },
  mounted() {
    // 处理searchList
    this.searchList.find((item) => item.prop == 'SupplierId').config.remoteMethod = (val) => {
      this.getSupplierList(val)
    }
    this.getSupplierList()
  },
  methods: {
    // 表格内的切换事件
    async onSwitch(val, key, row, set) {
      if (key == 'isEnable') {
        if (!this.$hasAccess('007500')) return this.$message.error('您暂无权限禁用/启用')
        // 禁用启用
        const params = { id: row.id }
        const res = await this.$api.ToggleTenant(params)
        set()
      }
    },
    // 获取税源地列表
    async getSupplierList(SupplierName) {
      const res = await this.$api.GetSupplierInfoByKeyWord({ SupplierName })
      // 处理数据结构并赋值
      this.searchList.find((item) => item.prop == 'SupplierId').options = (res.list || []).map((item) => {
        return Object.assign(
          {
            label: item.supplierName + '【' + item.supplierCode + '】',
            key: item.id
          },
          item
        )
      })
    }
  }
}
</script>
