import {
  kvTableTemplateStatus,
  kvRechargeStatus,
  kvRechargeType,
  kvPayState,
  kvWorkPlanGrantState,
  kvBillState,
  kvDistributionState,
  kvPaymentChannel,
  kvContractMode,
  kvAuthState,
  charmStatus,
  kvPeopleListStatus,
  parkOptions,
  taxState,
  kvRoleEenable,
  effectiveState,
  merchantsSignState,
  kvWithdrawSearchState,
  orderAuditState,
  kvAuditState,
  kvSearchUploadStatus,
  kvSearchAuditStatus,
  kvFundType,
  kvBusinessUploadState,
  kvBusinessAuditState,
  kvBussinessType,
  kvBusinessState
} from './formatters'

// 搜索模版
export const searchTemplate = [
  { cmp: 'input', label: '输入框1', prop: 'xxa' },
  {
    cmp: 'input',
    label: '输入框2',
    prop: 'xxb',
    more: true,
    config: { placeholder: '请输入自定义' }
  },
  {
    cmp: 'select',
    label: '选择框1',
    prop: 'xxc',
    options: kvTableTemplateStatus
  },
  { cmp: 'dtpickers', label: '时选框1', prop: ['xxd1', 'xxd2'] }
]

// 创客管理搜索
export const searchWorkPeople = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入创客编号/姓名/手机号/证件号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'input',
    label: '商户',
    prop: 'SearchMerchantTxt',
    config: {
      placeholder: '请输入商户名称/商户号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '税源地',
    prop: 'supplierId',
    options: []
  }
]

// 结算审核里面的明细
export const searchWorkPlanWorkDetail = [
  {
    cmp: 'input',
    label: '',
    prop: 'PeopleSearchTxt',
    config: {
      placeholder: '请输入订单号/姓名/证件号',
      style: { width: '250px' }
    }
  }
]

// 结算明细审核搜索
export const searchWorkPlanCheck = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入结算单编号/商户编号/商户名称',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '发放状态',
    prop: 'grantState',
    options: kvWorkPlanGrantState
  },
  {
    cmp: 'dtpickers',
    label: '创建时间',
    prop: ['createTimeBegin', 'createTimeEnd'],
    more: true
  },
  {
    cmp: 'input',
    label: '所属任务',
    prop: 'taskSearchTxt',
    more: true,
    config: { placeholder: '请输入任务名称/任务编号' }
  },
  {
    cmp: 'input',
    label: '供应商',
    prop: 'supplierSearchTxt',
    more: true,
    config: { placeholder: '请输入供应商名称/供应商编号' }
  },
  {
    cmp: 'select',
    label: '付款通道',
    prop: 'PaymentChannel',
    options: kvPaymentChannel
  }
]

// 结算明细搜索
export const searchWorkPlanDetail = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入订单号/结算单编号/商户编号/商户名称',
      style: { width: '350px' }
    }
  },
  { cmp: 'select', label: '打款状态', prop: 'payState', options: kvPayState },
  {
    cmp: 'dtpickers',
    label: '创建时间',
    prop: ['createTimeBegin', 'createTimeEnd']
  },
  { cmp: 'input', label: '姓名', prop: 'userName', more: true },
  { cmp: 'input', label: '证件号', prop: 'idCard', more: true },
  {
    cmp: 'input',
    label: '认证企业',
    prop: 'mainSearchTxt',
    more: true,
    config: { placeholder: '请输入企业编号/企业名称' }
  },
  {
    cmp: 'input',
    label: '关联任务',
    prop: 'taskSearchTxt',
    more: true,
    config: { placeholder: '请输入任务名称/任务编号' }
  },
  {
    cmp: 'input',
    label: '税源地',
    prop: 'SupplierSearchTxt',
    more: true,
    config: { placeholder: '请输入税源地名称/税源地编号' }
  },
  {
    cmp: 'select',
    label: '下发类型',
    prop: 'grantType',
    options: [
      { key: '银行/银行卡', label: '银行/银行卡' },
      { key: '支付宝', label: '支付宝' },
      { key: '微信', label: '微信' }
    ],
    more: true
  },
  { cmp: 'input', label: '商户流水号', prop: 'shopNo', more: true },
  { cmp: 'input', label: '外部订单号', prop: 'externalNo', more: true },
  {
    cmp: 'dtpickers',
    label: '打款完成时间',
    prop: ['payFinishTimeBegin', 'payFinishTimeEnd'],
    more: true
  },
  { cmp: 'select', label: '开票状态', prop: 'BillState', options: kvBillState }
]

//  充值记录
export const searchRechargeLog = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入商户编号/商户名称/流水号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '状态',
    prop: 'RechargeState',
    options: kvRechargeStatus
  },
  {
    cmp: 'select',
    label: '类型',
    prop: 'RechargeType',
    options: kvRechargeType
  },
  {
    cmp: 'dtpickers',
    label: '打款时间',
    prop: ['RechargeTimeBegin', 'RechargeTimeEnd']
  }
]

//  支出记录
export const searchDisburseLog = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入订单号/商户名称/商户编号/企业主体',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'dtpickers',
    label: '打款时间',
    prop: ['DisburseTimeBegin', 'DisburseTimeEnd']
  }
]

// 提现记录
export const searchWithDrawal = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入商户编号/商户名称/提现单号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '状态',
    prop: 'WithdrawState',
    options: kvWithdrawSearchState
  },
  {
    cmp: 'dtpickers',
    label: '申请时间',
    prop: ['WithdrawTimeBegin', 'WithdrawTimeEnd']
  }
]

// 系统-用户管理搜索
export const searchOperationUser = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入登录账号/用户姓名/用户手机号',
      style: { width: '300px' }
    }
  }
]

// 发票审核
export const searchInvoice = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入商户编号/商户名称/申请编号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'dtpickers',
    label: '申请时间',
    prop: ['CreateTimeBegin', 'CreateTimeEnd']
  },
  // {
  //   cmp: 'input',
  //   label: '税源地',
  //   prop: 'SupplierName'
  // },
  {
    cmp: 'select',
    label: '税源地',
    prop: 'supplierId',
    options: []
  },
  {
    cmp: 'select',
    label: '审核状态',
    prop: 'AuditState',
    options: [
      { key: 1, label: '待审核' },
      { key: 2, label: '审核通过' },
      { key: 3, label: '审核未通过' }
    ]
  },
  {
    cmp: 'select',
    label: '开票类型',
    prop: 'SettlementMakeInvoice',
    options: [
      { key: 0, label: '按结算单开票' },
      { key: 1, label: '按结算明细开票' },
      { key: 2, label: '按金额开票' }
    ]
  }
]

// 开票记录
export const searchBillInfo = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入商户号/商户名称/申请编号/主体企业',
      style: { width: '300px' }
    }
  },
  { cmp: 'select', label: '开票状态', prop: 'BillState', options: kvBillState },
  {
    cmp: 'select',
    label: '配送状态',
    prop: 'DistributionState',
    options: kvDistributionState
  },
  {
    cmp: 'dtpickers',
    label: '申请时间',
    prop: ['CreateTimeBegin', 'CreateTimeEnd']
  },
  // {
  //   cmp: 'input',
  //   label: '税源地',
  //   prop: 'SupplierName'
  // }
  {
    cmp: 'select',
    label: '税源地',
    prop: 'supplierId',
    options: []
  },
  {
    cmp: 'select',
    label: '开票类型',
    prop: 'SettlementMakeInvoice',
    options: [
      { key: 0, label: '按结算单开票' },
      { key: 1, label: '按结算明细开票' },
      { key: 2, label: '按金额开票' }
    ]
  }
]

// 完税凭证
export const searchTaxVoucher = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入商户号/商户名称/主体企业',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'dpicker',
    label: '完税月份',
    prop: 'dateMonth',
    config: { type: 'month', valueFormat: 'yyyy-MM' }
  }
]

// 任务
export const searchTask = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入任务名称/任务编号/商户名称/商户号/认证企业',
      style: { width: '400px' }
    }
  },
  {
    cmp: 'select',
    label: '任务类型',
    prop: 'Type',
    options: [
      { key: null, label: '全部' },
      { key: 0, label: '结算任务' },
      { key: 1, label: '普通任务' }
    ]
  },
  {
    cmp: 'select',
    label: '审核状态',
    prop: 'AuditStatus',
    options: [
      { key: null, label: '全部' },
      { key: 0, label: '待审核' },
      { key: 1, label: '审核通过' },
      { key: 2, label: '审核未通过' }
    ]
  },
  {
    cmp: 'select',
    label: '任务状态',
    prop: 'TaskState',
    options: [
      { key: null, label: '全部' },
      { key: 0, label: '未开始' },
      { key: 1, label: '进行中' },
      { key: 2, label: '已关闭' },
      { key: 3, label: '已终止' }
    ]
  }
]

// 税源地
export const searchPark = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入税源地编号/税源地名称',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '类型',
    prop: 'SupplierType',
    options: parkOptions
  }
]

// 通道
export const searchChannel = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入通道编号/通道名称/税源地名称',
      style: { width: '300px' }
    }
  }
]

// 商户
export const searchMerchant = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入商户号/商户名称/登录账号/认证企业',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '启用/禁用',
    prop: 'IsEnable',
    options: [
      { key: 0, label: '启用' },
      { key: 1, label: '禁用' }
    ]
  },
  {
    cmp: 'select',
    label: '来源',
    prop: 'Source',
    options: [
      // { key: 1, label: '系统商务' },
      // { key: 2, label: '开放平台' },
      // { key: 3, label: '开放平台FP' }
    ]
  },
  // { cmp: "select", label: "认证状态", prop: "AuthState", options: kvAuthState },
  // {
  //   cmp: 'input',
  //   label: '税源地名称',
  //   prop: 'SupplierName'
  // },
  // {
  //   cmp: 'input',
  //   label: '税源地编号',
  //   prop: 'SupplierCode'
  // },
  // {
  //   cmp: 'select',
  //   label: '签约状态',
  //   prop: 'SignStatus',
  //   options: kvSignOptions
  // },
  {
    cmp: 'select',
    label: '签约类型',
    prop: 'ContractMode',
    options: kvContractMode
  }
]

// 合同模板
export const searchContract = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入模板编号/模板名称进行搜索',
      style: { width: '280px' }
    }
  },
  {
    cmp: 'select',
    label: '模版类型',
    prop: 'templateType',
    options: [
      { key: null, label: '全部' },
      { key: 1, label: '企业/个体户' },
      { key: 3, label: '个人' }
    ]
  },
  {
    cmp: 'select',
    label: '签约方式',
    prop: 'templateSignType',
    options: [
      { key: null, label: '全部' },
      { key: 20, label: '活体电签' },
      { key: 30, label: '快捷签约' }
    ]
  },
  {
    cmp: 'select',
    label: '是否显示',
    prop: 'isShow',
    options: [
      { key: null, label: '全部' },
      { key: 0, label: '显示' },
      { key: 1, label: '不显示' }
    ]
  }
]
// 人员信息库搜索条件
export const searchPersonnelInfo = [
  {
    cmp: 'input',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入姓名/身份证/手机号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '效验等级',
    prop: 'VerifyLevel',
    options: charmStatus
  }
]

// 白名单
export const searchWhiteList = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入姓名/身份证号码/手机号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'input',
    label: '商户',
    prop: 'MerchantSearchTxt',
    config: {
      placeholder: '请输入商户号/商户名称',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '类型',
    prop: 'AuditState',
    options: [
      { key: 1, label: '待审核' },
      { key: 2, label: '审核通过' },
      { key: 3, label: '审核未通过' },
      { key: 0, label: '已撤回' }
    ]
  },
  {
    cmp: 'dtpickers',
    label: '类型',
    prop: ['StartAddDate', 'EndAddDate']
  }
]
// 黑名单
export const searchBlackList = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入姓名/身份证号码/手机号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'input',
    label: '商户',
    prop: 'MerchantSearchTxt',
    config: {
      placeholder: '请输入商户号/商户名称',
      style: { width: '300px' }
    }
  }
]
//交付物管理
export const searchDeliverable = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入商户号/商户名称',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'input',
    label: '创客姓名',
    prop: 'UsernName',
    config: {
      placeholder: '请输入创客姓名/身份证号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'dtpickers',
    label: '所属时间',
    prop: ['StartTime', 'EndTime'],
    config: {
      style: { width: '400px' }
    }
  },
  {
    cmp: 'select',
    label: '交付物上传状态',
    prop: 'DelUploadState',
    options: kvSearchUploadStatus
  },
  {
    cmp: 'select',
    label: '验收单物上传状态',
    prop: 'ApcUploadState',
    options: kvSearchUploadStatus
  },
  {
    cmp: 'select',
    label: '交付物审核状态',
    prop: 'DelAuditState',
    options: kvSearchAuditStatus
  },
  {
    cmp: 'select',
    label: '验收单审核状态',
    prop: 'ApcAuditState',
    options: kvSearchAuditStatus
  },
  { cmp: 'rangeInput', label: '发放金额', prop: ['MinAmount', 'MaxAmount'], config: { controls: false, precision: 2 } }
  // {
  //   cmp: 'input',
  //   label: '所属企业',
  //   prop: 'BeEnterprise',
  //   config: {
  //     placeholder: '请输入企业名称',
  //     style: { width: '300px' }
  //   }
  // }
]

//业务确认单
export const searchBusiness = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入商户号/商户名称',
      style: { width: '300px' }
    }
  },
  // {
  //   cmp: 'input',
  //   label: '企业名称',
  //   prop: 'MainName',
  //   config: {
  //     placeholder: '请输入企业名称',
  //     style: { width: '300px' }
  //   }
  // },
  // {
  //   cmp: 'select',
  //   label: '税源地',
  //   prop: 'SupplierName',
  //   options: [],
  //   config: {
  //     style: { width: '300px' },
  //     placeholder: '请选择税源地'
  //   }
  // },
  {
    cmp: 'dpicker',
    label: '当前月份',
    prop: 'Month',
    value: null,
    config: {
      type: 'month',
      valueFormat: 'yyyy-MM',
      placeholder: '请选择月份'
    }
  },
  {
    cmp: 'input',
    label: '税源地',
    prop: 'SupplierSearchTxt',
    config: {
      placeholder: '请输入税源地名称/税源地编号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '状态',
    prop: 'ConfirmState',
    options: kvBusinessState
  },
  {
    cmp: 'rangeInput',
    label: '总金额',
    prop: ['MinAmount', 'MaxAmount'],
    config: { controls: false, precision: 2 }
  }
  // {
  //   cmp: 'select',
  //   label: '上传状态',
  //   prop: 'UploadState',
  //   options: kvBusinessUploadState
  // },
  // {
  //   cmp: 'select',
  //   label: '审核状态',
  //   prop: 'AuditState',
  //   options: kvBusinessAuditState
  // },
]
// 完税凭证
export const searchcredentials = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入商户名称/商户号/税源地/完税名称',
      style: { width: '280px' }
    }
  },
  {
    cmp: 'dtpickers',
    label: '完税月份',
    prop: ['start', 'end'],
    config: { type: 'monthrange', startPlaceholder: '开始月份', endPlaceholder: '结束月份', valueFormat: 'yyyy-MM', style: { width: '340px' } }
  },
  {
    cmp: 'select',
    label: '完税状态',
    prop: 'TaxVoucherState',
    options: taxState
  }
]
// 角色
export const searchRole = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入角色名称',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '状态',
    prop: 'IsEenable',
    options: kvRoleEenable
  }
]

// 公告
export const searchNotice = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchKey',
    config: {
      placeholder: '请输入公告编号/公告名称',
      style: { width: '300px' }
    }
  },
  { cmp: 'dtpickers', label: '发布时间', prop: ['Start', 'End'] },
  {
    cmp: 'select',
    label: '是否开屏',
    prop: 'IsShow',
    options: [
      { key: 0, label: '不开屏' },
      { key: 1, label: '开屏' }
    ]
  }
]

export const searchSignManagement = [
  {
    cmp: 'input',
    label: '税源地编号',
    prop: 'supplierCode'
  },
  {
    cmp: 'input',
    label: '税源地名称',
    prop: 'supplierName'
  },
  {
    cmp: 'select',
    label: '签约状态',
    prop: 'signState',
    options: merchantsSignState
  },
  {
    cmp: 'select',
    label: '生效状态',
    prop: 'effectiveStatus',
    options: effectiveState
  }
]

// 数据统计 - 平台数据汇总
export const searchTerrace = [
  {
    cmp: 'dtpickers',
    label: '打款完成时间',
    prop: ['StartTime', 'EndTime']
  },
  {
    cmp: 'select',
    label: '类型',
    prop: 'QueryTimeType',
    options: [
      { key: 1, label: '按天' },
      { key: 2, label: '按月' },
      { key: 3, label: '按季' },
      { key: 4, label: '按年' }
    ]
  }
]

// 数据统计 - 商户数据汇总
export const searchMerchants = [
  {
    cmp: 'dtpickers',
    label: '打款完成时间',
    prop: ['StartTime', 'EndTime']
  },
  {
    cmp: 'select',
    label: '类型',
    prop: 'QueryTimeType',
    options: [
      { key: 1, label: '按天' },
      { key: 2, label: '按月' },
      { key: 3, label: '按季' },
      { key: 4, label: '按年' }
    ]
  },
  {
    cmp: 'input',
    label: '商户名称',
    prop: 'MerchantName'
  },
  {
    cmp: 'input',
    label: '商户编号',
    prop: 'MemberCode'
  },
  {
    cmp: 'input',
    label: '税源地',
    prop: 'SupplierName'
  },
  {
    cmp: 'input',
    label: '税源地编号',
    prop: 'SupplierCode'
  }
]

// 接单列表
export const searchOrder = [
  {
    cmp: 'input',
    label: '商户编号',
    config: { placeholder: '请输入姓名/手机号/身份证号' },
    prop: 'code'
  },
  {
    cmp: 'select',
    label: '任务',
    prop: 'task',
    options: [],
    config: {
      filterable: true,
      remote: true,
      remoteMethod: (val) => {
        let data = [
          { key: 1, label: '测试任务选择一' },
          { key: 2, label: '测试任务选择二' },
          { key: 3, label: '测试任务选择三' },
          { key: 4, label: '测试任务选择四' }
        ]
        arguments[1].searchOrder[1].options = data
      }
    }
  },
  {
    cmp: 'select',
    label: '商户',
    options: [],
    config: {
      filterable: true,
      remote: true,
      remoteMethod: (val) => {
        let data = [
          { key: 1, label: '测试商户选择一' },
          { key: 2, label: '测试商户选择二' },
          { key: 3, label: '测试商户选择三' },
          { key: 4, label: '测试商户选择四' }
        ]
        arguments[1].searchOrder[2].options = data
      }
    },
    prop: 'merchants'
  },
  {
    cmp: 'select',
    label: '状态',
    prop: 'state',
    options: orderAuditState
  }
]
// 接单审核
export const searchOrderReceiving = [
  {
    cmp: 'input',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入姓名/手机号/身份证号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    prop: 'TaskId',
    label: '任务',
    options: []
  },
  {
    cmp: 'select',
    prop: 'MerchantId',
    label: '商户',
    options: []
  },
  {
    prop: 'State',
    cmp: 'select',
    label: '状态',
    options: orderAuditState
  },
  {
    prop: 'AuditState',
    cmp: 'select',
    label: '审核状态',
    options: kvAuditState
  },
  {
    cmp: 'dtpickers',
    label: '申请时间',
    prop: ['StartTime', 'EndTime']
  }
]
// 结算明细搜索
export const searchAccountCapital = [
  {
    cmp: 'input',
    label: '商户',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入商户名称/商户号',
      style: { width: '250px' }
    }
  },
  {
    cmp: 'dtpickers',
    label: '查询时间',
    prop: ['FundTimeBegin', 'FundTimeEnd']
  },
  {
    cmp: 'select',
    label: '业务类型',
    prop: 'FundType',
    options: kvFundType
  },
  { cmp: 'input', label: '业务订单号', prop: 'FundNo', more: true },
  { cmp: 'rangeInput', label: '收支金额', prop: ['MinAmount', 'MaxAmount'], more: true, config: { controls: false, precision: 2 } },
  { cmp: 'input', label: '账户号码', prop: 'AccountNumber', more: true }
]
// 主体列表搜索
export const searchMainList = [
  { cmp: 'input', label: '企业名称', prop: 'MainName' },
  { cmp: 'input', label: '社会统一信用代码', prop: 'CreditCode' },
  {
    cmp: 'select',
    label: '授权状态',
    prop: 'AuthorState',
    options: [
      { key: 0, label: '未授权' },
      { key: 1, label: '已授权' }
    ]
  },
  {
    cmp: 'select',
    label: '认证状态',
    prop: 'AuthenState',
    options: [
      { key: 0, label: '未认证' },
      { key: 1, label: '已认证' }
    ]
  }
]
// 活体电签搜索
export const searchElectricitySign = [
  { cmp: 'input', label: '', prop: 'FundNo', config: { placeholder: '请输入创客姓名/手机号/身份证号' } },
  { cmp: 'input', label: '商户编号', prop: 'FundNo' },
  { cmp: 'input', label: '商户名称', prop: 'FundNo' },
  {
    cmp: 'select',
    label: '认证状态',
    prop: 'MerchantId',
    options: []
  },
  {
    cmp: 'select',
    label: '签约状态',
    prop: 'MerchantId',
    options: []
  },
  {
    cmp: 'select',
    label: '归档状态',
    prop: 'MerchantId',
    options: []
  }
]
// 新交付物搜索
export const searchNewDeliverable = [
  { cmp: 'input', label: '', prop: 'ExtractPlanNo', config: { placeholder: '计划编号' } },
  {
    cmp: 'dpicker',
    label: '结算所属期',
    prop: 'BelongTime',
    config: { type: 'month', valueFormat: 'yyyy-MM' }
  }
]

//人员列表
export const searchPersonList = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入商户号/商户名称',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'input',
    label: '创客姓名',
    prop: 'UsernName',
    config: {
      placeholder: '请输入创客姓名/身份证号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '交付物上传状态',
    prop: 'DelUploadState',
    options: kvSearchUploadStatus
  },
  {
    cmp: 'select',
    label: '交付物审核状态',
    prop: 'DelAuditState',
    options: kvSearchAuditStatus
  },
  { cmp: 'rangeInput', label: '发放金额', prop: ['MinAmount', 'MaxAmount'], config: { controls: false, precision: 2 } }
]
//电话复核
export const searchphoneReview = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入计划编号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'input',
    label: '商户名称',
    prop: 'UsernName',
    config: {
      style: { width: '300px' }
    }
  },
  {
    cmp: 'dpicker',
    label: '结算所属期',
    prop: 'BelongTime',
    config: { type: 'month', valueFormat: 'yyyy-MM' }
  },
  {
    cmp: 'select',
    label: '反馈结果',
    prop: 'QueryTimeType',
    options: [
      { key: null, label: '全部' },
      { key: 1, label: '待确认' },
      { key: 2, label: '合格' },
      { key: 3, label: '不合格' }
    ]
  }
]
//服务费返还
export const searchService = [
  {
    cmp: 'input',
    label: '服务费返还单号',
    prop: 'serialNo',
    config: {
      placeholder: '请输入服务费返还单号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '所属商户',
    prop: 'merchantId',
    config: {
      filterable: true,
      style: { width: '300px' }
    },
    options: []
  },
  {
    cmp: 'dtpickers',
    label: '发起时间',
    prop: ['createStart', 'createEnd']
  },
  {
    cmp: 'select',
    label: '发放状态',
    prop: 'state',
    options: [
      { key: null, label: '全部' },
      { key: 0, label: '待入账' },
      { key: 1, label: '已入账' }
    ]
  }
]
//赠送
export const searchGive = [
  {
    cmp: 'input',
    label: '业务流水号',
    prop: 'serialNo',
    config: {
      placeholder: '请输入业务流水号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '所属商户',
    prop: 'merchantId',
    config: {
      filterable: true,
      style: { width: '300px' }
    },
    options: []
  },
  {
    cmp: 'dtpickers',
    label: '发起时间',
    prop: ['createStart', 'createEnd']
  },
  {
    cmp: 'dtpickers',
    label: '发放时间',
    prop: ['finishStart', 'finishEnd']
  },
  {
    cmp: 'select',
    label: '审核状态',
    prop: 'auditState',
    options: [
      { key: null, label: '全部' },
      { key: 0, label: '待审核' },
      { key: 1, label: '审核通过' },
      { key: 2, label: '审核不通过' }
    ]
  },
  {
    cmp: 'select',
    label: '状态',
    prop: 'state',
    options: [
      { key: null, label: '全部' },
      { key: 0, label: '待入账' },
      { key: 1, label: '已入账' }
    ]
  }
]
// 商户
export const searchSms = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入短信模板编号/短信模板名称',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '分类',
    prop: 'SmsCategory',
    options: [
      { key: '短信验证码', label: '短信验证码' },
      { key: '商户登录短信', label: '商户登录短信' },
      { key: '创客短信签约', label: '创客短信签约' },
      { key: '风控通知短信', label: '风控通知短信' },
      { key: '退回短信通知', label: '退汇短信通知' }
    ]
  },
  {
    cmp: 'select',
    label: '启用/禁用',
    prop: 'IsEnable',
    options: [
      { key: 0, label: '启用' },
      { key: 1, label: '禁用' }
    ]
  }
]
