var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":"董监高列表","visible":_vm.show,"append-to-body":true,"close-on-click-modal":false,"width":"1000px"},on:{"update:visible":function($event){_vm.show=$event}}},[_c('div',{staticClass:"DJG-body"},[_c('div',{staticClass:"DJG-name"},[_c('div',[_c('span',{staticClass:"text"},[_vm._v("商户名称: ")]),_c('span',[_vm._v(_vm._s(_vm.merchantName))])]),_c('div',[_c('el-button',{attrs:{"plain":"","type":"primary","loading":_vm.getDJGBtn},on:{"click":_vm.getNewDJGList}},[_vm._v("获取最新董监高列表")])],1)]),_c('div',{staticClass:"Djg-title"},[_c('span',{staticClass:"text"},[_vm._v("董监高：")]),_c('span',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.addDjg}},[_vm._v("手动添加")])],1)]),_c('div',{staticClass:"Djg-tabel"},[_c('el-table',{ref:"merchantTariffingListTable",attrs:{"data":_vm.djgList,"border":""}},[_c('el-table-column',{attrs:{"label":"姓名"},scopedSlots:_vm._u([(_vm.djgList.length)?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"disabled":row.isEdit},model:{value:(row.boardName),callback:function ($$v) {_vm.$set(row, "boardName", $$v)},expression:"row.boardName"}})]}}:null],null,true)},[_c('template',{slot:"header"},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" 姓名 ")])],2),_c('el-table-column',{attrs:{"label":"身份证号码"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-input',{on:{"blur":function($event){return _vm.testId(row.boardIdCard, $index)}},model:{value:(row.boardIdCard),callback:function ($$v) {_vm.$set(row, "boardIdCard", $$v)},expression:"row.boardIdCard"}})]}}])}),_c('el-table-column',{attrs:{"label":"职位"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"disabled":row.isEdit},model:{value:(row.boardPosition),callback:function ($$v) {_vm.$set(row, "boardPosition", $$v)},expression:"row.boardPosition"}})]}}])},[_c('template',{slot:"header"},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" 职位 ")])],2),_c('el-table-column',{attrs:{"label":"天眼查ID"},scopedSlots:_vm._u([(_vm.djgList.length)?{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"disabled":true},model:{value:(row.hGid),callback:function ($$v) {_vm.$set(row, "hGid", $$v)},expression:"row.hGid"}})]}}:null],null,true)}),_c('el-table-column',{attrs:{"label":"操作","width":"120"},scopedSlots:_vm._u([(_vm.djgList.length)?{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(row.dataSource == 1)?_c('el-button',{staticClass:"el-button-error",attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteDjg(row, $index)}}},[_vm._v("删除")]):_vm._e()]}}:null],null,true)})],1)],1)]),_c('span',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.show = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.btnloadSubmit},on:{"click":_vm.submit}},[_vm._v("确 定")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }