<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'App',
  data() {
    return {
      time: 10,
      intervel: null
    }
  },
  mounted() {
    // 监听页面缩放，并实时清零
    // window.addEventListener('resize', () => {
    //   const tableListFixed = window.document.getElementsByClassName('el-table__fixed-body-wrapper')
    //   // const tableList = window.document.getElementsByClassName('el-table__body-wrapper')
    //   for (let item of tableListFixed) {
    //     item.style.height = ''
    //   }
    //   // for (let item of tableList) {
    //   //   item.style.height = ''
    //   // }
    // })
    sessionStorage.removeItem('LSY-hash')
    this.intervel = setInterval(() => this.getVersion(), 5 * 60 * 1000)
  },
  beforeDestroy() {
    this.intervel && clearInterval(this.intervel)
  },
  methods: {
    getVersion() {
      axios
        .get('/hash.json')
        .then((res) => {
          if (res.data && res.data.hash) {
            if (sessionStorage.hash == undefined) {
              sessionStorage.setItem('LSY-hash', res.data.hash)
            } else {
              if (sessionStorage.hash != res.data.hash) {
                sessionStorage.removeItem('LSY-hash')
                let timer = setInterval(() => {
                  this.time--
                  mes.message = `当前存在版本更新，在${this.time}秒后刷新当前页面`
                  if (this.time < 0) {
                    clearInterval(timer)
                    location.reload()
                  }
                }, 1000)
                const mes = this.$notify.warning({
                  title: '系统更新提示',
                  message: `当前存在版本更新，将在${this.time}秒后刷新当前页面`,
                  duration: 10000
                })
              }
            }
          } else {
            this.intervel && clearInterval(this.intervel)
          }
        })
        .catch((error) => {
          this.intervel && clearInterval(this.intervel)
        })
    }
  }
}
</script>

<style>
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  background-clip: padding-box;
  min-height: 28px;
}
::-webkit-scrollbar-track-piece {
  background-color: #fff;
}
</style>
