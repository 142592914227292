<template>
  <div>
    <el-dialog title="审核" :visible.sync="show" :append-to-body="true" :before-close="handlerClose" width="800px">
      <div>
        <el-descriptions
          :contentStyle="{ width: '250px', textAlign: 'left' }"
          :labelStyle="{ width: '120px', textAlign: 'right' }"
          :column="2"
          border
          title="基本信息"
        >
          <el-descriptions-item label="商户编号">{{ detail.memberCode || '无' }}</el-descriptions-item>
          <el-descriptions-item label="商户名称">{{ detail.merchantName || '无' }} </el-descriptions-item>
          <el-descriptions-item label="企业主体">{{ detail.mainName || '无' }} </el-descriptions-item>
          <el-descriptions-item label="当前月份">{{ detail.currMonth || '无' }}</el-descriptions-item>
          <el-descriptions-item label="业务确认单："
            ><span @click="downLoadWd" class="result-text">系统生成业务确认单.doc</span></el-descriptions-item
          >
        </el-descriptions>
      </div>
      <span slot="footer">
        <el-button v-if="detail.uploadState == 1 && $hasAccess('001703')" @click="showReason = true">退回</el-button>
        <el-button type="primary" @click="submitPass" :loading="btnloadSubmit">审核通过</el-button>
      </span>
    </el-dialog>
    <el-dialog width="30%" title="理由" :visible.sync="showReason" :append-to-body="true" :close-on-click-modal="false">
      <div>
        <el-input type="textarea" :rows="4" placeholder="请输入退回理由，不超过200字" v-model="reason"> </el-input>
      </div>
      <div slot="footer">
        <el-button @click="showReason = !showReason">取消</el-button>
        <el-button @click="reBack" type="primary" :loading="btnloadSubmit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formatStatus, kvAuditStatus } from '@/configs/formatters'
import ImageViewer from '@/components/tableView/imageViewer'
import WpView from '@/components/wpView'
import { downloadUrl, downloadDocx } from '@/utils'
export default {
  components: { ImageViewer, WpView },
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      showReason: false,
      id: null,
      detail: {
        attachList: []
      },
      reason: '',
      kvAuditStatus,
      isEdit: false,
      id: ''
    }
  },
  methods: {
    formatStatus,
    handlerClose() {
      this.show = false
      this.$emit('refresh')
    },
    viewPdf(e) {
      downloadUrl(e)
    },
    async open(obj) {
      this.reason = null
      this.id = obj.id
      const result = await this.$api.GetConfirmReceipts({ id: obj.id })
      result.confirmReceiptAttachs.map((item) => {
        let i = item.attachUrl.lastIndexOf('.')
        item.type = item.attachUrl.substr(i + 1)
      })
      this.detail = Object.assign({}, result)
      this.show = true
    },
    async downLoadWd() {
      await this.$api.ConfirmReceiptsTemplateCheck({ confirmReceiptsId: this.id })
      const result = await this.$api.DownloadConfirmReceiptsFile({
        confirmReceiptsId: this.id
      })
      downloadDocx(result, '业务确认单模板')
    },
    async reBack() {
      if (this.reason == '' || !this.reason) {
        this.$message.error('请输入退回原因')
        return false
      }
      this.btnloadSubmit = true
      let res = await this.$api
        .AuditNotPassConfirmReceipts({
          confirmReceiptsId: this.id,
          notPassReason: this.reason
        })
        .finally(() => {
          this.btnloadSubmit = false
        })
      if (res) {
        this.$message.success('退回成功')
        this.showReason = false
        this.open({ id: this.id })
      }
    },
    async submitPass() {
      this.btnloadSubmit = true
      const res = await this.$api
        .AuditPassConfirmReceipts({
          confirmReceiptsId: this.id
        })
        .finally(() => {
          this.btnloadSubmit = false
        })
      if (res) {
        this.$message.success('审核通过')
        this.show = false
        this.$emit('refresh')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.otherStyle {
  color: #3d78fa;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}
.result-text {
  color: #409eff;
  cursor: pointer;
}
</style>
