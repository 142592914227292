<template>
  <el-dialog :title="title" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false">
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 550px" v-if="show">
      <FormView ref="vForm" :formConfig="{ labelWidth: '140px' }" :labels="fromLabels" @onInputChange="onInputChange">
        <template v-slot:account="{ idx, form }">
          <el-col>
            <el-form-item label="付款账户">
              <span style=" margin-right: 10px;">{{ accountName }} </span>
            </el-form-item>
          </el-col>
        </template>
      </FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit(true)" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
const fromLabels = [
  {
    cmp: 'select',
    label: '选择税源地',
    prop: 'supplierId',
    options: [],
    itemConfig: {
      rules: [{ required: true, message: '选择税源地', trigger: 'blur' }]
    },
    config: {
      placeholder: '输入税源地名称搜索',
      filterable: true,
      remote: true,
      style: { width: '500px' }
    }
  },
  {
    cmp: 'select',
    label: '选择商户',
    prop: 'merchantId',
    options: [],
    itemConfig: {
      rules: [{ required: true, message: '请选择商户', trigger: 'blur' }]
    },
    config: {
      placeholder: '请输入商户编号/商户名称',
      filterable: true,
      style: { width: '500px' }
    }
  },
  {
    cmp: 'slot',
    name: 'account'
  },
  {
    cmp: 'number',
    label: '转账金额',
    prop: 'transferAmount',
    config: { style: { width: '200px' }, controls: false, precision: 2 },
    itemConfig: {
      rules: [{ required: true, message: '请输入转账金额，可精确到两位小数', trigger: 'blur' }]
    },
    afterSuffix: ' 元'
  }
]
export default {
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      title: '转账到支付宝',
      fromLabels: fromLabels,
      accountName: '无'
    }
  },
  mounted() {
    this.fromLabels.find((item) => item.prop == 'supplierId').config.remoteMethod = (val) => {
      this.getSupplierList(val)
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.accountList = []
      this.balance = 0
    },
    async open(obj) {
      this.clear()
      // 获取税源地列表
      this.getSupplierList()
      // this.getMerchantOptions()
      this.show = true
    },
    // 获取税源地列表
    async getSupplierList(SupplierName) {
      const res = await this.$api.GetSupplierListBySearch({ SupplierName })
      // 处理数据结构并赋值
      this.fromLabels.find((item) => item.prop == 'supplierId').options = (res.list || []).map((item) => {
        return Object.assign(
          {
            label: item.supplierName + '【' + item.supplierCode + '】',
            key: item.supplierId
          },
          item
        )
      })
    },
    // 获取商户备选
    async getMerchantOptions(val) {
      let v = [{key: '000000', label: '默认主户'}]
      let result = await this.$api.GetMergeMerchant({supplierId: val})
      result.list.map((item) => {
        v.push({
          key: item.merchantId,
          label: `【${item.memberCode}】${item.merchantName}`
        })
      })
      this.fromLabels.map((item) => {
        if (item.prop === 'merchantId') {
          item.options = v
        }
      })
    },
    // 获取合并账户
    async getAccount(val) {
      const res = await this.$api.GetMergeAccountByMerchant({supplierId: this.$refs.vForm.getFormItem('supplierId'), merchantId: val })
      this.$refs.vForm.upData({ accountId: res.id }, true)
      this.accountName = res.accountName || '无' + ' ' + res.accountNumber
    },
    onInputChange(val, key) {
      // 切换税源地
      if (key == 'supplierId') {
        this.getMerchantOptions(val)
        this.$refs.vForm.upData({ merchantId: undefined, accountId: undefined }, true)
        this.accountName = '无'
        this.balance = 0
        return
      }
      // 切换商户
      if (key == 'merchantId') {
        this.$refs.vForm.upData({ accountId: undefined }, true)
        this.accountName = '无'
        this.getAccount(val)
        return
      }
    },
    // 提交
    async submit() {
      // 结果更新
      const params = await this.$refs.vForm.getForm()
      const res = await this.$api.AddTransferAlipay(params).finally(() => {
        this.btnloadSubmit = false
      })
      this.$message.success('转账支付宝成功')
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
