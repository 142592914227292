<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView
      ref="vTable"
      :labels="tableLabels"
      apiName="GetSmsTemplatePageList"
      :searchList="searchList"
      @onSwitch="onSwitch"
    >
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item-left" v-if="$hasAccess('000300')">
        <el-button
          plain
          type="primary"
          icon="el-icon-plus"
          @click="$refs.Edit.open()"
          >添加</el-button
        >
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="180" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button
            v-if="$hasAccess('000302')"
            type="text"
            @click="$refs.Info.open(row)"
            >查看</el-button
          >
          <!-- <el-button type="text" @click="$refs.Edit.open(row)">编辑</el-button> -->
          <el-button
            v-if="$hasAccess('000303') && row.isEdit == 0"
            type="text"
            @click="$refs.Edit.open(row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </TableView>
    <!-- 弹窗 -->
    <Edit ref="Edit" @submit="$refs.vTable.refreshTable()"></Edit>
    <Info ref="Info"></Info>
  </div>
</template>
<script>
import { tableSms } from "@/configs/tables";
import { searchSms } from "@/configs/searches";
import Edit from "./edit";
import Info from "./info";
export default {
  components: { Edit, Info },
  data() {
    return {
      tableLabels: tableSms,
      searchList: searchSms,
    };
  },
  methods: {
    // 表格内的切换事件
    async onSwitch(val, key, row, set) {
      if (key == "isEnable") {
        if (!this.$hasAccess("000301"))
          return this.$message.error("您暂无权限禁用/启用");
        // 禁用启用
        const params = { id: row.id };
        const res = await this.$api.EnableSmsTemplate(params);
        set();
      }
    },
  },
};
</script>
