<template>
    <el-dialog :title="isEdit ? '处理' : '查看'" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false"
        width="800px">
        <!-- 内容区域 -->
        <el-descriptions title="基本信息" :column="2" border :labelStyle="{ width: '120px', textAlign: 'right' }" style="margin-bottom:18px">
            <el-descriptions-item label="打款时间">{{ detail.payTime || '无' }}</el-descriptions-item>
            <el-descriptions-item label="流水号">{{ detail.exceptionNo || '无' }}</el-descriptions-item>
            <el-descriptions-item label="商户名称">{{ detail.merchantName || '无' }}</el-descriptions-item>
            <el-descriptions-item label="商户编号">{{ detail.memberCode || '无' }}</el-descriptions-item>
            <el-descriptions-item label="付款账户名称">{{ detail.payerAccountName || '无' }}</el-descriptions-item>
            <el-descriptions-item label="付款账户号码">{{ detail.payerAccountNumber || '无' }}</el-descriptions-item>
            <el-descriptions-item label="收款账户名称">{{ detail.merchantAccountName || '无' }}</el-descriptions-item>
            <el-descriptions-item label="收款账户号码">{{ detail.merchantAccountNumber || '无' }}</el-descriptions-item>
            <el-descriptions-item label="收款开户银行">{{ detail.merchantAccountBank || '无' }}</el-descriptions-item>
            <el-descriptions-item label="充值金额"><span v-format="'#,##0.00'">{{ detail.exceptionAmount || 0
}}</span></el-descriptions-item>
        </el-descriptions>
        <el-descriptions v-if="!isEdit" title="处理结果" border :column="2"
            :labelStyle="{ width: '120px', textAlign: 'right' }">
            <el-descriptions-item label="处理时间">{{ detail.processingTime || '无' }}</el-descriptions-item>
            <el-descriptions-item label="处理人">{{ detail.processingPerson || '无' }}</el-descriptions-item>
            <el-descriptions-item label="处理结果" :span="detail.processingResult == 2 ? 1 : 2">{{
        formatStatus(kvExceptionResult, detail.processingResult, '')
}}</el-descriptions-item>
            <el-descriptions-item v-if="detail.processingResult == 2" label="退回状态">{{ formatStatus(kvReturnState,
        detail.returnState)
}}</el-descriptions-item>
            <el-descriptions-item label="备注" :span="2">{{ detail.processingDesc || '无' }}</el-descriptions-item>
        </el-descriptions>
        <div v-if="show && isEdit">
            <FormView ref="vForm" :labels="formLabels" @onInputChange="onInputChangeNew"> </FormView>
        </div>
        <span v-if="isEdit" slot="footer">
            <el-button @click="show = false">取消</el-button>
            <el-button type="primary" @click="submit" :loading="btnloadSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>
  
<script>
import { formatStatus, kvExceptionState, kvExceptionResult } from '@/configs/formatters.js'
import { deepClone } from '@/utils/index.js'
let formLabels = [
    {
        cmp: 'select',
        label: '处理结果',
        prop: 'result',
        options: kvExceptionResult,
        itemConfig: {
            rules: [{ required: true, message: '请选择处理结果', trigger: 'change' }]
        }
    },
    {
        cmp: 'input',
        label: '银行名称',
        prop: 'bankName',
        id: 'a',
        show: 'false',
        itemConfig: {
            rules: [{ required: true, message: '请完善付款账号的银行名称', trigger: 'blur' }]
        }
    },
    {
        cmp: 'input',
        label: '银行名称',
        prop: 'bankName',
        id: 'b',
        show: 'false',
        itemConfig: {
            rules: [{ required: false }]
        }
    },
    {
        cmp: 'input',
        label: '描述',
        prop: 'desc',
        itemConfig: {
            rules: [{ required: true, message: '请输入描述', trigger: 'blur' }]
        }
    }
]
export default {
    data() {
        return {
            btnloadSubmit: false,
            isEdit: false,
            id: null,
            show: false,
            detail: {},
            kvExceptionResult,
            kvReturnState: [
                { key: 1, label: '退回中' },
                { key: 2, label: '退回成功' },
                { key: 3, label: '退回失败' },
            ],
            formLabels: deepClone(formLabels)
        }
    },
    methods: {
        formatStatus,
        // 数据清空
        clear() {
            this.id = null
            this.detail = {}
            this.formLabels = deepClone(formLabels);
        },
        open(obj, isEdit) {
            this.clear()
            this.isEdit = isEdit
            if (obj && obj.id) {
                // 查看
                this.id = obj.id
                this.show = true
                this.getDetail()
            } else {
                this.$message.error('无效的信息')
            }
        },
        async getDetail() {
            const res = await this.$api.GetRechargeException({ Id: this.id })
            this.detail = res
        },
        async submit() {
            if ( this.btnloadSubmit ) return false
            const form = await this.$refs.vForm.getForm()
            this.btnloadSubmit = true
            const res = await this.$api.ProcessingRechargeException({ ...form, id: this.id }).finally(() => {
                this.btnloadSubmit = false
            })

            this.$message.success('已提交处理结果')
            this.$emit('submit')
            this.show = false
        },
        onInputChangeNew (val, key) {
            if(key == 'result'){
                let resultValue = this.$refs.vForm.getFormItem('result')
                if(this.detail.merchantAccountBank == '平安银行' && resultValue == '2' ) {
                    this.formLabels.find((item) => item.id == 'a').show = 'false';
                    this.formLabels.find((item) => item.id == 'b').show = 'true';
                } else if ( this.detail.merchantAccountBank == '招商银行' && resultValue == '2') {
                    this.formLabels.find((item) => item.id == 'a').show = 'true';
                    this.formLabels.find((item) => item.id == 'b').show = 'false';
                } else {
                    this.formLabels.find((item) => item.id == 'a').show = 'false';
                    this.formLabels.find((item) => item.id == 'b').show = 'false';
                }
            }
        }
    }
}
</script>
  