<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetBusinessMainPageList" :searchList="searchList">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item-left" v-if="$hasAccess('007600')">
        <el-button plain type="primary" icon="el-icon-plus" @click="$refs.Add.open()">添加</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="250" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button v-if="row.authenState == 0 && row.authorState == 0" type="text" @click="openUrl(row.authenUrl)">认证链接</el-button>
          <el-button v-if="row.authenState == 1 && row.authorState == 0" type="text" @click="openUrl(row.authorUrl)">授权链接</el-button>
          <el-button v-if="row.authenState == 1 && row.authorState == 1" type="text" @click="openUrl(row.authorContractUrl)">下载授权证书</el-button>
          <el-button v-if="row.authenState == 1 && row.authorState == 0" type="text" @click="$refs.Msg.open(row)">重新认证</el-button>
        </template>
      </el-table-column>
    </TableView>
    <Add @submit="updateDialogTemplate" ref="Add"></Add>
    <Msg @submit="updateDialogTemplate" ref="Msg"></Msg>
  </div>
</template>
<script>
import { tableMainList } from '@/configs/tables'
import { searchMainList } from '@/configs/searches'
import Add from './add'
import Msg from './msg'
export default {
  components: { Add, Msg },
  data() {
    return {
      tableLabels: tableMainList,
      searchList: searchMainList
    }
  },
  methods: {
    // 表格内的切换事件
    async sendMsg() {
      const res = await this.$api.SendSupplierAuthMsg(form)
      this.$message.success('发送成功')
    },
    openUrl(e) {
      if (e) {
        window.open(e)
      } else {
        this.$message.error('暂未生成链接')
      }
    },
    updateDialogTemplate() {
      this.$refs.vTable.refreshTable()
    }
  }
}
</script>
