<template>
  <div class="flex-home">
    <Item
      v-for="item in formDetail"
      :prop="item"
      :type="item.parameterType"
      :key="item.id"
      @submit="submit"
      @updateValue="updateValue"
    >
      <template v-slot:suffix="{ data }">
        <span v-if="data == 'MonthMaxRemitAmount'"> 元</span>
        <span v-if="data == 'SetAgeLimit'"> 岁</span>
      </template>
    </Item>
    <div class="cardItem" v-if="deliverableDetail.length">
      <el-card shadow="never">
        <el-form
          :rules="rules"
          ref="formDeliverableDetail"
          :model="formDeliverableDetail"
          label-position="left"
        >
          <el-form-item
            v-for="(item, index) in deliverableDetail"
            :key="index"
            :prop="item.parameterCode"
            :label="item.parameterName"
          >
            <template v-if="item.parameterCode == 'TaskDeliverableAmountLimit'">
              <el-col :span="4">
                <el-select
                  @change="changeAmount"
                  style="width: 100%"
                  v-model="formDeliverableDetail.TaskDeliverableAmountLimit"
                  placeholder="请选择"
                >
                  <el-option label="不限制金额范围" :value="0"></el-option>
                  <el-option label="限制金额范围" :value="1"></el-option>
                </el-select>
              </el-col>
              <el-col
                v-if="formDeliverableDetail.TaskDeliverableAmountLimit == 1"
                :offset="1"
                :span="15"
              >
                <el-col :span="5">
                  <el-input
                    @input="$forceUpdate()"
                    style="width: 100%"
                    @blur="
                      vilAmount(
                        formDeliverableDetail.DeliverableMinAmount,
                        'min'
                      )
                    "
                    v-model.number="formDeliverableDetail.DeliverableMinAmount"
                  ></el-input>
                </el-col>
                <el-col style="text-align: center" :span="2">-</el-col>
                <el-col :span="5">
                  <el-input
                    @input="$forceUpdate()"
                    style="width: 100%"
                    @blur="
                      vilAmount(
                        formDeliverableDetail.DeliverableMaxAmount,
                        'max'
                      )
                    "
                    v-model.number="formDeliverableDetail.DeliverableMaxAmount"
                  ></el-input>
                </el-col>
              </el-col>
            </template>
            <template v-if="item.parameterCode == 'TaskDeliverableCountLimit'">
              <el-input-number
                :controls="false"
                :min="0"
                :max="100"
                v-model="formDeliverableDetail.DeliverableCountLimit"
              />
              %
            </template>
          </el-form-item>
        </el-form>
        <div style="margin: 18px 0">
          <el-button
            v-if="$hasAccess('002200')"
            type="primary"
            @click="submitDeliverable"
            >保存</el-button
          >
        </div>
      </el-card>
    </div>
    <div class="cardItem" v-if="forbiddenList.length">
      <el-card shadow="never">
        <el-form
          ref="formForbiddenDetail"
          :model="formForbiddenDetail"
          label-position="left"
        >
          <el-form-item
            v-for="(item, index) in forbiddenList"
            :key="index"
            :prop="item.parameterCode"
            :label="item.parameterName"
          >
            <template v-if="item.parameterCode == 'SetSystemForbidTime'">
              <el-col :span="4">
                <el-select
                  @change="changeAmount"
                  style="width: 100%"
                  v-model="formForbiddenDetail.forbiddenLimit"
                  placeholder="请选择"
                >
                  <el-option label="不限制" value="0"></el-option>
                  <el-option label="限制" value="1"></el-option>
                </el-select>
              </el-col>
              <el-col
                style="position: relative"
                v-if="formForbiddenDetail.forbiddenLimit == 1"
                :offset="1"
                :span="15"
              >
                <el-col :span="20">
                  <el-col :span="5">
                    <el-time-picker
                      v-model="formForbiddenDetail.createTime"
                      placeholder="选择时间范围"
                      :editable="false"
                      value-format="HH:mm:ss"
                      format="HH:mm:ss"
                      @change="changeAmount"
                    >
                    </el-time-picker>
                  </el-col>
                  <el-col style="margin: 0 10px" :span="2"> - </el-col>
                  <el-col :span="5">
                    <el-time-picker
                      v-model="formForbiddenDetail.endTime"
                      placeholder="选择时间范围"
                      :editable="false"
                      value-format="HH:mm:ss"
                      format="HH:mm:ss"
                      @change="changeAmount"
                    >
                    </el-time-picker>
                  </el-col>
                </el-col>
                <div
                  style="position: absolute; top: 38px"
                  v-if="
                    formForbiddenDetail.createTime &&
                    formForbiddenDetail.endTime
                  "
                >
                  表示{{ formForbiddenDetail.createTime }}到{{
                    formForbiddenDetail.endTime
                  }}都不允许发放
                </div>
              </el-col>
            </template>
          </el-form-item>
        </el-form>
        <div style="margin: 18px 0">
          <el-button type="primary" @click="submitForbidden">保存</el-button>
        </div>
      </el-card>
    </div>
    <div class="cardItem" v-if="periodsTimeList.length">
      <el-card shadow="never">
        <el-form
          ref="formperiodsTimeDetail"
          :model="formperiodsTimeDetail"
          label-position="left"
        >
          <el-form-item
            v-for="(item, index) in periodsTimeList"
            :key="index"
            :prop="item.parameterCode"
            :label="item.parameterName"
          >
            <template v-if="item.parameterCode == 'SetSystemForbidPeriodsTime'">
              <el-col :span="4">
                <el-select
                  @change="changeAmount"
                  style="width: 100%"
                  v-model="formperiodsTimeDetail.forbiddenLimit"
                  placeholder="请选择"
                >
                  <el-option label="不限制" value="0"></el-option>
                  <el-option label="限制" value="1"></el-option>
                </el-select>
              </el-col>
              <el-col
                style="position: relative"
                v-if="formperiodsTimeDetail.forbiddenLimit == 1"
                :offset="1"
                :span="15"
              >
                <el-col :span="20">
                  <el-date-picker
                    @change="changePeriods"
                    v-model="formperiodsTime"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :picker-options="pickerOptions"
                  >
                  </el-date-picker>
                  <!-- <el-col :span="5">
                    <el-time-picker
                      v-model="formperiodsTimeDetail.createTime"
                      placeholder="选择时间范围"
                      :editable="false"
                      value-format="HH:mm:ss"
                      format="HH:mm:ss"
                      @change="changeAmount"
                    >
                    </el-time-picker>
                  </el-col>
                  <el-col style="margin: 0 10px" :span="2"> - </el-col>
                  <el-col :span="5">
                    <el-time-picker
                      v-model="formperiodsTimeDetail.endTime"
                      placeholder="选择时间范围"
                      :editable="false"
                      value-format="HH:mm:ss"
                      format="HH:mm:ss"
                      @change="changeAmount"
                    >
                    </el-time-picker>
                  </el-col> -->
                </el-col>
                <div
                  style="position: absolute; top: 38px"
                  v-if="
                    formperiodsTimeDetail.createTime &&
                    formperiodsTimeDetail.endTime
                  "
                >
                  表示{{ formperiodsTimeDetail.createTime }}到{{
                    formperiodsTimeDetail.endTime
                  }}都不允许发放
                </div>
              </el-col>
            </template>
          </el-form-item>
        </el-form>
        <div style="margin: 18px 0">
          <el-button type="primary" @click="submitPeriods">保存</el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import Item from "./item.vue";
export default {
  components: { Item },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      formDetail: [],
      deliverableDetail: [],
      formDeliverableDetail: {},
      forbiddenList: [],
      formperiodsTime: [],
      formForbiddenDetail: {
        createTime: null,
        endTime: null,
      },
      periodsTimeList: [],
      formperiodsTimeDetail: {
        createTime: null,
        endTime: null,
      },
      rules: {
        TaskDeliverableAmountLimit: [
          { required: true, message: "请选择交付物金额范围", trigger: "blur" },
        ],
        DeliverableCountLimit: [
          { required: true, message: "请输入交付数量范围", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submit(data) {
      const { code } = data;
      const { parameterCode, parameterValue } = this.formDetail.find(
        (item) => item.parameterCode == code
      );
      if (parameterCode) {
        switch (parameterCode) {
          case "MonthMaxRemitAmount": {
            this.submitOne("SaveSingleParameter", {
              parameterCode: 1,
              parameterValue,
            });
            break;
          }
          case "TaskSensitivityWord": {
            this.submitOne("SaveSingleParameter", {
              parameterCode: 0,
              parameterValue,
            });
            break;
          }
          case "LoginSetProhibit": {
            this.submitOne("SetLoginProhibit", {
              setParameterValue: parameterValue && parameterValue != 0 ? 1 : 0,
            });
            break;
          }
          case "SetAgeLimit": {
            this.submitOne("SetAgeLimit", {
              setParameterValue: parameterValue,
            });
            break;
          }
        }
      }
    },
    // 提交保存
    submitOne(api, params) {
      this.$api.SaveSetSystem(api, params).then((res) => {
        if (res) {
          this.$message.success("设置成功");
        }
      });
    },
    updateValue(data) {
      const { code, value, idx } = data;
      this.formDetail.forEach((item) => {
        if (item.parameterCode == code) {
          let parameterValue = null;
          if (idx && idx == 1) {
            parameterValue = JSON.parse(item.parameterValue);
            parameterValue[0] = value;
            parameterValue = JSON.stringify(parameterValue);
          } else if (idx && idx == 2) {
            parameterValue = JSON.parse(item.parameterValue);
            parameterValue[1] = value;
            parameterValue = JSON.stringify(parameterValue);
          } else {
            parameterValue = value;
          }
          item.parameterValue = parameterValue;
        }
      });
    },
    vilAmount(e, type) {
      let flag = new RegExp("^[1-9]([0-9])*$").test(e);
      if (!flag) {
        this.$message.error("仅能输入数字类型");
        e = null;
      }
    },

    changeAmount(e) {
      this.$forceUpdate();
    },
    changePeriods(e) {
      this.formperiodsTimeDetail.createTime = e[0];
      this.formperiodsTimeDetail.endTime = e[1];
    },
    async submitDeliverable() {
      this.$refs.formDeliverableDetail.validate(async (valid) => {
        if (valid) {
          if (
            this.formDeliverableDetail.DeliverableMinAmount >
            this.formDeliverableDetail.DeliverableMaxAmount
          ) {
            this.$message.error("最小金额不能大于最大金额");
            return false;
          }
          let res = await this.$api.SetTaskDeliverable({
            amountLimit: JSON.stringify([
              this.formDeliverableDetail.TaskDeliverableAmountLimit,
              this.formDeliverableDetail.DeliverableMinAmount,
              this.formDeliverableDetail.DeliverableMaxAmount,
            ]),
            countLimit:
              this.formDeliverableDetail.DeliverableCountLimit.toString(),
          });
          if (res) {
            this.$message.success("设置成功");
          }
          // },
        }
      });
    },
    async submitForbidden() {
      let res = await this.$api.SetSystemForbidTime({
        setParameterValue: JSON.stringify([
          this.formForbiddenDetail.forbiddenLimit,
          this.formForbiddenDetail.createTime,
          this.formForbiddenDetail.endTime,
        ]),
      });
      this.$message.success("设置成功");
    },
    async submitPeriods() {
      let res = await this.$api.SetSystemForbidPeriodsTime({
        setParameterValue: JSON.stringify([
          this.formperiodsTimeDetail.forbiddenLimit,
          this.formperiodsTimeDetail.createTime,
          this.formperiodsTimeDetail.endTime,
        ]),
      });
      this.$message.success("设置成功");
    },
    handlerData() {
      this.deliverableDetail.map((item) => {
        let p = JSON.parse(item.parameterValue);
        if (item.parameterCode == "TaskDeliverableAmountLimit") {
          this.formDeliverableDetail.TaskDeliverableAmountLimit = p[0];
          this.formDeliverableDetail.DeliverableMinAmount = p[1];
          this.formDeliverableDetail.DeliverableMaxAmount = p[2];
        } else if (item.parameterCode == "TaskDeliverableCountLimit") {
          this.formDeliverableDetail.DeliverableCountLimit =
            +item.parameterValue;
        }
      });
      this.forbiddenList.map((item) => {
        if (item.parameterCode == "SetSystemForbidTime") {
          item.parameterValue = item.parameterValue.replaceAll("'", '"');
          let p = JSON.parse(item.parameterValue);
          this.formForbiddenDetail.forbiddenLimit = p[0];
          this.formForbiddenDetail.createTime = p[1];
          this.formForbiddenDetail.endTime = p[2];
        }
      });
      this.periodsTimeList.map((item) => {
        if (item.parameterCode == "SetSystemForbidPeriodsTime") {
          item.parameterValue = item.parameterValue.replaceAll("'", '"');
          let p = JSON.parse(item.parameterValue);
          this.formperiodsTimeDetail.forbiddenLimit = p[0];
          this.formperiodsTimeDetail.createTime = p[1];
          this.formperiodsTimeDetail.endTime = p[2];
          this.formperiodsTime = [
            this.formperiodsTimeDetail.createTime,
            this.formperiodsTimeDetail.endTime,
          ];
        }
      });
    },
  },

  mounted() {
    this.$api.GetSystemParameterList().then((res) => {
      if (res) {
        // this.formDetail = res.list
        this.formDetail = res.list.filter((item) => {
          return (
            item.parameterCode != "TaskDeliverableAmountLimit" &&
            item.parameterCode != "TaskDeliverableCountLimit" &&
            item.parameterCode != "SetSystemForbidTime" &&
            item.parameterCode != "SetSystemForbidPeriodsTime"
          );
        });
        res.list.find((item) => {
          if (
            item.parameterCode == "TaskDeliverableAmountLimit" ||
            item.parameterCode == "TaskDeliverableCountLimit"
          ) {
            this.deliverableDetail.push(item);
            return false;
          }
        });
        res.list.find((item) => {
          if (item.parameterCode == "SetSystemForbidTime") {
            this.forbiddenList.push(item);
            return false;
          }
        });
        res.list.find((item) => {
          if (item.parameterCode == "SetSystemForbidPeriodsTime") {
            this.periodsTimeList.push(item);
            return false;
          }
        });
        this.handlerData();
      }
    });
  },
};
</script>

<style scoped lang="scss">
.flex-home {
  .form {
    display: flex;
    div {
      margin-right: 10px;
    }
  }
}
</style>
