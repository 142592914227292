<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vtable" :labels="labels" apiName="GetMergeAlipayAccountPageList" :searchList="searchList" @geted="getAmount">
      <el-form-item slot="btnline-item-left">
        <el-button style="margin-right: 15px;" type="text" @click="$refs.Setting.open()">差额提醒设置</el-button>
        累计转入金额：<span v-format="'#,##0.00'" style="margin-right: 15px;">{{ amount.totalInAmount || 0 }}</span>累计支付宝订单金额：<span v-format="'#,##0.00'" style="margin-right: 15px;">{{ amount.totalOutAmount || 0 }}</span>累计差额：<span v-format="'#,##0.00'" style="margin-right: 15px;">{{ amount.totalDiffAmount || 0 }}</span>
      </el-form-item>
      <el-form-item slot="btnline-item">
        <el-button plain type="primary" @click="exportData" :loading="btnloadDownload">导出</el-button>
      </el-form-item>
      <el-table-column label="支付宝订单金额" slot="table-item">
        <template v-slot="{ row, $index }">
          <el-button type="text" @click="$refs.Detail.open(row)"><span v-format="'#,##0.00'">{{ row.outAmount || 0 }}</span></el-button>
        </template>
      </el-table-column>
      <el-table-column label="差额" slot="table-item">
        <template v-slot="{ row, $index }">
          <span v-format="'#,##0.00'">{{ row.diffAmount || 0 }}</span>
        </template>
      </el-table-column>
    </TableView>
    <Detail ref="Detail" />
    <Setting ref="Setting" />
  </div>
</template>

<script>
import { downloadExcel } from '@/utils'
import Detail from './detail.vue'
import Setting from './setting.vue'
const searchList = [
  {
    cmp: 'input',
    label: '税源地名称',
    prop: 'supplierName'
  },
  {
    cmp: 'dtpickers',
    label: '日期',
    prop: ['beginTime', 'endTime']
  },
]
const labels = [
  { label: '日期', prop: 'currDateTime' },
  { label: '税源地名称', prop: 'supplierName' },
  { cmp: 'money', label: '转入金额', prop: 'inAmount' },
]
export default {
  components: { Detail, Setting },
  data() {
    return {
      btnloadDownload: false,
      labels: labels,
      searchList: searchList,
      amount: {}
    }
  },
  methods: {
    async exportData() {
      this.btnloadDownload = true
      const params = this.$refs.vtable.getParams()
      const res = await this.$api.ExportMergeAlipayAccountPageList(params).finally(() => {
        this.btnloadDownload = false
      })
      downloadExcel(res, '合并账户支付宝统计')
    },
    getAmount(res, query) {
      this.$api.GetMergeAlipayAccountStatisticsData(query).then((res) => {
        this.amount = res
      })
    }
  }
}
</script>
