<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView
      ref="vTable"
      :labels="tableLabels"
      :initSearch="{ SearchTxt: fundNo }"
      apiName="GetRechargeLogPageList"
      :searchList="searchList"
      @geted="getAmount"
    >
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item" v-if="$hasAccess('001301')">
        <el-button plain type="primary" icon="el-icon-download" @click="download" :loading="btnloadDownload">导出</el-button>
      </el-form-item>
      <!-- <el-form-item slot="btnline-item-left" v-if="$hasAccess('001302')">
        <span class="v-sel-info">已选择 {{ multipleSelection.length || 0 }} 项</span>
        <el-button plain type="primary" @click="multipleCheck">批量上帐</el-button>
      </el-form-item> -->
      <el-card shadow="never" slot="ext-table" style="margin-bottom: 18px">
        充值总金额（元）：<span v-format="'#,##0.00'">{{ amount }}</span>
      </el-card>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="150" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button type="text" @click="$refs.Detail.open(row, false)">查看</el-button>
          <!-- 此处记得加状态判断！ -->
          <el-button v-if="$hasAccess('001303') && row.auditState == 0 && row.rechargeState != 2" type="text" @click="$refs.Edit.open(row)"
            >审核</el-button
          >
          <el-button
            v-if="$hasAccess('001304') && row.rechargeState == 3 && row.auditState == 1"
            type="text"
            @click.stop="$refs.Detail.open(row, true)"
            >确认上账</el-button
          >
        </template>
      </el-table-column>
    </TableView>
    <Detail @submit="asyncRefreshTable" ref="Detail" />
    <Edit @submit="asyncRefreshTable" ref="Edit" />
  </div>
</template>

<script>
import { tableRechargeLog } from '@/configs/tables'
import { searchRechargeLog } from '@/configs/searches'
import { downloadExcel } from '@/utils'
import Detail from './detail'
import Edit from './edit'
export default {
  components: { Detail, Edit },
  data() {
    return {
      btnloadDownload: false,
      amount: 0,
      tableLabels: tableRechargeLog,
      searchList: searchRechargeLog,
      multipleSelection: []
    }
  },
  computed: {
    fundNo() {
      return this.$route.query.fundNo
    }
  },
  methods: {
    selectable(row) {
      if ((row.accountType == 2 || row.accountType == 4) && row.rechargeState == 1) return true
      return false
    },
    selChange(val) {
      this.multipleSelection = val
    },
    async download() {
      this.btnloadDownload = true
      const params = this.$refs.vTable.getParams()
      // if (!params.createTimeBegin || !params.createTimeEnd) return this.$message.warning('请选择创建时间段')
      const res = await this.$api.ExportRechargeLogList(params).finally(() => {
        this.btnloadDownload = false
      })
      downloadExcel(res, '充值记录')
    },
    // 确定上账
    async InAccount(row) {
      // 判断有没有2级税目
      await this.$confirm(`确定上账当前记录吗？`, '确定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      const res = await this.$api.InAlipayAccountBalance({ FinanceRechargeId: row.id })
      this.$message.success('上账完成')
      this.$refs.vTable.refreshTable()
    },

    getAmount(response, params) {
      const { pageIndex, pageSize, ...rest } = params
      this.$api.GetRechargeAmountStatistics(rest).then((res) => {
        this.amount = res.rechargeAmount
      })
    },
    // 表格延迟刷新
    asyncRefreshTable() {
      setTimeout(() => {
        this.$refs.vTable.refreshTable()
      }, 0)
    },
    async multipleCheck() {
      if (!this.multipleSelection.length) return this.$message.warning('请选择需要批量处理的数据')
      await this.$confirm(`确定上账当前记录吗？`, '确定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      const res = await this.$api.BatchInAlipayAccountBalance({ financeRechargeIdList: this.multipleSelection.map((item) => item.id) })
      this.$message.success('上账完成')
      this.$refs.vTable.refreshTable()
    }
  }
}
</script>
