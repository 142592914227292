<template>
  <el-dialog title="查看" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="1100px">
    <!-- 内容区域 -->
    <el-descriptions :column="2" :labelStyle="{ width: '200px', textAlign: 'right' }">
      <el-descriptions-item label="商户号">{{ detail.memberCode || '--' }}</el-descriptions-item>
      <el-descriptions-item label="登录账号">{{ detail.loginUser || '--' }}</el-descriptions-item>
      <el-descriptions-item label="商户名称">{{ detail.merchantName || '--' }}</el-descriptions-item>
      <el-descriptions-item label="手机号">{{ detail.mobile || '--' }}</el-descriptions-item>
      <el-descriptions-item label="社会统一信用代码">{{ detail.creditCode || '--' }}</el-descriptions-item>
      <el-descriptions-item label="业务类型"><span v-html="formatStatus(kvBM, detail.businessType)"></span></el-descriptions-item>
      <el-descriptions-item label="认证状态"><span v-html="formatStatus(kvIsAuthentication, detail.isAuthentication)"></span></el-descriptions-item>
      <el-descriptions-item label="组织类型"><span v-html="formatStatus(kvOrganizationType, detail.organizationType)"></span></el-descriptions-item>
      <el-descriptions-item label="企业名称">{{ detail.mainName || '--' }}</el-descriptions-item>
      <el-descriptions-item label="营业执照照片"><ImageViewer :path="detail.businessLicense" /></el-descriptions-item>
      <el-descriptions-item label="实际操作人"><span v-html="formatStatus(kvIsEntrust, detail.isEntrust)"></span></el-descriptions-item>
      <template v-if="detail.isEntrust == 0">
        <el-descriptions-item label="法人姓名">{{ detail.juridicalName || '--' }}</el-descriptions-item>
        <el-descriptions-item label="法人手机号">{{ detail.juridicalTel || '--' }}</el-descriptions-item>
        <el-descriptions-item label="法人身份证号">{{ detail.juridicalIdcard || '--' }}</el-descriptions-item>
        <el-descriptions-item label="法人身份证正面照片"><ImageViewer :path="detail.juridicalIdcardFront" /></el-descriptions-item>
        <el-descriptions-item label="法人身份证反面照片"><ImageViewer :path="detail.juridicalIdcardBack" /></el-descriptions-item>
      </template>
      <template v-if="detail.isEntrust == 1">
        <el-descriptions-item label="委托代理人姓名">{{ detail.consignorName || '--' }}</el-descriptions-item>
        <el-descriptions-item label="委托代理人身份证号">{{ detail.consignorIdcard || '--' }}</el-descriptions-item>
        <el-descriptions-item label="委托代理人身份证正面照片"><ImageViewer :path="detail.consignorIdcardFront" /></el-descriptions-item>
        <el-descriptions-item label="委托代理人身份证反面照片"><ImageViewer :path="detail.consignorIdcardBack" /></el-descriptions-item>
        <el-descriptions-item label="委托代理人手机号">{{ detail.consignorTel || '--' }}</el-descriptions-item>
        <el-descriptions-item label="代理人授权委托书"><ImageViewer :path="detail.consignorAuthorization" /></el-descriptions-item>
        <el-descriptions-item label="企业对公账户名称">{{ detail.accountName || '--' }}</el-descriptions-item>
        <el-descriptions-item label="企业对公开户银行">{{ detail.accountBank || '--' }}</el-descriptions-item>
        <el-descriptions-item label="企业对公银行账户">{{ detail.accountNumber || '--' }}</el-descriptions-item>
      </template>
    </el-descriptions>
    <el-descriptions :column="2" :labelStyle="{ width: '200px', textAlign: 'right' }">
      <el-descriptions-item label="来源">{{ detail.source || '--' }}</el-descriptions-item>
      <el-descriptions-item label="渠道归属">{{ detail.channelName || '--' }}</el-descriptions-item>
      <!-- <el-descriptions-item label="归属通道">{{ detail.attributionChannel || '--' }}</el-descriptions-item> -->
      <el-descriptions-item label="商务归属">{{
        detail.commerceList && detail.commerceList.length ? flatCommerceList(detail.commerceList) : '--'
      }}</el-descriptions-item>
      <el-descriptions-item label="联系人">{{ detail.contract || '--' }}</el-descriptions-item>
      <el-descriptions-item label="联系方式">{{ detail.contractPhone || '--' }}</el-descriptions-item>
      <el-descriptions-item label="备注" :span="2">{{ detail.remark || '无' }}</el-descriptions-item>
    </el-descriptions>
  </el-dialog>
</template>

<script>
import { formatStatus, kvOrganizationType, kvIsEntrust, kvIsAuthentication, kvBM } from '@/configs/formatters'
import ImageViewer from '@/components/tableView/imageViewer'
export default {
  components: { ImageViewer },
  data() {
    return {
      id: null,
      show: false,
      detail: {},
      kvOrganizationType,
      kvIsEntrust,
      kvIsAuthentication,
      kvBM
    }
  },
  methods: {
    formatStatus,
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    async open(obj) {
      this.clear()
      if (obj && obj.merchantId) {
        // 编辑
        this.id = obj.merchantId
        await this.getDetail()
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    },
    async getDetail() {
      const res = await this.$api.GetMerchantInfoDetail({ merchantId: this.id })
      this.detail = res
    },
    flatCommerceList(list) {
      const strList = list.map((it) => it.commerceName)
      return strList.join('；')
    }
  }
}
</script>
