<template>
  <el-dialog
    :title="id ? '编辑' : '开票登记'"
    :visible.sync="show"
    :append-to-body="true"
    :close-on-click-modal="false"
    width="800px"
    @close="closeDialog"
  >
    <div v-if="show">
      <FormView ref="vForm" :labels="fromLabels" @fileUploaded="fileUploaded" :formConfig="{ labelWidth: '150px' }">
        <template v-slot:taxItem="{ form }">
          <el-col :span="24">
            <el-form-item label="开票税目" prop="taxItemsId" :rules="[{ required: true, message: '请选择开票税目', trigger: 'blur' }]">
              <el-cascader style="width: 300px" v-model="form.taxItemsId" :options="options" :props="props" filterable placeholder="请选择开票税目">
              </el-cascader>
            </el-form-item>
          </el-col>
        </template>
      </FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      fromLabels,
      form: {
        billType: 1,
        billingState: 0
      },
      props: {
        value: 'id',
        label: 'name',
        children: 'childTaxItems'
      },
      fileName: '',
      id: '',
      cascaderKeys: 0,
      options: []
    }
  },
  methods: {
    async open(obj = null) {
      this.show = true
      this.id = obj?.id
      this.getTaxItemList()
      if (this.id) {
        const res = await this.$api.getInvoiceRegisterInfo({ id: this.id })
        this.form.companyName = res.companyName
        this.form.billType = res.billType
        this.form.supplierId = res.supplierId
        this.form.billingState = res.billingState
        this.form.remark = res.remarks
        this.form.invoiceAmount = res.invoiceAmount
        this.form.fileName = res.billFileList[0]?.fileName
        this.form.billFile = res.billFileList[0]?.filePath
        this.form.taxIdentificationNumber = res.taxIdentificationNumber
        this.form.taxItemsId = []
        if (res.baseTaxItemId) {
          this.form.taxItemsId.push(res.baseTaxItemId)
        }
        this.form.taxItemsId.push(res.taxItemsId)
        this.$nextTick(() => {
          this.$refs.vForm.fillData(this.form)
          this.cascaderKeys++
        })
      } else {
        this.$nextTick(() => {
          this.$refs.vForm.fillData(this.form)
        })
      }
    },

    async getTaxItemList() {
      const res = await this.$api.getAllTaxListData()
      for (let item of res.list) {
        if (item.childTaxItems.length == 0) item.childTaxItems = undefined
      }
      this.options = res.list
    },

    setSupplier(list) {
      for (let item of this.fromLabels) {
        if (item.prop == 'supplierId') {
          item.options = list
          break
        }
      }
    },

    getName(val) {
      const pos = val.lastIndexOf('\/')
      return val.substring(pos + 1)
    },

    fileUploaded(res, file) {
      this.fileName = file.name
    },

    closeDialog() {
      this.form = this.$options.data().form
      this.id = this.$options.data().id
    },

    async submit() {
      const form = await this.$refs.vForm.getForm()
      this.btnloadSubmit = true
      let pars = JSON.parse(JSON.stringify(form))
      if (pars.billFile) {
        let billFileList = [
          {
            fileName: this.fileName,
            filePath: pars.billFile
          }
        ]
        pars.billFileList = billFileList
      }
      pars.taxItemsId = form.taxItemsId[form.taxItemsId.length - 1]
      if (this.id) {
        pars.id = this.id
        await this.$api.EditInvoiceRegister(pars).finally(() => {
          this.btnloadSubmit = false
        })
      } else {
        await this.$api.AddInvoiceRegister(pars).finally(() => {
          this.btnloadSubmit = false
        })
      }
      this.btnloadSubmit = false
      this.$message.success(`${this.id ? '修改' : '新增'}成功`)
      this.$emit('submit')
      this.show = false
    }
  }
}

const fromLabels = [
  {
    cmp: 'input',
    label: '企业名称',
    prop: 'companyName',
    col: 11,
    config: { maxlength: 100 },
    itemConfig: { rules: [{ required: true, message: '请输入企业名称', trigger: 'blur' }] }
  },
  {
    cmp: 'input',
    label: '纳税人识别号',
    prop: 'taxIdentificationNumber',
    col: 11,
    config: { maxlength: 25 },
    itemConfig: { 'label-width': '140px', rules: [{ required: true, message: '请输入纳税人识别号', trigger: 'blur' }] }
  },
  {
    cmp: 'radio',
    label: '发票类型',
    prop: 'billType',
    col: 24,
    itemConfig: { rules: [{ required: true, message: '请选择发票类型', trigger: 'blur' }] },
    options: [
      { key: 1, label: '增值税专用发票' },
      { key: 2, label: '增值税普通发票' }
      // { key: '3', label: '增值税专用电子发票' },
      // { key: '4', label: '增值税普通电子发票' }
    ]
  },
  {
    cmp: 'slot',
    name: 'taxItem'
  },
  {
    cmp: 'number',
    label: '开票金额（元）',
    prop: 'invoiceAmount',
    col: 11,
    config: { maxlength: 100 },
    itemConfig: { rules: [{ required: true, message: '请输入开票金额', trigger: 'blur' }] },
    config: { controls: false, min: 0, precision: 2 }
  },
  {
    cmp: 'select',
    label: '税源地',
    prop: 'supplierId',
    col: 24,
    itemConfig: { rules: [{ required: true, message: '请选择税源地', trigger: 'blur' }] },
    options: []
  },
  {
    cmp: 'input',
    label: '备注',
    prop: 'remark',
    config: { type: 'textarea', maxlength: 200 },
    col: 22
  },
  {
    cmp: 'upload',
    label: '附件',
    prop: 'billFile',
    suffixArray: ['jpg', 'png', 'jpeg', 'pdf', 'zip', 'xlsx', 'rar'],
    isLineBreak: true,
    size: 20,
    afterSuffix: '支持jpg、png、jpeg、pdf、zip、xlsx、rar,单文件大小不超过20M'
  },
  {
    cmp: 'radio',
    label: '完结状态',
    prop: 'billingState',
    col: 24,
    itemConfig: { rules: [{ required: true, message: '请选择完结状态', trigger: 'blur' }] },
    options: [
      { key: 0, label: '未完结' },
      { key: 1, label: '已完结' }
    ]
  }
]
</script>
