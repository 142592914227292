<template>
  <el-dialog title="添加" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="600px">
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 150px">
      <FormView ref="vForm" :labels="fromLabels" :formConfig="{ labelWidth: '200px' }"> </FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { formMainList } from '@/configs/forms'
export default {
  data() {
    return {
      show: false,
      detail: {},
      fromLabels: formMainList,
      btnloadSubmit: false
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.detail = {}
      this.btnloadSubmit = false
    },
    async open(obj) {
      this.clear()
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData(this.detail)
      })
    },
    // 提交
    async submit() {
      // 结果更新
      const result = await this.$refs.vForm.validate()
      this.btnloadSubmit = true
      const params = await this.$refs.vForm.getForm()
      const res = await this.$api.AddBusinessMain(params).finally(() => {
        this.btnloadSubmit = false
      })
      if (res) {
        // this.btnloadSubmit = false
        this.$message.success('添加成功')
        this.$emit('submit')
        this.show = false
      }
    }
  }
}
</script>
