<template>
  <el-dialog title="添加" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="600px">
    <!-- 内容区域 -->
    <el-row>
      <el-col :span="20"
        ><FormView ref="vForm" :labels="formLabels" :formConfig="{ labelWidth: '150px' }" @onInputChange="onChange"></FormView
      ></el-col>
      <el-col :span="4"><el-button type="text" style="margin-left: 24px" @click="search">确定</el-button></el-col>
    </el-row>
    <template v-if="result.workDetailId">
      <el-divider></el-divider>
      <el-descriptions border :column="2" :labelStyle="{ width: '130px', textAlign: 'right' }" style="width: 100%; margin: 0 auto">
        <el-descriptions-item label="订单号">{{ result.customerOrderNo || '无' }}</el-descriptions-item>
        <el-descriptions-item label="打款状态">{{ formatStatus(kvPayState, result.payState) }}</el-descriptions-item>
        <el-descriptions-item label="商户名称">{{ result.merchantName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="商户号">{{ result.memberCode || '无' }}</el-descriptions-item>
        <el-descriptions-item label="结算单号">{{ result.planNo || '无' }}</el-descriptions-item>
        <el-descriptions-item label="下发类型">{{ result.grantType || '无' }}</el-descriptions-item>
        <el-descriptions-item label="税后金额"
          ><span v-format="'#,##0.00'">{{ result.afterTaxAmount || 0 }}</span></el-descriptions-item
        ><el-descriptions-item label="服务费"
          ><span v-format="'#,##0.00'">{{ result.serviceFee || 0 }}</span></el-descriptions-item
        ><el-descriptions-item label="差额服务费"
          ><span v-format="'#,##0.00'">{{ result.serviceDiffFee || 0 }}</span></el-descriptions-item
        ><el-descriptions-item label="抵扣金"
          ><span v-format="'#,##0.00'">{{ result.deductionAmount || 0 }}</span></el-descriptions-item
        ><el-descriptions-item label="订单金额"
          ><span v-format="'#,##0.00'">{{ result.orderAmount || 0 }}</span></el-descriptions-item
        >
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions border :column="2" :labelStyle="{ width: '130px', textAlign: 'right' }" style="width: 100%; margin: 0 auto">
        <el-descriptions-item label="姓名">{{ result.userName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="证件号">{{ result.idCard || '无' }}</el-descriptions-item>
        <el-descriptions-item label="账户名">{{ result.accountName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="账号">{{ result.accountNumber || '无' }}</el-descriptions-item>
      </el-descriptions>
    </template>
    <span slot="footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="submit" :disabled="!result.workDetailId">提交</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { formatStatus, kvPayState, kvGrantType } from '@/configs/formatters'
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      loading: false,
      formLabels: [
        {
          cmp: 'input',
          label: '订单号',
          prop: 'customerOrderNo',
          itemConfig: {
            rules: [{ required: true, message: '请输入单号', trigger: 'bulr' }]
          }
        }
      ],
      result: {},
      kvPayState,
      kvGrantType
    }
  },
  methods: {
    formatStatus,
    // 数据清空
    clear() {
      this.result = {}
    },
    open() {
      this.clear()
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData({})
      })
    },
    onChange() {
      this.result = {}
    },
    // 查询
    async search() {
      const { customerOrderNo } = await this.$refs.vForm.getForm()
      const res = await this.$api.GetWorkDetailByAddReturn({ customerOrderNo })
      this.result = res
    },
    // 提交
    async submit() {
      if (!this.result.workDetailId) return this.$message.warning('请先输入订单编号，确认退汇的订单信息')
      await this.$confirm(`确定将当前订单标记为退汇订单？`, '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      const { workDetailId } = this.result
      const res = await this.$api.AddFinanceReturn({ workDetailId })
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
