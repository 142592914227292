<template>
  <el-dialog :title="(!id ? '添加' : '编辑') + title" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
    <!-- 内容区域 -->
    <div>
      <FormView ref="vForm" :labels="fromLabels" :slotIdxList="[2, 3]" :formConfig="{ labelWidth: '140px' }">
        <template #form-items="{ idx, form }">
          <el-col>
            <el-form-item
              v-if="idx === 2"
              label="所属税源地"
              prop="supplierId"
              :rules="[{ required: true, message: '请选择所属税源地', trigger: 'change' }]"
            >
              <el-select
                v-model="form.supplierId"
                filterable
                remote
                :remote-method="getSupplierOptions"
                placeholder="请输入税源地名称"
                :loading="loading"
                @change="onSupplierChange"
              >
                <el-option
                  v-for="item in supplierOptions"
                  :key="item.id"
                  :label="item.supplierName + '【' + item.supplierCode + '】'"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="idx === 2"
              label="发放方式"
              prop="grantType"
              :rules="[{ required: true, message: '请选择所属税源地', trigger: 'change' }]"
            >
              <el-select v-model="form.grantType" placeholder="请选择发放方式">
                <el-option v-for="item in grantOptions" :key="item.key" :value="item.key" :label="item.label"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="idx === 3"
              label="额度限制"
              prop="isLimit"
              :rules="[{ required: true, message: '请选择是否开启额度限制', trigger: 'change' }]"
            >
              <el-radio-group v-model="form.isLimit">
                <el-radio :label="0">关闭</el-radio>
                <el-radio :label="1">开启</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="idx === 3">
              <el-form-item>
                <el-checkbox v-model="isSigleLimitDisabled" :disabled="!form.isLimit">单笔发放金额不得超过</el-checkbox>
                <el-input-number
                  style="margin-left: 5px"
                  :controls="false"
                  :min="0"
                  :precision="2"
                  :max="9999999999.99"
                  v-model="form.sigleLimit"
                  :disabled="!form.isLimit || !isSigleLimitDisabled"
                />
                元
              </el-form-item>
              <el-form-item>
                <el-checkbox v-model="isMonthLimitDisabled" :disabled="!form.isLimit">每人每月发放金额不得超过</el-checkbox>
                <el-input-number
                  style="margin-left: 5px"
                  :controls="false"
                  :min="0"
                  :precision="2"
                  :max="9999999999.99"
                  v-model="form.monthLimit"
                  :disabled="!form.isLimit || !isMonthLimitDisabled"
                />
                元
              </el-form-item>
              <el-form-item>
                <el-checkbox v-model="isMerchantLimitDisabled" :disabled="!form.isLimit">单个商户发放金额不得超过</el-checkbox>
                <el-input-number
                  style="margin-left: 5px"
                  :controls="false"
                  :min="0"
                  :precision="2"
                  :max="9999999999.99"
                  v-model="form.merchantLimit"
                  :disabled="!form.isLimit || !isMerchantLimitDisabled"
                />
                元
              </el-form-item>
            </el-form-item>
            <!-- 拆单 -->
            <el-form-item
              v-if="idx === 3"
              label="是否拆单"
              prop="isSplit"
              :rules="[{ required: true, message: '请选择是否开启额度限制', trigger: 'change' }]"
            >
              <el-radio-group v-model="form.isSplit">
                <el-radio :label="0">否</el-radio>
                <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="idx === 3 && form.isSplit" prop="splitLimit" :rules="[{ validator: valdateMoney, trigger: 'blur' }]">
              超过
              <el-input-number :controls="false" :min="1" :max="9999999999.99" v-model="form.splitLimit" style="width: 200px" />
              元,进行拆单
            </el-form-item>
          </el-col>
        </template>
      </FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { formChannel } from '@/configs/forms'
import { regular } from '@/configs/validate.js'
import { kvGrantType } from '@/configs/formatters'
export default {
  name: 'DialogChannel',
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      title: '通道',
      supplierOptions: [],
      fromLabels: formChannel,
      isSigleLimitDisabled: false,
      isMonthLimitDisabled: false,
      isMerchantLimitDisabled: false,
      detail: {},
      loading: false,
      grantOptions: kvGrantType
    }
  },
  methods: {
    async submit() {
      const form = await this.$refs.vForm.getForm()
      if (form.grantType == 2 && !form.channelConfig) return this.$message.error('请输入银行配置信息')
      let limitForm = {}
      // 处理limit
      if (!form.isLimit) {
        limitForm = { sigleLimit: null, monthLimit: null, merchantLimit: null }
      } else if (!this.isSigleLimitDisabled) {
        limitForm.sigleLimit = null
      } else if (!this.isMonthLimitDisabled) {
        limitForm.monthLimit = null
      } else if (!this.isMerchantLimitDisabled) {
        limitForm.merchantLimit = null
      }
      this.btnloadSubmit = true
      if (this.id) {
        await this.$api.EditWorkChannel(Object.assign({}, form, limitForm)).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('修改成功')
      } else {
        await this.$api.AddWorkChannel(Object.assign({}, form, limitForm)).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('添加成功')
      }
      this.$emit('submit')
      this.show = false
    },
    clear() {
      this.id = null
      this.detail = { isLimit: 0, isSplit: 0 }
      this.isSigleLimitDisabled = false
      this.isMonthLimitDisabled = false
      this.isMerchantLimitDisabled = false
    },
    async open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 编辑
        this.id = obj.id
        await this.getDetail()
      } else {
        // 新建
      }
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData(this.detail)
        this.getSupplierOptions(null)
      })
    },
    async getDetail() {
      const res = await this.$api.GetEditByWorkChannelDetailInfo({ id: this.id })
      const {
        id,
        channelCode,
        channelName,
        supplierId,
        grantType,
        isLimit,
        channelRemark,
        monthLimit,
        sigleLimit,
        merchantLimit,
        channelConfig,
        isSplit,
        splitLimit
      } = res
      if (sigleLimit) this.isSigleLimitDisabled = true
      if (monthLimit) this.isMonthLimitDisabled = true
      if (merchantLimit) this.isMerchantLimitDisabled = true
      this.detail = {
        id,
        channelCode,
        channelName,
        supplierId,
        grantType,
        isLimit,
        channelRemark,
        monthLimit,
        sigleLimit,
        merchantLimit,
        channelConfig,
        isSplit,
        splitLimit
      }
      const grantList = grantType == 4 ? [4] : [1, 2, 3]
      this.grantOptions = kvGrantType.filter((item) => grantList.includes(item.key))
    },
    // 获取税源地列表
    async getSupplierOptions(val) {
      this.loading = true
      const res = await this.$api.GetSupplierInfoByKeyWord({ SupplierName: val })
      this.supplierOptions = res.list
      this.loading = false
    },
    // 税源地变更
    onSupplierChange(val) {
      const { supplierType } = this.supplierOptions.find((item) => item.id == val)
      const grantList = supplierType == 1 ? [1, 2, 3] : [1, 2, 4]
      this.grantOptions = kvGrantType.filter((item) => grantList.includes(item.key))
      this.$refs.vForm.upData({ grantType: undefined })
    },
    // 效验金额
    valdateMoney(rule, value, callback) {
      if (/^[0-9]+([.]{1}[0-9]+){0,1}$/.test(value)) {
        callback()
      } else {
        callback(new Error('请输入拆单金额'))
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.btnline {
  text-align: right;
}
.el-col-12 {
  height: 51px;
}
</style>
