<template>
  <div>
    <el-dialog title="查看" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
      <div>
        <el-descriptions
          :contentStyle="{ width: '250px', textAlign: 'left' }"
          :labelStyle="{ width: '120px', textAlign: 'right' }"
          :column="2"
          border
          title="接单信息"
        >
          <el-descriptions-item label="申请时间">{{ detail.applyTime || '无' }}</el-descriptions-item>
          <el-descriptions-item label="创客姓名"
            >{{ detail.userName || '无' }}
            <WpView v-if="detail.userName" :id="detail.userId" :type="1" />
          </el-descriptions-item>
          <el-descriptions-item label="手机号"
            >{{ detail.mobile || '无' }}
            <WpView v-if="detail.mobile" :id="detail.userId" :type="1" />
          </el-descriptions-item>
          <el-descriptions-item label="证件号"
            >{{ detail.idCard || '无' }}
            <WpView v-if="detail.idCard" :id="detail.userId" :type="1" />
          </el-descriptions-item>
          <el-descriptions-item label="任务名称">{{ detail.taskName || '无' }}</el-descriptions-item>
          <el-descriptions-item label="任务编号">{{ detail.taskNo || '无' }}</el-descriptions-item>
          <el-descriptions-item label="商户名称">{{ detail.merchantName || '无' }}</el-descriptions-item>
          <el-descriptions-item label="商户编号">{{ detail.memberCode || '无' }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions
          :column="2"
          border
          title="审核信息"
          :contentStyle="{ width: '250px', textAlign: 'left' }"
          :labelStyle="{ width: '120px', textAlign: 'right' }"
          style="margin-top: 18px"
        >
          <el-descriptions-item label="审核时间">{{ detail.auditTime || '无' }}</el-descriptions-item>
          <el-descriptions-item label="审核人">{{ detail.auditer || '无' }}</el-descriptions-item>
          <el-descriptions-item label="审核状态">
            <span>{{ formatStatus(kvAuditState, detail.auditState) }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="审核意见">{{ detail.auditFailreason || '无' }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions
          :column="2"
          border
          title="验收信息"
          :contentStyle="{ width: '250px', textAlign: 'left' }"
          :labelStyle="{ width: '120px', textAlign: 'right' }"
          style="margin-top: 18px"
        >
          <el-descriptions-item label="完成时间">{{ detail.finishTime || '无' }}</el-descriptions-item>
          <el-descriptions-item label="操作人">{{ detail.finisher || '无' }}</el-descriptions-item>
          <el-descriptions-item label="成果验收单"
            ><span @click="downLoadResult(detail.acceptanceNoteUrl)" :style="{ color: detail.acceptanceNoteUrl ? 'blue' : '', cursor: 'pointer' }">
              {{ detail.acceptanceNoteUrl ? '下载文件' : '无' }}</span
            ></el-descriptions-item
          >
        </el-descriptions>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formatStatus, kvAuditState } from '@/configs/formatters'
import ImageViewer from '@/components/tableView/imageViewer'
import WpView from '@/components/wpView'
import { downloadUrl } from '@/utils'
export default {
  components: { ImageViewer, WpView },
  data() {
    return {
      show: false,
      id: null,
      detail: {},
      reason: '',
      kvAuditState
    }
  },
  methods: {
    formatStatus,
    async open(obj) {
      const result = await this.$api.GetReceiveTaskInfo({ id: obj.id })
      this.detail = Object.assign({}, result)
      this.show = true
    },
    downLoadResult(e) {
      if (e) {
        downloadUrl(e)
      }
    }
  }
}
</script>
