<template>
  <el-dialog title="充值结果查询" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="600px">
    <!-- 内容区域 -->
    <FormView ref="vForm" :labels="formLabels" :formConfig="{ labelWidth: '150px' }" @onInputChange="onChange"></FormView>
    <template v-if="result.thirdRechargeNo">
      <el-divider></el-divider>
      <el-descriptions border :column="1" :labelStyle="{ width: '130px', textAlign: 'right' }" style="width: 100%; margin: 0 auto">
        <el-descriptions-item label="充值审核状态">{{ formatStatus(auditState, result.auditState) }}</el-descriptions-item>
        <el-descriptions-item label="第三方充值账号"
          >{{ result.thirdRechargeNo }}
          <el-button style="margin-left: 100px" v-if="result.auditState" type="primary" @click="createRecharge"
            >生成充值记录</el-button
          ></el-descriptions-item
        >
        <el-descriptions-item label="打款金额">{{ result.entryAmount || '无' }}</el-descriptions-item>
        <el-descriptions-item label="实际到账">{{ result.rechargeAmount || '无' }}</el-descriptions-item>
        <el-descriptions-item label="账户类型">{{ formatStatus(payType, result.payType) }}</el-descriptions-item>
        <el-descriptions-item label="平台充值订单号">{{ result.rechargeBillNo || '无' }}</el-descriptions-item>
        <el-descriptions-item label="入账时间">{{ result.entryTime || '无' }}</el-descriptions-item>
        <el-descriptions-item label="失败原因">{{ result.failReason || '无' }}</el-descriptions-item>
      </el-descriptions>
    </template>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">查 询</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { regular } from '@/configs/validate'
import { formatStatus } from '@/configs/formatters'
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      loading: false,
      formLabels: [
        {
          cmp: 'select',
          label: '选择商户',
          prop: 'merchantId',
          options: [],
          itemConfig: {
            rules: [{ required: true, message: '请选择商户', trigger: 'change' }]
          },
          config: {
            placeholder: '请输入商户编号/商户名称',
            filterable: true,
            style: { width: '100%' }
          }
        },
        {
          cmp: 'select',
          label: '账户类型',
          prop: 'accountType',
          options: [
            { label: '银行卡账户', key: 1 },
            { label: '支付宝账户', key: 2 }
          ],
          itemConfig: {
            rules: [{ required: true, message: '请选择账户类型', trigger: 'change' }]
          },
          config: {
            placeholder: '请选择账户类型',
            style: { width: '100%' }
          }
        },
        {
          cmp: 'input',
          label: '充值单号',
          prop: 'thirdRechargeNo',
          itemConfig: {
            rules: [{ required: true, message: '请输入单号', trigger: 'bulr' }]
          }
        }
      ],
      result: {},
      auditState: [
        { label: '校验中', key: 0 },
        { label: '审核通过', key: 1 },
        { label: '审核不通过', key: -1 }
      ],
      payType: [
        { label: '银行卡账户', key: 0 },
        { label: '支付宝账户', key: 1 },
        { label: '微信账户', key: 2 }
      ]
    }
  },
  methods: {
    formatStatus,
    // 数据清空
    clear() {
      this.result = {}
    },
    open() {
      this.clear()
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData({})
      })
      this.getMerchantOptions()
    },
    onChange() {
      this.result = {}
    },
    // 获取税源地列表
    async getMerchantOptions() {
      let v = []
      let result = await this.$api.GetMerchantInfoCodeAndName()
      if (result.list.length < 0) {
        return false
      }
      result.list.map((item) => {
        v.push({
          key: item.merchantId,
          label: `【${item.memberCode}】${item.merchantName}`
        })
      })
      this.formLabels.map((item) => {
        if (item.prop === 'merchantId') {
          item.options = v
        }
      })
    },
    // 提交查询
    async submit() {
      const params = await this.$refs.vForm.getForm()
      this.result = {}
      this.btnloadSubmit = true
      const res = await this.$api.QuerySSCRechargeAccount(params).finally(() => {
        this.btnloadSubmit = false
      })
      this.result = { merchantId: params.merchantId, accountType: params.accountType, ...res }
    },
    // 生成充值记录
    async createRecharge() {
      const { merchantId, auditState, failReason, rechargeAmount, rechargeBillNo, accountType } = this.result
      const params = { merchantId, auditStatus: auditState, failReason, rechargeAmount, rechargeBillNo, accountType: this.result.payType }
      const res = await this.$api.CreateRechargeLog(params)
      this.$message.success('充值记录已生成')
    }
  }
}
</script>
