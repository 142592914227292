<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView @get="getAmount" ref="vTable" :labels="tableLabels" apiName="GetFinanceFundPageList" :searchList="searchList">
      <el-card slot="ext-table" style="margin-bottom: 18px">
        <el-descriptions :column="cardItem.length + 1" border direction="vertical">
          <el-descriptions-item label="业务类型">汇总金额</el-descriptions-item>
          <el-descriptions-item v-for="(item, index) in cardItem" :key="index" :label="item.label">{{ item.type }}<span class="text" v-format="'#,##0.00'">{{ item.value }}</span></el-descriptions-item>
        </el-descriptions>
      </el-card>
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item" v-if="$hasAccess('007201')">
        <el-button plain type="primary" icon="el-icon-download" @click="download" :loading="btnloadDownload">导出</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="80" fixed="right">
        <template v-slot="{ row }">
          <el-button v-if="$hasAccess('007200')" type="text" @click="routeLs(row)">查看</el-button>
        </template>
      </el-table-column>
    </TableView>
  </div>
</template>

<script>
import { formatStatus, kvFundType } from '@/configs/formatters'
import { searchAccountCapital } from '@/configs/searches'
import { downloadExcel } from '@/utils'
const tableSelfLabels = [
  { label: '入账时间', prop: 'fundTime', config: { width: 180 } },
  {
    label: '业务类型',
    prop: 'fundType',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvFundType, cellValue)
    }
  },
  {
    label: '业务订单',
    prop: 'fundNo'
  },
  {
    label: '商户号',
    prop: 'memberCode'
  },
  {
    label: '商户名称',
    prop: 'merchantName'
  },
  {
    label: '账户号码',
    prop: 'accountNo'
  },
  { label: '收支金额', prop: 'fundAmount', cmp: 'money' },
  { label: '账户余额', prop: 'accountBalance', cmp: 'money' }
]
export default {
  data() {
    return {
      btnloadDownload: false,
      searchList: searchAccountCapital,
      tableLabels: tableSelfLabels,
      cardItem: [
        { label: '充值', type: '+', value: 0, num: 0 },
        { label: '交易支出', type: '-', value: 0, num: 0 },
        { label: '提现', type: '-', value: 0, num: 0 },
        { label: '订单退汇', type: '+', value: 0, num: 0 },
        { label: '内部划入', type: '+', value: 0, num: 0 },
        { label: '内部划出', type: '-', value: 0, num: 0 }
      ]
    }
  },
  computed: {},
  mounted() {
    this.getOptions()
  },
  methods: {
    async getOptions() {
      let merchantRes = await this.$api.GetMerchantNameValueList({ PageIndex: 1, PageSize: 999 })
      this.searchList[0].options = merchantRes.list.map((item) => {
        return {
          key: item.id,
          label: `(${item.code}) ${item.name}`
        }
      })
    },
    async getAmount(e) {
      this.cardItem.map((item) => {
        item.value = 0
        item.num = 0
      })
      let res = await this.$api.GetFinanceFundTypeSummary(e)
      if (res.list.length) {
        res.list.map((item) => {
          switch (item.type) {
            case 10:
              this.cardItem[0].value = item.amount || 0
              this.cardItem[0].num = item.count || 0
              break
            case 20:
              this.cardItem[1].value = item.amount || 0
              this.cardItem[1].num = item.count || 0
              break
            case 30:
              this.cardItem[2].value = item.amount || 0
              this.cardItem[2].num = item.count || 0
              break
            case 40:
              this.cardItem[4].value = item.amount || 0
              this.cardItem[4].num = item.count || 0
              break
            case 50:
              this.cardItem[5].value = item.amount || 0
              this.cardItem[5].num = item.count || 0
              break
            case 60:
              this.cardItem[3].value = item.amount || 0
              this.cardItem[3].num = item.count || 0
              break
            default:
              break
          }
        })
      }
    },
    async download() {
      this.btnloadDownload = true
      const params = this.$refs.vTable.getParams()
      const res = await this.$api.ExportFinanceFundData(params).finally(() => {
        this.btnloadDownload = false
      })
      downloadExcel(res, `资金流水`)
    },
    //查看
    routeLs(row) {
      switch (row.fundType) {
        case 10:
          this.$router.push(`/financeRecharge?fundNo=${row.fundNo}`)
          break
        case 20:
          this.$router.push(`/financeDisburse?fundNo=${row.fundNo}`)
          break
        case 30:
          this.$router.push(`/financeWithdrawal?fundNo=${row.fundNo}`)
          break
        case 40:
          this.$router.push(`/depositInside?fundNo=${row.fundNo}`)
          break
        case 50:
          this.$router.push(`/depositInside?fundNo=${row.fundNo}`)
          break
        case 60:
          this.$router.push(`/financeReturnLog?fundNo=${row.fundNo}`)
          break
        default:
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ext {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  .title-text {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
  }
  .text {
    font-size: 26px;
    color: #333;
    font-weight: 600;
    margin-right: 10px;
  }
}
</style>
