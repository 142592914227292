<template>
  <el-dialog title="查看" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="1000px">
    <div v-if="show">
      <TableView
        ref="vTable"
        :labels="tableLabels"
        apiName="GetReceiptDetailPageList"
        :searchList="searchList"
        :tableConfig="{ height: '500px' }"
        :searchExt="searchExt"
      ></TableView>
    </div>
  </el-dialog>
</template>

<script>
import { formatStatus } from '@/configs/formatters'
export default {
  data() {
    return {
      show: false,
      searchExt: {},
      searchList: [
        {
          cmp: 'select',
          label: '匹配状态',
          prop: 'mappingState',
          options: [
            { key: 1, label: '成功' },
            { key: 2, label: '失败' }
          ]
        }
      ],
      tableLabels: [
        {
          label: '文件名称',
          prop: 'fileName'
        },
        {
          label: '已匹配订单号',
          prop: 'customerOrderNo'
        },
        {
          label: '匹配状态',
          prop: 'mappingState',
          formatter: (row, column, cellValue, index) => {
            return formatStatus(
              [
                { key: 0, label: '匹配中' },
                { key: 1, label: '成功' },
                { key: 2, label: '失败' }
              ],
              cellValue
            )
          }
        },
        {
          label: '失败原因',
          prop: 'mappingDesc'
        }
      ]
    }
  },
  methods: {
    open(obj) {
      this.searchExt = { searchTxt: ' ', receiptId: obj.id }
      this.show = true
      this.$nextTick()
    }
  }
}
</script>
