<template>
  <div class="flex-home">
    <el-menu active-text-color="#3d78fa" :default-active="activeIndex" class="table-menu" mode="horizontal" @select="handleSelect">
      <el-menu-item index="1">服务费返还</el-menu-item>
      <el-menu-item index="2">赠送</el-menu-item>
    </el-menu>
    <TableView
      :initSearch="{ grantType: 1 }"
      v-if="activeIndex == '1'"
      ref="vTable1"
      :labels="tableServiceLabels"
      apiName="queryDeduction"
      :searchList="searchServiceList"
    >
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="60" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button v-if="$hasAccess('007701')" type="text" @click="$refs.ServiceDetail.open(row)">查看</el-button>
          <!-- <el-button v-if="$hasAccess('000704') && row.auditState == 2" type="text" @click="$refs.DialogCheck.open(row)">审核</el-button> -->
        </template>
      </el-table-column>
    </TableView>
    <TableView :initSearch="{ grantType: 2 }" v-else ref="vTable2" :labels="tableGiveLabels" apiName="queryDeduction" :searchList="searchGiveList">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item" v-if="$hasAccess('007702')">
        <el-button plain type="primary" @click="$refs.GiveDiscount.open">发起赠送</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="120" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button v-if="$hasAccess('007703') && row.auditState == 0" type="text" @click="$refs.AuditDiscount.open(row)">审核</el-button>
          <el-button v-if="$hasAccess('007702') && row.auditState == 2" type="text" @click="$refs.GiveDiscount.open(row)">重新发起</el-button>
          <el-button v-if="$hasAccess('007704') && row.auditState != 1" type="text" @click="delItem(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </TableView>
    <ServiceDetail ref="ServiceDetail" />
    <GiveDiscount ref="GiveDiscount" @submit="asyncRefreshTable(2)" />
    <AuditDiscount ref="AuditDiscount" @submit="asyncRefreshTable(2)" />
  </div>
</template>

<script>
import { tableService, tableGive } from '@/configs/tables'
import { searchService, searchGive } from '@/configs/searches'
import ServiceDetail from './components/serviceDetail'
import GiveDiscount from './components/giveDiscount'
import AuditDiscount from './components/auditDiscount'
export default {
  components: { ServiceDetail, GiveDiscount, AuditDiscount },
  data() {
    return {
      tableServiceLabels: tableService,
      searchServiceList: searchService,
      tableGiveLabels: tableGive,
      searchGiveList: searchGive,
      activeIndex: '1'
    }
  },
  mounted() {
    this.getOptions()
  },
  methods: {
    // 删除项
    delItem(id) {
      let _this = this
      this.$confirm('确认删除此条记录吗?', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.deleteDeduction(id).then((res) => {
          if (res) {
            this.$message.success('删除成功')
            _this.asyncRefreshTable(2)
          }
        })
      })
    },
    async getOptions() {
      let merchantRes = await this.$api.GetMerchantInfoCodeAndName()
      let list = merchantRes.list.map((item) => {
        return {
          key: item.merchantId,
          label: `(${item.memberCode}) ${item.merchantName}`
        }
      })
      this.searchServiceList[1].options = list
      this.searchGiveList[1].options = list
    },
    handleSelect(key, keyPath) {
      this.activeIndex = keyPath[0]
      switch (key) {
        case '1':
          this.$nextTick(() => {
            this.$refs.vTable1.resetTable({ grantType: 1 })
          })

          break
        case '2':
          this.$nextTick(() => {
            this.$refs.vTable2.resetTable({ grantType: 2 })
          })
          break
        default:
          break
      }
    },
    multipleCheck() {},
    // 表格延迟刷新
    asyncRefreshTable(e) {
      setTimeout(() => {
        this.$refs[`vTable${e}`].refreshTable()
      }, 0)
    }
  }
}
</script>
