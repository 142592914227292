<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView :labels="labels" :searchList="searchList"></TableView>
  </div>
</template>

<script>
import { tableOrder } from '@/configs/tables'
import { searchOrder } from '@/configs/searches'
export default {
  data() {
    return {
      labels: tableOrder,
      searchList: searchOrder
    }
  }
}
</script>
