import store from '@/store'

// 类型判断
function isType(typeStr) {
  typeStr = typeStr.toLowerCase()
  return function (obj) {
    return Object.prototype.toString.call(obj).toLowerCase() === `[object ${typeStr}]`
  }
}
export const isDate = isType('date')
export const isObject = isType('object')
export const isArray = isType('array')
export const isNumber = isType('number')
export const isNull = (val) => val == null
export const isEmpty = (v) => {
  const emptyObj = isObject(v) && !Object.keys(v).length
  return v == null || emptyObj || (typeof v === 'string' && !v)
}

// 下载blob流Excel文件
export function downloadDocx(blob, filename) {
  const fr = new FileReader()
  fr.readAsDataURL(blob)
  fr.onload = function (e) {
    var exportLink = document.createElement('a')
    exportLink.style = 'visibility: hidden'
    exportLink.download = filename + '.docx'
    exportLink.href = e.target.result
    document.body.appendChild(exportLink)
    exportLink.click()
    document.body.removeChild(exportLink)
  }
}
// 获取地址栏信息
export const getUrlParams = (url) => {
  const params = url.split('?')[1]
  const reg = /&?([^&]+)/g
  let res = null
  const map = Object.create(null)
  while ((res = reg.exec(params))) {
    const paramEntry = res[1].split('=')
    map[paramEntry[0]] = paramEntry[1]
  }
  return map
}

// 深拷贝
export const deepClone = (target) => {
  if (!(target instanceof Object)) return {}
  return JSON.parse(JSON.stringify(target))
}

// 空处理
export function excludeEmpty(obj) {
  return Object.entries(obj).reduce((map, [k, v]) => {
    if (isEmpty(v)) return map
    map[k] = isObject(v) ? excludeEmpty(v) : v
    return map
  }, {})
}

// 随机位数字符串
export function randomString(e) {
  e = e || 32
  let t = 'ABCDEFGHJKMNPQRSTWXYZ1234567890',
    a = t.length,
    n = ''
  for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a))
  return n
}

// 判断权限
export function hasAccess(ruleName) {
  // 租户权限开放
  if (process.env.VUE_APP_BASE_TENANT) return true
  if (!ruleName) return true
  return store.state.user.route.some((item) => item == ruleName)
}

// 下载blob流Excel文件
export function downloadExcel(blob, filename) {
  const fr = new FileReader()
  fr.readAsDataURL(blob)
  fr.onload = function (e) {
    var exportLink = document.createElement('a')
    exportLink.style = 'visibility: hidden'
    exportLink.download = filename + '.xlsx'
    exportLink.href = e.target.result
    document.body.appendChild(exportLink)
    exportLink.click()
    document.body.removeChild(exportLink)
  }
}
//图片下载
export function downloadIamge(imgsrc, name) {
  //下载图片地址和图片名
  let image = new Image()
  // 解决跨域 Canvas 污染问题
  image.setAttribute('crossOrigin', 'anonymous')
  image.onload = function () {
    let canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    let context = canvas.getContext('2d')
    context.drawImage(image, 0, 0, image.width, image.height)
    let url = canvas.toDataURL('image/png') //得到图片的base64编码数据
    let a = document.createElement('a') // 生成一个a元素
    let event = new MouseEvent('click') // 创建一个单击事件
    a.download = name || 'photo' // 设置图片名称
    a.href = url // 将生成的URL设置为a.href属性
    a.dispatchEvent(event) // 触发a的单击事件
  }
  image.src = imgsrc
}
// 下载链接换头
export function downloadUrl(url) {
  window.open(url.replace(/^http:/, document.location.protocol))
}
// 函数节流
export function throttle(func, wait) {
  let lastTime = 0
  return function (...args) {
    let now = +new Date()
    if (now - lastTime > wait) {
      lastTime = now
      func.apply(this, args)
    }
  }
}
// 函数防抖
export function debounce(func, wait) {
  var timeout
  return function () {
    var context = this
    var args = arguments
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      func.apply(context, args)
    }, wait)
  }
}
