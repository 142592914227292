<template>
  <el-dialog :title="title" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false">
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 550px" v-if="show">
      <FormView ref="vForm" :formConfig="{ labelWidth: '140px' }" :labels="fromLabels" @onInputChange="onInputChange">
        <template v-slot:payerAccountId="{ idx, form }">
          <el-col>
            <el-form-item
              label="付款账户"
              prop="payerAccountId"
              :rules="[{ required: true, message: '请输入付款账户', trigger: 'blur' }]">
              <el-select
                v-model="form.payerAccountId"
                placeholder="请选择付款账户"
                style="width: 250px"
                @change="onPayerChange"
              >
                <el-option
                  v-for="item in accountList"
                  :key="item.accountId"
                  :label="item.accountNumber"
                  :value="item.accountId"
                >
                </el-option>
              </el-select>
              <div style="display: inline-block; margin-left: 10px;"> 可用余额：<span v-format="'#,##0.00'">{{balance}}</span>元</div>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item
              label="收款账户"
              prop="payeeAccountId"
              :rules="[{ required: true, message: '请选择收款账户', trigger: 'blur' }]">
              <el-select
                v-model="form.payeeAccountId"
                placeholder="请选择收款账户"
                style="width: 250px"
              >
                <el-option
                  v-for="item in accountList"
                  :key="item.accountId"
                  :label="item.accountNumber"
                  :value="item.accountId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </template>
      </FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit(true)" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
const fromLabels = [
{
    cmp: 'select',
    label: '选择商户',
    prop: 'merchantId',
    options: [],
    itemConfig: {
      rules: [{ required: true, message: '请选择商户', trigger: 'blur' }]
    },
    config: {
      placeholder: '请输入商户编号/商户名称',
      filterable: true,
      style: { width: '600px' }
    }
  },
  {
    cmp: 'slot',
    name: 'payerAccountId'
  },
  {
    cmp: 'number',
    label: '金额',
    prop: 'depositAmount',
    config: { style: { width: '200px' }, controls: false, precision: 2 },
    itemConfig: {
      rules: [{ required: true, message: '请输入金额，可精确到两位小数', trigger: 'blur' }]
    },
    afterSuffix: ' 元'
  },
  {
    cmp: 'input',
    label: '备注',
    prop: 'remark',
    config: { type: 'textarea', maxlength: 20, placeholder: '请输入备注,不超过20字' }
  }
]
export default {
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      title: '内部划转',
      fromLabels: fromLabels,
      accountList: [],
      balance: 0
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.accountList = []
      this.balance = 0
    },
    async open(obj) {
      this.clear()
      this.getMerchantOptions()
      this.show = true
    },
    async getMerchantOptions() {
      let v = []
      let result = await this.$api.GetMerchantInfoCodeAndName()
      if (result.list.length < 0) {
        return false
      }
      result.list.map((item) => {
        v.push({
          key: item.merchantId,
          label: `【${item.memberCode}】${item.merchantName}`
        })
      })
      this.fromLabels.map((item) => {
        if (item.prop === 'merchantId') {
          item.options = v
        }
      })
    },
    onInputChange(val, key) {
      // 切换商户
      if (key == 'merchantId') {
        this.getAccountList(val)
        this.$refs.vForm.upData({ payerAccountId: undefined }, true)
        this.balance = 0
        return
      }
    },
    onPayerChange(val) {
      console.log(val)
      const one = this.accountList.find(item => (item.accountId==val))
      if (one) this.balance = one.accountBalance
      else this.balance = 0
    },
    async getAccountList(val) {
      const res = await this.$api.GetMerchantAccountInfoList({ merchantId: val })
      this.accountList = res.list
    },
    // 提交
    async submit() {
      // 结果更新
      const params = await this.$refs.vForm.getForm()
      if (params.depositAmount > this.balance) return this.$message.error('划转金额不能大于付款账户可用余额')
      if (params.payerAccountId == params.payeeAccountId) return this.$message.error('收款账户不能与付款账户相同')
      const res = await this.$api.AddDepositInside(params).finally(() => {
        this.btnloadSubmit = false
      })
      this.$message.success('内部划转成功')
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
