<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView
      :initSearch="{ SearchTxt: fundNo }"
      ref="vTable"
      :labels="tableLabels"
      apiName="GetDisburseLogPageList"
      :searchList="searchList"
      @geted="getAmount"
    >
      <el-card shadow="never" slot="ext-table" style="margin-bottom: 18px">
        支出总金额（元）：<span v-format="'#,##0.00'">{{ amount }}</span>
      </el-card>
    </TableView>
  </div>
</template>

<script>
import { tableDisburseLog } from '@/configs/tables'
import { searchDisburseLog } from '@/configs/searches'
export default {
  data() {
    return {
      amount: 0,
      tableLabels: tableDisburseLog,
      searchList: searchDisburseLog
    }
  },
  computed: {
    fundNo() {
      return this.$route.query.fundNo
    }
  },
  methods: {
    getAmount(response, params) {
      const { pageIndex, pageSize, ...rest } = params
      this.$api.GetExpenditureAmountStatistics(rest).then((res) => {
        this.amount = res.expenditureAmount
      })
    }
  }
}
</script>
