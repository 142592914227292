<template>
  <div>
    <!-- 前置标示 -->
    <span class="before-suffix" v-if="beforeSuffix">{{ beforeSuffix }} </span>

    <!-- 显示 -->
    <span v-if="isTypeOf('txt')">{{ fakeValue }}</span>
    <span v-format="'#,##0.00'" v-if="isTypeOf('mountFee')">{{ fakeValue }}</span>
    <!-- 输入框 -->
    <el-input v-if="isTypeOf('input')" v-model.trim="fakeValue" v-bind="cfg" @change="onInputChange($event, prop)"></el-input>
    <!-- 数字输入框 -->
    <el-input-number v-if="isTypeOf('number')" v-model="fakeValue" v-bind="cfg" />
    <!-- 普通选择器，带options -->
    <el-select v-if="isTypeOf('select')" v-model="fakeValue" v-bind="cfg" @change="onInputChange($event, prop)">
      <el-option v-for="item in options" :key="item.key" :label="item.label" :value="item.key"> </el-option>
    </el-select>
    <!-- 单选，带options -->
    <el-radio-group v-if="isTypeOf('radio')" v-model="fakeValue" v-bind="cfg" @change="onInputChange($event, prop)">
      <el-radio :label="item.key" v-for="item in options" :key="item.key" :disabled='item.disabled'
        >{{ item.label }}&nbsp;
        <el-tooltip v-if="item.tip" effect="dark" :content="item.tip"><i class="el-icon-warning-outline"></i></el-tooltip>
      </el-radio>
    </el-radio-group>
    <!-- 省市区级联 -->
    <el-cascader v-if="isTypeOf('pca')" v-model="fakeValue" :options="pcaOptions" :props="{ value: 'label' }" style="width: 100%"></el-cascader>
    <!-- 多选，带options -->
    <template></template>
    <el-checkbox-group v-if="isTypeOf('checkbox')" v-model="fakeValue" v-bind="cfg" align="left">
      <el-checkbox v-for="item in options" :label="item.key" :key="item.key">{{ item.label }}</el-checkbox>
    </el-checkbox-group>
    <!-- 日期选择器 -->
    <el-date-picker v-if="isTypeOf('dpicker')" v-model="fakeValue" v-bind="cfg"> </el-date-picker>
    <!-- 日期时间范围选择器，prop是数组 -->
    <el-date-picker v-if="isTypeOf('dtpickers')" v-model="fakeValue" v-bind="cfg" @change="onInputChange($event, prop)"> </el-date-picker>
    <!-- 文件上传 -->
    <Upload
      v-if="isTypeOf('upload')"
      :modelValue="fakeValue"
      :config="cfg"
      :size="size"
      :suffixArray="suffixArray"
      @update="fileUpdate($event)"
      @uploaded="fileUploaded"
    ></Upload>
    <multipleUpload v-if="isTypeOf('multipleUpload')" :modelValue="fakeValue" :config="cfg" v-bind="uploadConfig" @update="fileUpdate($event)"></multipleUpload>

    <!-- 后置标示 -->
    <span class="after-suffix" v-if="afterSuffix && !isLineBreak"> {{ afterSuffix }}</span>
    <div class="after-suffix" v-if="afterSuffix && isLineBreak">{{ afterSuffix }}</div>
  </div>
</template>

<script>
// 表单item配置项
const DefaultItemCfg = {}
// 表单内容配置项
const DefaultCfg = {
  minWidth: '100px',
  align: 'center'
}
const DtpickersCfg = {
  defaultTime: ['00:00:00', '23:59:59'],
  startPlaceholder: '开始日期',
  endPlaceholder: '结束日期',
  rangeSeparator: '至',
  valueFormat: 'yyyy-MM-dd HH:mm:ss',
  type: 'datetimerange'
}
import { isNull, isArray, deepClone } from '@/utils'
import Upload from './upload'
import multipleUpload from './multipleUpload'
import { regionData } from 'element-china-area-data'
export default {
  name: 'ComponentFormItem',
  components: { Upload, multipleUpload },
  props: {
    model: Object,
    // 表单数据
    value: String | Number | Array,
    // 表单项标示
    cmp: String,
    // 表单域model字段
    prop: String | Array,
    // 标签文本
    label: String,
    // 其他配置项
    itemConfig: Object,
    config: Object,
    // other
    // select的options
    options: Array,
    // 可控
    disabled: Boolean,
    suffixArray: {
      type: Array,
      default: () => ['jpg', 'png', 'docx']
    },
    uploadConfig: Object,
    size: {
      type: Number,
      default: 10
    },
    // 前置标示
    beforeSuffix: String,
    // 后置标示
    afterSuffix: String,
    isLineBreak: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pcaOptions: regionData
    }
  },
  computed: {
    // 表单item配置项格式化
    itemCfg() {
      return Object.assign({}, DefaultItemCfg, this.itemConfig)
    },
    // 表单内容配置项格式化
    cfg() {
      if (this.cmp == 'dtpickers') return Object.assign({}, DefaultCfg, DtpickersCfg, this.config)
      const placeholder = (this.cmp == 'input' || this.cmp == 'number' ? '请输入' : '请选择') + (this.label || '')
      return Object.assign({ placeholder, disabled: this.disabled }, DefaultCfg, this.config)
    },
    fakeValue: {
      get() {
        if (this.cmp == 'checkbox') {
          return this.model[this.value] || []
        }
        if (isArray(this.value)) {
          return this.value.reduce((next, it) => {
            if (this.cmp == 'pca' && !this.model[it]) return next
            next.push(this.model[it] || '')
            return next
          }, [])
        }
        return this.model[this.value]
      },
      set(val) {
        if (isArray(this.value) && isNull(val)) {
          this.$emit('input', { val: [], cmp: this.cmp })
        } else {
          this.$emit('input', { val, cmp: this.cmp })
        }
      }
    }
  },
  methods: {
    // 特殊项匹配判定
    isTypeOf(name) {
      return name === this.cmp
    },
    // 上传回调
    fileUpdate(val) {
      this.fakeValue = val
    },
    fileUploaded(val, file){
      this.$emit('fileUploaded', val, file)
    },
    // change事件
    async onInputChange(val, key, row) {
      this.$emit('onInputChange', val, key)
    }
  }
}
</script>

<style lang="scss" scoped></style>
