<template>
  <el-dialog
    top="9vh"
    :title="isEdit ? '重新发起' : '发放抵扣金'"
    :visible.sync="show"
    :append-to-body="true"
    :close-on-click-modal="false"
    width="700px"
  >
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 300px">
      <FormView class="discount" ref="vForm" :labels="fromLabels"></FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="limtButton">确定，发起审核</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { timestampToTime } from '@/configs/formatters'
import { debounce } from '@/utils'
import { formGive } from '@/configs/forms'
export default {
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      detail: {},
      fromLabels: formGive,
      isEdit: false
    }
  },
  methods: {
    async open(obj) {
      let taskRes = await this.$api.GetMerchantInfoCodeAndName()
      this.fromLabels[0].options = taskRes.list.map((item) => {
        return {
          key: item.merchantId,
          label: `(${item.memberCode}) ${item.merchantName}`
        }
      })
      if (obj && obj.id) {
        this.isEdit = true
        this.fromLabels[0].disabled = true
        this.detail = obj
        this.show = true
        this.$nextTick(() => {
          this.$refs.vForm.fillData({
            type: '赠送',
            merchantId: obj.merchantId,
            diffServicefee: obj.diffServicefee,
            applyRemark: obj.applyRemark
          })
        })
        console.log(obj)
      } else {
        this.isEdit = false
        this.fromLabels[0].disabled = false
        this.show = true
        this.$nextTick(() => {
          this.$refs.vForm.fillData({ type: '赠送' })
        })
      }
    },
    // 提交
    limtButton: debounce(function (val) {
      this.submit()
    }, 500),
    async submit() {
      const result = await this.$refs.vForm.validate()
      // 结果更新
      if (result) {
        this.form = await this.$refs.vForm.getForm()
        let res
        if (this.isEdit) {
          res = await this.$api.reapplyDeduction(Object.assign({}, this.form, { deductionId: this.detail.id }))
        } else {
          res = await this.$api.handselDeduction(this.form)
        }
        if (res) {
          this.$message.success('已提交')
          // 编辑
          this.$emit('submit')
          this.show = false
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.otherItem {
  margin-bottom: 0;
}
.discount {
  ::v-deep .el-input__inner {
    text-align: left;
  }
}
</style>
