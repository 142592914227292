<template>
  <el-dialog :title="title" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="500px">
    <!-- 内容区域 -->
    <el-descriptions title="银行卡通道" :column="1" size="medium">
      <!-- <template slot="extra" v-if="!detail.currentBankChannel || !detail.currentBankChannel.channelId">
        <el-button type="text" @click="selChannel(1)">开启通道</el-button>
      </template> -->
      <template v-if="detail.currentBankChannel">
        <el-descriptions-item label="发放通道">{{ supplierName }} - {{ detail.currentBankChannel.channelName }}</el-descriptions-item>
        <el-descriptions-item label="账户名称">{{ detail.bankRechargeAccount.accountName }}</el-descriptions-item>
        <el-descriptions-item label="开户银行">{{ detail.bankRechargeAccount.accountBank }}</el-descriptions-item>
        <el-descriptions-item label="银行账户">{{ detail.bankRechargeAccount.accountNumber }}</el-descriptions-item>
      </template>
    </el-descriptions>
    <el-descriptions title="支付宝通道" :column="1" size="medium">
      <template slot="extra" v-if="!detail.currentAlipayChannel || !detail.currentAlipayChannel.channelId">
        <el-button v-if="hasBusinessAccess" type="text" @click="selChannel(2)">开启通道</el-button>
      </template>
      <template v-else>
        <el-descriptions-item label="发放通道">{{ supplierName }} - {{ detail.currentAlipayChannel.channelName }}</el-descriptions-item>
        <el-descriptions-item label="账户名称">{{ detail.alipayRechargeAccount.accountName }}</el-descriptions-item>
        <el-descriptions-item label="充值账户">{{ detail.alipayRechargeAccount.accountNumber }}</el-descriptions-item>
      </template>
    </el-descriptions>
    <el-descriptions title="微信通道" :column="1" size="medium">
      <template slot="extra" v-if="!detail.currentWechatChannel || !detail.currentWechatChannel.channelId">
        <el-button v-if="hasBusinessAccess" type="text" @click="selChannel(3)">开启通道</el-button>
      </template>
      <template v-else>
        <el-descriptions-item label="发放通道">{{ supplierName }} - {{ detail.currentWechatChannel.channelName }}</el-descriptions-item>
        <el-descriptions-item label="账户名称">{{ detail.wechatRechargeAccount.accountName }}</el-descriptions-item>
        <el-descriptions-item label="充值账户">{{ detail.wechatRechargeAccount.accountNumber }}</el-descriptions-item>
      </template>
    </el-descriptions>
    <el-descriptions title="供应商通道" :column="1" size="medium">
      <template slot="extra" v-if="!detail.currentProviderChannel || !detail.currentProviderChannel.channelId">
        <el-button v-if="hasBusinessAccess" type="text" @click="selChannel(4)">开启通道</el-button>
      </template>
      <template v-else>
        <el-descriptions-item label="发放通道">{{ supplierName }} - {{ detail.currentProviderChannel.channelName }}</el-descriptions-item>
        <el-descriptions-item label="账户名称">{{ detail.providerRechargeAccount.accountName }}</el-descriptions-item>
        <el-descriptions-item label="充值账户">{{ detail.providerRechargeAccount.accountNumber }}</el-descriptions-item>
      </template>
    </el-descriptions>
    <!-- 弹窗 -->
    <el-dialog title="开启通道" :visible.sync="selShow" :append-to-body="true" :close-on-click-modal="false">
      <el-form v-if="selShow" ref="form" :model="form" label-suffix="：" label-width="120px">
        <el-form-item label="签约税源地" prop="channelId" :rules="[{ required: true, message: '请选择发放通道', trigger: 'change' }]">
          <el-select v-model="form.channelId" placeholder="请选择">
            <el-option v-for="item in options" :key="item.channelId" :label="supplierName + ' - ' + item.channelName" :value="item.channelId">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="selShow = false">取 消</el-button>
        <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      id: null,
      merchantId: null,
      supplierId: null,
      supplierName: '',
      title: '通道管理',
      detail: {},
      selShow: false,
      form: {},
      options: [],
      businessType: null
    }
  },
  computed: {
    // 判断businessType和权限
    hasBusinessAccess() {
      console.log(this.businessType)
      if (this.businessType == 1) {
        return this.$hasAccess('000107')
      }
      if (this.businessType == 2) {
        return this.$hasAccess('007306')
      }
      return false
    }
  },
  methods: {
    clear() {
      this.id = null
      this.merchantId = null
      this.supplierId = null
      this.supplierName = ''
      this.businessType = null
    },
    open(obj, businessType) {
      this.clear()
      if (obj && obj.mainId) {
        this.businessType = businessType
        this.id = obj.mainId
        this.merchantId = obj.merchantId
        this.supplierId = obj.supplierId
        this.supplierName = obj.supplierName
        this.show = true
        this.getDetail()
      } else {
        this.$message.error('无效的信息')
      }
    },
    async getDetail() {
      const res = await this.$api.GetSupplierChannelInfo({
        merchantId: this.merchantId,
        supplierId: this.supplierId
      })
      this.detail = res
    },
    selChannel(type) {
      if (type == 1) this.options = this.detail.bankChannelList
      if (type == 2) this.options = this.detail.alipayChannelList
      if (type == 3) this.options = this.detail.wechatChannelList
      if (type == 4) this.options = this.detail.providerChannelList
      this.form = {
        type
      }
      this.selShow = true
    },
    async submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.btnloadSubmit = true
          const res = await this.$api
            .OpenChannel({
              merchantId: this.merchantId,
              bankChannelId: this.detail.currentBankChannel?.channelId,
              channelId: this.form.channelId
            })
            .finally(() => {
              this.btnloadSubmit = false
            })
          this.$message.success('开启成功')
          this.selShow = false
          this.getDetail()
        }
      })
    }
  }
}
</script>
