<template>
  <el-dialog
    :title="(updateType == 2 ? '编辑' : '添加') + (!level ? '一级' : '二级') + title"
    :visible.sync="show"
    :append-to-body="true"
    :close-on-click-modal="false"
    width="500px"
  >
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 220px; overflow: hidden">
      <FormView ref="vForm" :labels="!level ? formLabels1 : formLabels2" :slotIdxList="[0]" @onInputChange="onChange"></FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { regular } from '@/configs/validate'
export default {
  data() {
    return {
      btnloadSubmit: false,
      updateType: 2,
      level: 0,
      id: null,
      show: false,
      title: '税目',
      formLabels1: [
        {
          cmp: 'input',
          label: '一级税目名称',
          prop: 'parentName',
          itemConfig: {
            rules: [{ required: true, message: '请输入一级税目名称', trigger: 'blur' }],
            labelWidth: '150px'
          }
        }
      ],
      formLabels2: [
        {
          cmp: 'select',
          label: '所属一级税目',
          prop: 'parentId',
          itemConfig: {
            rules: [
              {
                required: true,
                message: '请选择所属一级税目',
                trigger: 'change'
              }
            ],
            labelWidth: '150px'
          },
          config: { style: { width: '100%' } }
        },
        {
          cmp: 'input',
          label: '二级税目名称',
          prop: 'childrenName',
          itemConfig: {
            rules: [
              {
                required: true,
                message: '请输入二级税目名称',
                trigger: 'blur'
              }
            ],
            labelWidth: '150px'
          }
        },
        {
          cmp: 'input',
          label: '税目编号',
          prop: 'taxCode',
          itemConfig: {
            rules: [{ pattern: regular.enNum, message: regular.enNumMsg, trigger: 'blur' }],
            labelWidth: '150px'
          },
          config: { style: { width: '100%' }, placeholder: '请输入税目编号' }
        }
      ],
      detail: {}
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    open(obj, add = false) {
      this.clear()
      this.level = add ? (obj ? '2' : undefined) : obj.level
      this.updateType = add ? 1 : 2
      if (!add) {
        // 编辑
        this.id = obj.id
        if (this.level == 2) {
          this.getParentList()
          this.detail = {
            parentId: obj.parentId,
            parentName: obj.parentName,
            childrenId: obj.id,
            childrenName: obj.name,
            taxCode: obj.taxCode
          }
        } else {
          this.detail = { parentId: obj.id, parentName: obj.name }
        }
      } else {
        // 添加
        if (this.level == 2) {
          this.getParentList()
          this.detail = { parentId: obj.id, parentName: obj.name }
        }
      }
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData(this.detail)
      })
    },
    async getParentList() {
      const res = await this.$api.GetEditByTaxItemsParentList()
      this.$set(
        this.formLabels2[0],
        'options',
        res.list.map((item) => {
          return { key: item.id, label: item.name }
        })
      )
    },
    onChange(val, key) {
      if (key == 'parentId') {
        const one = this.formLabels2[0].options.find((item) => {
          return item.key == val
        })
        if (one) this.$refs.vForm.upData({ parentName: one.label })
      }
    },
    // 提交
    async submit() {
      // 结果更新
      const params = await this.$refs.vForm.getForm()
      this.btnloadSubmit = true
      const res = await this.$api.UpdateTaxItem(Object.assign({ updateType: this.updateType }, params)).finally(() => {
        this.btnloadSubmit = false
      })
      if (this.updateType == 2) {
        // 编辑
        this.$message.success('编辑成功')
      } else {
        // 添加
        this.$message.success('添加成功')
      }
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
