<template>
  <div class="flex-home">
    <div class="pageTitle" @click="$refs.DialogInfo.open({ id: '12' })">
      {{ $route.meta.title }}
    </div>
    <TableView
      ref="vTable"
      :labels="tableLabels"
      apiName="GetMerchantSystemRolePageList"
      :searchList="searchList"
    >
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item" v-if="$hasAccess('002300')">
        <el-button
          plain
          type="primary"
          icon="el-icon-plus"
          @click="$refs.Edit.open()"
          >新增角色</el-button
        >
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="120" fixed="right">
        <template v-slot="{ row, $index }">
          <template v-if="row.adminType != 1">
            <template v-if="$hasAccess('002302')">
              <el-button
                v-if="row.isEenable == 0"
                type="text"
                class="el-button-error"
                @click="closeOne(row)"
                >禁用</el-button
              >
              <el-button v-else type="text" @click="openOne(row)"
                >启用</el-button
              >
            </template>
            <el-button
              v-if="$hasAccess('002301')"
              type="text"
              @click="$refs.Edit.open(row)"
              >编辑</el-button
            >
          </template>
        </template>
      </el-table-column>
    </TableView>
    <Edit ref="Edit" @submit="$refs.vTable.refreshTable()" />
  </div>
</template>

<script>
import { tableRole } from "@/configs/tables";
import { searchRole } from "@/configs/searches";
import Edit from "./edit";
export default {
  components: { Edit },
  data() {
    return {
      tableLabels: tableRole,
      searchList: searchRole,
    };
  },
  methods: {
    // async onSwitch(val, key, row, set) {
    //   if (row.id == this.$store.state.user.userId) return this.$message.warning('不能禁用自己')
    //   if (key == 'isEnable') {
    //     // 禁用启用
    //     const params = { userId: row.id }
    //     const res = await this.$api.DisabledOperationUser(params)
    //     set()
    //   }
    // },
    async closeOne(item) {
      const res = await this.$api.SetMerchantSystemRoleEenable({
        roleId: item.id,
        eenable: 1,
      });
      this.$message.success("角色已禁用");
      this.$refs.vTable.refreshTable();
    },
    async openOne(item) {
      const res = await this.$api.SetMerchantSystemRoleEenable({
        roleId: item.id,
        eenable: 0,
      });
      this.$message.success("角色已启用");
      this.$refs.vTable.refreshTable();
    },
  },
};
</script>
