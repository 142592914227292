<template>
  <el-dialog title="添加" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" top="8vh" width="1200px">
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 450px">
      <FormView ref="vForm" @onInputChange="selectChange" :labels="fromLabels" :formConfig="{ labelWidth: '200px' }"></FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { regular } from '@/configs/validate'
export default {
  props: {},
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      fromLabels: from,
      form: {}
    }
  },
  mounted() {},
  methods: {
    // 数据清空
    clear() {
      this.detail = {}
    },
    async open(obj) {
      this.clear()
      this.getMerchantOptions()
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData({})
      })
    },
    async getMerchantOptions() {
      let v = []
      let result = await this.$api.GetMerchantInfoCodeAndName()
      if (result.list.length < 0) {
        return false
      }
      result.list.map((item) => {
        v.push({
          key: item.merchantId,
          label: `【${item.memberCode}】${item.merchantName}`
        })
      })
      this.fromLabels.map((item) => {
        if (item.prop === 'mermchantId') {
          item.options = v
        }
      })
    },
    selectChange(key, prop) {
      switch (prop) {
        case 'blackReason':
          if (key == '其他') {
            for (let item of this.fromLabels) {
              if (item.prop == 'remark') {
                item.itemConfig.rules[0].required = true
              }
            }
          } else {
            for (let item of this.fromLabels) {
              if (item.prop == 'remark') {
                item.itemConfig.rules[0].required = false
              }
            }
          }
          break
      }
    },
    // 提交
    async submit() {
      // 结果更新
      const result = await this.$refs.vForm.validate()
      if (result) {
        this.form = await this.$refs.vForm.getForm()
        this.btnloadSubmit = true
        let res = await this.$api.AddSystemBlack(this.form).finally(() => {
          this.btnloadSubmit = false
        })
        if (res) {
          this.$message.success('添加成功')
          this.$emit('submit')
          this.show = false
        }
      }
    }
  }
}

const from = [
  {
    cmp: 'input',
    label: '创客姓名',
    prop: 'userName',
    col: 11,
    itemConfig: {
      rules: [{ required: true, message: '请输入创客姓名', trigger: 'blur' }]
    },
    config: { placeholder: '请输入创客姓名' }
  },
  {
    cmp: 'input',
    label: '手机号',
    prop: 'mobile',
    col: 11,
    itemConfig: {
      rules: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        {
          pattern: regular.mobile,
          message: regular.mobileMsg,
          trigger: 'blur'
        }
      ]
    },
    config: { placeholder: '请输入手机号', maxlength: 11 }
  },
  {
    cmp: 'input',
    label: '证件号',
    prop: 'idCard',
    col: 11,
    itemConfig: {
      rules: [
        { required: true, message: '请输入身份证号', trigger: 'blur' },
        {
          pattern: regular.IDCard,
          message: regular.IDCardMsg,
          trigger: 'blur'
        }
      ]
    },
    config: { placeholder: '请输入身份证号' }
  },
  {
    cmp: 'select',
    label: '所属商户',
    prop: 'mermchantId',
    options: [],
    col: 11,
    itemConfig: {
      rules: [{ required: true, message: '请选择商户', trigger: 'blur' }]
    },
    config: {
      placeholder: '请输入商户编号/商户名称',
      filterable: true,
      style: { width: '100%' }
    }
  },
  // other
  // {
  //   cmp: 'input',
  //   label: '备注',
  //   prop: 'remark',
  //   col: 22,
  //   config: { type: 'textarea', maxlength: 200 }
  // },
  {
    cmp: 'select',
    label: '拉黑原因',
    prop: 'blackReason',
    options: [
      { key: '董监高', label: '董监高' },
      { key: '其他', label: '其他' }
    ],
    col: 11,
    itemConfig: {
      rules: [{ required: true, message: '请选择拉黑原因', trigger: 'blur' }]
    },
    config: {
      placeholder: '请选择拉黑原因',
      style: { width: '100%' }
    }
  },
  {
    cmp: 'input',
    prop: 'remark',
    col: 11,
    itemConfig: {
      'label-width': '40px',
      rules: [{ required: true, message: '请输入拉黑原因', trigger: 'blur' }]
    },
    config: { placeholder: '请输入拉黑原因', maxlength: 50 }
  }
]
</script>
