<template>
    <div class="flex-home">
        <div class="pageTitle">{{ $route.meta.title }}</div>
        <TableView ref="vTable" :labels="tableLabels" apiName="GetFinanceReturnLogPageList" :searchList="searchList" :initSearch="{ SearchTxt: fundNo }">
            <!-- 按钮栏 -->
            <el-form-item slot="btnline-item">
                <el-button plain type="primary" icon="el-icon-download" @click="download"
                    :loading="btnloadDownload">导出</el-button>
            </el-form-item>
        </TableView>
    </div>
</template>
  
<script>
import { downloadExcel } from '@/utils'
const searchList = [
    {
        cmp: 'input',
        prop: 'SearchTxt',
        config: {
            placeholder: '请输入商户编号/商户名称/流水号',
            style: { width: '350px' }
        }
    },
    {
        cmp: 'dtpickers',
        label: '打款时间',
        prop: ['PayTimeBegin', 'PayTimeEnd']
    }
]
const tableLabels = [
    {
        label: '打款时间',
        prop: 'payTime'
    },
    {
        label: '流水号',
        prop: 'returnNo'
    },
    {
        label: '商户名称',
        prop: 'merchantName'
    },
    {
        label: '商户编号',
        prop: 'memberCode'
    },
    { label: '退款金额', prop: 'returnAmount', cmp: 'money' },
    {
        label: '收款账户名称',
        prop: 'merchantAccountName'
    },
    {
        label: '收款账户号码',
        prop: 'merchantAccountNumber'
    },
    {
        label: '收款开户银行',
        prop: 'merchantAccountBank'
    },
    {
        label: '备注',
        prop: 'remark', config: { showOverflowTooltip: true }
    }
]
export default {
    data() {
        return {
            searchList: searchList,
            tableLabels: tableLabels,
            btnloadDownload: false
        }
    },
    computed: {
        fundNo() {
            return this.$route.query.fundNo
        }
    },
    methods: {
        async download() {
            this.btnloadDownload = true
            const params = this.$refs.vTable.getParams()
            const res = await this.$api.ExportReturnLogList(params).finally(() => {
                this.btnloadDownload = false
            })
            downloadExcel(res, `退汇记录`)
        },
    }
}
</script>
  