<template>
  <span>
    <el-button v-if="visible" style="margin: 0 10px" type="text" icon="el-icon-view" @click="handlerClick"></el-button>
    <el-dialog title="验证消息" :visible.sync="smsShow" :append-to-body="true" :close-on-click-modal="false" width="500px">
      <el-form ref="vForm" :model="form" :rules="rules" :inline="true" @submit.native.prevent>
        <el-form-item prop="code" label="短信验证码： "><el-input v-model="form.code" placeholder="请输入验证码"></el-input></el-form-item>
        <el-form-item
          ><el-button type="primary" @click="sendVerifyCode" :disabled="vcCountdown != 0" style="width: 120px">{{
            vcCountdown != 0 ? vcCountdown + 's后再获取' : '获取验证码'
          }}</el-button></el-form-item
        >
      </el-form>
      <span slot="footer">
        <el-button @click="smsShow = false">取 消</el-button>
        <el-button type="primary" @click="submit" :loading="btnloadSubmit">确认</el-button>
      </span>
    </el-dialog>
    <el-dialog title="详细信息" :visible.sync="infoShow" :append-to-body="true" :close-on-click-modal="false" width="500px">
      <el-descriptions border :column="1" size="medium" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="姓名">{{ info.name || '--' }}</el-descriptions-item>
        <el-descriptions-item label="身份证号">{{ info.idCard || '--' }}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{ info.mobile || '--' }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </span>
</template>

<script>
export default {
  props: {
    id: String,
    visible: {
      type: Boolean,
      default: true
    },
    // 创客0，白名单1，黑名单2，任务交付物3，结算明细4，人员5
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      btnloadSubmit: false,
      smsShow: false,
      form: {},
      rules: {
        code: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }]
      },
      infoShow: false,
      info: { name: 'string', idCard: 'string', mobile: 'string' }
    }
  },
  computed: {
    vcCountdown() {
      return this.$store.state.app.vcCountdown
    }
  },
  methods: {
    handlerClick() {
      const code = this.$store.state.app.wpVerifyCode
      if (code) {
        // 有code，则直接请求显示
        this.$api
          .GetWpVerify({ QueryId: this.id, VerifyCode: code, QueryType: this.type })
          .then((res) => {
            if (this.visible) {
              this.info = res
              this.infoShow = true
            } else {
              this.$emit('geted', res)
            }
          })
          .catch((err) => {
            this.$store.commit('app/setWpVerify')
            this.openSms()
          })
      } else {
        // 无code，则获取code
        this.openSms()
      }
    },
    openSms() {
      this.form = {}
      if (this.$refs.vForm) this.$refs.vForm.clearValidate()
      this.smsShow = true
      this.$emit('need')
    },
    // 获取验证吗
    async sendVerifyCode() {
      const res = await this.$api.SendWpVerifySMS()
      this.$store.dispatch('app/vcDownStart', true)
    },
    submit() {
      this.$refs.vForm.validate(async (valid) => {
        if (valid) {
          this.btnloadSubmit = true
          const res = await this.$api.GetWpVerify({ QueryId: this.id, VerifyCode: this.form.code, QueryType: this.type }).finally(() => {
            this.btnloadSubmit = false
          })
          this.$store.commit('app/setWpVerify', this.form.code)
          this.smsShow = false
          if (this.visible) {
            this.info = res
            this.infoShow = true
          } else {
            this.$emit('geted', res)
          }
        }
      })
    }
  }
}
</script>
