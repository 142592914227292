<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetSystemWhitePageList" :searchList="searchList">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item-left" v-if="$hasAccess('001801')">
        <el-button plain type="primary" icon="el-icon-plus" @click="$refs.AddPeople.open()">添加</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="150" fixed="right">
        <template v-slot="{ row }">
          <el-button v-if="$hasAccess('001802')" type="text" @click="$refs.viewPeople.open(row)">查看</el-button>
          <el-button v-if="$hasAccess('001802') && row.auditState == '待审核'" type="text" @click="$refs.AddPeople.open(row)">审核</el-button>
          <el-button v-if="$hasAccess('001805')" class="deleteColor" type="text" @click="deletePeople(row)">删除</el-button>
        </template>
      </el-table-column>
    </TableView>
    <!-- 弹窗 -->
    <AddPeople ref="AddPeople" @submit="updateDialogTemplate"></AddPeople>
    <viewPeople ref="viewPeople"></viewPeople>
  </div>
</template>

<script>
import { tableWhiteList } from '@/configs/tables'
import { searchWhiteList } from '@/configs/searches'
import AddPeople from './components/addpeople'
import viewPeople from './components/viewPeople'
export default {
  components: { AddPeople, viewPeople },
  data() {
    return {
      tableLabels: tableWhiteList,
      searchList: searchWhiteList,
      merchantOptions: []
    }
  },
  methods: {
    updateDialogTemplate() {
      this.$refs.vTable.refreshTable()
    },
    async deletePeople(e) {
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let res = await this.$api.DeleteSystemWhite({ id: e.id })
          if (res) {
            this.$message.success('删除成功')
            this.updateDialogTemplate()
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.deleteColor {
  color: #f56c6c;
}
</style>
