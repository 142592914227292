<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView
      ref="vTable"
      :initSearch="{ ExtractPlanId: ExtractPlanId }"
      :labels="tableLabels"
      apiName="GetTaskDeliverableExtractPlanDetail"
      :searchList="searchList"
    >
      <el-card slot="ext-table" style="margin-bottom: 18px">
        <div class="title">计划信息</div>
        <div class="infoItem">
          <div v-for="(item, index) in infoItem" :key="index" class="content">
            <span class="text">{{ item.label }}:</span>
            <span class="text" v-format="'#,##0.00'" v-if="typeof item.value == 'number'">{{ item.value }}</span>
            <span v-else>{{ item.value }}</span>
          </div>
        </div>
      </el-card>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="300" fixed="right">
        <template v-slot="{ row }">
          <el-button v-if="$hasAccess('001605')" type="text" @click="$refs.Detail.open(row)">查看</el-button>
          <el-button
            v-if="$hasAccess('001609') && row.uploadState == 1 && row.delAuditState != 2 && row.delAuditState != 3"
            type="text"
            @click="$refs.Audit.open(row, 'deliveravle')"
            >交付物审核</el-button
          >
          <el-button
            v-if="$hasAccess('001610') && row.apcUploadState == 1 && row.apcAuditState != 2 && row.apcAuditState != 3"
            type="text"
            @click="$refs.Audit.open(row, 'result')"
            >验收单审核</el-button
          >
        </template>
      </el-table-column>
    </TableView>
    <Detail @refresh="asyncRefreshTable" ref="Detail" />
    <Audit @refresh="asyncRefreshTable" ref="Audit" />
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { tablePersonList } from '@/configs/tables'
import { searchPersonList } from '@/configs/searches'
import Detail from './detail'
import Audit from './audit'
export default {
  components: { ElImageViewer, Detail, Audit },
  data() {
    return {
      viewerlList: [],
      showViewer: false,
      tableLabels: tablePersonList,
      searchList: searchPersonList,
      form: {
        desc: ''
      },
      SpotState: null,
      infoItem: [
        { label: '计划编号', value: '' },
        { label: '结算所属日期', value: '' },
        { label: '抽取商家范围', value: '' },
        { label: '抽取创客', value: '' },
        { label: '发放金额', value: 0 },
        { label: '完成情况', value: '' }
      ]
    }
  },
  computed: {
    ExtractPlanId() {
      return this.$route.query.ExtractPlanId
    }
  },
  mounted() {
    this.getDeliverableDetaile()
  },
  methods: {
    // 表格延迟刷新
    asyncRefreshTable() {
      setTimeout(() => {
        this.$refs.vTable.refreshTable()
      }, 0)
    },
    //抽取详情
    async getDeliverableDetaile() {
      let detail = await this.$api.GetExtractPlanDetail({ ExtractPlanId: this.ExtractPlanId })
      if (detail) {
        this.infoItem[0].value = detail.extractPlanNo
        this.infoItem[1].value = detail.belongTime
        this.infoItem[2].value = detail.isSpecifyScope == 0 ? '不指定' : '指定'
        this.infoItem[3].value = detail.extractPeopleNumber
        this.infoItem[4].value = detail.grantAmount
        this.infoItem[5].value = detail.finishDetial
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-home {
  .dialog-body {
    display: flex;
    align-items: center;
  }
  .deleteColor {
    color: #f56c6c;
  }
  .infoItem {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .content {
      width: 500px;
      margin-top: 20px;
      span {
        font-size: 14px;
      }
    }
  }
}
</style>
