<template>
  <el-dialog title="缺失税目补全" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
    <div>
      <p>当前系统中，没有以下税目，请确认是否自动添加</p>
      <el-divider></el-divider>
      <div style="max-height: calc(70vh - 260px); overflow-y: auto">
        <p v-for="(item, index) in list" :key="index">{{ item }}</p>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      list: []
    }
  },
  methods: {
    open(obj) {
      this.id = obj.requestId
      this.list = obj.showTaxItemsList
      this.show = true
    },
    submit() {
      this.$emit('submit', this.id)
      this.show = false
    }
  }
}
</script>
