<template>
    <el-dialog title="支付宝订单金额" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
        <!-- 内容区域 -->
        <div class="flex-home" style="height: 650px" v-if="show">
            <TableView ref="vtable" :labels="labels" apiName="GetMergeAlipayAccountDetailPageList"
                :initSearch="{ SupplierId: id, CurrDateTime: time }" :searchList="searchList">
            </TableView>
        </div>
    </el-dialog>
</template>

<script>
const searchList = [
    {
        cmp: 'input',
        config: { placeholder: '请输入商户名称/商户号' },
        prop: 'SearchTxt'
    }
]
const labels = [
    { label: '商户名称', prop: 'merchantName' },
    { label: '商户号', prop: 'memberCode' },
    { cmp: 'money', label: '税后金额', prop: 'afterTaxAmount' },
    { cmp: 'money', label: '服务费', prop: 'serviceFee' },
    { cmp: 'money', label: '订单总额', prop: 'orderAmount' }
]
export default {
    data() {
        return {
            id: null,
            time: null,
            show: false,
            labels: labels,
            searchList: searchList
        }
    },
    methods: {
        // 数据清空
        clear() {
            this.id = null
        },
        open(obj) {
            this.clear()
            if (obj && obj.supplierId && obj.currDateTime) {
                // 查看
                this.id = obj.supplierId
                this.time = obj.currDateTime
                this.show = true
            } else {
                this.$message.error('无效的信息')
            }
        }
    }
}
</script>
