<template>
  <el-dialog title="跟进" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" top="8vh" width="550px">
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 250px">
      <FormView ref="vForm" :labels="fromLabels" :formConfig="{ labelWidth: '150px' }"></FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { formFollowPhoneReview } from '@/configs/forms'
import { mapState } from 'vuex'
export default {
  props: {},
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      fromLabels: formFollowPhoneReview,
      form: {}
    }
  },
  mounted() {},
  methods: {
    // 数据清空
    clear() {
      this.btnloadSubmit = false
      this.form = {}
    },
    async open() {
      this.clear()
      this.getMerchantOptions()
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData(this.form)
      })
    },
    // label字段添加
    async getMerchantOptions() {
      let v = []
      let result = await this.$api.GetMerchantInfoCodeAndName()
      if (result.list.length < 0) {
        return false
      }
      result.list.map((item) => {
        v.push({
          key: item.merchantId,
          label: `【${item.memberCode}】${item.merchantName}`
        })
      })
      this.fromLabels.forEach((item) => {
        if (item.prop === 'merchantIds') {
          item.options = v
        }
      })
    },
    // 提交
    async submit() {
      // 结果更新
      const result = await this.$refs.vForm.validate()
      if (result) {
        this.form = await this.$refs.vForm.getForm()
        this.btnloadSubmit = true
        let res = await this.$api.AddTaskDeliverableExtractPlan(Object.assign({}, this.form)).finally(() => {
          this.btnloadSubmit = false
        })
        if (res) {
          this.$message.success('添加成功')
          this.$emit('submit')
          this.show = false
        }
      }
    }
  }
}
</script>
