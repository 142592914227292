<template>
    <el-dialog title="生成回单" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
        <div v-if="show">
            <FormView ref="vForm" :labels="formLabels" :formConfig="{ labelWidth: '150px' }"> </FormView>
        </div>
        <span slot="footer">
            <el-button @click="show = false">取 消</el-button>
            <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>
  
<script>
import dayjs from 'dayjs'
const formLabels = [
    {
        cmp: 'select',
        label: '选择商户',
        prop: 'memberCode',
        options: [],
        itemConfig: {
            rules: [{ required: true, message: '请选择商户', trigger: 'blur' }]
        },
        config: {
            placeholder: '请输入商户编号/商户名称',
            filterable: true,
            style: { width: '500px' }
        }
    },
    {
        cmp: 'dtpickers',
        label: '选择日期',
        prop: ['startTime', 'endTime'],
        itemConfig: {
            rules: [{ required: true, message: '请选择结算单发放完成日期', trigger: 'change' }]
        },
        config: {
            placeholder: '请选择结算单发放完成日期',
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > dayjs().endOf('day').subtract(2, 'day').valueOf();
                }
            },
        },
        style: { width: '500px' }
    },
    {
        cmp: 'input',
        label: '姓名',
        prop: 'userName',
    },
    {
        cmp: 'input',
        label: '身份证号',
        prop: 'idCard',
    },
    {
        cmp: 'input',
        label: '收款账号',
        prop: 'receivingAccount',
    },
]
export default {
    data() {
        return {
            btnloadSubmit: false,
            show: false,
            formLabels
        }
    },
    methods: {
        // 数据清空
        clear() { },
        async open(obj) {
            this.clear()
            this.getMerchantOptions()
            this.show = true
        },
        async getMerchantOptions() {
            let v = []
            let result = await this.$api.GetMerchantInfoCodeAndName()
            if (result.list.length < 0) {
                return false
            }
            result.list.map((item) => {
                v.push({
                    key: item.memberCode,
                    label: `【${item.memberCode}】${item.merchantName}`
                })
            })
            this.formLabels.map((item) => {
                if (item.prop === 'memberCode') {
                    item.options = v
                }
            })
        },
        // submit
        async submit() {
            const obj = await Object.assign(this.$refs.vForm.getForm())
            obj.startTime = dayjs(obj.startTime).format()
            obj.endTime = dayjs(obj.endTime).format()
            this.btnloadSubmit = true;
            const res = await this.$api.BuildReceiptZip(obj).finally(() => {
                this.btnloadSubmit = false
            })
            if (res) {
                this.$message.success('生成回单中')
                this.$emit('submit')
                this.show = false
            }
        }
    }
}
</script>