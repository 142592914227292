<template>
  <el-dialog title="解除" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
    <!-- 内容区域 -->
    <el-descriptions title="基本信息" border :column="2" :labelStyle="{ width: '120px', textAlign: 'right' }" :contentStyle="{ width: '34%' }">
      <el-descriptions-item label="姓名">{{ detail.userName || '--' }}<WpView v-if="detail.userName" :id="id" :type="2" /></el-descriptions-item>
      <el-descriptions-item label="手机号">{{ detail.mobile || '--' }}<WpView v-if="detail.mobile" :id="id" :type="2" /></el-descriptions-item>
      <el-descriptions-item label="证件号">{{ detail.idCard || '--' }}<WpView v-if="detail.idCard" :id="id" :type="2" /></el-descriptions-item>
      <el-descriptions-item label="所属商户">{{ detail.merchantName || '--' }}</el-descriptions-item>
      <el-descriptions-item label="添加方式">{{ detail.GenerationType | resultStr }}</el-descriptions-item>
      <el-descriptions-item label="添加时间">{{ detail.createTime || '--' }}</el-descriptions-item>
      <el-descriptions-item label="拉黑原因">{{ detail.blackReason || '--' }}</el-descriptions-item>
      <el-descriptions-item label="描述">{{ detail.remark || '--' }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      title="解除信息"
      border
      :column="2"
      :labelStyle="{ width: '120px', textAlign: 'right' }"
      :contentStyle="{ width: '34%' }"
      style="margin-top: 20px"
    >
      <el-descriptions-item label="身份证正面">
        <Upload :modelValue="from.idFrontPhoto" :config="cfg" :size="10" :suffixArray="['jpg', 'png']" @update="identityJust($event)"></Upload>
      </el-descriptions-item>
      <el-descriptions-item label="身份证反面">
        <Upload :modelValue="from.idBackPhoto" :config="cfg" :size="10" :suffixArray="['jpg', 'png']" @update="identityAgainst($event)"></Upload>
      </el-descriptions-item>
      <el-descriptions-item label="证明文件">
        <Upload
          :modelValue="from.documentaryEvidence"
          :config="cfg"
          :size="10"
          :suffixArray="['jpg', 'png', 'pdf']"
          @update="proveFile($event)"
        ></Upload>
      </el-descriptions-item>
      <el-descriptions-item label="备注">
        <el-input v-model="from.lostRemark" maxlength="50" placeholder="请输入备注"></el-input>
      </el-descriptions-item>
    </el-descriptions>
    <div slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
      <el-button type="primary" @click="submitAndWhiteList" :loading="btnloadWhileSubmit">确定并加入白名单</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { formatStatus, kvOrganizationType, kvIsEntrust, kvIsAuthentication } from '@/configs/formatters'
import ImageViewer from '@/components/tableView/imageViewer'
import WpView from '@/components/wpView'
import Upload from '@/components/formView/upload'
export default {
  components: { ImageViewer, WpView, Upload },
  data() {
    return {
      id: null,
      show: false,
      detail: {},
      kvOrganizationType,
      kvIsEntrust,
      kvIsAuthentication,
      cfg: {},
      from: {
        idFrontPhoto: '',
        idBackPhoto: '',
        documentaryEvidence: '',
        lostRemark: ''
      },
      btnloadSubmit: false,
      btnloadWhileSubmit: false,
      fileName: ''
    }
  },
  filters: {
    resultStr(val) {
      return statusStr.get(val)
    }
  },
  methods: {
    formatStatus,
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    async open(obj) {
      this.clear()
      if (obj && obj.merchantId) {
        // 编辑
        this.id = obj.id
        await this.getDetail()
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    },
    async getDetail() {
      const res = await this.$api.GetSystemBlackInfo({
        id: this.id
      })
      this.detail = res
    },
    identityJust(e) {
      this.from.idFrontPhoto = e
    },
    identityAgainst(e) {
      this.from.idBackPhoto = e
    },
    proveFile(e) {
      this.from.documentaryEvidence = e
    },

    getParameter() {
      let fileList = [
        {
          fileName: Date.now(),
          fileUrl: this.from.documentaryEvidence
        }
      ]
      let obj = {
        idFrontPhoto: this.from.idFrontPhoto,
        idBackPhoto: this.from.idBackPhoto,
        documentaryEvidence: fileList,
        id: this.detail.id,
        remark: this.from.lostRemark
      }
      return obj
    },

    async submit() {
      this.btnloadSubmit = true
      let obj = this.getParameter()
      let res = await this.$api.confirmLost(obj).finally(() => {
        this.btnloadSubmit = false
      })
      if (res) {
        this.$message.success('操作成功')
        this.show = false
        this.$emit('submit')
      }
    },
    async submitAndWhiteList() {
      this.btnloadWhileSubmit = true
      let obj = this.getParameter()
      let res = await this.$api.lostAndWhiteList(obj).finally(() => {
        this.btnloadWhileSubmit = false
      })
      if (res) {
        this.$message.success('操作成功')
        this.show = false
        this.$emit('submit')
      }
    }
  }
}
const statusStr = new Map([
  [0, '手动添加'],
  [1, '自动添加'],
  [null, '--'],
  [undefined, '--']
])
</script>
