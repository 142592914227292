<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView
      ref="vTable"
      :labels="tableLabels"
      apiName="GetFPWorkPlanPageList"
      :searchList="searchList"
      :selection="true"
      :selConfig="{ selectable }"
      @selection-change="selChange"
    >
      <!-- 按钮栏 -->
      <el-form-item v-if="$hasAccess('007000')" slot="btnline-item-left">
        <span class="v-sel-info">已选择 {{ multipleSelection.length || 0 }} 项</span>
        <el-button plain type="primary" @click="multipleCheck">批量提现</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <!-- <el-table-column slot="table-item" label="操作" width="100" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button type="text" @click="$refs.DialogInfo.open(row)">查看</el-button>
        </template>
      </el-table-column> -->
    </TableView>
    <DialogWd ref="DialogWd" @submit="$refs.vTable.refreshTable()" />
  </div>
</template>

<script>
import DialogWd from './wd'
import { formatStatus } from '@/configs/formatters'
import { randomString } from '@/utils'

const tableLabels = [
  { label: '结算单号', prop: 'planNo' },
  { label: '所属任务', prop: 'taskName' },
  { label: '商户名称', prop: 'merchantName' },
  { label: '商户号', prop: 'memberCode' },
  { label: '认证企业', prop: 'mainName' },
  { label: '付款通道', prop: 'paymentChannel' },
  { label: '提交金额', prop: 'issueAmount', cmp: 'money' },
  { label: '人数', prop: 'totalPeopleNum' },
  {
    label: '税源地-通道',
    prop: 'supplierName',
    formatter: (row, column, cellValue, index) => {
      return (cellValue || '--') + '-' + (row.channelName || '--')
    }
  },
  {
    label: '状态',
    prop: 'withdrawState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '待提现' },
          { key: 1, label: '提现中' },
          { key: 2, label: '已提现' }
        ],
        cellValue
      )
    }
  }
]
const searchList = [
  {
    cmp: 'dtpickers',
    label: '日期',
    prop: ['CreateTimeBegin', 'CreateTimeEnd']
  },
  {
    cmp: 'input',
    label: '商户',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入商户号/商户名称',
      style: { width: '300px' }
    }
  }
]
export default {
  components: { DialogWd },
  data() {
    return {
      amount: 0,
      multipleSelection: [],
      tableLabels,
      searchList
    }
  },
  methods: {
    selChange(val) {
      this.multipleSelection = val
    },
    selectable(row) {
      if (row.withdrawState == 0) return true
      return false
    },
    multipleCheck() {
      if (!this.multipleSelection.length) return this.$message.warning('请选择需要批量处理的数据')
      const workPlanIds = this.multipleSelection.map((item) => {
        return item.id
      })
      this.tableWithDrawal(workPlanIds)
    },
    // 获取上账id
    async tableWithDrawal(workPlanIds) {
      const serialNumber = randomString(16)
      const res = await this.$api.GetFPWorkPlanWithdraw({ workPlanIds, serialNumber })
      this.$refs.DialogWd.open(res)
    }
  }
}
</script>
