<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetReceiveTaskManagePageList" :searchList="searchList">
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="120" fixed="right">
        <template v-slot="{ row }">
          <template>
            <el-button v-if="$hasAccess('006200')" type="text" @click="$refs.Detail.open(row, false)">查看</el-button>
          </template>
        </template>
      </el-table-column>
    </TableView>
    <Detail @refresh="asyncRefreshTable" ref="Detail"></Detail>
  </div>
</template>

<script>
import Detail from './detail.vue'
import { tableOrderReceiving } from '@/configs/tables'
import { searchOrderReceiving } from '@/configs/searches'
export default {
  components: {
    Detail
  },
  data() {
    return {
      tableLabels: tableOrderReceiving,
      searchList: searchOrderReceiving
    }
  },
  mounted() {
    this.getOptions()
  },
  methods: {
    // 表格延迟刷新
    asyncRefreshTable() {
      setTimeout(() => {
        this.$refs.vTable.refreshTable()
      }, 0)
    },
    async getOptions() {
      let taskRes = await this.$api.GetTaskNameValueList({ PageIndex: 1, PageSize: 999 })
      let merchantRes = await this.$api.GetMerchantNameValueList({ PageIndex: 1, PageSize: 999 })
      this.searchList[1].options = taskRes.list.map((item) => {
        return {
          key: item.id,
          label: `(${item.code}) ${item.name}`
        }
      })
      this.searchList[2].options = merchantRes.list.map((item) => {
        return {
          key: item.id,
          label: `(${item.code}) ${item.name}`
        }
      })
    }
  }
}
</script>
