<template>
  <el-dialog title="添加" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="850px">
    <div class="dialog-wrap">
      <FormView ref="FormView" :labels="labels" :formConfig="{ labelWidth: '150px' }" @onInputChange="onInputChange">
        <!-- 选择模板 -->
        <template v-slot:contractTemplateId="{ form, idx }">
          <el-col>
            <el-form-item label="选择模板" prop="contractTemplateId" :rules="[{ required: true, message: '请选择模板并填写', trigger: 'blur' }]">
              <div class="fillTemplate">
                <!-- <span>{{ formatStatus(agreementType, form.templateType) }}</span> -->
                <el-select style="width: 300px" v-model="form.contractTemplateId" placeholder="请选择" @change="changeContractTemplateId">
                  <el-option v-for="item in tempOptions" :key="item.id" :label="item.templateCode + ' ' + item.templateName" :value="item.id">
                  </el-option>
                </el-select>
                <el-button type="text" @click="openContractTemplate">填充模板</el-button>
              </div>
            </el-form-item>
          </el-col>
        </template>
        <!-- 盖章关键字 -->
        <template v-slot:signatoryDtos="{ form, idx }">
          <el-col>
            <el-form-item label="盖章关键字" class="is-required">
              <el-table :data="keywordList">
                <el-table-column prop="keyword" label="关键字" width="120"> </el-table-column>
                <el-table-column prop="signatoryType" label="签约方类型" width="100">
                  <template slot-scope="{ row, column, $index }">{{ formatStatus(stampOptions, row.signatoryType) }}</template>
                </el-table-column>
                <el-table-column prop="signatoryName" label="签约方" width="300">
                  <template slot-scope="{ row, column, $index }">
                    <!-- 商户&创客 -->
                    <el-input
                      v-if="row.signatoryType == 1 || row.signatoryType == 2"
                      disabled
                      :placeholder="row.signatoryType == 1 ? '商户主体' : '创客'"
                    ></el-input>
                    <el-select v-else v-model="row.signatoryId" filterable style="width: 100%" @change="onSignatoryChange($event, row)">
                      <el-option
                        style="width: 300px"
                        v-for="item in signatoryOptions"
                        :key="item.mainId"
                        :label="item.mainCode + ' ' + item.mainName"
                        :value="item.mainId"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="授权状态" width="80" prop="authorState"></el-table-column>
              </el-table>
            </el-form-item>
          </el-col>
        </template>
        <!-- 签约税源地 -->
        <template v-slot:supplierId="{ form, idx }">
          <el-col>
            <el-form-item label="签约税源地" prop="supplierId" :rules="[{ required: true, message: '请选择签约税源地', trigger: 'blur' }]">
              <el-select
                :style="{ width: '300px' }"
                v-model="form.supplierId"
                filterable
                remote
                placeholder="请输入税源地名称"
                :remote-method="getSupplierOptions"
                @change="setSupplierNameValue"
                :loading="loading"
              >
                <el-option v-for="item in options" :key="item.id" :label="`(${item.supplierCode}) ${item.supplierName}`" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </template>
      </FormView>
    </div>
    <div slot="footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="limtButton" :loading="btnloadSubmit">确定</el-button>
    </div>
    <FillMain ref="FillMain" @submit="updateTemplateDetail"></FillMain>
  </el-dialog>
</template>

<script>
import { formSign } from '@/configs/forms'
import { agreementType, formatStatus } from '@/configs/formatters.js'
import FillMain from './fillMain.vue'
import { debounce } from '@/utils'
export default {
  // 组件名称
  name: 'addSign',
  components: { FillMain },
  // 组件状态值
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      loading: false,
      labels: formSign,
      merchantId: null,
      merchantMainId: null,
      tempOptions: [],
      options: [],
      agreementType,
      templateDetail: {
        contractTemplateValue: '',
        contractTemplateId: ''
      },
      stampOptions: [
        { label: '商户', key: 1 },
        { label: '创客', key: 2 },
        { label: '税源地', key: 3 },
        { label: '其他', key: 99 }
      ],
      keywordList: [],
      signatoryOptions: []
    }
  },
  // 计算属性
  computed: {},
  // 组件方法
  methods: {
    formatStatus,
    clear() {
      this.keywordList = []
      this.templateDetail = {
        contractTemplateValue: '',
        contractTemplateId: ''
      }
    },
    open(data) {
      this.clear()
      const { merchantId, mainId } = data
      this.merchantId = merchantId
      this.merchantMainId = mainId
      this.getSupplierOptions(null)
      this.getBusinessMainCodeAndNameList()
      this.show = true
      this.$nextTick(() => {
        this.$refs.FormView.clearValidate()
        this.$refs.FormView.fillData({ signType: 1 })
      })
    },
    // 更新填充模板项
    updateTemplateDetail(val, state) {
      // 主submit回调
      this.templateDetail.contractTemplateValue = JSON.stringify(val)
    },
    // 获取合同模板列表
    getTemplateList(params) {
      this.$api.GetContractTemplateByTypePageList({ ...params, pageSize: 500 }).then((res) => {
        this.tempOptions = res.list
      })
    },
    // 选中模板，获取关键字
    changeContractTemplateId(val) {
      this.$refs.FillMain.open(val)
      this.templateDetail.contractTemplateId = val
      this.templateDetail.contractTemplateValue = ''
      this.$api.GetContractTemplateKeywordList({ templateId: val }).then((res) => {
        this.keywordList = res.list.map((item) => {
          return {
            keyword: item.keyword,
            signatoryType: item.signatoryType,
            signatoryId: '',
            signatoryName: ''
          }
        })
      })
    },
    // 获取主体信息下拉列表
    async getBusinessMainCodeAndNameList(val) {
      const res = await this.$api.GetBusinessMainCodeAndNameList()
      this.signatoryOptions = res.list
    },
    // 主体切换, 填充name，并判断授权
    async onSignatoryChange(val, item) {
      if (val) {
        const one = this.signatoryOptions.find((it) => it.mainId == val)
        if (one) {
          const res = await this.$api.GetBusinessMainAuthorizationState({ MainId: val })
          this.$set(item, 'signatoryName', one.mainName)
          this.$set(
            item,
            'authorState',
            formatStatus(
              [
                { key: 0, label: '未授权' },
                { key: 1, label: '已授权' }
              ],
              res.authorState,
              ''
            )
          )
        } else {
          this.$set(item, 'signatoryId', '')
          this.$set(item, 'signatoryName', '')
          this.$set(item, 'authorState', '')
        }
      } else {
        this.$set(item, 'signatoryName', '')
        this.$set(item, 'authorState', '')
      }
    },
    // 获取税源地数据源
    getSupplierOptions(val) {
      this.loading = true
      this.$api.GetSupplierInfoByKeyWord({ SupplierName: val }).then((res) => {
        this.options = res.list
        this.loading = false
      })
    },
    // 选中税源地主体,显示相应税源地名称
    setSupplierNameValue(val) {
      this.options.forEach((item) => {
        if (item.id == val) {
          this.$refs.FormView.upData({ mainName: item.mainName })
        }
      })
    },
    // 表单回调事件
    onInputChange(event, prop) {
      if (prop == 'templateType') {
        this.getTemplateList({ templateType: 1 || event })
        this.$refs.FormView.upData({ contractTemplateId: null })
        this.templateDetail = {
          contractTemplateValue: '',
          contractTemplateId: ''
        }
        this.keywordList = []
      }
    },
    // 提交
    limtButton: debounce(function (val) {
      this.submit()
    }, 500),
    // 提交
    async submit() {
      const data = await this.$refs.FormView.validate()
      const { signType } = data
      const { contractTemplateValue, contractTemplateId } = this.templateDetail
      // 判断合同模板
      if (signType == 1 && contractTemplateValue.length == 0) return this.$message.error('请选择合同模板并填写')
      // 判断关键字
      if (signType == 1) {
        for (let idx in this.keywordList) {
          const item = this.keywordList[idx]
          if (item.signatoryType != 1 && item.signatoryType != 2 && !item.signatoryId)
            return this.$message.error(`请填写盖章关键字【${item.keyword}】`)
          const one = this.keywordList.find((it, ix) => ix != idx && it.signatoryType == item.signatoryType && it.signatoryId == item.signatoryId)
          if (one) return this.$message.error(`盖章关键字【${item.keyword}】与【${one.keyword}】，不能选择相同签约方`)
        }
      }
      // 入参
      const params = Object.assign(
        { contractTemplateValue, contractTemplateId, merchantId: this.merchantId, merchantMainId: this.merchantMainId },
        data
      )
      if (signType == 1) {
        params.signatoryDtos = this.keywordList
      }
      this.btnloadSubmit = true
      const res = await this.$api.AddBusinessMainSupplier(params).finally(() => {
        this.btnloadSubmit = false
      })
      if (res) {
        this.$message.success('添加成功')
        this.show = false
        this.$emit('updateList')
      }
    },
    openContractTemplate() {
      if (!this.templateDetail.contractTemplateId) {
        this.$message.error('请先选择模板')
      } else {
        this.$refs.FillMain.open(this.templateDetail.contractTemplateId, this.templateDetail.contractTemplateValue)
      }
    }
  },
  created() {}
}
</script>
<style scoped>
.fillTemplate {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 3fr;
  margin-bottom: 10px;
}
</style>
