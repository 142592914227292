import {
  setToken,
  getToken,
  setUserId,
  getUserId,
  setUsername,
  getUsername,
  setAvatar,
  getAvatar,
  setRoute,
  getRoute,
  Encrypt,
  Decrypt
} from '@/utils/auth'

import API from '@/api'

export default {
  namespaced: true,
  state: {
    token: getToken(),
    userId: getUserId(),
    username: getUsername(),
    avatar: getAvatar(),
    route: getRoute(),
    menuRouters: []
  },
  mutations: {
    setToken(state, token) {
      state.token = Decrypt(token)
      setToken(token)
    },
    setUserId(state, userId) {
      state.userId = userId
      setUserId(userId)
    },
    setUsername(state, username) {
      state.username = username
      setUsername(username)
    },
    setAvatar(state, avataer) {
      state.avataer = avataer
      setAvatar(avataer)
    },
    setRoute(state, route) {
      state.route = route
      setRoute(route)
    },
    setMenuRouters(state, routers) {
      state.menuRouters = routers
    }
  },
  actions: {
    login({ commit }, { username: user, password, vCode }) {
      return new Promise((resolve, reject) => {
        const params = {
          code: Encrypt(JSON.stringify({ user, password })),
          vCode
        }
        API.login(params)
          .then((res) => {
            if (res.token) {
              // 保存token & username & avatar
              commit('setToken', res.token)
              commit('setUserId', res.userId)
              commit('setUsername', res.userName)
              // commit('setAvatar', result.avatar)
              commit('setRoute', res.routeList)
              resolve()
            }
            reject()
          })
          .catch((err) => {
            reject()
          })
      })
    },
    logOut({ commit }) {
      return new Promise((resolve, reject) => {
        commit('setToken', '')
        commit('setUserId', '')
        commit('setUsername', '')
        commit('setAvatar', '')
        commit('setRoute', [])
        resolve()
      })
    },
    clearCache({ commit }) {
      commit('setToken', '')
      commit('setUserId', '')
      commit('setUsername', '')
      commit('setAvatar', '')
      commit('setRoute', [])
    }
  }
}
