var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":"差额提醒","visible":_vm.show,"append-to-body":true,"close-on-click-modal":false,"width":"800px"},on:{"update:visible":function($event){_vm.show=$event}}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.add}},[_vm._v("添 加")]),_c('el-table',{ref:"listTable",attrs:{"data":_vm.listData}},[_c('el-table-column',{attrs:{"label":"#","type":"index","width":"65"}}),_c('el-table-column',{attrs:{"label":"税源地"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":""},on:{"change":function($event){return _vm.onSupplierChange($event, row, $index)}},model:{value:(row.supplierId),callback:function ($$v) {_vm.$set(row, "supplierId", $$v)},expression:"row.supplierId"}},_vm._l((_vm.supplierOptions),function(item){return _c('el-option',{key:item.supplierId,staticStyle:{"width":"300px"},attrs:{"label":item.supplierName + '【' + item.supplierCode + '】',"value":item.supplierId}})}),1)]}}])}),_c('el-table-column',{attrs:{"label":"累计差额（转入金额-支付宝订单金额）"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-input-number',{attrs:{"controls":false,"min":-9999999.99,"precision":2,"max":99999999.99},model:{value:(row.warnLimit),callback:function ($$v) {_vm.$set(row, "warnLimit", $$v)},expression:"row.warnLimit"}})]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-button',{staticClass:"el-button-error",attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteOne(row, $index)}}},[_vm._v("删除")])]}}])})],1),_c('span',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.show = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("确 定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }