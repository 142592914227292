<template>
  <div>
    <el-dialog title="查看" :visible.sync="show" :append-to-body="true" :before-close="handlerClose" width="800px">
      <div>
        <el-descriptions
          :contentStyle="{ width: '250px', textAlign: 'left' }"
          :labelStyle="{ width: '120px', textAlign: 'right' }"
          :column="2"
          border
          title="基本信息"
        >
          <el-descriptions-item label="商户编号">{{ detail.memberCode || '无' }}</el-descriptions-item>
          <el-descriptions-item label="商户名称">{{ detail.merchantName || '无' }} </el-descriptions-item>
          <el-descriptions-item label="创客姓名"
            >{{ detail.userName || '无' }}
            <WpView v-if="detail.userName" :id="detail.id" :type="1" />
          </el-descriptions-item>
          <el-descriptions-item label="身份证号"
            >{{ detail.idCard || '无' }}
            <WpView v-if="detail.idCard" :id="detail.id" :type="1" />
          </el-descriptions-item>
          <el-descriptions-item label="当前月份">{{ detail.currMonth || '无' }}</el-descriptions-item>
          <el-descriptions-item label="发放金额">
            <span v-format="'#,##0.00'">{{ detail.grantAmount }}</span>
          </el-descriptions-item>

          <el-descriptions-item label="抽取状态">{{ detail.spotState == 0 ? '未抽取' : '已抽取' }}</el-descriptions-item>
          <el-descriptions-item label="任务编号">{{ detail.taskNo || '无' }}</el-descriptions-item>
          <el-descriptions-item label="任务名称">{{ detail.taskName || '无' }}</el-descriptions-item>
          <el-descriptions-item label="成果验收单模板"><span @click="downLoadWd" class="result-text">《成果验收单》</span></el-descriptions-item>
        </el-descriptions>
        <el-descriptions
          :column="2"
          border
          title="交付物"
          :contentStyle="{ width: '250px', textAlign: 'left' }"
          :labelStyle="{ width: '120px', textAlign: 'right' }"
          style="margin-top: 18px"
        >
          <!-- <template slot="extra">
            <el-button
              v-if="detail.uploadState == 1 && detail.taskType == 0 && $hasAccess('005305')"
              @click=";(showReason = true), (backType = 1)"
              type="primary"
              size="small"
              >退回</el-button
            >
          </template> -->
          <el-descriptions-item label="上传时间">{{ detail.uploadTime || '无' }}</el-descriptions-item>
          <el-descriptions-item label="上传人">{{ detail.uploader || '无' }}</el-descriptions-item>
          <el-descriptions-item label="交付上传物状态">{{ formatStatus(kvAuditStatus, detail.uploadState) }}</el-descriptions-item>
          <el-descriptions-item label="交付物审核状态">{{ formatStatus(kvSearchAuditStatus, detail.delAuditState) }}</el-descriptions-item>
          <el-descriptions-item label="审核时间">{{ detail.delAuditTime || '无' }}</el-descriptions-item>
          <el-descriptions-item label="退回理由">{{ detail.delBackReason || '无' }}</el-descriptions-item>
          <el-descriptions-item label="上传文件">
            <template v-if="detail.deliverablesAttachments && detail.deliverablesAttachments.length > 0">
              <div v-for="(item, index) in detail.deliverablesAttachments" :key="index">
                <ImageViewer v-if="item.attachUrl && item.type != 'pdf'" :path="item.attachUrl" :name="'查看文件' + (index + 1)" />
                <span @click="viewPdf(item.attachUrl)" class="otherStyle" v-else>查看文件{{ index + 1 }}</span>
              </div>
            </template>
            <span v-else>无</span>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions
          :column="2"
          border
          title="成果验收单"
          :contentStyle="{ width: '250px', textAlign: 'left' }"
          :labelStyle="{ width: '120px', textAlign: 'right' }"
          style="margin-top: 18px"
        >
          <!-- <template slot="extra">
            <el-button
              v-if="detail.apcUploadState == 1 && detail.taskType == 0 && $hasAccess('001606')"
              @click=";(showReason = true), (backType = 2)"
              type="primary"
              size="small"
              >退回</el-button
            >
          </template> -->
          <!-- <el-descriptions-item label="上传时间">{{ detail.apcUploadDateTime || '无' }}</el-descriptions-item>
          <el-descriptions-item label="上传人">{{ detail.apcUploader || '无' }}</el-descriptions-item> -->
          <el-descriptions-item label="验收单上传状态">{{ formatStatus(kvAuditStatus, detail.apcUploadState) }}</el-descriptions-item>
          <el-descriptions-item label="验收单审核状态">{{ formatStatus(kvSearchAuditStatus, detail.apcAuditState) }}</el-descriptions-item>
          <el-descriptions-item label="审核时间">{{ detail.apcAuditTime || '无' }}</el-descriptions-item>
          <el-descriptions-item label="退回理由">{{ detail.apcBackReason || '无' }}</el-descriptions-item>
          <el-descriptions-item label="上传文件">
            <template v-if="detail.resultAcceptanceAttachments && detail.resultAcceptanceAttachments.length > 0">
              <div v-for="(item, index) in detail.resultAcceptanceAttachments" :key="index">
                <ImageViewer v-if="item.attachUrl && item.type != 'pdf'" :path="item.attachUrl" :name="'查看文件' + (index + 1)" />
                <span @click="viewPdf(item.attachUrl)" class="otherStyle" v-else>查看文件{{ index + 1 }}</span>
                <i class="el-icon-view el-icon--right"></i>
              </div>
            </template>
            <span v-else>无</span>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <!-- <span slot="footer">
        <el-button @click="showReason = true">审核不通过</el-button>
        <el-button type="primary" @click="submit">审核通过</el-button>
      </span> -->
    </el-dialog>
    <el-dialog width="30%" title="理由" :visible.sync="showReason" :append-to-body="true" :close-on-click-modal="false">
      <div>
        <el-input type="textarea" :rows="4" placeholder="请输入退回理由，不超过200字" v-model="reason"> </el-input>
      </div>
      <div slot="footer">
        <el-button @click="showReason = !showReason">取消</el-button>
        <el-button @click="submitBack" type="primary">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formatStatus, kvAuditStatus, kvSearchAuditStatus } from '@/configs/formatters'
import ImageViewer from '@/components/tableView/imageViewer'
import WpView from '@/components/wpView'
import { downloadUrl, downloadDocx } from '@/utils'
export default {
  components: { ImageViewer, WpView },
  data() {
    return {
      show: false,
      showReason: false,
      id: null,
      detail: {
        attachList: []
      },
      reason: '',
      kvAuditStatus,
      kvSearchAuditStatus,
      isEdit: false,
      backType: 0
    }
  },
  methods: {
    formatStatus,
    handlerClose() {
      this.show = false
      this.$emit('refresh')
    },
    viewPdf(e) {
      downloadUrl(e)
    },
    async open(obj) {
      this.reason = null
      const result = await this.$api.GetTaskDeliverable({ id: obj.id })
      result.deliverablesAttachments.map((item) => {
        let i = item.attachUrl.lastIndexOf('.')
        item.type = item.attachUrl.substr(i + 1)
      })
      result.resultAcceptanceAttachments.map((item) => {
        let i = item.attachUrl.lastIndexOf('.')
        item.type = item.attachUrl.substr(i + 1)
      })
      this.detail = Object.assign({}, result)
      this.show = true
    },
    async downLoadWd() {
      const result = await this.$api.DownloadAcceptanceNoteFile({ id: this.detail.id, type: 2 })
      downloadDocx(result, '交付物模板')
    },

    async submitBack() {
      //type:1:交付物,2:创客成果验收单
      if (this.reason == '') {
        this.$message.error('请输入退回原因')
        return false
      }
      let enclosureId = []
      if (this.backType == 1) {
        enclosureId = this.detail.deliverablesAttachments.map((item) => {
          return item.id
        })
      } else {
        enclosureId = this.detail.resultAcceptanceAttachments.map((item) => {
          return item.id
        })
      }
      enclosureId = enclosureId.toString()
      const res = await this.$api.TaskDeliverableBack({
        taskDeliverableId: this.detail.id,
        enclosureId: enclosureId,
        attachType: this.backType,
        remark: this.reason
      })
      if (res) {
        this.$message.success('退回成功')
        this.showReason = false
        this.open(this.detail)
        // this.$emit('refresh')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.otherStyle {
  color: #3d78fa;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}
.result-text {
  color: #409eff;
  cursor: pointer;
}
</style>
