<template>
  <el-dialog title="税源地税目调整确认" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
    <div>
      <p>对比系统与表格中税源地税目，将进行以下调整，请确认</p>
      <el-table :data="list" height="calc(70vh - 260px)">
        <el-table-column label="税源地">
          <template slot-scope="{ row, $index }">
            {{ row.supplierName }}
          </template>
        </el-table-column>
        <el-table-column label="税目">
          <template slot-scope="{ row, $index }">
            {{ row.taxNameStr }}
          </template>
        </el-table-column>
        <el-table-column label="原状态" width="120px">
          <template slot-scope="{ row, $index }">
            {{ row.beforeState }}
          </template>
        </el-table-column>
        <el-table-column label="改后状态" width="120px">
          <template slot-scope="{ row, $index }">
            {{ row.afterState }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      list: []
    }
  },
  methods: {
    open(obj) {
      this.id = obj.contrastRequestId
      this.list = obj.supplierTaxUpdateList
      this.show = true
    },
    async submit() {
      this.btnloadSubmit = true
      const res = await this.$api.ConfirmImportTaxItemBySupplier({ ContrastRequestId: this.id }).finally(() => {
        this.btnloadSubmit = false
      })
      this.$message.success('税源地税目调整成功')
      this.show = false
    }
  }
}
</script>
