<template>
  <el-dialog title="查看" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="600px">
    <!-- 内容区域 -->
    <el-descriptions :column="1" size="medium" :labelStyle="{ width: '100px', textAlign: 'right' }">
      <el-descriptions-item label="短信模板分类">{{ detail.smsCategory || '--' }}</el-descriptions-item>
      <el-descriptions-item label="短信模板名称">{{ detail.smsName || '--' }}</el-descriptions-item>
      <el-descriptions-item label="收件人"
        ><span v-if="detail.smsRecipients">
          {{ detail.smsRecipients.toString() }}
        </span>
        <span v-else>--</span>
      </el-descriptions-item>
      <el-descriptions-item label="短信签名">{{ detail.smsSignature || '--' }}</el-descriptions-item>
      <el-descriptions-item label="短信模板内容"><div class="conetnt" v-html="detail.smsContent || '--'"></div></el-descriptions-item>
      <!-- <el-descriptions-item label="短信签名"><span v-html="formatStatus(kvGrantType, detail.grantType)"></span></el-descriptions-item> -->
      <el-descriptions-item label="备注">{{ detail.smsRemark || '无' }}</el-descriptions-item>
    </el-descriptions>
  </el-dialog>
</template>

<script>
import { formatStatus } from '@/configs/formatters'
export default {
  data() {
    return {
      id: null,
      show: false,
      detail: {},
      temOptions: []
    }
  },
  methods: {
    formatStatus,
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    async open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 编辑
        this.id = obj.id
        await this.getOtionps()
        await this.getDetail()
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    },
    async getDetail() {
      const res = await this.$api.GetSmsTemplate({ id: this.id })
      this.detail = res
      this.smsImport()
    },
    async getOtionps() {
      const resOptions = await this.$api.GetSmsParameterAllList()
      this.temOptions = resOptions.list
    },
    // sms文本导入成结构
    smsImport() {
      const content = this.detail.smsContent
      const regex = /(\{[^}]+\})/g
      const resList = content.split(regex)
      const regimg = /^\{([^}]+)\}$/
      let result = ''
      for (let i = 0; i < resList.length; i++) {
        const one = resList[i]
        const match = one.match(regimg)
        if (match) {
          const tag = this.temOptions.find((it) => it.parameterCode == match[1])
          if (tag) {
            result += `<span class="el-tag el-tag--small el-tag--light" style="margin: 0 5px;">${tag.parameterName}（${match[1]}）</span>`
          }
        } else {
          result += one
        }
      }
      this.$set(this.detail, 'smsContent', result)
    }
  }
}
</script>

<style lang="scss" scoped>
.delme {
  text-decoration: line-through;
  opacity: 0.5;
}
/deep/ .el-descriptions-item__label {
  white-space: nowrap;
}
.conetnt {
  line-height: 28px;
  margin-top: -4px;
}
</style>
