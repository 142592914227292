<template>
    <div class="flex-home">
        <div class="pageTitle">{{ $route.meta.title }}</div>
        <TableView ref="vTable" :labels="tableLabels" apiName="GetRechargeExceptionPageList" :searchList="searchList">
            <!-- 操作栏 -->
            <el-table-column slot="table-item" label="操作" width="100" fixed="right">
                <template v-slot="{ row, $index }">
                    <el-button v-if="!row.processingState" type="text"
                        @click="$refs.Detail.open(row, true)">处理</el-button>
                    <el-button type="text" @click="$refs.Detail.open(row)">查看</el-button>
                </template>
            </el-table-column>
        </TableView>
        <Detail ref="Detail" @submit="$refs.vTable.refreshTable()" />
    </div>
</template>
  
<script>
import Detail from './detail'
import { formatStatus, kvExceptionState, kvExceptionResult } from '@/configs/formatters'

const tableLabels = [
    { label: '打款时间', prop: 'payTime' },
    { label: '流水号', prop: 'exceptionNo' },
    { label: '商户名称', prop: 'merchantName' },
    { label: '商户编号', prop: 'memberCode' },
    { label: '充值金额', prop: 'exceptionRechargeAmount', cmp: 'money' },
    { label: '收款账户名称', prop: 'merchantAccountName' },
    { label: '收款账户号码', prop: 'merchantAccountNumber' },
    { label: '收款开户银行', prop: 'merchantAccountBank' },
    { label: '付款账户名称', prop: 'payerAccountName' },
    { label: '付款账户号码', prop: 'payerAccountNumber' },
    {
        label: '状态',
        prop: 'processingState',
        formatter: (row, column, cellValue, index) => {
            return formatStatus(kvExceptionState, cellValue)
        }
    },
    {
        label: '处理结果',
        prop: 'processingResult',
        formatter: (row, column, cellValue, index) => {
            return formatStatus(kvExceptionResult, cellValue, '')
        }
    }
]
const searchList = [
    {
        cmp: 'input',
        prop: 'SearchTxt',
        config: {
            placeholder: '请输入商户编号/商户名称/流水号',
            style: { width: '300px' }
        }
    },
    {
        cmp: 'select',
        label: '状态',
        prop: 'State',
        options: kvExceptionState
    },
    {
        cmp: 'dtpickers',
        label: '打款时间',
        prop: ['PayTimeBegin', 'PayTimeEnd']
    },
    {
        cmp: 'select',
        label: '处理结果',
        prop: 'Result',
        options: kvExceptionResult
    }
]
export default {
    components: { Detail },
    data() {
        return {
            tableLabels,
            searchList
        }
    }
}
</script>
  