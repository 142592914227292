<template>
  <el-dialog :title="!id ? '添加' : '编辑'" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="500px">
    <!-- 内容区域 -->
    <div v-if="show">
      <FormView ref="vForm" :labels="fromLabels"> </FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { kcChannelType, kvChannelState } from '@/configs/formatters'
import { regular } from '@/configs/validate'
const fromLabels = [
  {
    cmp: 'input',
    label: '渠道编号',
    prop: 'channelCode',
    config: { disabled: true, placeholder: '系统生成' }
  },
  {
    cmp: 'input',
    label: '渠道名称',
    prop: 'channelName',
    itemConfig: {
      rules: [{ required: true, message: '请输入渠道名称', trigger: 'blur' }]
    },
    config: { maxlength: 50 }
  },
  {
    cmp: 'radio',
    label: '渠道类型',
    prop: 'channelType',
    options: kcChannelType,
    itemConfig: {
      rules: [{ required: true, message: '请选择渠道类型', trigger: 'change' }]
    }
  },
  {
    cmp: 'input',
    label: '联系方式',
    prop: 'channelPhone',
    itemConfig: {
      rules: [{ required: true, message: '请输入联系方式', trigger: 'blur' }]
    },
    itemConfig: {
      rules: [
        { required: true, message: '请输入联系方式', trigger: 'blur' },
        {
          pattern: regular.mobile,
          message: regular.mobileMsg,
          trigger: 'blur'
        }
      ]
    },
    config: { maxlength: 11 }
  },
  {
    cmp: 'select',
    label: '所属商务',
    prop: 'channelEmployeeList',
    options: [],
    config: {
      multiple: true,
      multipleLimit: 5,
      style: { width: '100%' }
    },
    itemConfig: {
      rules: [{ required: true, message: '请选择所属商务', trigger: 'change' }]
    }
  },
  {
    cmp: 'radio',
    label: '状态',
    prop: 'channelState',
    options: kvChannelState,
    itemConfig: {
      rules: [{ required: true, message: '请选择状态', trigger: 'change' }]
    }
  }
]
export default {
  data() {
    return {
      show: false,
      id: null,
      fromLabels,
      btnloadSubmit: false
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
    },
    open(obj) {
      this.clear()
      this.getEmployee()
      this.show = true
      if (obj && obj.id) {
        // 编辑
        this.id = obj.id
        this.getDetail()
      } else {
        // 添加
        this.$nextTick(() => {
          this.$refs.vForm.fillData({ channelType: 0, channelState: 0, channelEmployeeList: [] })
        })
      }
    },
    async getDetail() {
      const res = await this.$api.GetChannelInfo({ Id: this.id })
      const channelEmployeeList = (res.channelEmployeeList || []).map((it) => it.employeeId)
      this.$nextTick(() => {
        this.$refs.vForm.fillData({ ...res, channelEmployeeList })
      })
    },
    async getEmployee() {
      const res = await this.$api.GetEmployeeInfoList()
      const options = (res.list || []).map((it) => {
        return {
          key: it.id,
          label: it.employeeName
        }
      })
      this.fromLabels.find((item) => item.prop == 'channelEmployeeList').options = options
    },
    async submit() {
      const params = await this.$refs.vForm.getForm()
      this.btnloadSubmit = true
      if (this.id) {
        // 编辑
        const res = await this.$api.EditChannelInfo(params).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('编辑成功')
      } else {
        // 添加
        const res = await this.$api.AddChannelInfo(params).finally(() => {
          this.btnloadSubmit = false
        })
        this.$message.success('添加成功')
      }
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
