<template>
  <el-dialog title="查看" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 600px">
      <el-descriptions border title="基本信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right' }">
        <el-descriptions-item label="人员编号">{{ detail.peopleNo || '无' }}</el-descriptions-item>
        <el-descriptions-item label="创客姓名">{{ detail.name || '无' }}<WpView v-if="detail.name" :id="id" /></el-descriptions-item>
        <el-descriptions-item label="手机号">{{ detail.mobile || '无' }}<WpView v-if="detail.mobile" :id="id" /></el-descriptions-item>
        <el-descriptions-item label="身份证号">{{ detail.idCard || '无' }}<WpView v-if="detail.idCard" :id="id" /></el-descriptions-item>

        <el-descriptions-item label="性别">{{ detail.sex || '无' }}</el-descriptions-item>
        <el-descriptions-item label="年龄">{{ detail.age || '无' }}</el-descriptions-item>
        <el-descriptions-item label="关联微信openid">{{ detail.openID || '无' }}</el-descriptions-item>

        <el-descriptions-item label="身份证照片正面">
          <ImageViewer v-if="detail.idFrontPhoto" :path="detail.idFrontPhoto" />
          <span v-else>无</span>
        </el-descriptions-item>
        <el-descriptions-item label="身份证照片反面">
          <ImageViewer v-if="detail.idBackPhoto" :path="detail.idBackPhoto" />
          <span v-else>无</span>
        </el-descriptions-item>
        <el-descriptions-item label="采集图像">
          <ImageViewer v-if="detail.livingPhoto" :path="detail.livingPhoto" />
          <span v-else>无</span>
        </el-descriptions-item>
        <el-descriptions-item label="采集时间">{{ detail.livingCreateTime || '无' }}</el-descriptions-item>
        <el-descriptions-item label="备注" :span="2">无</el-descriptions-item>
        <el-descriptions-item label="认证状态" :span="2">{{ detail.isAuth ? '已认证' : '未认证' }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="账户信息" :column="1" :labelStyle="{ width: '120px', textAlign: 'right' }">
        <el-descriptions-item label="银行卡">
          <!-- <template v-if="detail.bankNoList && detail.bankNoList.length">
            <div v-for="(item, index) in detail.bankNoList" :key="index">{{ item.bankNo }} {{ item.bankName }}</div>
          </template>
          <span v-else>无</span> -->

          <span>{{ detail.bankNo || '无' }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="支付宝账户">
          <span>{{ detail.alipayNo || '无' }}</span>
          <!-- <template v-if="detail.alipayNoList && detail.alipayNoList.length">
            <div v-for="(item, index) in detail.alipayNoList" :key="index">{{ item.alipayTel }} {{ item.alipayEmail }}</div>
          </template>
          <span v-else>无</span> -->
        </el-descriptions-item>
        <el-descriptions-item label="微信账户">{{ detail.wechatNo || '无' }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <div class="el-descriptions__header">
        <div class="el-descriptions__title">合作商户</div>
        <div class="el-descriptions__extra"></div>
      </div>
      <div>
        <el-table :data="detail.workMerchantList">
          <el-table-column type="index" label="序号" align="center" :width="65" fixed> </el-table-column>
          <el-table-column prop="memberCode" label="商户编号"> </el-table-column>
          <el-table-column prop="merchantName" label="商户名称"> </el-table-column>
          <el-table-column prop="contractMode" label="创客签约类型"> </el-table-column>
        </el-table>
      </div>
      <el-divider></el-divider>
      <div class="el-descriptions__header">
        <div class="el-descriptions__title">签约记录</div>
        <div class="el-descriptions__extra"></div>
      </div>
      <div>
        <el-table :data="detail.supplierPeopleInfoList">
          <el-table-column prop="supplierMainName" label="签约主体公司"></el-table-column>
          <el-table-column prop="contractMode" label="签约类型">
            <!-- <template slot-scope="{row}">
              {{row.isSign == 10?'静默签约':'电子签约'}}
            </template> -->
          </el-table-column>
          <el-table-column prop="contractScheme" label="签约方案">
            <!-- <template slot-scope="{row}">
              {{row.isSign == 10?'静默签约':'电子签约'}}
            </template> -->
          </el-table-column>
          <el-table-column prop="contractName" label="合同模板"></el-table-column>
          <el-table-column prop="isSign" label="签约状态">
            <template slot-scope="{ row }">
              {{ formatStatus(kvTableWorkPeopleContract, row.isSign) }}
            </template>
          </el-table-column>
          <el-table-column prop="signCreateTime" label="生成时间"></el-table-column>
          <el-table-column prop="signTime" label="签约时间"></el-table-column>
          <el-table-column prop="signMode" label="签约方式">
            <template slot-scope="{ row }">
              {{ row.signMode == 1 ? 'API' : row.signMode == 2 ? '小程序' : row.signMode == 3 ? '短信' : '' }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <el-button type="text" v-if="row.isSign == 0" @click="copyUrl(row)">复制链接</el-button>
              <el-button type="text" v-if="row.isSign == 1" @click="download(row)">下载</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <button ref="CopyBtn" class="copyBtn" :data-clipboard-text="copyText"></button>
  </el-dialog>
</template>

<script>
import ImageViewer from '@/components/tableView/imageViewer'
import WpView from '@/components/wpView'
import Clipboard from 'clipboard'
import { downloadUrl } from '@/utils'
import { formatStatus, kvTableWorkPeopleContract } from '@/configs/formatters'
export default {
  components: { ImageViewer, WpView },
  data() {
    return {
      kvTableWorkPeopleContract,
      id: null,
      show: false,
      detail: {},
      copyText: ''
    }
  },
  methods: {
    formatStatus,
    // 数据清空
    clear() {
      this.detail = {}
    },
    open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 编辑
        this.id = obj.id
        this.show = true
        this.getDetail()
      } else {
        this.$message.error('无效的信息')
      }
    },
    // 复制链接
    copyUrl(row) {
      if (row.signUrl) {
        this.copyText = row.signUrl
        setTimeout(() => {
          this.copy(row.signUrl, '签约')
        }, 0)
      } else {
        this.$message.warning('链接不存在')
      }
    },
    // 下载
    download(row) {
      if (!row.contractUrl) this.$message.warning('下载链接不存在')
      downloadUrl(row.contractUrl)
    },
    copy(text, name) {
      const clipboard = new Clipboard('.copyBtn')
      clipboard.on('success', () => {
        this.$notify({
          title: name + '链接已复制到剪切板',
          message: text,
          type: 'success'
        })
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        this.$notify({
          title: '请手动复制' + name + '链接',
          message: text,
          type: 'success'
        })
        // 释放内存
        clipboard.destroy()
      })
      this.$refs.CopyBtn.click()
    },
    // 获取详情
    async getDetail() {
      const res = await this.$api.GetWorkPeopleInfo({
        id: this.id
      })
      this.detail = res
    }
  }
}
</script>
