<template>
  <el-dialog title="查看" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" fullscreen>
    <!-- 内容区域 -->
    <div class="dialog-wrap-full" v-if="show">
      <div class="flex-home">
        <el-descriptions :title="'结算单信息【结算单号：' + detail.planNo + '】'" :column="2" :labelStyle="{ width: '120px', textAlign: 'right' }">
          <el-descriptions-item label="所属任务">{{ detail.taskName || '无' }}</el-descriptions-item>
          <el-descriptions-item label="任务编号">{{ detail.taskNo || '无' }}</el-descriptions-item>
          <el-descriptions-item label="人数">{{ detail.totalPeopleNum || '0' }}</el-descriptions-item>
          <el-descriptions-item label="付款通道">{{ detail.paymentChannel || '无' }}</el-descriptions-item>
          <el-descriptions-item label="提交金额"
            ><span v-format="'#,##0.00'">{{ detail.issueAmount || 0 }}</span> 元</el-descriptions-item
          >
          <el-descriptions-item label="服务费"
            ><span v-format="'#,##0.00'">{{ detail.serviceFee || 0 }}</span> 元</el-descriptions-item
          >
          <el-descriptions-item label="差额服务费"
            ><span v-format="'#,##0.00'">{{ detail.serviceDiffFee || 0 }}</span> 元</el-descriptions-item
          >
          <el-descriptions-item label="结算金额"
            ><span v-format="'#,##0.00'">{{ detail.settleAmount || 0 }}</span> 元</el-descriptions-item
          >
          <el-descriptions-item label="服务费抵扣金"
            ><span v-format="'#,##0.00'">{{ detail.deductionAmount || 0 }}</span> 元</el-descriptions-item
          >
          <el-descriptions-item label="支付金额"
            ><span v-format="'#,##0.00'">{{ detail.balanceAmount || 0 }}</span> 元</el-descriptions-item
          >
          <el-descriptions-item label="发放税源地">{{ detail.supplierName || '无' }}</el-descriptions-item>
          <el-descriptions-item label="发放通道">{{ detail.channelName || '无' }}</el-descriptions-item>
          <el-descriptions-item label="审核状态"
            ><span v-html="formatStatusHasColor(kvWorkPlanAuditState, detail.auditState)"></span
          ></el-descriptions-item>
          <el-descriptions-item label="发放状态"
            ><span v-html="formatStatusHasColor(kvWorkPlanGrantState, detail.grantState)"></span
          ></el-descriptions-item>
          <el-descriptions-item label="成功金额"
            ><span v-format="'#,##0.00'">{{ detail.successAmount || 0 }}</span> 元</el-descriptions-item
          >
          <el-descriptions-item label="失败金额"
            ><span v-format="'#,##0.00'">{{ detail.failAmount || 0 }}</span> 元</el-descriptions-item
          >
          <el-descriptions-item label="成本"
            ><span v-format="'#,##0.00'">{{ detail.costServiceFee || 0 }}</span> 元</el-descriptions-item
          >
          <el-descriptions-item label="差额成本"
            ><span v-format="'#,##0.00'">{{ detail.costServiceDiffFee || 0 }}</span> 元</el-descriptions-item
          >
          <el-descriptions-item label="利润"
            ><span v-format="'#,##0.00'">{{ detail.profitAmount || 0 }}</span> 元</el-descriptions-item
          >
          <el-descriptions-item :span="2" v-if="detail.auditState == 4" label="理由">{{ detail.aduitFailReason || '无' }}</el-descriptions-item>
        </el-descriptions>
        <el-divider></el-divider>
        <el-descriptions title="商户信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right' }">
          <el-descriptions-item label="商户名称">{{ detail.merchantName || '无' }}</el-descriptions-item>
          <el-descriptions-item label="商户编号">{{ detail.memberCode || '无' }}</el-descriptions-item>
          <el-descriptions-item label="认证企业">{{ detail.mainName || '无' }}</el-descriptions-item>
          <el-descriptions-item label="签约类型">
            <span v-html="formatStatus(kvContractMode, detail.contractMode)"></span>
          </el-descriptions-item>
        </el-descriptions>
        <el-divider></el-divider>
        <el-steps
          :active="
            detail.grantFinishTime
              ? 5
              : detail.grantConfirmTime
              ? 4
              : detail.auditFinishTime
              ? 3
              : detail.auditStartTime
              ? 2
              : detail.createTime
              ? 1
              : 0
          "
          align-center
        >
          <el-step title="创建结算单" :description="detail.createTime || ''"></el-step>
          <el-step title="提交结算单" :description="detail.auditStartTime || ''"></el-step>
          <el-step title="审核结算单" :description="detail.auditFinishTime || ''"></el-step>
          <el-step title="确认发放" :description="detail.grantConfirmTime || ''"></el-step>
          <el-step title="发放完成" :description="detail.grantFinishTime || ''"></el-step>
        </el-steps>
        <el-divider></el-divider>
        <div class="el-descriptions__header">
          <div class="el-descriptions__title">结算明细</div>
          <div class="el-descriptions__extra"></div>
        </div>
        <TableView
          ref="vTable"
          :labels="tableLabels"
          apiName="GetWorkDetailPageList"
          :searchList="searchList"
          :searchExt="{ PlanNo: id, PayState }"
          @geted="onTableGeted"
        >
          <!-- 附加栏 -->
          <div slot="ext-search" style="float: left; margin-right: 200px">
            <el-radio-group v-model="PayState" @change="onPayStateChange">
              <el-radio-button :label="null">全部</el-radio-button>
              <el-radio-button :label="1">待打款（{{ summary.waitPayCount || 0 }}）</el-radio-button>
              <el-radio-button :label="2">打款中（{{ summary.payingCount || 0 }}）</el-radio-button>
              <el-radio-button :label="3">打款成功（{{ summary.paySuccessCount || 0 }}）</el-radio-button>
              <el-radio-button :label="4">打款失败（{{ summary.payFailCount || 0 }}）</el-radio-button>
              <el-radio-button :label="5">拒绝打款（{{ summary.rejectPayCount || 0 }}）</el-radio-button>
              <el-radio-button :label="99">挂起（{{ summary.pendingCount || 0 }}）</el-radio-button>
            </el-radio-group>
          </div>
          <el-table-column slot="table-item" label="电子回单" width="100" fixed="right">
            <template v-slot="{ row }">
              <el-button v-if="row.receiptInfo" type="text" @click="downReceipt(row)">下载回单</el-button>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </TableView>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { formatStatus, formatStatusHasColor, kvWorkPlanAuditState, kvWorkPlanGrantState, kvContractMode } from '@/configs/formatters'
import { tableWorkPlanWorkDetail } from '@/configs/tables'
import { searchWorkPlanWorkDetail } from '@/configs/searches'
import { downloadUrl } from '@/utils'
export default {
  data() {
    return {
      id: null,
      show: false,
      detail: {},
      PayState: null,
      summary: {},
      tableLabels: tableWorkPlanWorkDetail,
      searchList: searchWorkPlanWorkDetail,
      kvWorkPlanAuditState,
      kvWorkPlanGrantState,
      kvContractMode
    }
  },
  methods: {
    formatStatus,
    formatStatusHasColor,
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
      this.PayState = null
      // this.tableLabels = tableWorkPlanWorkDetail
    },
    open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 编辑
        this.id = obj.planNo
        this.detail = obj
        this.show = true
        // if (obj.auditState == 1) {
        //   this.tableLabels.map((item, index) => {
        //     if (item.label == '服务费抵扣金') {
        //       this.tableLabels = JSON.parse(JSON.stringify(this.tableLabels))
        //       this.tableLabels.splice(index, 1)
        //     }
        //   })
        // }
      } else {
        this.$message.error('无效的信息')
      }
    },
    onPayStateChange() {
      this.$refs.vTable.searchTable()
    },
    async onTableGeted(response, params) {
      const res = await this.$api.GetWorkPlanByDetailPageListSummaryData(Object.assign(params, { PayState: null }))
      this.summary = res
    },
    // 下载回执
    async downReceipt(row) {
      downloadUrl(row.receiptInfo)
    }
  }
}
</script>
