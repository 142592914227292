<template>
  <el-dialog title="查看" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" top="8vh" width="800px">
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 500px">
      <el-descriptions
        :contentStyle="{ width: '250px', textAlign: 'left' }"
        :labelStyle="{ width: '120px', textAlign: 'right' }"
        :column="2"
        border
        title="基本信息"
      >
        <el-descriptions-item label="商户编号">{{ detail.memberCode || '无' }}</el-descriptions-item>
        <el-descriptions-item label="商户名称">{{ detail.merchantName || '无' }} </el-descriptions-item>
        <el-descriptions-item label="创客姓名"
          >{{ detail.userName || '无' }}
          <WpView v-if="detail.userName" :id="detail.id" :type="1" />
        </el-descriptions-item>
        <el-descriptions-item label="身份证号"
          >{{ detail.idCard || '无' }}
          <WpView v-if="detail.idCard" :id="detail.id" :type="1" />
        </el-descriptions-item>
        <el-descriptions-item label="结算所属期">{{ detail.currMonth || '无' }}</el-descriptions-item>
        <el-descriptions-item label="发放金额">
          <span v-format="'#,##0.00'">{{ detail.grantAmount }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="任务编号">{{ detail.taskNo || '无' }}</el-descriptions-item>
        <el-descriptions-item label="任务名称">{{ detail.taskName || '无' }}</el-descriptions-item>
      </el-descriptions>
      <div class="followRecords">
        <div class="title-text">跟进记录</div>
        <div class="block">
          <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              :type="activity.type"
              :timestamp="activity.timestamp"
              placement="top"
              size="large"
            >
              <el-card>
                <h4>更新 Github 模板</h4>
                <p>王小虎 提交于 2018/4/12 20:46</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { formFollowPhoneReview } from '@/configs/forms'
import { mapState } from 'vuex'
export default {
  props: {},
  data() {
    return {
      btnloadSubmit: false,
      show: true,
      fromLabels: formFollowPhoneReview,
      form: {},
      detail: {},
      activities: [
        {
          content: '支持使用图标',
          timestamp: '2018-04-12 20:46',
          type: 'primary'
        },
        {
          content: '支持自定义颜色',
          timestamp: '2018-04-03 20:46',
          color: '#0bbd87'
        },
        {
          content: '支持自定义尺寸',
          timestamp: '2018-04-03 20:46'
        },
        {
          content: '默认样式的节点',
          timestamp: '2018-04-03 20:46'
        }
      ]
    }
  },
  mounted() {},
  methods: {
    // 数据清空
    clear() {
      this.btnloadSubmit = false
      this.form = {}
    },
    async open() {
      this.clear()
      this.getMerchantOptions()
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData(this.form)
      })
    },
    // label字段添加
    async getMerchantOptions() {
      let v = []
      let result = await this.$api.GetMerchantInfoCodeAndName()
      if (result.list.length < 0) {
        return false
      }
      result.list.map((item) => {
        v.push({
          key: item.merchantId,
          label: `【${item.memberCode}】${item.merchantName}`
        })
      })
      this.fromLabels.forEach((item) => {
        if (item.prop === 'merchantIds') {
          item.options = v
        }
      })
    },
    // 提交
    async submit() {
      // 结果更新
      const result = await this.$refs.vForm.validate()
      if (result) {
        this.form = await this.$refs.vForm.getForm()
        this.btnloadSubmit = true
        let res = await this.$api.AddTaskDeliverableExtractPlan(Object.assign({}, this.form)).finally(() => {
          this.btnloadSubmit = false
        })
        if (res) {
          this.$message.success('添加成功')
          this.$emit('submit')
          this.show = false
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-wrap {
  .followRecords {
    margin-top: 30px;
    .title-text {
      font-size: 16px;
      font-weight: bold;
      color: #303133;
      margin-bottom: 20px;
    }
  }
}
</style>
