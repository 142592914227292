<template>
  <div class="login">
    <div class="container">
      <div class="box">
        <!-- <p class="desc">{{ $title }}</p> -->
      </div>
      <div class="login-form">
        <p class="desc">{{ $title }}</p>
        <div class="title">
          <el-image class="logo" :src="logoSrc"></el-image>
          <div class="t">登录</div>
        </div>
        <div class="content">
          <el-form :model="loginFormData" :rules="loginRules" ref="loginForm" size="medium">
            <el-form-item prop="username">
              <el-input class="input" clearable placeholder="请输入登录账号" v-model="loginFormData.username">
                <i slot="prefix" class="el-input__icon el-icon-user icon-input"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                class="input"
                show-password
                placeholder="请输入密码"
                :maxlength="32"
                v-model="loginFormData.password"
                @keyup.enter.native="handleBeforeLogin('loginForm')"
              >
                <i slot="prefix" class="el-input__icon el-icon-lock icon-input"></i>
              </el-input>
            </el-form-item>
            <el-form-item style="margin-bottom: 14px">
              <el-checkbox v-model="spwd">保存密码</el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-button :loading="loading" class="login-btn" type="primary" @click="handleBeforeLogin"> 登录 </el-button>
            </el-form-item>
            <!-- <el-form-item style="text-align: center">
              <el-checkbox v-model="agree"></el-checkbox>
              <el-link type="primary" style="display: inline; margin-left: 10px" @click="$refs.DialogAgree.open()">同意《XXXXX》协议</el-link>
            </el-form-item> -->
          </el-form>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <DialogAgree ref="DialogAgree" />
    <!-- 验证码 -->
    <el-dialog title="需要短信验证码验证登录" :visible.sync="smsShow" :append-to-body="true" :close-on-click-modal="false" width="500px">
      <div style="margin-bottom: 10px">已发送到手机号 {{ phone }}</div>
      <el-form ref="vForm" :model="form" :rules="rules" :inline="true" @submit.native.prevent>
        <el-form-item prop="vCode"><el-input v-model="form.vCode" placeholder="请输入验证码"></el-input></el-form-item>
        <el-form-item
          ><el-button type="primary" @click="handleBeforeLogin" :disabled="vcCountdown != 0" style="width: 120px">{{
            vcCountdown != 0 ? vcCountdown + 's后再获取' : '获取验证码'
          }}</el-button></el-form-item
        >
      </el-form>
      <span slot="footer">
        <el-button @click="smsShow = false">取 消</el-button>
        <el-button type="primary" @click="handleLogin">登录</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import logoSrc from '@/assets/logo.png'
import { setPwd, getPwd, Encrypt, Decrypt } from '@/utils/auth'
import DialogAgree from './agree'
export default {
  name: 'Login',
  components: { DialogAgree },
  data() {
    return {
      loading: false,
      logoSrc,
      loginFormData: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{ required: true, message: '请输入登录账号！', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码！', trigger: 'blur' }]
      },
      spwd: false,
      agree: false,
      smsShow: false,
      smsLoading: false,
      phone: '',
      form: {},
      rules: {
        vCode: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }]
      }
    }
  },
  computed: {
    vcCountdown() {
      return this.$store.state.app.vcCountdown
    }
  },
  mounted() {
    const pwd = getPwd()
    if (pwd) {
      const { username, password } = JSON.parse(pwd)
      this.loginFormData = { username, password }
      this.spwd = true
      this.agree = true
    } else {
      this.loginFormData = {
        username: '',
        password: ''
      }
      this.spwd = false
      this.agree = false
    }
    this.$nextTick(() => {
      this.$refs.loginForm.clearValidate()
    })
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          // if (!this.agree) return this.$message.error('请阅读协议，并勾选同意后登录')
          this.loading = true
          // 登录操作
          const params = {
            code: Encrypt(JSON.stringify({ user: this.loginFormData.username, password: this.loginFormData.password }))
          }
          this.$api
            .LoginSendSmsGetPhone(params)
            .then((res) => {
              this.phone = res.phone
              this.smsShow = true
              this.loading = false
              if (this.vcCountdown == 0) {
                this.$store.dispatch('app/vcDownStart', true)
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    },
    handleBeforeLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          // this.smsLoading = true
          this.loading = true
          // 登录操作
          const params = {
            username: this.loginFormData.username,
            password: this.loginFormData.password,
            vCode: this.form.vCode || 0
          }
          this.$store
            .dispatch('user/login', params)
            .then((res) => {
              // this.smsLoading = false
              this.loading = false
              if (this.spwd) setPwd(JSON.stringify(params))
              else setPwd('')
              // this.smsShow = false
              this.$router.push({ path: this.redirect || '/' })
            })
            .catch(() => {
              // this.smsLoading = false
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';
.login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background-image: url('../../assets/login-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #f7f7f7;

  .container {
    width: 100%;
    padding: 50px 240px 50px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .box {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
      .desc {
        color: #fff;
        font-size: 36px;
        font-weight: bold;
        text-shadow: 0 5px 10px rgba($color: #000000, $alpha: 1);
      }
    }

    .login-form {
      flex-shrink: 0;
      width: 340px;
      background: rgba($color: #fff, $alpha: 0.95);
      box-shadow: 0 5px 15px rgba($color: #000000, $alpha: 0.6);
      border-radius: 10px;
      position: relative;
      .desc {
        position: absolute;
        top: 0px;
        right: 15px;
        margin-top: -30px;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        text-shadow: 0 2px 5px rgba($color: #000000, $alpha: 1);
      }
      .title {
        text-align: center;
        padding: 20px 40px;
        font-size: 18px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .t {
          position: absolute;
          bottom: -7px;
          left: 0;
          right: 0;
          text-align: center;
          font-size: 14px;
          line-height: 1;
          display: flex;
          align-items: center;
          &::before {
            content: '';
            display: block;
            height: 1px;
            background: $--border-color-base;
            flex: 1;
            margin-right: 20px;
          }
          &::after {
            content: '';
            display: block;
            height: 1px;
            background: $--border-color-base;
            flex: 1;
            margin-left: 20px;
          }
        }
        .logo {
          width: 64px;
          // margin-right: 10px;
        }
      }
      .content {
        padding: 40px 40px 10px;
      }
    }

    .el-form-item {
      // margin-bottom: 30px;
    }

    // .input {
    //   background: transparent;
    //   input {
    //     background: $--color-text-primary;
    //     border: none;
    //     color: #fff;
    //   }
    // }

    .icon-input {
      font-size: 20px;
    }

    .login-btn {
      width: 100%;
      margin-top: 10px;
    }
  }
}
</style>
