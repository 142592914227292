<template>
  <el-dialog :title="title" :visible.sync="show" :append-to-body="true" width="800px">
    <!-- 内容区域 -->
    <div class="dialog-wrap">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra
      justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam
      fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.
    </div>
    <span slot="footer">
      <!-- <el-button @click="show = false">取 消</el-button> -->
      <el-button type="primary" @click="show = false">我知道了</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      show: false,
      title: '《XXXXX》协议'
    }
  },
  methods: {
    open() {
      this.show = true
    }
  }
}
</script>
