<template>
  <el-upload
    :action="config.type == 'excel' ? uploadExcelUrl : uploadUrl"
    :show-file-list="false"
    v-loading="loading"
    class="file-uploader"
    :headers="extraData"
    :before-upload="onBeforeUpload"
    :on-error="onUploadError"
    :on-success="onUpload"
    :on-preview="handlePreview"
    v-bind="config"
    style="margin-right: 15px; display: inline-block"
  >
    <span v-if="config.disabled && !fakeValue" slot="trigger">无</span>
    <el-tooltip
      slot="trigger"
      v-if="!config.disabled"
      effect="dark"
      :disabled="!config.tips"
      :content="config.tips || ''"
      placement="top-start"
      style="margin-right: 15px"
    >
      <el-button icon="el-icon-upload" style="margin-right: 15px" type="primary" size="mini">{{ fakeValue ? '重传' : '上传' }}</el-button>
    </el-tooltip>

    <el-link v-if="fakeValue" type="primary" @click="lookFile" style="max-height: 24px"
      >查看文件 <i class="el-icon-view el-icon--right"></i>
    </el-link>
    <el-image-viewer
      v-if="showViewer"
      :zIndex="9999"
      :url-list="viewerlList"
      :on-close="
        () => {
          showViewer = false
        }
      "
    />
  </el-upload>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { downloadUrl } from '@/utils'
export default {
  name: 'ComponentFormUpload',
  components: {
    ElImageViewer
  },
  props: {
    modelValue: String,
    type: String,
    config: Object,
    suffixArray: {
      type: Array,
      default: () => ['jpg', 'png', 'jpeg']
    },
    size: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      loading: false,
      uploadUrl: process.env.VUE_APP_BASE_HOST + process.env.VUE_APP_BASE_API + 'Upload/UploadFile',
      uploadExcelUrl: process.env.VUE_APP_BASE_HOST + process.env.VUE_APP_BASE_API + 'Upload/UploadExcelFile',
      showViewer: false,
      viewerlList: []
    }
  },
  computed: {
    extraData() {
      return {
        Authorization: 'Bearer ' + this.$store.state.user.token
      }
    },
    fakeValue: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update', val)
      }
    }
  },
  methods: {
    handlePreview() {},
    onBeforeUpload(file, fileList) {
      // 文件大小限制
      if (file.size / (1024 * 1024) > this.size) {
        this.$message.warning(`当前限制文件大小不能大于${this.size}M`)
        return false
      }
      // 格式限制
      if (this.config.type != 'all') {
        let suffix = this.getFileType(file.name)
        if (this.suffixArray.indexOf(suffix) === -1) {
          this.$message.warning(`只能上传${this.suffixArray.toString()}`)
          return false
        }
      }
      this.loading = true
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf('.')
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase()
      } else {
        return ''
      }
    },
    onUploadError(err) {
      this.loading = false
      this.$message.error(err.message || '上传失败')
    },
    onUpload(res, file) {
      if (!res || res.code !== '0' || !res.data) return this.onUploadError(res)
      this.loading = false
      this.fakeValue = res.data
      this.$message.success('上传成功')
      this.$emit('uploaded', res.data, file)
    },
    lookFile() {
      const regImg = /\.(jpg|jpeg|png|JPG)$/
      if (regImg.test(this.fakeValue)) {
        this.viewerlList = [this.fakeValue]
        this.showViewer = true
      } else {
        downloadUrl(this.fakeValue)
      }
    }
  }
}
</script>
