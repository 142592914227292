<template>
  <el-dialog :title="(!id ? '新建' : '编辑') + title" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="1000px">
    <!-- 内容区域 -->
    <div class="dialog-wrap">
      <el-form>
        <el-form-item v-for="i in 20" :key="i" :label="'测试' + i"></el-form-item>
      </el-form>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'DialogTemplate',
  data() {
    return {
      id: null,
      show: false,
      title: '弹窗测试'
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
    },
    open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 编辑
        this.id = id
      } else {
        // 新建
      }
      this.show = true
    },
    // 提交
    submit() {
      // 结果更新
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
