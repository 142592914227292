<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView @get="getOtherAmount" ref="vTable" :labels="tableLabels" apiName="GetInvoicePageList" :searchList="searchList" @geted="getAmount">
      <!-- <el-card slot="ext-table" shadow="never" style="margin-bottom: 18px; flex-shrink: 0">
        <el-descriptions :column="4" :colon="false" size="medium" style="margin-bottom: -10px">
          <el-descriptions-item label="申请金额汇总"
            ><b v-format="'#,##0.00'"> {{ amount }}</b> 元</el-descriptions-item
          >
        </el-descriptions>
      </el-card> -->
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item" v-if="$hasAccess('007201')">
        <el-button plain type="primary" icon="el-icon-download" @click="download" :loading="btnloadDownload">导出</el-button>
      </el-form-item>
      <el-card slot="ext-table" style="margin-bottom: 18px">
        <div class="ext">
          <div v-for="(item, index) in cardItem" :key="index" class="ext-money">
            <div class="title-text">{{ item.label }}</div>
            <div>
              <span class="text" v-format="'#,##0.00'">{{ item.value }}</span>
            </div>
          </div>
        </div>
      </el-card>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="140" fixed="right">
        <template v-slot="{ row }">
          <el-button v-if="$hasAccess('000901')" type="text" @click="$refs.Detail.open(row)">查看</el-button>
          <el-button v-if="row.verifyState == '待审核'" type="text" @click="$refs.Check.open(row)">审核</el-button>
          <!-- <el-button type="text">下载明细</el-button> -->
        </template>
      </el-table-column>
    </TableView>
    <Detail ref="Detail" />
    <Check ref="Check" @submit="$refs.vTable.refreshTable()" />
  </div>
</template>

<script>
import { tableInvoice } from '@/configs/tables'
import { searchInvoice } from '@/configs/searches'
import Detail from './detail'
import Check from './check'
import { downloadExcel } from '@/utils'
import { timestampToTime } from '@/configs/formatters.js'
import { Message } from 'element-ui'

export default {
  components: { Detail, Check },
  data() {
    return {
      tableLabels: tableInvoice,
      searchList: searchInvoice,
      btnloadDownload: false,
      amount: '',
      cardItem: [
        { label: '待审核金额（元）', value: 0 },
        { label: '审核通过金额（元）', value: 0 },
        { label: '审核不通过金额（元）', value: 0 }
      ]
    }
  },
  mounted() {
    this.GetSupplierList();
  },
  methods: {
    async getOtherAmount(params) {
      const res = await this.$api.GetInvoiceAuditSummaryAmount(params)
      if (res) {
        res.list.map((item) => {
          switch (item.type) {
            case 10:
              this.cardItem[0].value = item.amount
              break
            case 20:
              this.cardItem[1].value = item.amount
              break
            case 30:
              this.cardItem[2].value = item.amount
              break
            default:
              break
          }
        })
      }
    },
    async GetSupplierList() {
      let supplierRes = await this.$api.GetSupplierListBySearch({ PageIndex: 1, PageSize: 999 })
      this.searchList[2].options = supplierRes.list.map((item) => {
        return {
          key: item.supplierId,
          label: item.supplierName
        }
      })
    },
    getAmount(res, query) {
      this.amount = res.list[0].totalInvoiceAmount
    },
    async download() {
      const params = this.$refs.vTable.getParams()
      if(params.supplierId && params.AuditState ){
        this.btnloadDownload = true
        const supplierName = this.searchList[2].options.find(item => item.key == params.supplierId).label;
        const YMDdate = timestampToTime(Date.parse(new Date())/1000, 'NewYMD');
        const res = await this.$api.ExportInvoice(params).finally(() => {
          this.btnloadDownload = false
        })
        if (res.type == 'application/json') {
        // 将blob文件流转换成json
        const reader = new FileReader()
        reader.onload = (event) => {
          const error = JSON.parse(reader.result)
          this.$message.error(error.message || '导出错误')
        }
        reader.readAsText(res)
      } else {
        downloadExcel(res, supplierName + "开票申请" + YMDdate)
      }
        
      } else if(!params.supplierId) {
        this.$message.error('请先筛选一个税源地')
      } else {
        this.$message.error('请先选择审核状态')
      }
      
  },
  }
}
</script>
<style lang="scss" scoped>
.ext {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  .title-text {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
  }
  .text {
    font-size: 26px;
    color: #333;
    font-weight: 600;
    margin-right: 10px;
  }
}
</style>
