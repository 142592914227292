import Vue from 'vue'
import VueRouter from 'vue-router'

import { currencyRoutes, asyncRoutes } from './routes'

import { hasAccess } from '@/utils'
import { getToken } from '@/utils/auth'
import { setTitle } from '@/utils/setting'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    const _main = document.getElementById('main')
    if (_main) _main.scrollTo(0, 0)
  },
  routes: [...currencyRoutes, ...asyncRoutes, { path: '*', redirect: '/error-404', hidden: true }]
})

// 登录白名单
const whiteList = ['/login', '/error-530', '/error-403', '/error-404']
// 首页path
const indexPath = '/'

// 前置操作
router.beforeEach((to, from, next) => {
  const hasToken = getToken()
  // 判定token
  if (hasToken) {
    if (to.meta && (process.env.VUE_APP_BASE_TENANT ? !to.meta.tenantShow : !hasAccess(to.meta.ruleName))) {
      // 判定权限
      next({ path: '/error-403' })
    } else if (to.path === '/login') {
      // 判定登录
      next({ path: '/' })
      // } else if (to.path === '/') {
      //   // 重定向
      //   next({ path: indexPath })
    } else {
      next()
    }
  } else {
    // 判定白名单
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      // 增加redirect
      next(`/login?redirect=${to.path}`)
    }
  }
})

// 后置操作
router.afterEach((to, from) => {
  if (to.path === '/replace') {
    router.replace({ path: from.path })
  }
  setTitle(to.meta.title, true)
})

export default router
