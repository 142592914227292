<template>
  <el-dialog :title="title" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="1000px">
    <!-- 内容区域 -->
    <div class="dialog-wrap">
      <div class="flex-home" v-if="show">
        <TableView ref="vTable" :labels="tableLabels" apiName="GetMainSupplierInfoPageList" :searchExt="{ mainId: id }" :noPage="true">
          <!-- 操作栏 -->
          <el-table-column slot="table-item" label="操作" width="80" fixed="right">
            <template v-slot="{ row, $index }">
              <el-button v-if="row.signState == 2" type="text" @click="downContract(row)">下载合同</el-button>
            </template>
          </el-table-column>
        </TableView>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { tableWorkPeopleContarct } from '@/configs/tables'
import { downloadUrl } from '@/utils'
export default {
  data() {
    return {
      tableLabels: tableWorkPeopleContarct,
      show: false,
      id: null,
      title: '合同列表'
    }
  },
  methods: {
    clear() {
      this.id = null
    },
    open(obj) {
      this.clear()
      if (obj && obj.id) {
        this.id = obj.id
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    },
    // 下载合同
    async downContract(row) {
      downloadUrl(row.contractUrl)
    }
  }
}
</script>
