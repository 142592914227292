<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetWorkChannelPageList" :searchList="searchList" @onSwitch="onSwitch">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item-left" v-if="$hasAccess('000300')">
        <el-button plain type="primary" icon="el-icon-plus" @click="$refs.Add.open()">添加</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="250" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button v-if="$hasAccess('000302')" type="text" @click="open(row)">复制链接</el-button>
          <el-button v-if="$hasAccess('000303')" type="text" @click="open(row)">下载</el-button>
        </template>
      </el-table-column>
    </TableView>
  </div>
</template>
<script>
import { tableElectricitySign } from '@/configs/tables'
import { searchElectricitySign } from '@/configs/searches'
export default {
  data() {
    return {
      tableLabels: tableElectricitySign,
      searchList: searchElectricitySign
    }
  },
  methods: {
    // 表格内的切换事件
    async sendMsg() {
      const res = await this.$api.SendSupplierAuthMsg(form)
      this.$message.success('发送成功')
    }
  }
}
</script>
