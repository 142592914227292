<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView
      ref="vTable"
      :labels="tableLabels"
      apiName="GetWorkDetailPageList"
      :searchList="searchList"
      :initSearch="initSearch"
      @geted="onTableGeted"
      :selection="true"
      @selection-change="selChange"
      :selConfig="{ selectable }"
    >
      <div style="margin-bottom: 18px" slot="ext-table">
        <el-table :data="tableData" style="width: 100%" border :cell-style="cellStyle" :header-cell-style="cellStyle">
          <el-table-column prop="" label="数据汇总">
            <template slot-scope="scope">
              {{ scope.$index == 0 ? '税后金额' : '笔数' }}
            </template>
          </el-table-column>
          <el-table-column prop="waitPay" label="待打款">
            <template slot-scope="scope">
              {{ scope.$index == 0 ? textFormat(scope.row.waitPay, '#,##0.00') : scope.row.waitPay }}
            </template>
          </el-table-column>
          <el-table-column prop="paying" label="打款中">
            <template slot-scope="scope">
              {{ scope.$index == 0 ? textFormat(scope.row.paying, '#,##0.00') : scope.row.paying }}
            </template></el-table-column
          >
          <el-table-column prop="successPay" label="打款成功">
            <template slot-scope="scope">
              {{ scope.$index == 0 ? textFormat(scope.row.successPay, '#,##0.00') : scope.row.successPay }}
            </template></el-table-column
          >
          <el-table-column prop="failPay" label="打款失败">
            <template slot-scope="scope">
              {{ scope.$index == 0 ? textFormat(scope.row.failPay, '#,##0.00') : scope.row.failPay }}
            </template></el-table-column
          >
          <el-table-column prop="rejectPay" label="拒绝打款">
            <template slot-scope="scope">
              {{ scope.$index == 0 ? textFormat(scope.row.rejectPay, '#,##0.00') : scope.row.rejectPay }}
            </template></el-table-column
          >
          <el-table-column prop="auditNotPass" label="审核不通过">
            <template slot-scope="scope">
              {{ scope.$index == 0 ? textFormat(scope.row.auditNotPass, '#,##0.00') : scope.row.auditNotPass }}
            </template></el-table-column
          >
        </el-table>
      </div>
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item-left" v-if="$hasAccess('000804') || $hasAccess('000806')">
        <span class="v-sel-info">已选择 {{ multipleSelection.length || 0 }} 项</span>
        <el-button plain type="primary" v-if="$hasAccess('000804')" @click="repay()">批量重发</el-button>
        <el-button plain type="primary" v-if="$hasAccess('000806')" @click="payfail()">批量打款失败</el-button>
      </el-form-item>
      <el-form-item slot="btnline-item" v-if="$hasAccess('000802')">
        <el-button plain type="primary" icon="el-icon-download" @click="download" :loading="btnloadDownload">导出</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="150" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button v-if="$hasAccess('000801')" type="text" @click="$refs.DialogInfo.open(row)">查看</el-button>
          <el-button v-if="$hasAccess('000803') && row.payState == 98" type="text" @click="$refs.Reissue.open(row)">补发</el-button>
          <el-button v-if="$hasAccess('000803') && row.payState == 99" type="text" @click="repay(row)">重发</el-button>
          <el-button v-if="$hasAccess('000805') && (row.payState == 98 || row.payState == 99)" type="text" @click="payfail(row)">打款失败</el-button>
        </template>
      </el-table-column>
    </TableView>
    <DialogInfo ref="DialogInfo" />
    <Reissue ref="Reissue" @submit="$refs.vTable.refreshTable()" />
  </div>
</template>

<script>
import { tableWorkPlanDetail } from '@/configs/tables'
import { searchWorkPlanDetail } from '@/configs/searches'
import { downloadExcel } from '@/utils'
import DialogInfo from './info'
import Reissue from './reissue'
export default {
  components: { DialogInfo, Reissue },
  data() {
    return {
      btnloadDownload: false,
      tableData: [],
      tableLabels: tableWorkPlanDetail,
      searchList: searchWorkPlanDetail,
      summary: {},
      initSearch: {},
      // 多选框
      multipleSelection: []
    }
  },
  created() {
    if (this.$route && this.$route.query && this.$route.query.endTime) {
      const { endTime, startTime } = this.$route.query
      this.initSearch = { createTimeEnd: endTime, createTimeBegin: startTime, AuditState: 3 }
    }
  },
  methods: {
    // 选择框事件
    selChange(val) {
      this.multipleSelection = val
    },
    selectable(row, index) {
      if (row.payState == 99) return true
      return false
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        return {
          background: '#f5f7fa',
          fontWeight: 'bold'
        }
      }
    },
    async onTableGeted(respons, params) {
      const res = await this.$api.GetDetailSumData(params)
      // this.summary = res
      const {
        waitPayAmount,
        waitPayCount,
        payingAmount,
        payingCount,
        successPayAmount,
        successPayCount,
        failPayAmount,
        failPayCount,
        rejectPayAmount,
        rejectPayCount,
        auditNotPassAmount,
        auditNotPassCount
      } = res
      this.tableData = [
        {
          waitPay: waitPayAmount,
          paying: payingAmount,
          successPay: successPayAmount,
          failPay: failPayAmount,
          rejectPay: rejectPayAmount,
          auditNotPass: auditNotPassAmount
        },
        {
          waitPay: waitPayCount,
          paying: payingCount,
          successPay: successPayCount,
          failPay: failPayCount,
          rejectPay: rejectPayCount,
          auditNotPass: auditNotPassCount
        }
      ]
    },
    async download() {
      this.btnloadDownload = true
      const params = this.$refs.vTable.getParams()
      // if (!params.createTimeBegin || !params.createTimeEnd) return this.$message.warning('请选择创建时间段')
      const res = await this.$api.ExportDetailData(params).finally(() => {
        this.btnloadDownload = false
      })
      downloadExcel(res, '结算明细')
    },
    async repay(item) {
      if (item && item.id) {
        // 单发
        await this.$confirm(`确认重发此笔订单吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        await this.$api.WorkDetailRepay({ id: item.id })
      } else {
        // 批量发
        if (!this.multipleSelection.length) return this.$message.warning('请选择需要批量处理的订单')
        const idList = this.multipleSelection.map((item) => item.id)
        await this.$confirm(`确认重发这些订单吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        await this.$api.WorkDetailRepayByBatch(idList)
      }
      this.$message.success('订单已重发')
      this.$refs.vTable.refreshTable()
    },
    async payfail(item) {
      if (item && item.id) {
        // 单打
        const { value } = await this.$prompt(`确认此笔订单打款失败吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^\S+$/,
          inputErrorMessage: '请输入打款失败原因',
          inputValue: (item.failCause || '').replace(/\s*/g,""),
          closeOnClickModal: false
        })
        await this.$api.WorkDetailPayfail({ id: item.id, failCause: value })
      } else {
        // 批量打
        if (!this.multipleSelection.length) return this.$message.warning('请选择需要批量处理的订单')
        const { value } = await this.$prompt(`确认这些订单打款失败吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^\S+$/,
          inputErrorMessage: '请输入打款失败原因'
        })
        const idList = this.multipleSelection.map((item) => ({ id: item.id, failCause: value }))
        await this.$api.WorkDetailPayfailByBatch(idList)
      }
      this.$message.success('订单已打款失败')
      this.$refs.vTable.refreshTable()
    }
  }
}
</script>
<style scoped lang="scss">
.grid-box {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  text-align: center;
  > span + span {
    border-left: 1px solid rgba(rgb(131, 128, 128), 0.4);
  }
}
</style>
