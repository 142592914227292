<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView :initSearch="{ SearchTxt: fundNo }" ref="vTable" :labels="tableLabels" apiName="GetWithdrawLogPageList" :searchList="searchList">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item" v-if="$hasAccess('001451')">
        <el-button plain type="primary" icon="el-icon-download" @click="download" :loading="btnloadDownload">导出</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="120" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button type="text" v-if="row.withdrawState == 0" @click="$refs.Check.open(row)">审核</el-button>
          <el-button v-if="$hasAccess('001450')" type="text" @click="$refs.Detail.open(row)">查看</el-button>
        </template>
      </el-table-column>
    </TableView>
    <Detail ref="Detail" />
    <Check ref="Check" @submit="$refs.vTable.refreshTable()" />
  </div>
</template>

<script>
import { tableWithDrawal } from '@/configs/tables'
import { searchWithDrawal } from '@/configs/searches'
import { downloadExcel } from '@/utils'
import Detail from './detail'
import Check from './check'
export default {
  components: { Detail, Check },
  data() {
    return {
      btnloadDownload: false,
      tableLabels: tableWithDrawal,
      searchList: searchWithDrawal
    }
  },
  computed: {
    fundNo() {
      return this.$route.query.fundNo
    }
  },
  methods: {
    async download() {
      this.btnloadDownload = true
      const params = this.$refs.vTable.getParams()
      // if (!params.WithdrawTimeBegin || !params.WithdrawTimeEnd) return this.$message.warning('请选择申请时间段')
      const res = await this.$api.ExportWithdrawData(params).finally(() => {
        this.btnloadDownload = false
      })
      downloadExcel(res, '提现记录')
    }
  }
}
</script>
