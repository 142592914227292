<template>
  <el-dialog
    title="抵扣金设置"
    :visible.sync="show"
    :append-to-body="true"
    :close-on-click-modal="false"
    custom-class="dialog-full-hasfoot"
    fullscreen
  >
    <!-- 内容区域 -->
    <div class="dialog-wrap-full" v-if="show">
      <FormView @onInputChange="onInputChange" ref="vForm" :labels="formLabels" :formConfig="formConfig">
        <template v-if="showRemark" v-slot:prompt>
          <span style="color: #ff9b5f"
            >请确认上月抵扣金是否已返还完成，如未返还完成，关闭后上月抵扣金不再做返还，已返还或者无待返还抵扣金可忽略！</span
          >
        </template>
        <!-- 阶梯服务费月结费率设置 -->
        <template v-slot:step>
          <el-col>
            <el-form-item>
              <el-button plain type="primary" icon="el-icon-plus" @click="addStep()" style="margin-bottom: 12px">添加</el-button>
              <el-table ref="stepTable" :data="stepList" border>
                <el-table-column label="阶梯">
                  <template v-slot="{ row, $index }">{{ `第${1 + $index}阶梯` }}</template>
                </el-table-column>
                <el-table-column label="金额（万元）">
                  <template v-slot="{ row, $index }">
                    <span v-if="$index >= stepList.length - 1">大于 </span>
                    <el-input-number :controls="false" :precision="0" disabled :value="issueAmountBefore($index)" />
                    <template v-if="$index < stepList.length - 1">
                      -
                      <el-input-number :controls="false" :min="issueAmountBefore($index)" :max="999999999" :precision="0" v-model="row.ladderEnd" />
                    </template>
                  </template>
                </el-table-column>
                <el-table-column label="月结服务费率">
                  <template v-slot="{ row }">
                    <el-input-number :controls="false" :min="0" :precision="2" :max="100" v-model="row.rate" />
                    %
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="120">
                  <template v-slot="{ row, $index }">
                    <el-button type="text" class="el-button-error" @click="deleteStep($index)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-col>
        </template>
      </FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs'
import { isEmpty } from '@/utils'
const formLabels = [
  {
    sessionTitle: '抵扣金生成规则',
    cmp: 'radio',
    label: '是否返还服务费抵扣金',
    prop: 'isEnableMonthly',
    options: [
      { key: false, label: '否' },
      { key: true, label: '是' }
    ],
    itemConfig: {
      rules: [{ required: true, message: '请选择是否返还服务费抵扣金', trigger: 'change' }]
    }
  },
  {
    cmp: 'slot',
    name: 'prompt'
  },
  {
    cmp: 'dtpickers',
    label: '生效时间',
    prop: ['monthlyStartTime', 'monthlyEndTime'],
    config: { type: 'monthrange', style: { width: '400px' } },
    itemConfig: {
      rules: [{ required: true, message: '请选择生效时间', trigger: 'change' }]
    },
    show: 'form.isEnableMonthly'
  },
  {
    sessionTitle: '阶梯服务费月结费率设置',
    cmp: 'slot',
    name: 'step',
    show: 'form.isEnableMonthly'
  }
]
export default {
  data() {
    return {
      id: null,
      btnloadSubmit: false,
      show: false,
      formConfig: {
        labelWidth: '180px'
      },
      formLabels,
      stepList: [],
      showRemark: false
    }
  },
  methods: {
    clear() {
      this.showRemark = false
    },
    async open(obj) {
      this.clear()
      if (obj && obj.merchantId) {
        this.id = obj.merchantId
        this.show = true
        this.formLabels.find((item) => {
          if (item.cmp == 'dtpickers') {
            item.config['picker-options'] = this.banTime()
          }
        })
        this.getDetail()
      } else {
        this.$message.error('无效的信息')
      }
    },
    // 大于当前月分的日期不可选
    banTime() {
      return {
        disabledDate(time) {
          let t = new Date()
          let pickTime = time.getFullYear().toString() + (time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1).toString()
          let currentTime = t.getFullYear().toString() + (t.getMonth() + 1 < 10 ? '0' + (t.getMonth() + 1) : t.getMonth() + 1).toString()
          return Number(pickTime) < Number(currentTime)
        }
      }
    },
    onInputChange(val, key) {
      if (key == 'isEnableMonthly') {
        this.showRemark = val ? false : true
      }
    },
    // 获取详情
    async getDetail() {
      const res = await this.$api.GetMerchantMonthly({ merchantId: this.id })
      const { isEnableMonthly, merchantId, monthlyEndTime, monthlyStartTime, monthlyRateDtos } = res
      // this.$nextTick(() => {
      if (isEnableMonthly) {
        this.$refs.vForm.fillData({ isEnableMonthly, merchantId, monthlyEndTime, monthlyStartTime })
        // 初始化stepList
        this.initStepList(monthlyRateDtos)
      } else {
        this.$refs.vForm.fillData({ isEnableMonthly, merchantId })
        // 初始化stepList
        this.initStepList([])
      }
      // })
    },
    initStepList(ori) {
      if (ori.length) {
        this.stepList = ori.map((item, index) => {
          const { ladderStart, ladderEnd, ...other } = item
          if (index == ori.length - 1) {
            return other
          }
          return { ladderEnd, ...other }
        })
      } else {
        // 空
        this.stepList = [
          { merchantId: this.id, rate: 0 },
          { merchantId: this.id, rate: 0 },
          { merchantId: this.id, rate: 0 }
        ]
      }
    },
    // 增加阶梯
    addStep() {
      if (this.stepList.length >= 6) return this.$message.warning('阶梯不能超过6个')
      // this.stepList.splice(this.stepList.length - 1, 0, {
      //   rate: 0
      // })
      this.stepList.push({ rate: 0 })
    },
    // 删除阶梯
    deleteStep(idx) {
      if (this.stepList.length <= 3) return this.$message.warning('阶梯不能少于3个')
      this.stepList.splice(idx, 1)
    },
    // 获取当前阶梯前置金额
    issueAmountBefore(idx) {
      if (idx == 0) return 0
      return this.stepList[idx - 1].ladderEnd
    },
    async submit() {
      const form = await this.$refs.vForm.getForm()
      const { isEnableMonthly, monthlyStartTime, monthlyEndTime } = form
      // 附加判断=====================================
      // 阶梯的判断
      if (isEnableMonthly) {
        if (this.stepList.length < 3) return this.$message.error(`请至少输入3项阶梯`)
        for (let idx in this.stepList) {
          const index = parseInt(idx)
          const item = this.stepList[index]
          // 判断空值
          if (!item.rate || (index < this.stepList.length - 1 && !item.ladderEnd)) {
            return this.$message.error(`请将第${index + 1}项阶梯输入完整`)
          }
          // 判断阶梯
          if (index < this.stepList.length - 1 && index != 0 && item.ladderEnd <= this.stepList[index - 1].ladderEnd) {
            return this.$message.error(`第${index + 1}项阶的金额不能小于上一项金额`)
          }
        }
      }
      // 附加判断结束，处理特殊项=====================================
      // 处理阶梯
      const monthlyRateDtos = this.stepList.map((item, index) => {
        let { ladderStart, ladderEnd, merchantId, ...other } = item
        if (index == 0) {
          ladderStart = 0
        } else {
          ladderStart = this.stepList[index - 1].ladderEnd
        }
        if (index == this.stepList.length - 1) ladderEnd = 999999999
        return { ladderStart, ladderEnd, merchantId: this.id, ...other }
      })
      // 处理特殊项结束，提交=====================================
      const params = {
        merchantId: this.id,
        isEnableMonthly
      }
      if (isEnableMonthly) {
        Object.assign(params, {
          monthlyStartTime: dayjs(monthlyStartTime).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
          monthlyEndTime: dayjs(monthlyEndTime).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
          monthlyRateDtos
        })
      }
      const res = await this.$api.SaveMerchantMonthly(params)
      this.$message.success('设置成功')
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
