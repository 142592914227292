<template>
  <el-dialog title="认证查询" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="600px">
    <!-- 内容区域 -->
    <FormView ref="vForm" :labels="formLabels" :formConfig="{ labelWidth: '150px' }" @onInputChange="onChange"></FormView>
    <template v-if="result.authStatus === 0 || result.authStatus === 1">
      <el-divider></el-divider>
      <el-descriptions border :column="1" :labelStyle="{ width: '130px', textAlign: 'right' }" style="width: 70%; margin: 0 auto">
        <template v-if="result.authStatus && info">
          <template v-if="type == 2">
            <el-descriptions-item label="状态">{{ formatStatus(companyStatus, info.status) }}</el-descriptions-item>
            <el-descriptions-item label="认证方式">{{ formatStatus(companyVerifyType, info.verifyType) }}</el-descriptions-item>
            <el-descriptions-item label="认证企业">{{ info.companyName || '无' }}</el-descriptions-item>
            <el-descriptions-item label="社会统一信用代码">{{ info.organization || '无' }}</el-descriptions-item>
            <el-descriptions-item label="管理员姓名">{{ manager.personName || '无' }}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{ manager.mobile || '无' }}</el-descriptions-item>
          </template>
          <template v-else>
            <el-descriptions-item label="状态">{{ formatStatus(personStatus, info.status) }}</el-descriptions-item>
            <el-descriptions-item v-if="info.status == 4" label="不通过原因">{{ info.auditFailReason || result.msg || '无' }}</el-descriptions-item>
          </template>
        </template>
      </el-descriptions>
    </template>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">验 证</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { regular } from '@/configs/validate'
import { formatStatus } from '@/configs/formatters'
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      loading: false,
      formLabels: [
        {
          cmp: 'select',
          label: '认证类型',
          prop: 'type',
          options: [
            { key: 1, label: '个人实名认证' },
            { key: 2, label: '企业实名认证' }
          ],
          itemConfig: {
            rules: [{ required: true, message: '请选择认证类型', trigger: 'change' }]
          },
          config: { style: { width: '100%' } }
        },
        {
          cmp: 'select',
          label: '商户选择',
          prop: 'merchantId',
          options: [],
          itemConfig: {
            rules: [{ required: true, message: '请选择商户', trigger: 'change' }]
          },
          config: {
            placeholder: '请输入商户编号/商户名称',
            filterable: true,
            style: { width: '100%' }
          },
          show: 'form.type==2'
        },
        {
          cmp: 'input',
          label: '创客姓名',
          prop: 'userName',
          itemConfig: {
            rules: [{ required: true, message: '请输入创客姓名', trigger: 'bulr' }]
          },
          show: 'form.type==1'
        },
        {
          cmp: 'input',
          label: '身份证号',
          prop: 'idCard',
          itemConfig: {
            rules: [
              { required: true, message: '请输入身份证号', trigger: 'bulr' },
              { pattern: regular.IDCard, message: regular.IDCardMsg, trigger: 'blur' }
            ]
          },
          config: { maxLength: 18 },
          show: 'form.type==1'
        }
      ],
      result: {},
      info: {},
      manager: {},
      type: 0,
      companyStatus: [
        { label: '未认证', key: 0 },
        { label: '管理员资料已提交', key: 1 },
        { label: '企业基本资料已提交', key: 2 },
        { label: '已提交待审核', key: 3 },
        { label: '审核通过', key: 4 },
        { label: '审核不通过', key: 5 },
        { label: '人工初审通过', key: 6 }
      ],
      companyVerifyType: [
        { label: '银行卡认证', key: 0 },
        { label: '纸质审核认证', key: 1 },
        { label: '法人认证', key: 2 },
        { label: '法人授权认证', key: 4 }
      ],
      personStatus: [
        { label: '未激活', key: 0 },
        { label: '未认证', key: 1 },
        { label: '审核通过', key: 2 },
        { label: '已提交待审核', key: 3 },
        { label: '审核不通过', key: 4 }
      ]
    }
  },
  methods: {
    formatStatus,
    // 数据清空
    clear() {},
    open() {
      this.show = true
      this.getMerchantOptions()
    },
    onChange() {
      this.result = {}
      this.info = {}
    },
    // 获取税源地列表
    async getMerchantOptions() {
      let v = []
      let result = await this.$api.GetMerchantInfoCodeAndName()
      if (result.list.length < 0) {
        return false
      }
      result.list.map((item) => {
        v.push({
          key: item.merchantId,
          label: `【${item.memberCode}】${item.merchantName}`
        })
      })
      this.formLabels.map((item) => {
        if (item.prop === 'merchantId') {
          item.options = v
        }
      })
    },
    // 提交
    async submit() {
      const params = await this.$refs.vForm.getForm()
      this.btnloadSubmit = true
      // if (this.loading) return false
      // this.loading = true
      this.type = params.type
      // 结果更新
      if (params.type == 1) {
        const res = await this.$api.GetFddPerson(params).finally(() => {
          this.btnloadSubmit = false
        })
        this.result = res
        this.info = res.personCertInfo && res.personCertInfo.data && res.personCertInfo.data.person
      } else {
        const res = await this.$api.GetFddCompany(params).finally(() => {
          this.btnloadSubmit = false
        })
        this.result = res
        this.info = res.companyCertInfo && res.companyCertInfo.data && res.companyCertInfo.data.company
        this.manager = res.companyCertInfo && res.companyCertInfo.data && res.companyCertInfo.data.manager
      }
      // this.loading = false
    }
  }
}
</script>
