<template>
  <el-dialog title="董监高列表" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="1000px">
    <div class="DJG-body">
      <div class="DJG-name">
        <div>
          <span class="text">商户名称: </span>
          <span>{{ merchantName }}</span>
        </div>
        <div>
          <el-button plain @click="getNewDJGList" type="primary" :loading="getDJGBtn">获取最新董监高列表</el-button>
        </div>
      </div>
      <div class="Djg-title">
        <span class="text">董监高：</span>
        <span><el-button @click="addDjg" type="primary">手动添加</el-button></span>
      </div>
      <div class="Djg-tabel">
        <el-table ref="merchantTariffingListTable" :data="djgList" border>
          <!-- <el-table-column label="公司名称">
            <template v-slot="{ row }">
              <span>{{ row.mainName }}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="姓名">
            <template slot="header"> <span style="color: red">*</span> 姓名 </template>
            <template v-if="djgList.length" v-slot="{ row }">
              <el-input :disabled="row.isEdit" v-model="row.boardName" />
            </template>
          </el-table-column>
          <el-table-column label="身份证号码">
            <template v-slot="{ row, $index }">
              <el-input @blur="testId(row.boardIdCard, $index)" v-model="row.boardIdCard" />
            </template>
          </el-table-column>
          <el-table-column label="职位">
            <template slot="header"> <span style="color: red">*</span> 职位 </template>
            <template v-slot="{ row }">
              <el-input :disabled="row.isEdit" v-model="row.boardPosition" />
              <!-- <el-select :disabled="row.isEdit" v-model="row.boardPosition">
                <el-option v-for="item in workOptions" :key="item.key" :label="item.label" :value="item.key"> </el-option>
              </el-select> -->
            </template>
          </el-table-column>
          <el-table-column label="天眼查ID">
            <template v-if="djgList.length" v-slot="{ row }">
              <el-input :disabled="true" v-model="row.hGid" />
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template v-if="djgList.length" v-slot="{ row, $index }">
              <el-button v-if="row.dataSource == 1" type="text" class="el-button-error" @click="deleteDjg(row, $index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { regular } from '@/configs/validate'
export default {
  name: 'dongJianGao',
  data() {
    return {
      btnloadSubmit: false,
      getDJGBtn: false,
      idx: null,
      show: false,
      merchantName: '',
      merchantId: '',
      mainName: '',
      workOptions: [
        { key: '执行董事', label: '执行董事' },
        { key: '监事', label: '监事' },
        { key: '经理', label: '经理' }
      ],
      djgList: []
    }
  },
  methods: {
    open(row) {
      this.djgList = []
      this.merchantName = row.merchantName
      this.merchantId = row.merchantId
      this.mainName = row.mainName
      this.getInitInfo(row)
      this.show = true
    },
    async getInitInfo(row) {
      let res = await this.$api.GetMerchantBoardList({ MerchantId: row.merchantId })
      if (res.list.length) {
        res.list.map((item) => {
          if (item.dataSource == 1) {
            item.isEdit = false
          } else {
            item.isEdit = true
          }
        })
        this.djgList = res.list
      }
    },
    testId(e) {
      // let flag = regular.IDCard.test(e)
      // if (!flag) {
      //   this.$message.error('请输入合法的身份证号码')
      // }
    },
    addDjg() {
      this.djgList.push({
        mainName: this.mainName,
        boardName: '',
        boardIdCard: '',
        boardPosition: '',
        hGid: '',
        newAdd: true,
        dataSource: 1
      })
    },
    deleteDjg(item, idx) {
      this.djgList.splice(idx, 1)
    },

    async getNewDJGList() {
      if (this.getDJGBtn) return
      this.getDJGBtn = true
      const res = await this.$api.getDongJiangaoData({ merchantId: this.merchantId })
      let data = res.list
      if (data.length <= 0) {
        this.$message.success('没有获取到数据')
        this.getDJGBtn = false
        return
      }
      data.forEach((e) => (e.isEdit = true))
      const result = this.mergeArrays(this.djgList, data)
      if (!result.updated) {
        this.getDJGBtn = false
        this.$message.success('已是最新数据，暂无更新')
      }
      this.djgList = result.merged
      this.getDJGBtn = false
    },

    temporary() {
      let dataArr = []
      if (this.djgList.length <= 0) {
        data.forEach((element) => {
          dataArr.push({
            boardName: element.boardName,
            boardIdCard: '',
            boardPosition: element.boardPosition,
            hGid: element.hGid,
            dataSource: 1
          })
        })
      } else {
        let newArr = [...this.djgList, ...data]
        let sumArr = newArr.filter((x) => {
          if (x.hGid == '' || x.hGid == null) {
            return x
          } else {
            return data.some((y) => {
              if (y.hGid === x.hGid) {
                x.boardPosition = y.boardPosition
                return y
              }
            })
          }
        })
        sumArr.forEach((item) => {
          if (item.newAdd) {
            dataArr.push(item)
          } else {
            if (!dataArr.find((e) => e.hGid == item.hGid)) dataArr.push(item)
          }
        })
      }
      this.djgList = dataArr
    },

    mergeArrays(arr1, arr2) {
      let newArr = JSON.parse(JSON.stringify(arr1))
      let updated = false // 标记 arr1 是否存在更新
      for (let i = 0; i < newArr.length; i++) {
        const obj1 = newArr[i]
        const index = arr2.findIndex((obj2) => obj2.hGid === obj1.hGid)
        if (index !== -1) {
          // 如果 arr2 中存在相同的 hGid
          const obj2 = arr2[index]
          newArr[i] = { ...obj1, ...obj2 } // 更新 arr1 中的数据
          arr2.splice(index, 1) // 从 arr2 中删除已匹配的数据
          if (obj1.boardPosition !== obj2.boardPosition) updated = true
        } else if (obj1.hGid === null || obj1.hGid === '') {
          // 如果 arr1 中的 hGid 为空，保留
          continue
        } else {
          // 如果 arr1 中的 hGid 不存在于 arr2 中，则删除 arr1 中的数据
          newArr.splice(i, 1)
          i--
        }
      }
      // 将 arr2 中剩余的数据追加到 arr1 中
      if (arr2.length > 0) {
        newArr.push(...arr2)
        updated = true
      }
      return { merged: newArr, updated }
    },

    async submit() {
      let flag = true
      let msg = '请将表单项填充完整'
      for (let [index, item] of this.djgList.entries()) {
        if (item.boardName == '' || item.boardPosition == null || item.boardPosition == '') {
          flag = false
          break
        }
        if (item.boardIdCard) {
          flag = regular.IDCard.test(item.boardIdCard)
          if (!flag) {
            msg = `第${index + 1}条身份证格式错误`
            break
          }
        }
      }
      if (!flag) {
        this.$message.error(msg)
        return false
      }
      this.btnloadSubmit = true
      const res = await this.$api.SaveMerchantBoard({ merchantId: this.merchantId, dataList: this.djgList }).finally(() => {
        this.btnloadSubmit = false
        this.show = false
      })
      this.$message.success('保存成功')
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.DJG-body {
  display: flex;
  flex-direction: column;
  .DJG-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .Djg-title {
    margin-bottom: 15px;
  }
  .Djg-tabel {
    width: 93%;
    margin-left: 54px;
    max-height: 400px;
    overflow-y: auto;
  }
  .text {
  }
}
</style>
