<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView
      ref="vTable"
      :selection="true"
      :searchExt="{ SpotState }"
      :labels="tableLabels"
      apiName="GetTaskDeliverableExtractPlanPageList"
      :searchList="searchList"
    >
      <el-form-item slot="btnline-item-left" v-if="$hasAccess('001608')">
        <el-button plain type="primary" @click="$refs.AddPlan.open()">新建计划</el-button>
      </el-form-item>
      <!-- 附加栏 -->
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="300" fixed="right">
        <template v-slot="{ row }">
          <el-button v-if="$hasAccess('001611')" type="text" @click="routePerson(row)">人员名单</el-button>
        </template>
      </el-table-column>
    </TableView>
    <AddPlan @refresh="asyncRefreshTable" ref="AddPlan" />
  </div>
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { tableNewDeliverable } from '@/configs/tables'
import { searchNewDeliverable } from '@/configs/searches'
import { downloadUrl } from '@/utils'
import AddPlan from './components/addPlan.vue'
export default {
  components: { ElImageViewer, AddPlan },
  data() {
    return {
      viewerlList: [],
      tableLabels: tableNewDeliverable,
      searchList: searchNewDeliverable,
      showReason: false,
      form: {
        desc: ''
      },
      SpotState: null,
      multipleSelection: []
    }
  },
  methods: {
    addNewPlan() {},
    // 表格延迟刷新
    asyncRefreshTable() {
      setTimeout(() => {
        this.$refs.vTable.refreshTable()
      }, 0)
    },
    routePerson(row) {
      this.$router.push(`/personList?ExtractPlanId=${row.id}`)
    }
  }
}
</script>

<style lang="scss" scoped></style>
