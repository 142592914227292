<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" seqLabel="序号" :labels="tableLabels" apiName="GetSystemBlackPageList" :searchList="searchList">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item-left">
        <el-button v-if="$hasAccess('001901')" plain type="primary" icon="el-icon-plus" @click="$refs.AddPeople.open()">添加</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="150" fixed="right">
        <template v-slot="{ row }">
          <el-button v-if="$hasAccess('001902')" type="text" @click="$refs.viewPeople.open(row)">查看</el-button>
          <el-button v-if="$hasAccess('001905')" class="deleteColor" type="text" @click="deletePeople(row)">删除</el-button>
          <el-button v-if="$hasAccess('001906') && row.isLost == 0" class="loseColor" type="text" @click="$refs.LosePeople.open(row)">失效</el-button>
        </template>
      </el-table-column>
    </TableView>
    <!-- 弹窗 -->
    <AddPeople ref="AddPeople" @submit="updateDialogTemplate"></AddPeople>
    <viewPeople ref="viewPeople" @submit="updateDialogTemplate"></viewPeople>
    <!-- 解除 -->
    <LosePeople ref="LosePeople" @submit="updateDialogTemplate"></LosePeople>
  </div>
</template>

<script>
import AddPeople from './components/addpeople'
import viewPeople from './components/viewPeople'
import LosePeople from './components/losePeople'
import { formatStatus } from '@/configs/formatters'
export default {
  components: { AddPeople, viewPeople, LosePeople },
  data() {
    return {
      tableLabels: [
        { label: '姓名', prop: 'userName' },
        { label: '证件号', prop: 'idCard' },
        { label: '手机号', prop: 'mobile' },
        { label: '所属商户', prop: 'merchantName' },
        { label: '商户编号', prop: 'memberCode' },
        { label: '拉黑原因', prop: 'blackReason' },
        { label: '备注', prop: 'remark' },
        { label: '创建时间', prop: 'createTime' },
        {
          label: '状态',
          prop: 'isLost',
          formatter: (row, column, cellValue, index) => {
            return formatStatus(
              [
                { key: 0, label: '已生效' },
                { key: 1, label: '已失效' }
              ],
              cellValue,
              '--'
            )
          }
        }
      ],
      searchList: [
        {
          cmp: 'input',
          label: '',
          prop: 'SearchTxt',
          config: {
            placeholder: '请输入姓名/身份证号码/手机号',
            style: { width: '300px' }
          }
        },
        {
          cmp: 'input',
          label: '商户',
          prop: 'MerchantSearchTxt',
          config: {
            placeholder: '请输入商户号/商户名称',
            style: { width: '300px' }
          }
        },
        {
          cmp: 'select',
          label: '类型',
          prop: 'isLost',
          options: [
            { key: '', label: '全部' },
            { key: 0, label: '已生效' },
            { key: 1, label: '已失效' }
          ]
        }
      ],
      merchantOptions: []
    }
  },
  methods: {
    updateDialogTemplate() {
      this.$refs.vTable.refreshTable()
    },
    deletePeople(e) {
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let res = await this.$api.DeleteSystemBlack({ id: e.id })
          if (res) {
            this.$message.success('删除成功')
            this.updateDialogTemplate()
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.deleteColor {
  color: #f56c6c;
}
.loseColor {
  color: #e6a23c;
}
</style>
