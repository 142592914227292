<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetVerifyPeoplePList" :searchList="searchList">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item-left" v-if="$hasAccess('008002')">
        <el-button plain type="primary" icon="el-icon-plus" @click="$refs.AddPeople.open()">新建校验批次</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="150" fixed="right">
        <template v-slot="{ row }">
          <el-button v-if="$hasAccess('008000') && row.verifyState == 0" type="text" @click="oneKeyVerifyClick(row)">一键校验</el-button>
          <el-button v-if="$hasAccess('008001') && row.verifyState == 2" class="deleteColor" type="text" @click="download(row)">导出结果</el-button>
        </template>
      </el-table-column>
    </TableView>
    <AddPeople ref="AddPeople" @submit="updateDialogTemplate"></AddPeople>
  </div>
</template>

<script>
import { formatStatus } from '@/configs/formatters'
import { downloadExcel } from '@/utils'
import AddPeople from './components/addpeople'
export default {
  components: { AddPeople },
  data() {
    return {
      tableLabels: factorVerifyTable,
      searchList: factorVerifySearch,
      merchantOptions: [],
      loadDownload: false
    }
  },

  methods: {
    updateDialogTemplate() {
      this.$refs.vTable.refreshTable()
    },
    async oneKeyVerifyClick(row) {
      let message = `<span style="color:red">抽查${row.verifyCount}人可能需要3-5分钟</span>，是否继续？完成后请手动刷新页面查询`
      this.$confirm(message, '确认信息', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await this.$api.OneKeyCheck({ planId: row.id })
          this.updateDialogTemplate()
        })
        .catch(() => {})
    },

    async download(row) {
      if (this.loadDownload) {
        this.$message.warning('正在下载，请耐心等待')
        return
      }
      this.loadDownload = true
      // const params = this.$refs.vTable.getParams()
      const res = await this.$api.ExportResult({ planId: row.id }).finally(() => {
        this.loadDownload = false
      })
      downloadExcel(res, row.verifyCode)
    }
  }
}

const factorVerifyTable = [
  { label: '校验批次号', prop: 'verifyCode' },
  { label: '商户名称', prop: 'merchantName' },
  {
    label: '校验人数',
    prop: 'verifyCount',
    formatter: (row, column, cellValue, index) => {
      return `${row.verifyCount} 人`
    }
  },
  {
    label: '通过率',
    prop: 'verifyRate',
    formatter: (row, column, cellValue, index) => {
      return `${row.verifyState == 2 ? row.verifyRate : '--'}`
    }
  },
  {
    label: '状态',
    prop: 'verifyState',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return `<span class="status-color-${row.verifyState}">${formatStatus(
        [
          { key: 0, label: '未开始' },
          { key: 1, label: '处理中' },
          { key: 2, label: '处理完成' }
        ],
        cellValue
      )}</span> `
    }
  },
  { label: '创建时间', prop: 'createTime' }
]
const factorVerifySearch = [
  {
    cmp: 'input',
    label: '',
    prop: 'VerifyCode',
    config: {
      placeholder: '校验批次号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'dtpickers',
    label: '创建时间',
    prop: ['CreateTimeBegin', 'CreateTimeEnd']
  }
]
</script>

<style lang="scss" scoped>
.status-color {
  margin-left: 12px;
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 50%;
    left: -12px;
    transform: translateY(-50%);
    border-radius: 50%;
  }
}
.status-color ::v-deep .status-color-0 {
  @extend .status-color;
  &::before {
    background: #bfbfbf;
  }
}
::v-deep .status-color-0 {
  @extend .status-color;
  &::before {
    background: #bfbfbf;
  }
}
::v-deep .status-color-1 {
  @extend .status-color;
  &::before {
    background: #0e77d1;
  }
}
::v-deep .status-color-2 {
  @extend .status-color;
  &::before {
    background: #00a854;
  }
}
</style>
