import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'normalize.css'

import ElementUI from 'element-ui'
import '@/styles/element-theme.scss'

import '@/assets/icon/iconfont.css'

import '@/styles/index.scss'

import format from 'vue-text-format'

import tableView from '@/components/tableView'
import formView from '@/components/formView'

import API from '@/api'

import { hasAccess } from '@/utils'
import { prototype } from 'clipboard'

Vue.use(ElementUI, { size: 'small' })
Vue.use(format)
Vue.use(tableView)
Vue.use(formView)

Vue.prototype.$title = '灵时云 · 管理平台'

Vue.prototype.$api = API

Vue.prototype.$hasAccess = hasAccess

Vue.prototype.$noimg = require('@/assets/noimg.png')

Vue.config.productionTip = false

// new Error
Vue.prototype.$error = (txt) => {
  return new Error(txt)
}

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
