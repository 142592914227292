<template>
  <el-dialog title="查看任务" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 450px">
      <el-descriptions
        border
        title="基本信息"
        :column="2"
        :contentStyle="{ width: '250px', textAlign: 'left' }"
        :labelStyle="{ width: '120px', textAlign: 'right' }"
      >
        <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="创建时间">{{
          detail.taskCreateTime || '无'
        }}</el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="创建人">{{
          detail.creator || '无'
        }}</el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="商户名称">{{
          detail.merchantName || '无'
        }}</el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="商户编号">{{
          detail.memberCode || '无'
        }}</el-descriptions-item>
        <el-descriptions-item :span="2" label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="任务名称">{{
          detail.taskName || '无'
        }}</el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="任务类型">{{
          detail.taskTypeText || '无'
        }}</el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="任务编号">{{
          detail.taskNumber || '无'
        }}</el-descriptions-item>

        <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="工作时间">
          <span v-if="detail.timeLimit"> {{ detail.limitTimeEnd || '无' }} - {{ detail.limitTimeStart || '无' }}</span>
          <span v-else> 不限制 </span>
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="佣金范围">
          <span v-if="detail.commissionLimit != 0">
            <span v-format="'#,##0.00'">{{ detail.taskMinAmount }}</span> - <span v-format="'#,##0.00'">{{ detail.taskMaxAmount }}</span></span
          >
          <span v-else>不限制</span>
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="需求人数">{{
          detail.countLimit ? detail.limitCount : '不限制'
        }}</el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="任务状态">{{
          detail.taskState || '无'
        }}</el-descriptions-item>
        <el-descriptions-item :span="2" label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="工作地点">{{
          detail.addressLimit ? detail.limitAddress : '不限制'
        }}</el-descriptions-item>
        <el-descriptions-item :span="2" label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="任务内容">
          {{ detail.taskDescribe || '无' }}
        </el-descriptions-item>
        <el-descriptions-item :span="2" label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="验收标准">
          {{ detail.acceptCriteria || '无' }}
        </el-descriptions-item>
        <el-descriptions-item :span="2" label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="备注">
          {{ detail.remark || '无' }}
        </el-descriptions-item>
      </el-descriptions>

      <el-descriptions
        border
        title="审核信息"
        :column="2"
        :contentStyle="{ width: '250px', textAlign: 'left' }"
        :labelStyle="{ width: '120px', textAlign: 'right' }"
        style="margin-top: 18px"
      >
        <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="审核时间"
          >{{ detail.auditTime || '无' }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="审核人"
          >{{ detail.auditer || '无' }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="审核状态">
          <span>{{ formatStatus(kvAuditState, detail.auditStatus) }}</span>
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="绑定税目"
          >{{ detail.taxName || '无' }}
        </el-descriptions-item>
        <!-- <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="任务场景"
          >{{ detail.scenesTypeLable || '无' }}
        </el-descriptions-item> -->
        <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="理由"
          >{{ detail.auditFailreason || '无' }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :contentStyle="{ width: '250px', textAlign: 'left' }"
        :labelStyle="{ width: '120px', textAlign: 'right' }"
        style="margin-top: 18px"
        border
        title="结算信息"
        :column="2"
      >
        <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="结算单数">{{
          detail.workPlanNum || '无'
        }}</el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item" content-class-name="descriptions-item-content" label="累计发放金额"
          ><span v-format="'#,##0.00'">{{ detail.totalPaymentAmount || 0 }}</span></el-descriptions-item
        >
      </el-descriptions>
    </div>
  </el-dialog>
</template>
<
<script>
import { formatStatus, kvAuditState } from '@/configs/formatters.js'
export default {
  data() {
    return {
      id: null,
      show: false,
      kvAuditState,
      detail: {
        taskCreateTime: null,
        taskNumber: null,
        taskName: null,
        taskState: null,
        taskDescribe: '',
        merchantName: null,
        memberCode: null,
        merchantName: null,
        workPlanNum: null,
        totalPaymentAmount: null,
        message: '页面加载于 ' + new Date().toLocaleString()
      }
    }
  },
  methods: {
    formatStatus,
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 查看
        this.id = obj.id
        this.show = true
        this.getDetail()
      } else {
        this.$message.error('无效的信息')
      }
    },
    async getDetail() {
      const res = await this.$api.GetTaskManageInfo({ Id: this.id })
      this.detail = res
      // if (this.detail.auditStatus == 0) {
      //   this.detail.otherState = '等待'
      //   return false
      // }
      // if (this.detail.auditStatus == 2) {
      //   this.detail.otherState = '关闭'
      //   return false
      // }
      // if (this.detail.taskState == '开启') {
      //   this.detail.otherState = '正常'
      //   return false
      // }
      // if (this.detail.taskState == '终止') {
      //   this.detail.otherState = '关闭'
      //   return false
      // }
    }
  }
}
</script>
<style lang="scss" scoped>
.descriptions-item {
  padding-top: 15px;
}
.descriptions-item-content {
  padding-top: 15px;
  text-align: left;
}
</style>
