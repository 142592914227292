<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView
      ref="vTable"
      :selection="true"
      @selection-change="selChange"
      :selConfig="{ selectable }"
      :searchExt="{ SpotState }"
      :labels="tableLabels"
      apiName="GetTaskDeliverablePageList"
      :searchList="searchList"
    >
      <el-form-item slot="btnline-item-left" v-if="$hasAccess('001608')">
        <span class="v-sel-info">已选择 {{ multipleSelection.length || 0 }} 项</span>
        <el-button plain type="primary" @click="multipleCheck">批量抽取</el-button>
      </el-form-item>
      <!-- 附加栏 -->
      <div slot="ext-search" style="margin-bottom: 18px">
        <el-radio-group v-model="SpotState" @change="asyncRefreshTable">
          <el-radio-button :label="null">全部</el-radio-button>
          <el-radio-button :label="0">未抽取</el-radio-button>
          <el-radio-button :label="1">已抽取</el-radio-button>
        </el-radio-group>
      </div>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="300" fixed="right">
        <template v-slot="{ row }">
          <el-button v-if="$hasAccess('001605')" type="text" @click="$refs.Detail.open(row)">查看</el-button>
          <el-button v-if="$hasAccess('001607')" type="text" @click="checkRow(row)">{{ row.spotState == 0 ? '抽取' : '取消抽取' }}</el-button>
          <el-button
            v-if="$hasAccess('001609') && row.uploadState == 1 && row.delAuditState != 2 && row.delAuditState != 3"
            type="text"
            @click="$refs.Audit.open(row, 'deliveravle')"
            >交付物审核</el-button
          >
          <el-button
            v-if="$hasAccess('001610') && row.apcUploadState == 1 && row.apcAuditState != 2 && row.apcAuditState != 3"
            type="text"
            @click="$refs.Audit.open(row, 'result')"
            >验收单审核</el-button
          >
        </template>
      </el-table-column>
    </TableView>
    <el-image-viewer
      v-if="showViewer"
      :zIndex="9999"
      :url-list="viewerlList"
      :on-close="
        () => {
          showViewer = false
        }
      "
    />
    <el-dialog title="退回" :visible.sync="showReason" width="30%" :before-close="handleClose">
      <div class="dialog-body">
        <span>留言：</span>
        <el-input :autosize="{ minRows: 4, maxRows: 6 }" style="width: 80%" type="textarea" v-model="form.desc"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitBack">确 定</el-button>
      </span>
    </el-dialog>
    <Detail @refresh="asyncRefreshTable" ref="Detail" />
    <Audit @refresh="asyncRefreshTable" ref="Audit" />
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { tableDeliverable } from '@/configs/tables'
import { searchDeliverable } from '@/configs/searches'
import { downloadUrl } from '@/utils'
import Detail from './detail'
import Audit from './audit'
export default {
  components: { ElImageViewer, Detail, Audit },
  data() {
    return {
      viewerlList: [],
      showViewer: false,
      tableLabels: tableDeliverable,
      searchList: searchDeliverable,
      showReason: false,
      form: {
        desc: ''
      },
      SpotState: null,
      multipleSelection: []
    }
  },
  methods: {
    // 表格延迟刷新
    asyncRefreshTable() {
      setTimeout(() => {
        this.$refs.vTable.refreshTable()
      }, 0)
    },
    async checkRow(e) {
      if (e.spotState == 0) {
        const res = await this.$api.SpotCheck({
          TaskDeliverableId: e.id
        })
        if (res) {
          this.$message.success('抽取成功')
          this.asyncRefreshTable()
        }
      } else {
        const res = await this.$api.CanelSpotCheck({
          TaskDeliverableId: e.id
        })
        if (res) {
          this.$message.success('取消抽取成功')
          this.asyncRefreshTable()
        }
      }
    },
    multipleCheck() {
      if (!this.multipleSelection.length) return this.$message.warning('请选择需要批量处理的数据')
      this.$confirm('确定抽取?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$api.BatchSpotCheck({
          TaskDeliverableIdList: this.multipleSelection.map((item) => item.id)
        })
        this.$refs.vTable.refreshTable()
        this.$message({
          type: 'success',
          message: '抽取成功'
        })
      })
    },
    selectable(row) {
      if (row.spotState != 1) return true
      return false
    },
    selChange(val) {
      this.multipleSelection = val
    },
    handleClose() {
      this.form.desc = ''
      this.showReason = false
    },
    updateDialogTemplate() {
      this.$refs.vTable.refreshTable()
    },
    previewAchUser(e) {
      let urlList = this.filterType(e.resultAcceptanceAttachments, 2)
      if (urlList.length < 1) {
        this.$message.error('没有附件')
        return false
      }
      this.viewerlList = urlList.map((item) => {
        return item.attachUrl
      })
      this.showViewer = true
    },
    downloadAchUser(e) {
      let urlList = this.filterType(e.resultAcceptanceAttachments, 2)
      if (urlList.length < 1) {
        this.$message.error('没有附件')
        return false
      }
      urlList.map((item) => {
        downloadUrl(item.attachUrl)
      })
    },
    previewAchFile(e) {
      let urlList = this.filterType(e.deliverablesAttachments, 1)
      if (urlList.length < 1) {
        this.$message.error('没有附件')
        return false
      }
      this.viewerlList = urlList.map((item) => {
        return item.attachUrl
      })
      this.showViewer = true
    },
    downloadAchFile(e) {
      let urlList = this.filterType(e.deliverablesAttachments, 1)
      if (urlList.length < 1) {
        this.$message.error('没有附件')
        return false
      }
      urlList.map((item) => {
        downloadUrl(item.attachUrl)
      })
    },
    filterType(e, type) {
      //type:1:交付物,2:创客成果验收单
      let v = e.filter((item) => {
        return item.attachType == type
      })
      return v
    },
    async submitBack() {
      //type:1:交付物,2:创客成果验收单
      if (this.form.desc == '') {
        this.$message.error('请输入退回原因')
        return false
      }
      let enclosureId = []
      if (this.form.type == 1) {
        enclosureId = this.form.content.deliverablesAttachments.map((item) => {
          return item.id
        })
      } else {
        enclosureId = this.form.content.resultAcceptanceAttachments.map((item) => {
          return item.id
        })
      }
      enclosureId = enclosureId.toString()
      const res = await this.$api.TaskDeliverableBack({
        taskDeliverableId: this.form.content.id,
        enclosureId: enclosureId,
        attachType: this.form.type,
        remark: this.form.desc
      })
      if (res) {
        this.$message.success('退回成功')
        this.showReason = false
        this.updateDialogTemplate()
      }
    },
    backFile(e, type) {
      this.form = {
        content: e,
        type: type,
        desc: ''
      }
      this.showReason = true
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-home {
  .dialog-body {
    display: flex;
    align-items: center;
  }
  .deleteColor {
    color: #f56c6c;
  }
}
</style>
