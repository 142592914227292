var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":(!_vm.id ? '添加' : '编辑') + _vm.title,"visible":_vm.show,"append-to-body":true,"close-on-click-modal":false,"top":"8vh","width":"1200px"},on:{"update:visible":function($event){_vm.show=$event}}},[(_vm.show)?_c('div',{staticClass:"dialog-wrap",staticStyle:{"height":"600px"}},[_c('FormView',{ref:"vForm",attrs:{"labels":_vm.fromLabels,"formConfig":{ labelWidth: '200px' }},on:{"onInputChange":_vm.onInputChange},scopedSlots:_vm._u([{key:"openInfo",fn:function(ref){
var form = ref.form;
var idx = ref.idx;
return [_c('el-col',[_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end","margin-right":"100px","margin-bottom":"18px"}},[_c('el-button',{on:{"click":_vm.addAccount}},[_vm._v("添加")])],1),_c('div',{staticStyle:{"margin-left":"200px","margin-right":"100px"}},[_c('el-table',{attrs:{"data":form.supplierAccounts,"cell-style":_vm.tdStyle}},[_c('el-table-column',{attrs:{"label":"开户行","prop":"accountBank","width":"210px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-form-item',{attrs:{"label-width":"0","prop":'supplierAccounts.' + $index + '.accountBank',"rules":[
                      {
                        required: true,
                        message: '请填写开户行',
                        trigger: 'blur'
                      }
                    ]}},[_c('el-input',{attrs:{"placeholder":"请填写开户行"},model:{value:(row.accountBank),callback:function ($$v) {_vm.$set(row, "accountBank", $$v)},expression:"row.accountBank"}})],1)]}}],null,true)}),_c('el-table-column',{attrs:{"label":"开户名称","prop":"accountName","width":"210px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
                    var $index = ref.$index;
return [_c('el-form-item',{attrs:{"label-width":"0","prop":'supplierAccounts.' + $index + '.accountName',"rules":[
                      {
                        required: true,
                        message: '请填写开户名称',
                        trigger: 'blur'
                      }
                    ]}},[_c('el-input',{attrs:{"placeholder":"请填写开户名称"},model:{value:(row.accountName),callback:function ($$v) {_vm.$set(row, "accountName", $$v)},expression:"row.accountName"}})],1)]}}],null,true)}),_c('el-table-column',{attrs:{"label":"银行配置","prop":"accountConfig","width":"340px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
                    var $index = ref.$index;
return [_c('el-form-item',{attrs:{"label-width":"0","prop":'supplierAccounts.' + $index + '.accountConfig',"rules":[
                      {
                        required: true,
                        message: '请填写银行配置',
                        trigger: 'blur'
                      }
                    ]}},[_c('el-input',{attrs:{"placeholder":"请填写银行配置"},model:{value:(row.accountConfig),callback:function ($$v) {_vm.$set(row, "accountConfig", $$v)},expression:"row.accountConfig"}})],1)]}}],null,true)}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(!scope.row.id)?_c('el-button',{staticStyle:{"color":"red"},attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteItem(scope.$index)}}},[_vm._v("删除")]):_vm._e()]}}],null,true)})],1)],1)])]}},{key:"taxTable",fn:function(ref){
                    var form = ref.form;
                    var idx = ref.idx;
return [_c('el-col',[_c('el-form-item',{attrs:{"label":"可开票税目","required":""}},[_c('el-popover',{attrs:{"placement":"bottom-start","width":"600","trigger":"manual"},model:{value:(_vm.showTaxPopover),callback:function ($$v) {_vm.showTaxPopover=$$v},expression:"showTaxPopover"}},[_c('div',{attrs:{"slot":"reference"},slot:"reference"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.openTaxPopover}},[_vm._v("添加")]),(_vm.id)?_c('el-button',{staticStyle:{"float":"right","margin-right":"100px"},attrs:{"type":"primary","icon":"el-icon-download","loading":_vm.btnloadDownload},on:{"click":_vm.exportTax}},[_vm._v("导出")]):_vm._e()],1),_c('div',{staticClass:"tax-pop",staticStyle:{"max-height":"500px","overflow":"auto"}},[_c('div',{staticClass:"text"},[_vm._v("税目列表")]),_c('el-tree',{ref:"taxAllTree",attrs:{"data":_vm.taxItemsLevelList,"show-checkbox":"","default-expand-all":"","node-key":"value","default-checked-keys":_vm.checkTaxItemsId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(data.title || ''))]),_vm._v("  "),_c('span',{staticClass:"label-num"},[_vm._v(_vm._s(data.taxCode || ''))])])}}],null,true)})],1),_c('div',{staticStyle:{"text-align":"right","margin":"0"}},[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){_vm.showTaxPopover = false}}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.addTax}},[_vm._v("确定")])],1)]),_c('div',{staticStyle:{"margin-top":"18px","margin-right":"100px"}},[_c('el-table',{attrs:{"data":_vm.taxTable}},[_c('el-table-column',{attrs:{"label":"税目"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
                    var $index = ref.$index;
return [_vm._v(" "+_vm._s(row.baseTaxItemsName + ' * ' + row.taxItemsName)+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"税目状态","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
                    var $index = ref.$index;
return [_vm._v(" "+_vm._s(row.isEnable ? '已停用' : '生效')+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"调整时间","width":"160px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
                    var $index = ref.$index;
return [_vm._v(" "+_vm._s(row.updateTime || row.createTime || '')+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"操作","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
                    var $index = ref.$index;
return [(row.id)?[(row.isEnable)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.onTax(row)}}},[_vm._v("启用")]):_c('el-button',{staticStyle:{"color":"red"},attrs:{"type":"text"},on:{"click":function($event){return _vm.offTax(row)}}},[_vm._v("停用")])]:_vm._e()]}}],null,true)})],1)],1)],1)],1)]}}],null,false,2049984891)})],1):_vm._e(),_c('span',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.show = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.btnloadSubmit},on:{"click":_vm.submit}},[_vm._v("确 定")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }