<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetChannelInfoPageList" :searchList="searchList">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item">
        <el-button plain type="primary" icon="el-icon-plus" @click="$refs.Edit.open()">添加</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="120" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button type="text" @click="$refs.Edit.open(row)">编辑</el-button>
        </template>
      </el-table-column>
    </TableView>
    <Edit ref="Edit" @submit="$refs.vTable.refreshTable()" />
  </div>
</template>

<script>
import { formatStatus, kcChannelType, kvChannelState } from '@/configs/formatters'
import Edit from './edit.vue'
const tableLabels = [
  { label: '渠道编号', prop: 'channelCode' },
  { label: '渠道名称', prop: 'channelName' },
  {
    label: '渠道类型',
    prop: 'channelType',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kcChannelType, cellValue)
    }
  },
  { label: '联系方式', prop: 'channelPhone' },
  { label: '所属商务', prop: 'channelEmployee' },
  {
    label: '状态',
    prop: 'channelState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvChannelState, cellValue)
    }
  },
  { label: '添加时间', prop: 'createTime' }
]
const searchList = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入渠道名称搜索',
      style: { width: '250px' }
    }
  }
]
export default {
  components: { Edit },
  data() {
    return {
      tableLabels,
      searchList
    }
  }
}
</script>
