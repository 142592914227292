<template>
  <div>
    <el-dialog :title="title" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
      <div class="dialog-wrap" v-if="show">
        <el-descriptions title="信息" border :column="2" :colon="false">
          <el-descriptions-item label="模板编号">{{ detail.templateCode }}</el-descriptions-item>
          <el-descriptions-item label="模板名称">{{ detail.templateName }}</el-descriptions-item>
          <el-descriptions-item label="模板类型">{{ detail.templateType | formatters }}</el-descriptions-item>
          <template v-if="detail.templateSignType == 20 || detail.templateSignType == 30">
            <el-descriptions-item></el-descriptions-item>
            <el-descriptions-item label="模板字段" :span="2">
              <el-descriptions border :column="2" :colon="false">
                <template v-for="item in detail.templateList">
                  <el-descriptions-item label="字段编号" :key="item.fieldCode">{{ item.fieldCode }}</el-descriptions-item>
                  <el-descriptions-item label="字段名称" :key="item.fieldCode">{{ item.fieldName }}</el-descriptions-item>
                </template>
              </el-descriptions>
            </el-descriptions-item>
            <el-descriptions-item label="盖章关键字" :span="2" v-if="detail.templateSignType == 20">
              <el-descriptions border :column="2" :colon="false">
                <template v-for="item in detail.keyWordList">
                  <el-descriptions-item label="关键字" :key="item.keyword">{{ item.keyword }}</el-descriptions-item>
                  <el-descriptions-item label="签约类型" :key="item.keyword">
                    {{ formatStatus(kvSignContractType, item.signatoryType) }}
                  </el-descriptions-item>
                </template>
              </el-descriptions>
            </el-descriptions-item>
          </template>
          <el-descriptions-item v-if="detail.templateSignType != 20" label="模板文件">
            <ImageViewer v-if="detail.templatePath" :path="detail.templatePath" />
            <span v-else>无</span>
          </el-descriptions-item>
          <el-descriptions-item label="备注">{{ detail.remark }}</el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { kvTemplateType, formatStatus, kvSignContractType } from '@/configs/formatters.js'
import ImageViewer from '@/components/tableView/imageViewer'
export default {
  components: { ImageViewer },
  data() {
    return {
      title: '查看',
      show: false,
      detail: [],
      kvSignContractType
    }
  },
  filters: {
    formatters(val) {
      const labels = kvTemplateType.find((item) => item.key == val)
      return labels && labels.label
    }
  },
  methods: {
    formatStatus,
    async open(row) {
      if (row.templateSignType == 20 || row.templateSignType == 30) {
        let res = await this.$api.GetConteactTemplateList({ templateId: row && row.id })
        let keyRes = await this.$api.GetEditByContractTemplateKeywordList({ templateId: row && row.id })
        this.detail = Object.assign({}, { keyWordList: keyRes.list, templateList: res.list }, row)
      } else {
        this.detail = Object.assign({}, row)
      }
      this.show = true
    }
  }
}
</script>
