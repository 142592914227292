<template>
  <el-dialog top="9vh" title="审核抵扣金赠送订单" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="700px">
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 300px">
      <FormView class="discount" ref="vForm" :labels="fromLabels"></FormView>
    </div>
    <span slot="footer">
      <el-button @click="limtButton(false)">驳回</el-button>
      <el-button type="primary" @click="limtButton(true)" :loading="btnloadSubmit">审核通过</el-button>
      <div class="text">审核通过后系统将自动发放抵扣金</div>
    </span>
  </el-dialog>
</template>

<script>
import { timestampToTime } from '@/configs/formatters'
import { debounce } from '@/utils'
import { formAudit } from '@/configs/forms'
export default {
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      detail: {},
      fromLabels: formAudit
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    async open(obj) {
      console.log(obj)
      this.clear()
      this.id = obj.id
      let taskRes = await this.$api.GetMerchantInfoCodeAndName()
      this.merchantOptions = taskRes.list.map((item) => {
        return {
          key: item.merchantId,
          label: `(${item.memberCode}) ${item.merchantName}`
        }
      })
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData({
          type: '赠送',
          merchantName: obj.merchantName,
          applyer: obj.applyer,
          diffServicefee: obj.diffServicefee,
          auditRemark: ''
        })
      })
    },
    // 提交
    limtButton: debounce(function (val) {
      this.submit(val)
    }, 500),
    async submit(auditPass) {
      // 结果更新
      const result = await this.$refs.vForm.validate()
      // 结果更新
      if (result) {
        this.form = await this.$refs.vForm.getForm()
        this.btnloadSubmit = true
        const res = await this.$api.auditDeduction(Object.assign({}, this.form, { auditPass, deductionId: this.id })).finally(() => {
          this.btnloadSubmit = false
        })
        if (res) {
          this.$message.success('已审核')
          // 编辑
          this.$emit('submit')
          this.show = false
        }
      }
    },
    vilMinAmount(e) {
      if (e) {
        this.detail.taskMinAmount = Number(e).toFixed(2)
      }
    },
    vilMaxAmount(e) {
      if (e) {
        this.detail.taskMaxAmount = Number(e).toFixed(2)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.otherItem {
  margin-bottom: 0;
}
.discount {
  ::v-deep .el-input__inner {
    text-align: left;
  }
}
.text {
  margin-top: 10px;
  font-size: 13px;
}
</style>
