<template>
  <div class="flex-home">
    <TableView :labels="labels" apiName="GetWorkPeopleVerifyPageList" seqLabel="序号" :searchList="searchList">
      <el-table-column slot="table-item" label="操作" width="100px">
        <template v-slot="{ row }">
          <el-button v-if="$hasAccess('000501')" type="text" style="color: #6493fb" @click="$refs.Detail.open(row)">更新</el-button>
        </template>
      </el-table-column>
    </TableView>
    <Detail ref="Detail"></Detail>
  </div>
</template>

<script>
import { searchPersonnelInfo } from '@/configs/searches.js'
import { tablePersonnel } from '@/configs/tables.js'
import Detail from './detail.vue'
import WpView from '@/components/wpView'
export default {
  components: { Detail, WpView },
  data() {
    return {
      labels: tablePersonnel,
      searchList: searchPersonnelInfo
    }
  }
}
</script>
