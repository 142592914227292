<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" :searchList="searchList" apiName="GetSystemMessagePageList" @onSwitch="onSwitch">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item">
        <el-button v-if="$hasAccess('005701')" plain type="primary" icon="el-icon-plus" @click="$refs.Edit.open()">新增</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="180" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button type="text" v-if="$hasAccess('005702') && !row.issueState" @click="$refs.Edit.open(row)">修改</el-button>
          <el-button v-if="$hasAccess('005700')" type="text" @click="$refs.Info.open(row)">详情</el-button>
          <el-button v-if="$hasAccess('005703')" type="text" @click="delOne(row)">删除</el-button>
        </template>
      </el-table-column>
    </TableView>
    <!-- 弹窗 -->
    <Edit ref="Edit" @submit="$refs.vTable.refreshTable()"></Edit>
    <Info ref="Info" @submit="$refs.vTable.refreshTable()"></Info>
  </div>
</template>

<script>
import { tableNotice } from '@/configs/tables'
import { searchNotice } from '@/configs/searches'
import Edit from './edit'
import Info from './info'
export default {
  components: { Edit, Info },
  data() {
    return {
      tableLabels: tableNotice,
      searchList: searchNotice
    }
  },
  methods: {
    // 切换开屏状态
    async onSwitch(val, key, row, set) {
      if (key == 'isShow') {
        if (!row.issueState) return this.$message.error('请在发布后调整开屏状态')
        if (!this.$hasAccess('')) return this.$message.error('您暂无权限调整开屏状态')
        // 禁用启用
        const params = { id: row.id, isShow: val }
        const res = await this.$api.UpdateMessageIsShow(params)
        set()
      }
    },
    // 删除
    async delOne(item) {
      await this.$confirm('确认删除此条公告吗?', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      })
      const res = await this.$api.RemoveMessage({ id: item.id })
      if (res) {
        this.$message.success('删除成功')
        this.$refs.vTable.refreshTable()
      }
    }
  }
}
</script>
