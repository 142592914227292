<template>
  <div>
    <el-dialog title="上传合同" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false">
      <div>
        <FormView v-if="show" :labels="labels" :slotIdxList="[0]" ref="FormView"> </FormView>
      </div>
      <div slot="footer">
        <el-button @click="show = false">取消</el-button>
        <el-button type="primary" @click="submit" :loading="btnloadSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { uploadContractForm } from '@/configs/forms'
export default {
  data() {
    return {
      btnloadSubmit: false,
      labels: uploadContractForm,
      show: false
    }
  },
  methods: {
    clear() {
      this.$nextTick(() => {
        this.$refs.FormView.fillData({})
        this.$refs.FormView.clearValidate()
      })
    },
    open(obj) {
      this.show = true
      this.clear()
      this.$nextTick(() => {
        this.$refs.FormView.fillData(Object.assign({}, obj))
      })
    },
    // 提交
    async submit() {
      const result = await this.$refs.FormView.validate()
      if (result) {
        this.btnloadSubmit = true
        const data = await this.$api.UploadContract(result).finally(() => {
          this.btnloadSubmit = false
        })
        if (data) {
          this.$message.success('合同上传成功')
          this.$emit('uploaded')
          this.show = false
        }
      }
    }
  }
}
</script>
