var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.show,"append-to-body":true,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.show=$event}}},[_c('div',{staticClass:"dialog-wrap"},[(_vm.show)?_c('FormView',{ref:"form",attrs:{"labels":_vm.labels,"slotIdxList":[4, 5],"formConfig":{ labelWidth: '140px' }},on:{"onInputChange":_vm.onInputChange},scopedSlots:_vm._u([{key:"form-items",fn:function(ref){
var idx = ref.idx;
var form = ref.form;
return [(idx == 4 )?_c('el-col',[(form.templateType != 3 && form.templateSignType != 30)?_c('div',{staticStyle:{"margin-left":"140px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.addTemplate}},[_vm._v("添加")])],1):_vm._e(),(form.templateType == 1 && form.templateSignType == 20)?_c('el-form-item',{attrs:{"label":"模板字段"}},[_c('el-table',{attrs:{"data":form.fieldDtos,"cell-style":_vm.tdStyle}},[_c('el-table-column',{attrs:{"label":"字段编号","prop":"templateCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-form-item',{attrs:{"prop":'fieldDtos.' + $index + '.templateCode',"rules":[{ required: true, message: '请填写模板字段编号', trigger: 'blur' }]}},[_c('el-input',{attrs:{"spellcheck":"false"},model:{value:(row.templateCode),callback:function ($$v) {_vm.$set(row, "templateCode", $$v)},expression:"row.templateCode"}})],1)]}}],null,true)}),_c('el-table-column',{attrs:{"label":"字段名称","prop":"templateName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-form-item',{attrs:{"prop":'fieldDtos.' + $index + '.templateName',"rules":[{ required: true, message: '请填写模板字段名称', trigger: 'blur' }]}},[_c('el-input',{attrs:{"spellcheck":"false"},model:{value:(row.templateName),callback:function ($$v) {_vm.$set(row, "templateName", $$v)},expression:"row.templateName"}})],1)]}}],null,true)}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-button',{staticStyle:{"color":"red"},attrs:{"type":"text"},on:{"click":function($event){return _vm.removeItem(row, $index)}}},[_vm._v("删除")])]}}],null,true)})],1)],1):_vm._e(),(form.templateType == 3 )?_c('el-form-item',{attrs:{"label":"模板字段"}},[_c('el-table',{attrs:{"data":form.templateFields,"cell-style":_vm.tdStyle}},[_c('el-table-column',{attrs:{"label":"字段编号","prop":"templateCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-form-item',{attrs:{"prop":'templateFields.' + $index + '.templateCode',"rules":[{ required: true, message: '请填写模板字段编号', trigger: 'blur' }]}},[_c('el-input',{attrs:{"spellcheck":"false","disabled":true},model:{value:(row.templateCode),callback:function ($$v) {_vm.$set(row, "templateCode", $$v)},expression:"row.templateCode"}})],1)]}}],null,true)}),_c('el-table-column',{attrs:{"label":"字段名称","prop":"templateName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-form-item',{attrs:{"prop":'templateFields.' + $index + '.templateName',"rules":[{ required: true, message: '请填写模板字段名称', trigger: 'blur' }]}},[_c('el-input',{attrs:{"spellcheck":"false","disabled":true},model:{value:(row.templateName),callback:function ($$v) {_vm.$set(row, "templateName", $$v)},expression:"row.templateName"}})],1)]}}],null,true)}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(( _vm.$refs.form.getFormItem('templateSignType') == 20 && row.orderNo!=2) || ( _vm.$refs.form.getFormItem('templateSignType') == 30 && row.orderNo!=0 && row.orderNo!=2))?_c('el-button',{staticStyle:{"color":"red"},attrs:{"type":"text"},on:{"click":function($event){return _vm.newRemoveItem(row, $index, form)}}},[_vm._v("删除")]):_vm._e()]}}],null,true)})],1)],1):_vm._e()],1):_vm._e(),(idx == 5 && form.templateSignType == 20)?_c('el-col',[_c('div',{staticStyle:{"margin-left":"140px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.addKeyword}},[_vm._v("添加")])],1),_c('el-form-item',{attrs:{"label":"盖章关键字","rules":[{ required: true }]}},[_c('el-table',{attrs:{"data":form.keywordDtos,"cell-style":_vm.tdStyle}},[_c('el-table-column',{attrs:{"label":"关键字","prop":"keyword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-form-item',{attrs:{"prop":'keywordDtos.' + $index + '.keyword',"rules":[{ required: true, message: '请填写关键字', trigger: 'blur' }]}},[_c('el-input',{model:{value:(row.keyword),callback:function ($$v) {_vm.$set(row, "keyword", $$v)},expression:"row.keyword"}})],1)]}}],null,true)}),_c('el-table-column',{attrs:{"label":"签约方类型","prop":"signatoryType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-form-item',{attrs:{"prop":'keywordDtos.' + $index + '.signatoryType',"rules":[{ required: true, message: '请选择签约方类型', trigger: 'blur' }]}},[_c('el-select',{attrs:{"placeholder":"请选择签约方类型"},on:{"change":_vm.changeSignType},model:{value:(row.signatoryType),callback:function ($$v) {_vm.$set(row, "signatoryType", $$v)},expression:"row.signatoryType"}},_vm._l((_vm.stampOptions),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.label,"value":item.value}})}),1)],1)]}}],null,true)}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-button',{staticStyle:{"color":"red"},attrs:{"type":"text"},on:{"click":function($event){return _vm.removeKeyword(row, $index)}}},[_vm._v("删除")])]}}],null,true)})],1)],1)],1):_vm._e()]}}],null,false,335007609)}):_vm._e()],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.show = false}}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.btnloadSubmit},on:{"click":_vm.submit}},[_vm._v("确定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }