<template>
  <el-dialog title="明细" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="1000px">
    <div class="dialog-wrap" style="height: 600px" v-if="show">
      <div class="flex-home">
        <TableView ref="vTable" :labels="tableLabels" apiName="GetBillByInvoiceDetailReceiptPageList" :searchExt="searchExt">
          <!-- 操作栏 -->
          <el-table-column slot="table-item" label="发票图片" width="180" fixed="right">
            <template v-slot="{ row }">
              <ImageViewer :path="row.receiptFilePath" />
            </template>
          </el-table-column>
        </TableView>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { tableBillReceipt } from '@/configs/tables'
import ImageViewer from '@/components/tableView/imageViewer'
export default {
  components: { ImageViewer },
  data() {
    return {
      show: false,
      tableLabels: tableBillReceipt,
      searchExt: {}
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.searchExt = {}
    },
    open(obj) {
      this.clear()
      this.searchExt = obj
      this.show = true
    }
  }
}
</script>
