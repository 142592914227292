<template>
    <el-dialog title="差额提醒" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
        <!-- 内容区域 -->
        <el-button type="primary" @click="add">添 加</el-button>
        <el-table ref="listTable" :data="listData">
            <el-table-column label="#" type="index" width="65"></el-table-column>
            <el-table-column label="税源地">
                <template v-slot="{ row, $index }">
                    <el-select v-model="row.supplierId" filterable style="width: 100%"
                        @change="onSupplierChange($event, row, $index)">
                        <el-option style="width: 300px" v-for="item in supplierOptions" :key="item.supplierId"
                            :label="item.supplierName + '【' + item.supplierCode + '】'" :value="item.supplierId">
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="累计差额（转入金额-支付宝订单金额）">
                <template v-slot="{ row, $index }">
                    <el-input-number :controls="false" :min="-9999999.99" :precision="2" :max="99999999.99"
                        v-model="row.warnLimit" />
                </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
                <template v-slot="{ row, $index }">
                    <el-button type="text" class="el-button-error" @click="deleteOne(row, $index)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <span slot="footer">
            <el-button @click="show = false">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            listData: [{}],
            show: false,
            supplierOptions: []
        }
    },
    mounted() {
        this.getSupplierList()
    },
    methods: {
        // 数据清空
        clear() {
            this.listData = [{}]
        },
        open() {
            this.clear()
            this.show = true
            this.getDetail()
        },
        async getDetail() {
            const res = await this.$api.GetMergeAlipayDiffWarn()
            this.listData = res.list
            if (!this.listData.length) this.add()
        },
        add() {
            this.listData.push({})
        },
        deleteOne(item, idx) {
            this.listData.splice(idx, 1)
        },
        // 获取税源地列表
        async getSupplierList(SupplierName) {
            const res = await this.$api.GetSupplierListBySearch({ SupplierName })
            // 处理数据结构并赋值
            this.supplierOptions = res.list || []
        },
        onSupplierChange(val, row, idx) {
            // supplier查重
            const re = this.listData.find((item, index) => {
                return idx != index && item.supplierId == val
            })
            if (re) {
                this.$message.error('不能选择重复的税源地')
                row.supplierId = null
            }
            // 录入supplier信息
            const one = this.supplierOptions.find(item => {
                return item.supplierId == val
            })
            if (one) {
                row.supplierCode = one.supplierCode
                row.supplierName = one.supplierName
            } else {
                this.$message.error('无效的税源地')
                row.supplierId = null
            }
        },
        async submit() {
            for (let i = 0; i < this.listData.length; i++) {
                const one = this.listData[i]
                if (!one.supplierId || !one.warnLimit) return this.$message.error(`请补齐第${i + 1}条数据`)
                one.orderNo = i + 1
            }
            const res = await this.$api.SaveMergeAlipayDiffWarn({ dataList: this.listData })
            this.$message.success('设置成功')
            this.$emit('submit')
            this.show = false
        }
    }
}
</script>
