<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView
      ref="TableView"
      :labels="labels"
      apiName="GetTaxInfoReceipt"
      :searchList="searchList"
    >
      <el-table-column width="220" slot="table-item" label="操作">
        <template v-slot="{ row }">
          <el-button
            v-if="$hasAccess('001201')"
            type="text"
            @click="$refs.Import.open(row, 1)"
            >查看</el-button
          >
          <el-button
            v-if="$hasAccess('001201')"
            type="text"
            @click="$refs.Import.open(row, 0)"
            >上传完税凭证</el-button
          >
          <el-button
            v-if="$hasAccess('001203')"
            type="text"
            @click="download(row)"
            >下载完税清单</el-button
          >
        </template>
      </el-table-column>
    </TableView>
    <Import ref="Import" @updateList="$refs.TableView.refreshTable()"></Import>
  </div>
</template>

<script>
import Import from "./import.vue";
import { searchcredentials } from "@/configs/searches.js";
import { tablecredentials } from "@/configs/tables.js";
import { downloadExcel } from "@/utils";
export default {
  components: { Import },
  data() {
    return {
      searchList: searchcredentials,
      labels: tablecredentials,
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    async download({ taxVoucherNo }) {
      const res = await this.$api.ExportTaxDetailData({ taxVoucherNo });
      downloadExcel(res, "完税清单");
    },
  },
};
</script>
<style scoped>
/* @import url(); 引入css类 */
</style>
