<template>
  <div class="flex-home">
    <div class="pageTitle">{{ '创客列表' || $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetWorkPeopleQueries" :searchList="searchList">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item" >
        <el-button plain type="primary" icon="el-icon-download" @click="download" :loading="btnloadDownload">导出</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="120" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button v-if="$hasAccess('000400')" type="text" @click="$refs.DialogInfo.open(row)">查看</el-button>
          <!-- <el-button type="text" @click="$refs.Contract.open(row)">合同列表</el-button> -->
        </template>
      </el-table-column>
    </TableView>
    <DialogInfo ref="DialogInfo" />
    <Contract ref="Contract" />
  </div>
</template>

<script>
import { tableWorkPeople } from '@/configs/tables'
import { searchWorkPeople } from '@/configs/searches'
import DialogInfo from './info'
import Contract from './contract'
import { downloadExcel } from '@/utils'

export default {
  components: { DialogInfo, Contract },
  data() {
    return {
      tableLabels: tableWorkPeople,
      searchList: searchWorkPeople,
      btnloadDownload: false,
    }
  },
  mounted() {
    this.GetSupplierList();
  },
  methods: {
    async download() {
      this.btnloadDownload = true
      const params = this.$refs.vTable.getParams()
      if(params.supplierId) {
        const res = await this.$api.ExportWorkPeopleList(params).finally(() => {
          this.btnloadDownload = false
        })
        downloadExcel(res, '创客信息')
      } else {
        this.btnloadDownload = false
        this.$message.error('请选择税源地后再导出');
      }
    },
    async GetSupplierList() {
      let supplierRes = await this.$api.GetSupplierListBySearch({ PageIndex: 1, PageSize: 999 })
      this.searchList[2].options = supplierRes.list.map((item) => {
        return {
          key: item.supplierId,
          label: item.supplierName
        }
      })
    }
  },
  
}
</script>
