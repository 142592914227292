<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView
      ref="vTable"
      :labels="tableLabels"
      apiName="GetIndividualMerchantInfoPageList"
      :searchList="searchList"
      @onSwitch="onSwitch"
      @refreshed="refreshed"
    >
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item-left" v-if="$hasAccess('007300')">
        <el-button plain type="primary" icon="el-icon-plus" @click="$refs.Edit.open(2)">添加</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="180" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button v-if="$hasAccess('007301')" type="text" @click="$refs.Info.open(row)">查看</el-button>
          <el-button v-if="$hasAccess('007308') && row.signType == 0" type="text" @click="$refs.parkSetup.open(row)">设置税源地</el-button>
          <el-button v-if="$hasAccess('007302') && row.signType != 0" type="text" @click="$refs.DialogOpen.open(row, 2)">通道管理</el-button>
          <el-button v-if="$hasAccess('007303') && row.signType != 0" type="text" @click="$refs.DialogMerchantConfigure.open(row)"
            >配置管理</el-button
          >
          <el-button v-if="$hasAccess('007304') && row.signType != 0" type="text" @click="$refs.DialogSign.open(row)">签约管理</el-button>
          <!-- <el-button v-if="$hasAccess('000105') && row.signType != 0" type="text">切换园区</el-button> -->
          <el-dropdown style="margin-left: 10px">
            <span class="el-dropdown-link"> 更多<i class="el-icon-arrow-down el-icon--right"></i> </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="$hasAccess('007309')" @click.native="$refs.DialogApi.open(row)">对接参数</el-dropdown-item>
              <el-dropdown-item v-if="$hasAccess('000112')" @click.native="$refs.Deduction.open(row)">设置抵扣金</el-dropdown-item>
              <el-dropdown-item v-if="$hasAccess('007305') && row.signType != 0" @click.native="resetPassword(row)">重置密码</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </TableView>
    <!-- 弹窗 -->
    <Edit ref="Edit" @submit="updateDialogTemplate"></Edit>
    <Info ref="Info"></Info>
    <DialogMerchantConfigure ref="DialogMerchantConfigure" @submit="$refs.vTable.refreshTable()"></DialogMerchantConfigure>
    <DialogSign ref="DialogSign"></DialogSign>
    <DialogOpen ref="DialogOpen"></DialogOpen>
    <parkSetup @submit="updateDialogTemplate" ref="parkSetup"></parkSetup>
    <Deduction @submit="$refs.vTable.refreshTable()" ref="Deduction"></Deduction>
    <DialogApi ref="DialogApi"></DialogApi>
  </div>
</template>

<script>
import { tableMerchant } from '@/configs/tables'
import { searchMerchant } from '@/configs/searches'
import Edit from './selfEdit'
import Info from './info'
import DialogMerchantConfigure from './configure'
import DialogSign from './sign'
import DialogOpen from './open'
import parkSetup from './parkSetup'
import Deduction from './deduction'
import DialogApi from './api'
export default {
  components: {
    Edit,
    Info,
    DialogMerchantConfigure,
    DialogSign,
    DialogOpen,
    parkSetup,
    Deduction,
    DialogApi
  },
  data() {
    return {
      tableLabels: tableMerchant,
      searchList: searchMerchant,
      callBackState: false
    }
  },
  methods: {
    async onSwitch(val, key, row, set) {
      if (key == 'isEnable') {
        if (!this.$hasAccess('007307')) return this.$message.error('您暂无权限禁用/启用')
        // 禁用启用
        const params = { merchantId: row.merchantId }
        const res = await this.$api.EnableMerchantInfo(params)
        set()
      }
    },
    deleteData() {},
    updateDialogTemplate() {
      this.$refs.vTable.refreshTable()
      this.callBackState = true
    },
    async refreshed() {
      if (this.callBackState) {
        const { merchantId } = this.$refs.vTable.datas[0]
        const res = await this.$api.GetMainSupplierInfoPageList({ merchantId })
        if (res.list.length == 0) {
          this.$confirm('当前商户与该园区没有签约记录，是否去创建签约记录？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'info'
          }).then(() => {
            this.$refs.DialogSign.open(this.$refs.vTable.datas[0])
          })
        }
        this.callBackState = false
      }
    },
    resetPassword(e) {
      this.$confirm('是否重置密码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let res = await this.$api.ResetMerchantUserPwd({
            merchantId: e.merchantId
          })
          if (res) {
            this.$message.success('重置成功')
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #3d78fa;
  font-size: 12px;
  .el-icon-arrow-down {
    font-size: 12px;
  }
  ::v-deep .el-dropdown-menu__item {
    font-size: 12px;
  }
}
</style>
