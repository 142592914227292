<template>
  <el-dialog :title="title" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="1000px">
    <!-- <TableView ref="vTable" :labels="tableLabels" apiName="GetSupplierInfoCodeName" :noPage="true" :selection="true" @selection-change="selectRows">
    </TableView> -->
    <el-table ref="vTable" :data="parkData" border @selection-change="selectRows">
      <!-- 序号列 -->
      <el-table-column type="index" :index="seqIndex" :label="seqLabel" align="center" :width="65" fixed :selectable="selectable"> </el-table-column>
      <el-table-column type="selection" width="55"></el-table-column>
      <!-- 参数型表格项组件 -->
      <el-table-column v-for="(item, idx) in tableLabels" :key="idx" v-bind="item" />
    </el-table>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { tableSelectPark } from '@/configs/tables'
export default {
  name: 'DialogSelectPark',
  data() {
    return {
      tableLabels: tableSelectPark,
      show: false,
      title: '选择税源地',
      parkData: [], //[{supplierCode:null,supplierName:null,isAuth:null}],
      seqLabel: '#',
      seqIndex: null,
      selData: []
    }
  },
  mounted() {
    this.getTableList()
  },
  methods: {
    async getTableList() {
      const res = await this.$api.GetSupplierInfoCodeName()
      this.parkData = res.list || []
    },
    clear() {},
    open(objs) {
      this.clear()
      this.selData = objs || []
      this.show = true
      this.$nextTick(() => {
        for (let item of this.parkData) {
          if (
            !objs.find((it) => {
              return item.supplierId == it.supplierId
            })
          )
            this.$refs.vTable.toggleRowSelection(item, false)
          else this.$refs.vTable.toggleRowSelection(item, true)
        }
      })
    },
    selectable(row, index) {
      const has = this.selData.find((item) => {
        return item.supplierId == row.supplierId
      })
      if (!has) return true
      return false
    },
    submit() {
      this.$emit('selectPark', this.selData)
      this.show = false
    },
    selectRows(selection) {
      this.selData = selection
    }
  }
}
</script>
