<template>
  <el-dialog class="taxManagement-import" :visible.sync="show" :title="isLook ? '上传完税凭证' : '查看完税凭证'" width="600px">
    <div>
      <el-form :model="detail" ref="vForm" label-position="right">
        <el-row :gutter="20" type="flex" justify="center" align="middle">
          <el-col :span="12">
            <el-form-item label="完税编号">{{ detail.taxVoucherNo }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="完税月份">{{ detail.taxVoucherDateMonth }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="center" align="middle">
          <el-col :span="12">
            <el-form-item label="商户名称">{{ detail.merchantName }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商户编号">{{ detail.memberCode }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="center" align="middle">
          <el-col :span="12">
            <el-form-item label="税源地名称">{{ detail.supplierName }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="税源地编号">{{ detail.supplierCode }}</el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="!isLook" :gutter="20" type="flex" justify="center" align="middle">
          <el-col :span="12">
            <el-form-item label="完税清单" prop="urlList">
              <div style="color: #236df4; cursor: pointer" @click="downloadFile" :loading="btnloadDownload">下载完税清单</div>
            </el-form-item>
          </el-col>
          <el-col :span="12"> </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="center" align="middle">
          <el-col :span="24">
            <el-form-item label="完税凭证" prop="urlList" :rules="[{ required: true, message: '请上传图片', trigger: 'blur' }]">
              <Upload
                :fileSize="20"
                :fileSuffix="fileSuffix"
                :isEdit="isLook"
                :number="999"
                :uploadOutUrl="'Upload/UploadApiFile'"
                :modelValue="detail.urlList || []"
                :config="config"
                @update="uploadMul"
              ></Upload>
              <div style="margin-left: 80px">支持jpg、png、jpeg、pdf、zip、xlsx,单文件大小不超过20M</div>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-form-item label="完税名称">{{ detail.taxVoucherName }}</el-form-item> -->
      </el-form>
    </div>
    <div v-if="isLook" slot="footer">
      <el-button @click="show = false">取消</el-button>
      <el-button v-if="$hasAccess('001102')" type="primary" @click="submit" :loading="btnloadSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Upload from '@/components/formView/multipleUpload.vue'
import { downloadExcel } from '@/utils'
export default {
  components: { Upload },
  data() {
    return {
      btnloadDownload: false,
      btnloadSubmit: false,
      show: false,
      detail: {},
      config: {
        disabled: false
      },
      isLook: false,
      fileSuffix: ['jpg', 'png', 'jpeg', 'pdf', 'zip', 'xlsx']
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    uploadMul(val) {
      this.detail.urlList = val
    },
    clear() {
      this.$nextTick(() => {
        this.$refs.vForm.resetFields()
      })
    },
    open(row, edit) {
      if (edit == 1) {
        this.isLook = false
        this.config.disabled = true
      } else {
        this.isLook = true
        this.config.disabled = false
      }
      let { taxVoucherName, taxVoucherNo, merchantName, supplierName, supplierCode, taxVoucherDateMonth, memberCode, id, taxVoucherAttaches } = row
      let urlList = []
      if (taxVoucherAttaches.length > 0) {
        urlList = taxVoucherAttaches.map((item) => {
          return {
            fileUrl: item.filePath,
            fileName: item.fileName
          }
        })
      }
      this.detail = Object.assign({
        taxVoucherName,
        taxVoucherNo,
        merchantName,
        supplierName,
        supplierCode,
        memberCode,
        taxVoucherDateMonth,
        id,
        urlList
      })
      this.show = true
    },
    async downloadFile() {
      this.btnloadDownload = true
      const res = await this.$api
        .ExportTaxDetailData({
          taxVoucherNo: this.detail.taxVoucherNo
        })
        .finally(() => {
          this.btnloadDownload = false
        })
      downloadExcel(res, '完税清单')
    },
    async submit() {
      const params = await this.validate()
      this.btnloadSubmit = true
      params.urlList.map((item, index) => {
        item.Name = item.fileName
        item.Url = item.fileUrl
      })
      params.Id = params.id
      const res = await this.$api.UploadFileUrl(params).finally(() => {
        this.btnloadSubmit = false
      })
      if (res) {
        this.$message.success('导入成功')
        this.$emit('updateList')
        this.show = false
      }
    },
    validate() {
      return new Promise((reslove, reject) => {
        this.$refs.vForm.validate((valid) => {
          if (valid) {
            reslove(this.detail)
          } else {
            return false
          }
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.taxManagement-import {
  ::v-deep .imageList {
    padding-left: 78px;
  }
}
/* @import url(); 引入css类 */
</style>
