<template>
  <el-dialog title="填充模板" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false">
    <div class="dialog-wrap">
      <el-form :model="formData" label-width="150px" ref="templateForm">
        <el-form-item :label="item.fieldName" :rules="item.rule" :prop="item.fieldCode" v-for="(item, index) in options" :key="index">
          <el-input v-model="formData[item.fieldCode]" type="text"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      formData: {},
      options: []
    }
  },
  methods: {
    async open(val, obj) {
      let result = await this.$api.GetFillFieldList({ templateId: val })
      if (result.list.length == 0) return this.$message.warning('模板中不存在填充项')
      let form = new Object()
      result = result.list.map((item) => {
        const { fieldCode, fieldName } = item
        form[fieldCode] = ''
        const rule = [{ required: true, message: `请输入${fieldName}`, trigger: ['change', 'blur'] }]
        return { fieldCode, fieldName, rule }
      })
      this.show = true
      this.formData = form
      this.options = result
      if (obj) {
        this.formData = JSON.parse(obj)
      }
      this.$nextTick(() => {
        this.resetForm()
      })
    },
    submit() {
      this.$refs.templateForm.validate((valid) => {
        if (valid) {
          this.$emit('submit', this.formData, 'host')
          this.show = false
        } else {
          return false
        }
      })
    },

    // 清除验证
    resetForm() {
      this.$refs.templateForm.clearValidate()
    }
  }
}
</script>
