<template>
  <div class="flex-home">
    <TableView ref="vTable" :labels="tableLabels" apiName="GetInvoiceRegisterList" :searchList="searchList">
      <el-form-item slot="btnline-item-left">
        <el-button v-if="$hasAccess('008100')" plain type="primary" @click="$refs.Edit.open()">开票登记</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="120" fixed="right">
        <template v-slot="{ row }">
          <el-button v-if="$hasAccess('008101')" type="text" @click="$refs.Detail.open(row)">查看</el-button>
          <el-button v-if="$hasAccess('008102')" type="text" @click="$refs.Edit.open(row)">编辑</el-button>
        </template>
      </el-table-column>
    </TableView>
    <Detail ref="Detail" />
    <Edit ref="Edit" @submit="$refs.vTable.refreshTable()" />
  </div>
</template>

<script>
import { formatStatus } from '@/configs/formatters'
import Detail from './detail'
import Edit from './edit'
export default {
  components: { Detail, Edit },
  data() {
    return {
      tableLabels: tableBillInfo,
      searchList: searchBillInfo
    }
  },
  mounted() {
    this.GetSupplierList()
  },
  methods: {
    async GetSupplierList() {
      let supplierRes = await this.$api.GetSupplierListBySearch({ PageIndex: 1, PageSize: 999 })
      let list = supplierRes.list.map((item) => {
        return {
          key: item.supplierId,
          label: item.supplierName
        }
      })
      this.searchList[2].options = list
      this.$refs.Edit.setSupplier(list)
    }
  }
}

const tableBillInfo = [
  { label: '企业名称', prop: 'companyName', config: { width: 200 } },
  { label: '纳税人识别号', prop: 'taxIdentificationNumber', config: { width: 150 } },
  {
    label: '发票类型',
    prop: 'billType',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 1, label: '增值税专用发票' },
          { key: 2, label: '增值税普通发票' },
          { key: 3, label: '增值税专用电子发票' },
          { key: 4, label: '增值税普通电子发票' }
        ],
        cellValue
      )
    }
  },
  { label: '开票税目', prop: 'taxItemsName' },
  { label: '开票金额（元）', prop: 'invoiceAmount', cmp: 'money' },
  { label: '税源地', prop: 'supplierName' },
  {
    label: '完结状态',
    prop: 'billingState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '未完结' },
          { key: 1, label: '已完结' }
        ],
        cellValue
      )
    }
  },
  { label: '登记时间', prop: 'createTime', config: { width: 150 } }
]

const searchBillInfo = [
  {
    cmp: 'input',
    label: '',
    prop: 'CompanyName',
    config: {
      placeholder: '请输入企业名称',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '完结状态',
    prop: 'BillingState',
    options: [
      { key: 0, label: '未完结' },
      { key: 1, label: '已完结' }
    ]
  },
  {
    cmp: 'select',
    label: '税源地',
    prop: 'SupplierId',
    options: []
  },
  {
    cmp: 'dpicker',
    label: '登记时间',
    prop: 'BillRegistrationDate',
    config: { type: 'month', valueFormat: 'yyyy-MM' }
  }
]
</script>
<style lang="scss" scoped></style>
