<template>
  <el-dialog :title="title" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="500px">
    <!-- 内容区域 -->
    <el-descriptions :column="1" size="medium" :labelStyle="{ width: '100px', textAlign: 'right', flexShrink: 0 }">
      <el-descriptions-item label="商户">{{ merchantName }}【{{ memberCode }}】</el-descriptions-item>
      <el-descriptions-item label="税源地">{{ supplierName }}【{{ supplierCode }}】</el-descriptions-item>
    </el-descriptions>
    <el-descriptions style="margin-top: 15px;margin-bottom: -20px;">
      <template slot="title">
        <el-tooltip effect="dark" content="仅对自营税源地生效，开启后，充值账户默认为展示银行卡账户，账户金额为银行卡账户和支付宝账户之和。开启后不可关闭，请谨慎开启。"><i
            class="el-icon-warning-outline"></i></el-tooltip> 合并账户
      </template>
      <template slot="extra">
        <el-switch :disabled="!!detail.isMergeAccount||canMerge||mergeLoading" :active-value="1" :inactive-value="0" @change="switchMerge"
          v-model="detail.isMergeAccount" active-color="#1a94fa">
        </el-switch>
      </template>
    </el-descriptions>
    <el-divider></el-divider>
    <el-descriptions title="银行卡通道" :column="1" size="medium" :labelStyle="{ width: '100px', textAlign: 'right', flexShrink: 0 }">
      <template slot="extra">
        <el-switch
          v-if="detail.bankRechargeAccount"
          class="closeBtn"
          :disabled="!!detail.isMergeAccount"
          :active-value="0"
          :inactive-value="1"
          @change="changeEnable($event, detail.bankRechargeAccount.accountId)"
          v-model="detail.bankRechargeAccount.isEnable"
          active-color="#1a94fa"
        >
        </el-switch>
        <el-button
          v-if="$hasAccess('007404') && (!detail.currentBankChannel || !detail.currentBankChannel.channelId)"
          type="text"
          @click="selChannel(1)"
          >配置通道</el-button
        >
        <el-button
          :disabled="detail.bankRechargeAccount && detail.bankRechargeAccount.isEnable == 1"
          v-if="detail.currentBankChannel && $hasAccess('007405')"
          type="text"
          @click="selChannel(1, true)"
          >更换发放通道</el-button
        >
      </template>
      <template v-if="detail.currentBankChannel && detail.bankRechargeAccount && detail.bankRechargeAccount.isEnable == 0">
        <el-descriptions-item label="发放通道">{{ supplierName }} - {{ detail.currentBankChannel.channelName }}</el-descriptions-item>
        <el-descriptions-item label="开户方式">
          <div class="disClass">
            <span> {{ formatStatus(kvOpenMode, detail.bankRechargeAccount.accountOpenMode) }}</span>
            <span
              v-if="$hasAccess('007406') && detail.bankRechargeAccount && detail.bankRechargeAccount.isEnable == 0"
              @click="changeAccount(1, detail.bankRechargeAccount)"
              class="handler"
              >更换充值帐户</span
            >
          </div>
        </el-descriptions-item>
        <template v-if="detail.bankRechargeAccount.accountOpenMode != 4">
          <el-descriptions-item label="账户名称">{{ detail.bankRechargeAccount.accountName }}</el-descriptions-item>
          <el-descriptions-item label="开户银行">{{ detail.bankRechargeAccount.accountBank }}</el-descriptions-item>
          <el-descriptions-item label="银行账户">{{ detail.bankRechargeAccount.accountNumber }}</el-descriptions-item>
          <el-descriptions-item v-if="detail.bankRechargeAccount.accountCode" label="银行行号">{{
            detail.bankRechargeAccount.accountCode
          }}</el-descriptions-item>
        </template>
        <template v-else>
          <el-descriptions-item label="付款账号">{{ detail.bankRechargeAccount.accountNumber }}</el-descriptions-item>
          <el-descriptions-item label="账户名称">{{ detail.bankRechargeAccount.accountName }}</el-descriptions-item>
          <el-descriptions-item label="汇款充值信息">{{ detail.bankRechargeAccount.isRemitAccount ? '开启' : '关闭' }}</el-descriptions-item>
          <template v-if="detail.bankRechargeAccount.isRemitAccount">
            <el-descriptions-item label="户名">{{ detail.bankRechargeAccount.alipayAccountName }}</el-descriptions-item>
            <el-descriptions-item label="账号">{{ detail.bankRechargeAccount.alipayAccountNumber }}</el-descriptions-item>
            <el-descriptions-item label="银行">{{ detail.bankRechargeAccount.alipayAccountBank }}</el-descriptions-item>
            <el-descriptions-item label="支行">{{ detail.bankRechargeAccount.alipayAccountBranch }}</el-descriptions-item>
            <el-descriptions-item label="开户地">{{ detail.bankRechargeAccount.alipayAccountAddresses }}</el-descriptions-item>
            <el-descriptions-item label="联行号">{{ detail.bankRechargeAccount.alipayAccountLines }}</el-descriptions-item>
          </template>
        </template>
        <el-descriptions-item label="充值记录">
          <div class="disClass">
            <span>{{ detail.bankRechargeAccount.accountGenerationMode == 1 ? '自动生成' : '手动提交' }}</span>
            <span
              v-if="
                $hasAccess('007408') &&
                detail.bankRechargeAccount &&
                detail.bankRechargeAccount.isEnable == 0 &&
                (detail.bankRechargeAccount.accountOpenMode == 3 || detail.bankRechargeAccount.accountOpenMode == 4)
              "
              @click="changeHandler(detail.bankRechargeAccount.accountId)"
              class="handler"
              >{{ detail.bankRechargeAccount.accountGenerationMode == 1 ? '切换手动' : '切换自动' }}
            </span>
          </div></el-descriptions-item
        >
      </template>
    </el-descriptions>
    <el-divider></el-divider>
    <el-descriptions title="支付宝通道" :column="1" size="medium" :labelStyle="{ width: '100px', textAlign: 'right', flexShrink: 0 }">
      <template slot="extra">
        <el-switch
          class="closeBtn"
          :disabled="!!detail.isMergeAccount"
          v-if="detail.alipayRechargeAccount"
          :active-value="0"
          :inactive-value="1"
          @change="changeEnable($event, detail.alipayRechargeAccount.accountId)"
          v-model="detail.alipayRechargeAccount.isEnable"
          active-color="#1a94fa"
        >
        </el-switch>
        <el-button
          v-if="$hasAccess('007404') && (!detail.currentAlipayChannel || !detail.currentAlipayChannel.channelId)"
          type="text"
          @click="selChannel(2)"
          >配置通道</el-button
        >
        <el-button
          :disabled="detail.alipayRechargeAccount && detail.alipayRechargeAccount.isEnable == 1"
          v-if="detail.currentAlipayChannel && $hasAccess('007405')"
          type="text"
          @click="selChannel(2, true)"
          >更换发放通道</el-button
        >
      </template>
      <template v-if="detail.currentAlipayChannel && detail.alipayRechargeAccount && detail.alipayRechargeAccount.isEnable == 0">
        <el-descriptions-item label="发放通道">{{ supplierName }} - {{ detail.currentAlipayChannel.channelName }}</el-descriptions-item>
        <el-descriptions-item label="开户方式">
          <div class="disClass">
            <span> {{ formatStatus(kvOpenMode, detail.alipayRechargeAccount.accountOpenMode) }}</span>
            <span
              v-if="$hasAccess('007406') && detail.alipayRechargeAccount && detail.alipayRechargeAccount.isEnable == 0"
              @click="changeAccount(2, detail.alipayRechargeAccount)"
              class="handler"
              >更换充值帐户</span
            >
          </div>
        </el-descriptions-item>
        <template v-if="detail.alipayRechargeAccount.accountOpenMode != 4">
          <el-descriptions-item label="账户名称">{{ detail.alipayRechargeAccount.accountName }}</el-descriptions-item>
          <el-descriptions-item label="开户银行">{{ detail.alipayRechargeAccount.accountBank }}</el-descriptions-item>
          <el-descriptions-item label="银行账户">{{ detail.alipayRechargeAccount.accountNumber }}</el-descriptions-item>
          <el-descriptions-item v-if="detail.alipayRechargeAccount.accountCode" label="银行行号">{{
            detail.alipayRechargeAccount.accountCode
          }}</el-descriptions-item>
        </template>
        <template v-else>
          <el-descriptions-item label="付款账号">{{ detail.alipayRechargeAccount.accountNumber }}</el-descriptions-item>
          <el-descriptions-item label="账户名称">{{ detail.alipayRechargeAccount.accountName }}</el-descriptions-item>
          <el-descriptions-item label="汇款充值信息">{{ detail.alipayRechargeAccount.isRemitAccount ? '开启' : '关闭' }}</el-descriptions-item>
          <template v-if="detail.alipayRechargeAccount.isRemitAccount">
            <el-descriptions-item label="户名">{{ detail.alipayRechargeAccount.alipayAccountName }}</el-descriptions-item>
            <el-descriptions-item label="账号">{{ detail.alipayRechargeAccount.alipayAccountNumber }}</el-descriptions-item>
            <el-descriptions-item label="银行">{{ detail.alipayRechargeAccount.alipayAccountBank }}</el-descriptions-item>
            <el-descriptions-item label="支行">{{ detail.alipayRechargeAccount.alipayAccountBranch }}</el-descriptions-item>
            <el-descriptions-item label="开户地">{{ detail.alipayRechargeAccount.alipayAccountAddresses }}</el-descriptions-item>
            <el-descriptions-item label="联行号">{{ detail.alipayRechargeAccount.alipayAccountLines }}</el-descriptions-item>
          </template>
        </template>
        <el-descriptions-item label="充值记录">
          <div class="disClass">
            <span>{{ detail.alipayRechargeAccount.accountGenerationMode == 1 ? '自动生成' : '手动提交' }}</span>
            <span
              v-if="
                $hasAccess('007408') &&
                detail.alipayRechargeAccount &&
                detail.alipayRechargeAccount.isEnable == 0 &&
                (detail.alipayRechargeAccount.accountOpenMode == 3 || detail.alipayRechargeAccount.accountOpenMode == 4)
              "
              class="handler"
              @click="changeHandler(detail.alipayRechargeAccount.accountId)"
              >{{ detail.alipayRechargeAccount.accountGenerationMode == 1 ? '切换手动' : '切换自动' }}</span
            >
          </div></el-descriptions-item
        >
      </template>
    </el-descriptions>
    <el-divider></el-divider>
    <el-descriptions title="微信通道" :column="1" size="medium" :labelStyle="{ width: '100px', textAlign: 'right', flexShrink: 0 }">
      <template slot="extra">
        <el-button v-if="!detail.currentWechatChannel || !detail.currentWechatChannel.channelId" type="text" @click="selChannel(3)">配置通道</el-button>
        <el-button v-else type="text" @click="selChannel(3, true)">更换发放通道</el-button>
      </template>
      <template v-if="detail.currentWechatChannel">
        <el-descriptions-item label="发放通道">{{ supplierName }} - {{ detail.currentWechatChannel.channelName }}</el-descriptions-item>
        <el-descriptions-item label="开户方式">
          <div class="disClass">
            <span> {{ formatStatus(kvOpenMode, detail.wechatRechargeAccount.accountOpenMode) }}</span>
            <span
              v-if="$hasAccess('007406') && detail.wechatRechargeAccount && detail.wechatRechargeAccount.isEnable == 0"
              @click="changeAccount(3, detail.wechatRechargeAccount)"
              class="handler"
              >更换充值帐户</span
            >
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="账户名称">{{ detail.wechatRechargeAccount.accountName }}</el-descriptions-item>
        <el-descriptions-item label="充值账户">{{ detail.wechatRechargeAccount.accountNumber }}</el-descriptions-item>
        <el-descriptions-item label="开户银行">{{ detail.wechatRechargeAccount.accountBank }}</el-descriptions-item>
        <el-descriptions-item label="充值记录">
          <div class="disClass">
            <span>{{ detail.wechatRechargeAccount.accountGenerationMode == 1 ? '自动生成' : '手动提交' }}</span>
            <span
              v-if="
                $hasAccess('007408') &&
                detail.wechatRechargeAccount &&
                detail.wechatRechargeAccount.isEnable == 0 &&
                (detail.wechatRechargeAccount.accountOpenMode == 3 || detail.wechatRechargeAccount.accountOpenMode == 4)
              "
              class="handler"
              @click="changeHandler(detail.wechatRechargeAccount.accountId)"
              >{{ detail.wechatRechargeAccount.accountGenerationMode == 1 ? '切换手动' : '切换自动' }}</span
            >
          </div></el-descriptions-item
        >
      </template>
    </el-descriptions>
    <el-divider></el-divider>
    <el-descriptions title="供应商通道" :column="1" size="medium" :labelStyle="{ width: '100px', textAlign: 'right', flexShrink: 0 }">
      <template slot="extra">
        <el-switch
          v-if="detail.providerRechargeAccount"
          class="closeBtn"
          :active-value="0"
          :inactive-value="1"
          @change="changeEnable($event, detail.providerRechargeAccount.accountId)"
          v-model="detail.providerRechargeAccount.isEnable"
          active-color="#1a94fa"
        >
        </el-switch>
        <el-button
          v-if="$hasAccess('007404') && (!detail.currentProviderChannel || !detail.currentProviderChannel.channelId)"
          type="text"
          @click="selChannel(4)"
          >配置通道</el-button
        >
        <el-button
          :disabled="detail.providerRechargeAccount && detail.providerRechargeAccount.isEnable == 1"
          v-if="detail.currentProviderChannel && $hasAccess('007405')"
          type="text"
          @click="selChannel(4, true)"
          >更换发放通道</el-button
        >
      </template>
      <template v-if="detail.currentProviderChannel && detail.providerRechargeAccount && detail.providerRechargeAccount.isEnable == 0">
        <el-descriptions-item label="发放通道">{{ supplierName }} - {{ detail.currentProviderChannel.channelName }}</el-descriptions-item>
        <el-descriptions-item label="开户方式">
          <div class="disClass">
            <span> {{ formatStatus(kvOpenMode, detail.providerRechargeAccount.accountOpenMode) }}</span>
            <span
              v-if="$hasAccess('007406') && detail.providerRechargeAccount && detail.providerRechargeAccount.isEnable == 0"
              @click="changeAccount(4, detail.providerRechargeAccount)"
              class="handler"
              >更换充值帐户</span
            >
          </div>
        </el-descriptions-item>
        <template v-if="detail.providerRechargeAccount.accountOpenMode != 4">
          <el-descriptions-item label="账户名称">{{ detail.providerRechargeAccount.accountName }}</el-descriptions-item>
          <el-descriptions-item label="开户银行">{{ detail.providerRechargeAccount.accountBank }}</el-descriptions-item>
          <el-descriptions-item label="银行账户">{{ detail.providerRechargeAccount.accountNumber }}</el-descriptions-item>
          <el-descriptions-item v-if="detail.providerRechargeAccount.accountCode" label="银行行号">{{
            detail.providerRechargeAccount.accountCode
          }}</el-descriptions-item>
        </template>
        <template v-else>
          <el-descriptions-item label="付款账号">{{ detail.providerRechargeAccount.accountNumber }}</el-descriptions-item>
          <el-descriptions-item label="账户名称">{{ detail.providerRechargeAccount.accountName }}</el-descriptions-item>
          <el-descriptions-item label="汇款充值信息">{{ detail.providerRechargeAccount.isRemitAccount ? '开启' : '关闭' }}</el-descriptions-item>
          <template v-if="detail.providerRechargeAccount.isRemitAccount">
            <el-descriptions-item label="户名">{{ detail.providerRechargeAccount.alipayAccountName }}</el-descriptions-item>
            <el-descriptions-item label="账号">{{ detail.providerRechargeAccount.alipayAccountNumber }}</el-descriptions-item>
            <el-descriptions-item label="银行">{{ detail.providerRechargeAccount.alipayAccountBank }}</el-descriptions-item>
            <el-descriptions-item label="支行">{{ detail.providerRechargeAccount.alipayAccountBranch }}</el-descriptions-item>
            <el-descriptions-item label="开户地">{{ detail.providerRechargeAccount.alipayAccountAddresses }}</el-descriptions-item>
            <el-descriptions-item label="联行号">{{ detail.providerRechargeAccount.alipayAccountLines }}</el-descriptions-item>
          </template>
        </template>
        <el-descriptions-item label="充值记录" contentStyle="width: 100%">
          <div class="disClass">
            <span>{{ detail.providerRechargeAccount.accountGenerationMode == 1 ? '自动生成' : '手动提交' }}</span>
            <span
              v-if="
                $hasAccess('007408') &&
                detail.providerRechargeAccount &&
                detail.providerRechargeAccount.isEnable == 0 &&
                (detail.providerRechargeAccount.accountOpenMode == 3 || detail.providerRechargeAccount.accountOpenMode == 4)
              "
              @click="changeHandler(detail.providerRechargeAccount.accountId)"
              class="handler"
              >{{ detail.providerRechargeAccount.accountGenerationMode == 1 ? '切换手动' : '切换自动' }}</span
            >
          </div>
        </el-descriptions-item>
      </template>
    </el-descriptions>
    <!-- 弹窗 -->
    <el-dialog :title="(form._isEdit ? '更换' : '配置') + '通道'" :visible.sync="selShow" :append-to-body="true" :close-on-click-modal="false">
      <el-form v-if="selShow" ref="form" :model="form" label-suffix="：" label-width="120px">
        <el-form-item
          :label="(form._isEdit ? '发放' : formatStatus(kvTypeName, form.accountType)) + '通道'"
          prop="channelId"
          :rules="[{ required: true, message: '请选择发放通道', trigger: 'change' }]"
        >
          <el-select v-model="form.channelId" placeholder="请选择" style="width: 100%">
            <el-option v-for="item in options" :key="item.channelId" :label="supplierName + ' - ' + item.channelName" :value="item.channelId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="!form._isEdit"
          label="充值账户"
          prop="openMode"
          :rules="[{ required: true, message: '请选择账户类型', trigger: 'change' }]"
        >
          <el-radio-group v-model="form.openMode">
            <el-radio :label="1">系统开户</el-radio>
            <el-radio :label="2">手动开户</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="!form._isEdit && form.openMode == 1">
          <el-form-item prop="supplierAccountId" :rules="[{ required: true, message: '请选择充值账户', trigger: 'change' }]">
            <el-select v-model="form.supplierAccountId" placeholder="请选择充值账户" style="width: 100%">
              <el-option v-for="item in detail.rechargeBankChannelList" :key="item.channelId" :label="item.channelName" :value="item.channelId">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
        <template v-if="!form._isEdit && form.openMode == 2">
          <el-form-item
            label="账户名称"
            prop="accountName"
            :rules="[{ required: true, message: '请输入账户名称', trigger: 'blur' }]"
            style="margin-left: 90px"
          >
            <el-input v-model="form.accountName" placeholder="请输入账户名称"></el-input>
          </el-form-item>
          <el-form-item
            label="开户银行"
            prop="accountBank"
            :rules="[{ required: true, message: '请输入开户银行', trigger: 'blur' }]"
            style="margin-left: 90px"
          >
            <el-input v-model="form.accountBank" placeholder="请输入开户行名称含支行"></el-input>
          </el-form-item>
          <el-form-item
            label="银行账户"
            prop="accountNumber"
            :rules="[{ required: true, message: '请输入银行账户', trigger: 'blur' }]"
            style="margin-left: 90px"
          >
            <el-input v-model="form.accountNumber" placeholder="请输入银行账户"></el-input>
          </el-form-item>
        </template>
      </el-form>
      <span slot="footer">
        <el-button @click="selShow = false">取 消</el-button>
        <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 开启弹窗 -->
    <el-dialog title="配置通道" :visible.sync="openShow" :append-to-body="true" :close-on-click-modal="false">
      <FormView ref="vForm" :labels="formLabels" :formConfig="formConfig" @onInputChange="onInputChange"></FormView>
      <span slot="footer">
        <el-button @click="openShow = false">取 消</el-button>
        <el-button type="primary" @click="submitOpen">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 更换充值帐户 -->
    <el-dialog title="更换充值帐户" :visible.sync="openAccount" :append-to-body="true" :close-on-click-modal="false">
      <FormView ref="changeForm" :labels="formChangeAccount" :formConfig="formConfig" @onInputChange="inputChange"></FormView>
      <span slot="footer">
        <el-button @click="openAccount = false">取 消</el-button>
        <el-button type="primary" @click="submitAccount">确 定</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { formatStatus, kvOpenMode } from '@/configs/formatters'
import { channelForm, channelSubmit } from './channel'
const kvTypeName = [
  { key: 1, label: '银行卡', name: 'bank' },
  { key: 2, label: '支付宝', name: 'alipay' },
  { key: 3, label: '微信', name: 'wechat' },
  { key: 4, label: '供应商', name: 'provider' }
]
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      id: null,
      merchantId: null,
      merchantName: '',
      supplierName: '',
      memberCode: '',
      supplierCode: '',
      title: '通道管理',
      kvTypeName,
      kvOpenMode,
      detail: {},
      selShow: false,
      form: {},
      options: [],
      openShow: false,
      openAccount: false,
      formLabels: [],
      formChangeAccount: [],
      formConfig: {
        labelWidth: '180px'
      },
      value1: false,
      value2: false,
      value3: false,
      mergeLoading: false
    }
  },
  computed: {
    // 判断businessType和权限
    hasBusinessAccess() {
      // console.log(this.businessType)
      // if (this.businessType == 1) {
      //   return this.$hasAccess('000107')
      // }
      // if (this.businessType == 2) {
      //   return this.$hasAccess('007306')
      // }
      return false
    },
    canMerge() {
      if (this.detail.bankRechargeAccount && this.detail.bankRechargeAccount.isEnable == 0 && this.detail.alipayRechargeAccount && this.detail.alipayRechargeAccount.isEnable == 0) return false
      return true
    }
  },
  methods: {
    formatStatus,
    clear() {
      this.id = null
      this.merchantId = null
      this.merchantName = this.supplierName = this.memberCode = this.supplierCode = ''
    },
    open(obj) {
      this.clear()
      if (obj && obj.id) {
        this.id = obj.id
        this.merchantId = obj.merchantId
        this.merchantName = obj.merchantName
        this.memberCode = obj.memberCode
        this.supplierName = obj.supplierName
        this.supplierCode = obj.supplierCode
        this.show = true
        this.getDetail()
      } else {
        this.$message.error('无效的信息')
      }
    },
    async getDetail() {
      const res = await this.$api.GetMerchantConfigAccountInfo({
        merchantConfigId: this.id
      })
      this.detail = res
      this.mergeLoading = false
    },
    // 名词转换
    formatStatusName(value) {
      const result = kvTypeName.find((item) => {
        return item.key == parseInt(value)
      })
      if (result) return result.name
    },
    // type 1银行卡，2支付宝，3微信，4供应商；_isEdit true更换，false开启
    selChannel(accountType, _isEdit) {
      this.options = []
      if (this.detail.supplierType == 2 && accountType == 1) {
        this.options.push(...this.detail.bankChannelList, ...this.detail.providerChannelList)
      } else if (this.detail.supplierType == 2 && accountType == 2) {
        this.options.push(...this.detail.alipayChannelList, ...this.detail.providerChannelList)
      } else if (this.detail.supplierType == 1&& accountType == 3) {
        this.options.push(...this.detail.wechatChannelList, ...this.detail.providerChannelList)
      } 
      else {
        this.options = this.detail[this.formatStatusName(accountType) + 'ChannelList']
      }
      if (!this.options.length) return this.$message.warning('当前税源地下无对应发放通道')
      // 开启，跳转openChannel逻辑
      if (!_isEdit) return this.openChannel(accountType)
      this.form = {
        accountType,
        openMode: 1,
        _isEdit
      }
      this.selShow = true
    },
    async submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.btnloadSubmit = true
          if (this.form._isEdit) {
            // 更换
            const res = await this.$api
              .MerchantChangeChannel({
                merchantConfigAccountId: this.detail[this.formatStatusName(this.form.accountType) + 'RechargeAccount'].accountId,
                channelId: this.form.channelId
              })
              .finally(() => {
                this.btnloadSubmit = false
              })
            this.$message.success('更换成功')
          } else {
            // 开启，逻辑已废弃
            const { channelId, openMode, accountType, supplierAccountId, accountName, accountBank, accountNumber } = this.form
            const params = {
              merchantConfigId: this.id,
              channelId,
              openMode,
              accountType
            }
            if (openMode == 1) {
              Object.assign(params, { supplierAccountId })
            }
            if (openMode == 2) {
              Object.assign(params, { accountName, accountBank, accountNumber })
            }
            const res = await this.$api.MerchantOpenChannel(params).finally(() => {
              this.btnloadSubmit = false
            })
            this.$message.success('开启成功')
          }
          this.selShow = false
          this.getDetail()
        }
      })
    },
    openChannel(accountType) {
      this.openShow = true
      switch (accountType) {
        case 1:
          this.formLabels = [...channelForm('bank')]
          this.formLabels.find((item) => item.prop == 'bank_channelId').options = (this.options || []).map((item) => {
            return Object.assign(
              {
                label: item.channelName,
                key: item.channelId
              },
              item
            )
          })
          this.formLabels.find((item) => item.prop == 'bank_supplierAccountId').options = (this.detail.rechargeBankChannelList || []).map((item) => {
            return Object.assign(
              {
                label: item.channelName,
                key: item.channelId
              },
              item
            )
          })
          if (this.detail.supplierType == 1) {
            this.formLabels.find((item) => item.prop == 'bank_openMode').options = kvOpenMode.filter((item) => item.key == 1)
          } else {
            this.formLabels.find((item) => item.prop == 'bank_openMode').options = kvOpenMode.filter((item) => item.key == 1 || item.key == 3)
          }
          break
        case 2:
          this.formLabels = [...channelForm('alipay')]
          this.formLabels.find((item) => item.prop == 'alipay_channelId').options = (this.options || []).map((item) => {
            return Object.assign(
              {
                label: item.channelName,
                key: item.channelId
              },
              item
            )
          })
          this.formLabels.find((item) => item.prop == 'alipay_supplierAccountId').options = (this.detail.rechargeBankChannelList || []).map(
            (item) => {
              return Object.assign(
                {
                  label: item.channelName,
                  key: item.channelId
                },
                item
              )
            }
          )
          if (this.detail.supplierType == 1) {
            this.formLabels.find((item) => item.prop == 'alipay_openMode').options = kvOpenMode.filter((item) => item.key == 1)
          } else {
            this.formLabels.find((item) => item.prop == 'alipay_openMode').options = kvOpenMode
          }
          break
        case 3:
          this.formLabels = [...channelForm('wechat')]
          this.formLabels.find((item) => item.prop == 'wechat_channelId').options = (this.options || []).map((item) => {
            return Object.assign(
              {
                label: item.channelName,
                key: item.channelId
              },
              item
            )
          })
          this.formLabels.find((item) => item.prop == 'wechat_supplierAccountId').options = (this.detail.rechargeBankChannelList || []).map(
            (item) => {
              return Object.assign(
                {
                  label: item.channelName,
                  key: item.channelId
                },
                item
              )
            }
          )
          if (this.detail.supplierType == 1) {
            this.formLabels.find((item) => item.prop == 'wechat_openMode').options = kvOpenMode.filter((item) => item.key == 1)
          } else {
            this.formLabels.find((item) => item.prop == 'wechat_openMode').options = kvOpenMode
          }
          break
        case 4:
          this.formLabels = [...channelForm('provider')]
          this.formLabels.find((item) => item.prop == 'provider_channelId').options = (this.options || []).map((item) => {
            return Object.assign(
              {
                label: item.channelName,
                key: item.channelId
              },
              item
            )
          })
          this.formLabels.find((item) => item.prop == 'provider_supplierAccountId').options = (this.detail.rechargeBankChannelList || []).map(
            (item) => {
              return Object.assign(
                {
                  label: item.channelName,
                  key: item.channelId
                },
                item
              )
            }
          )
          //开户方式逻辑
          if (this.detail.supplierType != 1) {
            this.formLabels.find((item) => item.prop == 'provider_openMode').options = kvOpenMode.filter((item) => item.key == 1 || item.key == 3)
          } else {
            this.formLabels.find((item) => item.prop == 'provider_openMode').options = kvOpenMode
          }
          break
        default:
          break
      }
      this.$nextTick(() => {
        this.$refs.vForm.fillData({
          accountType,
          _supplierType: 2,
          bank_isRemitAccount: false,
          alipay_isRemitAccount: false,
          provider_isRemitAccount: false
        })
      })
    },
    onInputChange(val, key) {
      console.log(val, key)
      if (key == 'alipay_channelId') {
        this.$refs.vForm.upData({ alipay_supplierAccountId: undefined }, true)
        return
      }
      if (key == 'bank_channelId') {
        this.$refs.vForm.upData({ bank_supplierAccountId: undefined }, true)
        return
      }
      if (key == 'provider_channelId') {
        this.$refs.vForm.upData({ provider_supplierAccountId: undefined }, true)
        return
      }
      if (key == 'alipay_openMode') {
        this.formLabels.find((item) => item.prop == 'alipay_generationMode').disabled = false
        if (val == 1) {
          this.$refs.vForm.upData({ alipay_generationMode: 1 }, true)
          this.formLabels.find((item) => item.prop == 'alipay_generationMode').disabled = true
        } else {
          this.$refs.vForm.upData({ alipay_generationMode: 2 }, true)
        }
        return
      }
      if (key == 'wechat_openMode') {
        this.formLabels.find((item) => item.prop == 'wechat_generationMode').disabled = false
        if (val == 1) {
          this.$refs.vForm.upData({ wechat_generationMode: 1 }, true)
          this.formLabels.find((item) => item.prop == 'wechat_generationMode').disabled = true
        } else {
          this.$refs.vForm.upData({ wechat_generationMode: 2 }, true)
        }
        return
      }
      if (key == 'bank_openMode') {
        this.formLabels.find((item) => item.prop == 'bank_generationMode').disabled = false
        if (val == 1) {
          this.$refs.vForm.upData({ bank_generationMode: 1 }, true)
          this.formLabels.find((item) => item.prop == 'bank_generationMode').disabled = true
        } else {
          this.$refs.vForm.upData({ bank_generationMode: 2 }, true)
        }
        return
      }
      if (key == 'provider_openMode') {
        this.formLabels.find((item) => item.prop == 'provider_generationMode').disabled = false
        if (val == 1) {
          this.$refs.vForm.upData({ provider_generationMode: 1 }, true)
          this.formLabels.find((item) => item.prop == 'provider_generationMode').disabled = true
        } else {
          this.$refs.vForm.upData({ provider_generationMode: 2 }, true)
        }
        return
      }
    },
    inputChange(val, key) {
      // 切换开户方式
      if (key == 'alipay_openMode') {
        this.formChangeAccount.find((item) => item.prop == 'alipay_generationMode').disabled = false
        if (val == 1) {
          this.$refs.changeForm.upData({ alipay_generationMode: 1 }, true)
          this.formChangeAccount.find((item) => item.prop == 'alipay_generationMode').disabled = true
        } else {
          this.$refs.changeForm.upData({ alipay_generationMode: 2 }, true)
        }
        return
      }
      if (key == 'bank_openMode') {
        this.formChangeAccount.find((item) => item.prop == 'bank_generationMode').disabled = false
        if (val == 1) {
          this.$refs.changeForm.upData({ bank_generationMode: 1 }, true)
          this.formChangeAccount.find((item) => item.prop == 'bank_generationMode').disabled = true
        } else {
          this.$refs.changeForm.upData({ bank_generationMode: 2 }, true)
        }
        return
      }
      if (key == 'wechat_openMode') {
        this.formChangeAccount.find((item) => item.prop == 'wechat_generationMode').disabled = false
        if (val == 1) {
          this.$refs.changeForm.upData({ wechat_generationMode: 1 }, true)
          this.formChangeAccount.find((item) => item.prop == 'wechat_generationMode').disabled = true
        } else {
          this.$refs.changeForm.upData({ wechat_generationMode: 2 }, true)
        }
        return
      }
      if (key == 'provider_openMode') {
        this.formChangeAccount.find((item) => item.prop == 'provider_generationMode').disabled = false
        if (val == 1) {
          this.$refs.changeForm.upData({ provider_generationMode: 1 }, true)
          this.formChangeAccount.find((item) => item.prop == 'provider_generationMode').disabled = true
        } else {
          this.$refs.changeForm.upData({ provider_generationMode: 2 }, true)
        }
        return
      }
      // if (key == 'openMode') {
      //   this.formChangeAccount.find((item) => item.prop == 'generationMode').disabled = false
      //   if (val == 1) {
      //     this.$refs.changeForm.upData({ generationMode: 1 }, true)
      //     this.formChangeAccount.find((item) => item.prop == 'generationMode').disabled = true
      //   } else {
      //     this.$refs.changeForm.upData({ generationMode: 2 }, true)
      //   }
      //   return
      // }
    },
    async submitOpen() {
      const form = await this.$refs.vForm.getForm()
      // 处理channels
      let channel
      switch (form.accountType) {
        case 1:
          channel = channelSubmit(form, 'bank')
          break
        case 2:
          channel = channelSubmit(form, 'alipay')
          break
        case 3:
          channel = channelSubmit(form, 'wechat')
          break
        case 4:
          channel = channelSubmit(form, 'provider')
          break
        default:
          break
      }
      const res = await this.$api.MerchantOpenChannel({ merchantConfigId: this.id, accountType: form.accountType, ...channel })
      this.$message.success('开启成功')
      this.openShow = false
      this.getDetail()
    },
    async submitAccount() {
      const form = await this.$refs.changeForm.getForm()
      // 处理channels
      let channel
      let merchantConfigAccountId
      switch (form.accountType) {
        case 1:
          channel = channelSubmit(form, 'bank')
          merchantConfigAccountId = this.detail.bankRechargeAccount.accountId
          break
        case 2:
          channel = channelSubmit(form, 'alipay')
          merchantConfigAccountId = this.detail.alipayRechargeAccount.accountId
          break
        case 3:
          channel = channelSubmit(form, 'wechat')
          merchantConfigAccountId = this.detail.wechatRechargeAccount.accountId
          break
        case 4:
          channel = channelSubmit(form, 'provider')
          merchantConfigAccountId = this.detail.providerRechargeAccount.accountId
          break
        default:
          break
      }
      const res = await this.$api.ChangeAccountChannel({
        merchantConfigAccountId: merchantConfigAccountId,
        merchantConfigId: this.id,
        accountType: form.accountType,
        ...channel
      })
      this.$message.success('切换成功')
      this.openAccount = false
      this.getDetail()
    },
    // 更换合并
    switchMerge() {
      this.$confirm(`开启后不可关闭，确定要开启合并账户吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.mergeLoading = true
        this.$api
        .SwitchMergeAccount({ merchantConfigId: this.id })
        .then((res) => {
          if (res) {
            this.$message.success('已开启合并账户')
            this.getDetail()
          }
        })
        .catch((res) => {
          this.$set(this.detail,'isMergeAccount', 0)
          this.mergeLoading = false
        })
      }).catch(() => {
        this.$set(this.detail,'isMergeAccount', 0)
        this.mergeLoading = false
      })
    },
    //更换充值帐户
    changeAccount(accountType, e) {
      // type 1银行卡，2支付宝，3微信，4供应商；_isEdit true更换，false开启
      this.openAccount = true
      let options = []
      options.push(...this.detail.alipayChannelList, ...this.detail.providerChannelList, ...this.detail.bankChannelList)
      switch (accountType) {
        case 1:
          this.formChangeAccount = [...channelForm('bank')]
          this.formChangeAccount.find((item) => item.prop == 'bank_channelId').disabled = true
          this.formChangeAccount.find((item) => item.prop == 'bank_channelId').options = (options || []).map((item) => {
            return Object.assign(
              {
                label: item.channelName,
                key: item.channelId
              },
              item
            )
          })
          this.formChangeAccount.find((item) => item.prop == 'bank_supplierAccountId').options = (this.detail.rechargeBankChannelList || []).map(
            (item) => {
              return Object.assign(
                {
                  label: item.channelName,
                  key: item.channelId
                },
                item
              )
            }
          )
          if (this.detail.supplierType == 1) {
            this.formChangeAccount.find((item) => item.prop == 'bank_openMode').options = kvOpenMode.filter((item) => item.key == 1)
          } else {
            this.formChangeAccount.find((item) => item.prop == 'bank_openMode').options = kvOpenMode.filter((item) => item.key == 1 || item.key == 3)
          }
          this.$nextTick(() => {
            this.$refs.changeForm.upData(
              {
                bank_channelId: this.detail.currentBankChannel?.channelId,
                bank_openMode: e.accountOpenMode,
                bank_generationMode: e.accountGenerationMode,
                bank_supplierAccountId: this.detail.currentBankRecharge?.channelId,
                accountType,
                _supplierType: 2,
                bank_isRemitAccount: false,
                alipay_isRemitAccount: false,
                provider_isRemitAccount: false
              },
              true
            )
            this.inputChange(e.accountOpenMode, 'bank_openMode')
          })
          break
        case 2:
          this.formChangeAccount = [...channelForm('alipay')]
          this.formChangeAccount.find((item) => item.prop == 'alipay_channelId').disabled = true
          this.formChangeAccount.find((item) => item.prop == 'alipay_channelId').options = (options || []).map((item) => {
            return Object.assign(
              {
                label: item.channelName,
                key: item.channelId
              },
              item
            )
          })
          this.formChangeAccount.find((item) => item.prop == 'alipay_supplierAccountId').options = (this.detail.rechargeBankChannelList || []).map(
            (item) => {
              return Object.assign(
                {
                  label: item.channelName,
                  key: item.channelId
                },
                item
              )
            }
          )
          if (this.detail.supplierType == 1) {
            this.formChangeAccount.find((item) => item.prop == 'alipay_openMode').options = kvOpenMode.filter((item) => item.key == 1)
          } else {
            this.formChangeAccount.find((item) => item.prop == 'alipay_openMode').options = kvOpenMode
          }
          this.$nextTick(() => {
            this.$refs.changeForm.upData(
              {
                alipay_channelId: this.detail.currentAlipayChannel?.channelId,
                alipay_openMode: e.accountOpenMode,
                alipay_generationMode: e.accountGenerationMode,
                alipay_supplierAccountId: this.detail.currentAlipayRecharge?.channelId,
                accountType,
                _supplierType: 2,
                bank_isRemitAccount: false,
                alipay_isRemitAccount: false,
                provider_isRemitAccount: false
              },
              true
            )
            this.inputChange(e.accountOpenMode, 'alipay_openMode')
          })
          break
        case 3:
          this.formChangeAccount = [...channelForm('wechat')]
          this.formChangeAccount.find((item) => item.prop == 'wechat_channelId').disabled = true
          this.formChangeAccount.find((item) => item.prop == 'wechat_channelId').options = (options || []).map((item) => {
            return Object.assign(
              {
                label: item.channelName,
                key: item.channelId
              },
              item
            )
          })
          this.formChangeAccount.find((item) => item.prop == 'wechat_supplierAccountId').options = (this.detail.rechargeBankChannelList || []).map(
            (item) => {
              return Object.assign(
                {
                  label: item.channelName,
                  key: item.channelId
                },
                item
              )
            }
          )
          if (this.detail.supplierType == 1) {
            this.formChangeAccount.find((item) => item.prop == 'wechat_openMode').options = kvOpenMode.filter((item) => item.key == 1)
          } else {
            this.formChangeAccount.find((item) => item.prop == 'wechat_openMode').options = kvOpenMode
          }
          this.$nextTick(() => {
            this.$refs.changeForm.upData(
              {
                wechat_channelId: this.detail.currentAlipayChannel?.channelId,
                wechat_openMode: e.accountOpenMode,
                wechat_generationMode: e.accountGenerationMode,
                wechat_supplierAccountId: this.detail.currentAlipayRecharge?.channelId,
                accountType,
                _supplierType: 2,
                bank_isRemitAccount: false,
                wechat_isRemitAccount: false,
                provider_isRemitAccount: false
              },
              true
            )
            this.inputChange(e.accountOpenMode, 'wechat_openMode')
          })
          break
        case 4:
          this.formChangeAccount = [...channelForm('provider')]
          this.formChangeAccount.find((item) => item.prop == 'provider_channelId').disabled = true
          this.formChangeAccount.find((item) => item.prop == 'provider_channelId').options = (options || []).map((item) => {
            return Object.assign(
              {
                label: item.channelName,
                key: item.channelId
              },
              item
            )
          })
          this.formChangeAccount.find((item) => item.prop == 'provider_supplierAccountId').options = (this.detail.rechargeBankChannelList || []).map(
            (item) => {
              return Object.assign(
                {
                  label: item.channelName,
                  key: item.channelId
                },
                item
              )
            }
          )
          //开户方式逻辑
          if (this.detail.supplierType != 1) {
            this.formChangeAccount.find((item) => item.prop == 'provider_openMode').options = kvOpenMode.filter(
              (item) => item.key == 1 || item.key == 3
            )
          } else {
            this.formChangeAccount.find((item) => item.prop == 'provider_openMode').options = kvOpenMode
          }
          this.$nextTick(() => {
            this.$refs.changeForm.upData(
              {
                provider_channelId: this.detail.currentProviderChannel?.channelId,
                provider_openMode: e.accountOpenMode,
                provider_generationMode: e.accountGenerationMode,
                provider_supplierAccountId: this.detail.currentProviderRecharge?.channelId,
                accountType,
                _supplierType: 2,
                bank_isRemitAccount: false,
                alipay_isRemitAccount: false,
                provider_isRemitAccount: false
              },
              true
            )
            this.inputChange(e.accountOpenMode, 'provider_openMode')
          })
          break
        default:
          break
      }
    },
    //切换开启禁用
    changeEnable(e, id) {
      if (!this.$hasAccess('007407')) return this.$message.error('您暂无权限禁用/启用')
      this.$confirm(`确定${e == 1 ? '禁用' : '启用'}当前通道？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$api
            .DisabledChannel({ merchantConfigAccountId: id })
            .then((res) => {
              if (res) {
                this.$message.success('操作成功')
                this.getDetail()
              }
            })
            .catch((res) => {
              this.getDetail()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
          this.getDetail()
        })
    },
    //切换生成记录
    changeHandler(id) {
      this.$confirm(`确定切换当前充值帐户的充值记录生成方式？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.SwitchGenerationMode({ merchantConfigAccountId: id }).then((res) => {
          if (res) {
            this.$message.success('操作成功')
            this.getDetail()
          }
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-descriptions__header {
  position: relative;
}
::v-deep.el-descriptions-item__content {
  width: 100%;
  .disClass {
    display: flex;
    justify-content: space-between;
  }
  .handler {
    color: #3d78fa;
    font-size: 13px;
    cursor: pointer;
    font-weight: 500;
  }
}
.closeBtn {
  position: absolute;
  left: 90px;
  top: 6px;
}
</style>
