<template>
  <el-dialog title="回单上传" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="600px">
    <!-- 内容区域 -->
    <FormView ref="vForm" :labels="formLabels" :formConfig="{ labelWidth: '150px' }"></FormView>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      loading: false,
      formLabels: [
        {
          cmp: 'input',
          label: '订单号',
          prop: 'CustomerOrderNo',
          itemConfig: {
            rules: [{ required: true, message: '请输入订单号', trigger: 'bulr' }]
          }
        },
        {
          cmp: 'upload',
          label: '导入文件',
          prop: 'FilePath',
          itemConfig: {
            rules: [{ required: true, message: '请导入文件', trigger: 'change' }]
          },
          suffixArray: ['png', 'jpg', 'jpeg', 'pdf'],
          size: 20
        }
      ],
      result: {}
    }
  },
  methods: {
    // 数据清空
    clear() {},
    open() {
      this.clear()
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData({})
      })
    },
    // 提交
    async submit() {
      const params = await this.$refs.vForm.getForm()
      const res = await this.$api.SetReceipt(params)
      if (res) {
        this.$message.success('回单已上传成功')
        this.show = false
      }
    }
  }
}
</script>
