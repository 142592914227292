<template>
    <div class="flex-home">
        <div class="pageTitle">{{ $route.meta.title }}</div>
        <el-alert type="warning" :closable="false" style="margin-bottom: 18px; flex-shrink: 0">
            <p>
                1.此操仅支持下载T+2之前的回单，按日生成；<br />2.已生成的批量回单留存失效为3天（或3个自然日），超过3天（或3个自然日）将会自动删除，请及时下载。
            </p>
        </el-alert>
        <TableView ref="vTable" :labels="tableLabels" apiName="GetReceiptZipPageList" :searchList="searchList">
            <!-- 按钮栏 -->
            <el-form-item slot="btnline-item-left">
                <el-button plain type="primary" icon="el-icon-download" @click="$refs.Create.open()">生成回单</el-button>
            </el-form-item>
            <!-- 操作栏 -->
            <el-table-column slot="table-item" label="操作" width="80" fixed="right">
                <template v-slot="{ row, $index }">
                    <el-button v-if="row.state == 1 && !!row.zipUrl" type="text" @click="download(row)">下载</el-button>
                </template>
            </el-table-column>
        </TableView>
        <Create ref="Create" @submit="$refs.vTable.refreshTable()"></Create>
    </div>
</template>
  
<script>
import Create from './create.vue'
import { formatStatus } from '@/configs/formatters'
import { downloadUrl } from '@/utils'
const searchList = [
    {
        cmp: 'dtpickers',
        label: '生成时间',
        prop: ['StartTime', 'EndTime']
    },
    {
        cmp: 'select',
        label: '文件状态',
        prop: 'State',
        options: [
            { key: '0', label: '生成中' },
            { key: '1', label: '已生成' },
        ]
    }
]
const tableLabels = [
    {
        label: '选择日期',
        prop: 'selectTime',
        formatter: (row, column, cellValue, index) => {
            return (cellValue.split('_') || [])[0] + '-' + (cellValue.split('_') || [])[1] + '，共' + (row.zipCount || 0) + '笔'
        }
    },
    {
        label: '商户编号',
        prop: 'memberCode'
    },
    {
        label: '商户名称',
        prop: 'merchantName'
    },
    {
        label: '状态',
        prop: 'state',
        formatter: (row, column, cellValue, index) => {
            return formatStatus([
                { key: 0, label: '文件生成中' },
                { key: 1, label: '文件已生成' }
            ], cellValue)
        }
    },
    {
        label: '生成时间',
        prop: 'finishTime',
        formatter: (row, column, cellValue, index) => {
            if (!cellValue || cellValue == '0001-01-01 00:00:00') return '-'
            return cellValue
        }
    }
]
export default {
    components: { Create },
    data() {
        return {
            searchList: searchList,
            tableLabels: tableLabels,
            btnloadDownload: false
        }
    },
    methods: {
        download(one) {
            if (!one.zipUrl) return this.$message.error('没有可下载的链接')
            downloadUrl(one.zipUrl)
        },
    }
}
</script>
  