<template>
  <el-dialog title="批量审核" :before-close="handleClose" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="600px">
    <!-- 内容区域 -->
    <el-form ref="vForm" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="服务税源地" prop="supplierId">
        <el-select v-model="form.supplierId" placeholder="请选择服务税源地">
          <el-option
            v-for="item in supplierOptions"
            :key="item.supplierId"
            :label="item.supplierName + '【' + item.supplierCode + '】'"
            :value="item.supplierId"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-divider content-position="left">创客签约概况</el-divider>
    <template v-if="signRate.merchantSignRateInfo">
      <el-table border :data="[signRate.merchantSignRateInfo]">
        <el-table-column prop="totalPeopleCount" label="创客总数" min-width="100"> </el-table-column>
        <el-table-column prop="signPeopleCount" label="已签约" min-width="100"> </el-table-column>
        <el-table-column
          prop="currSignRate"
          label="实际签约率"
          min-width="100"
          :formatter="(row, colum, cellValue, index) => (cellValue || 0) + '%'"
        ></el-table-column>
        <el-table-column
          prop="merchantSignRate"
          label="签约率"
          min-width="100"
          :formatter="(row, colum, cellValue, index) => (cellValue || 0) + '%'"
        ></el-table-column>
      </el-table>
    </template>
    <el-divider content-position="left">结算单签约概况</el-divider>
    <template v-if="signRate.workPlanSignRateList">
      <el-table border max-height="200px" :data="signRate.workPlanSignRateList">
        <el-table-column prop="planNo" label="结算单号" min-width="100"> </el-table-column>
        <el-table-column prop="totalPeopleCount" label="创客总数" min-width="100"> </el-table-column>
        <el-table-column prop="signPeopleCount" label="已签约" min-width="100"> </el-table-column>
        <el-table-column
          prop="currSignRate"
          label="实际签约率"
          min-width="100"
          :formatter="(row, colum, cellValue, index) => (cellValue || 0) + '%'"
        ></el-table-column>
      </el-table>
    </template>
    <div style="padding-top: 20px">
      总金额:
      <span v-format="'#,##0.00'">{{ auditAmount.totalAmount }}</span>
      银行卡:
      <span v-format="'#,##0.00'">{{ auditAmount.bankAmount }}</span>
      支付宝:
      <span v-format="'#,##0.00'">{{ auditAmount.alipayAmount }}</span>
    </div>
    <el-dialog width="30%" title="理由" :visible.sync="showReason" :append-to-body="true" :close-on-click-modal="false">
      <div>
        <el-input type="textarea" :rows="4" placeholder="请输入不通过理由，不超过200字" v-model="reason"> </el-input>
      </div>
      <div slot="footer">
        <el-button @click="showReason = !showReason">取消</el-button>
        <el-button @click="submit(false)" type="primary">确认</el-button>
      </div>
    </el-dialog>
    <span slot="footer">
      <el-button @click="showReason = true" :loading="btnloadSubmit">审核不通过</el-button>
      <el-button type="primary" @click="submit(true)" :loading="btnloadSubmit">审核通过</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      reason: '',
      showReason: false,
      btnloadSubmit: false,
      show: false,
      list: [],
      supplierOptions: [],
      form: {},
      signRate: {},
      rules: {
        supplierId: [{ required: true, message: '请选择服务税源地', trigger: 'change' }]
      },
      auditAmount: {}
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.list = []
      this.form = {}
      this.signRate = {}
      this.auditAmount = {}
      this.reason = ''
    },
    open(obj) {
      this.clear()
      if (obj) {
        // 编辑
        this.list = obj
        this.show = true
        this.getMerchantInfo()
        this.getSupplierOptions()
        this.getSignRate()
        this.getAuditAmonunt()
      } else {
        this.$message.error('无效的信息')
      }
    },

    // 获取统计金额
    async getAuditAmonunt() {
      const ids = this.list.map((it) => {
        return it.id
      })
      const res = await this.$api.GetWorkPlanAuditAmount({
        ids
      })
      console.log(res)
      this.auditAmount = res
    },
    // 获取签约率
    async getSignRate() {
      const ids = this.list.map((it) => {
        return it.id
      })
      const res = await this.$api.GetSignRateByBatch({
        merchantId: this.list[0].merchantId,
        workPlanIdList: ids
      })
      this.signRate = res
    },
    // 获取税源地列表
    async getSupplierOptions() {
      const res = await this.$api.GetSupplierInfoCodeName()
      this.supplierOptions = res.list
    },
    // 获取商户配置信息，并赋值发放税源地
    async getMerchantInfo() {
      const res = await this.$api.GetBatchAuditByMerchantInfo({ merchantId: this.list[0].merchantId })
      const { supplierId } = res
      this.$set(this.form, 'supplierId', supplierId || null)
    },
    handleClose() {
      this.$emit('submit')
      this.show = false
    },
    // 提交审核
    async submit(audit) {
      const rlist = this.list.map((item) => {
        return {
          memberCode: item.memberCode,
          planNo: item.planNo,
          supplierId: this.form.supplierId,
          aduitFailReason: this.showReason ? this.reason : null
        }
      })
      if (audit) {
        this.$refs.vForm.validate(async (valid) => {
          if (valid) {
            this.btnloadSubmit = true
            const res = await this.$api.BatchAuditPassWorkPlan(rlist).finally(() => {
              this.btnloadSubmit = false
            })
            this.$message.success('结算单批量审核通过')
            this.$emit('submit')
            this.show = false
          }
        })
      } else {
        this.btnloadSubmit = true
        const res = await this.$api.BatchAuditNotPassWorkPlan(rlist).finally(() => {
          this.btnloadSubmit = false
        })
        this.showReason = false
        this.$message.success('结算单批量审核不通过')
        this.$emit('submit')
        this.show = false
      }
    }
  }
}
</script>
