<template>
  <el-dialog title="上传电子回单" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
    <FormView ref="vForm" :labels="fromLabels" :formConfig="{ labelWidth: '150px' }"> </FormView>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { formatStatus, kvWithdrawState } from '@/configs/formatters.js'
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      fromLabels: [
        {
          cmp: 'select',
          label: '回单提供方',
          prop: 'providerName',
          options: [
            { key: '平安银行', value: '平安银行' },
            { key: '招商银行', value: '招商银行' },
            { key: '支付宝', value: '支付宝' }
          ],
          itemConfig: {
            rules: [{ required: true, message: '请选择回单提供方', trigger: 'change' }]
          }
        },
        {
          cmp: 'multipleUpload',
          label: '导入文件',
          prop: 'files',
          itemConfig: {
            rules: [{ required: true, message: '请导入文件', trigger: 'blur' }]
          },
          uploadConfig: { number: 200, fileSize: 20 }
        }
      ]
    }
  },
  methods: {
    // 数据清空
    clear() {},
    open() {
      this.clear()
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData({})
      })
    },
    // submit
    async submit() {
      const form = await this.$refs.vForm.getForm()
      const files = form.files.map((item) => {
        return {
          fileName: item.fileName,
          filePath: item.fileUrl
        }
      })
      this.btnloadSubmit = true
      const res = await this.$api.ImportReceipt({ providerName: form.providerName, files }).finally(() => {
        this.btnloadSubmit = false
      })
      if (res) {
        this.$message.success('批量上传成功')
        this.$emit('submit')
        this.show = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .file-uploader .imageList {
  max-height: 100px;
}
</style>
