<template>
  <el-dialog :title="idx < 0 ? '添加' : '编辑'" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="600px">
    <div v-if="show">
      <FormView ref="vForm" :labels="fromLabels"> </FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      btnloadSubmit: false,
      idx: -1,
      show: false,
      fromLabels: [
        {
          cmp: 'input',
          label: '发票编号',
          prop: 'receiptNo',
          itemConfig: { rules: [{ required: true, message: '请输入发票编号', trigger: 'blur' }] }
        },
        {
          cmp: 'number',
          label: '发票金额',
          prop: 'receiptAmount',
          itemConfig: { rules: [{ required: true, message: '请输入发票金额', trigger: 'blur' }] },
          config: { controls: false, min: 0, precision: 2 }
        },
        {
          cmp: 'upload',
          label: '发票附件',
          prop: 'receiptFilePath',
          suffixArray: ['jpg', 'png', 'jpeg', 'pdf', 'zip', 'xlsx', 'rar'],
          isLineBreak: true,
          size: 20,
          afterSuffix: '支持jpg、png、jpeg、pdf、zip、xlsx、rar,单文件大小不超过20M',
          itemConfig: { rules: [{ required: true, message: '请上传发票附件', trigger: 'change' }] }
        }
      ],
      form: {}
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.idx = -1
      this.form = {}
    },
    open(idx = -1, obj) {
      this.clear()
      if (idx >= 0 && obj) {
        // 编辑
        this.idx = idx
        this.form = obj
      } else {
        // 新建
      }
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData(this.form)
      })
    },
    getName(val) {
      const pos = val.lastIndexOf('\/')
      return val.substring(pos + 1)
    },
    async submit() {
      const form = await this.$refs.vForm.getForm()
      let i = form.receiptFilePath.lastIndexOf('.')
      form.type = form.receiptFilePath.substr(i + 1)
      this.btnloadSubmit = true
      this.$emit('submit', Object.assign({}, form, { receiptFileName: this.getName(form.receiptFilePath) }), this.idx)
      this.btnloadSubmit = false
      this.show = false
    }
  }
}
</script>
