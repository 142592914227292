<template>
  <el-container direction="vertical" class="layout-wrap">
    <Head />
    <el-container>
      <Side />
      <el-container direction="vertical" style="position: relative">
        <Breadcrumb />
        <el-main id="main">
          <transition appear mode="out-in" name="fade-transform">
            <router-view v-if="isRouterAlive" :key="$route.path" class="router-view" />
          </transition>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import Head from './head'
import Side from './side'
import Breadcrumb from './breadcrumb'

export default {
  name: 'Layout',
  components: {
    Head,
    Side,
    Breadcrumb
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-wrap {
  height: 100%;
}
#main {
  background: #f6f7f9;
  padding: 0 20px 20px;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
