// type:bank aplipay provider
import { kvOpenMode } from '@/configs/formatters'
// channelLabels表项
export const channelForm = (type) => {
  let labelName, propName, stype
  switch (type) {
    case 'bank':
      labelName = '银行卡'
      propName = 'bank_'
      stype = ''
      break
    case 'alipay':
      labelName = '支付宝'
      propName = 'alipay_'
      stype = ''
      break
    case 'wechat':
      labelName = '微信'
      propName = 'wechat_'
      stype = ''
      break
    case 'provider':
      labelName = '供应商'
      propName = 'provider_'
      stype = '==2'
      break
    default:
      break
  }
  // 通道
  const channel = [
    {
      sessionTitle: `${labelName}通道`,
      cmp: 'select',
      label: '发放通道',
      prop: `${propName}channelId`,
      options: [],
      itemConfig: {
        rules: [{ required: true, message: `请选择${labelName}通道`, trigger: 'change' }]
      },
      config: {
        style: 'width: 350px'
      },
      show: `form._supplierType&&form._supplierType${stype}`
    },
    {
      cmp: 'select',
      label: '开户方式',
      prop: `${propName}openMode`,
      options: kvOpenMode,
      itemConfig: {
        rules: [{ required: true, message: '请选择开户方式', trigger: 'change' }]
      },
      config: {
        style: 'width: 350px'
      },
      show: `!!form.${propName}channelId&&form.${propName}channelId!="000"&&form._supplierType${stype}`
    },
    // 自动开户-银行卡
    {
      cmp: 'select',
      label: '充值账户',
      prop: `${propName}supplierAccountId`,
      options: [],
      itemConfig: {
        rules: [{ required: true, message: '请选择充值账户', trigger: 'change' }]
      },
      config: {
        style: 'width: 350px'
      },
      show: `!!form.${propName}channelId&&form.${propName}channelId!="000"&&form._supplierType${stype}&&form.${propName}openMode==1`
    },
    // 手动开户-银行卡
    {
      cmp: 'input',
      label: '账户名称',
      prop: `${propName}accountName`,
      itemConfig: {
        rules: [{ required: true, message: '请输入账户名称', trigger: 'blur' }]
      },
      show: `!!form.${propName}channelId&&form.${propName}channelId!="000"&&form._supplierType${stype}&&form.${propName}openMode==3`
    },
    {
      cmp: 'input',
      label: '开户银行',
      prop: `${propName}accountBank`,
      itemConfig: {
        rules: [{ required: true, message: '请输入开户银行', trigger: 'blur' }]
      },
      show: `!!form.${propName}channelId&&form.${propName}channelId!="000"&&form._supplierType${stype}&&form.${propName}openMode==3`
    },
    {
      cmp: 'input',
      label: '银行账户',
      prop: `${propName}accountNumber`,
      itemConfig: {
        rules: [{ required: true, message: '请输入银行账户', trigger: 'blur' }]
      },
      show: `!!form.${propName}channelId&&form.${propName}channelId!="000"&&form._supplierType${stype}&&form.${propName}openMode==3`
    },
    {
      cmp: 'input',
      label: '银行行号',
      prop: `${propName}accountBankCode`,
      show: `!!form.${propName}channelId&&form.${propName}channelId!="000"&&form._supplierType${stype}&&form.${propName}openMode==3`
    },
    // 手动开户-支付宝
    {
      cmp: 'input',
      label: '付款账号',
      prop: `${propName}alipayAccountNumber`,
      itemConfig: {
        rules: [{ required: true, message: '请输入付款账号', trigger: 'blur' }]
      },
      show: `!!form.${propName}channelId&&form.${propName}channelId!="000"&&form._supplierType${stype}&&form.${propName}openMode==4`
    },
    {
      cmp: 'input',
      label: '账户名称',
      prop: `${propName}alipayAccountName`,
      itemConfig: {
        rules: [{ required: true, message: '请输入账户名称', trigger: 'blur' }]
      },
      show: `!!form.${propName}channelId&&form.${propName}channelId!="000"&&form._supplierType${stype}&&form.${propName}openMode==4`
    },
    {
      cmp: 'radio',
      label: '汇款充值信息',
      prop: `${propName}isRemitAccount`,
      options: [
        { key: false, label: '关闭' },
        { key: true, label: '开启' }
      ],
      itemConfig: {
        rules: [{ required: true, message: '请选择是否开启汇款充值信息', trigger: 'change' }]
      },
      show: `!!form.${propName}channelId&&form.${propName}channelId!="000"&&form._supplierType${stype}&&form.${propName}openMode==4`
    },
    {
      cmp: 'input',
      label: '户名',
      prop: `${propName}accountName`,
      itemConfig: {
        rules: [{ required: true, message: '请输入银行账户', trigger: 'blur' }]
      },
      show: `!!form.${propName}channelId&&form.${propName}channelId!="000"&&form._supplierType${stype}&&form.${propName}openMode==4&&form.${propName}isRemitAccount`
    },
    {
      cmp: 'input',
      label: '账号',
      prop: `${propName}accountNumber`,
      itemConfig: {
        rules: [{ required: true, message: '请输入银行账号', trigger: 'blur' }]
      },
      show: `!!form.${propName}channelId&&form.${propName}channelId!="000"&&form._supplierType${stype}&&form.${propName}openMode==4&&form.${propName}isRemitAccount`
    },
    {
      cmp: 'input',
      label: '银行',
      prop: `${propName}accountBank`,
      itemConfig: {
        rules: [{ required: true, message: '请输入开户行名称', trigger: 'blur' }]
      },
      show: `!!form.${propName}channelId&&form.${propName}channelId!="000"&&form._supplierType${stype}&&form.${propName}openMode==4&&form.${propName}isRemitAccount`
    },
    {
      cmp: 'input',
      label: '支行',
      prop: `${propName}accountBranch`,
      itemConfig: {
        rules: [{ required: true, message: '请输入开户行支行名称', trigger: 'blur' }]
      },
      show: `!!form.${propName}channelId&&form.${propName}channelId!="000"&&form._supplierType${stype}&&form.${propName}openMode==4&&form.${propName}isRemitAccount`
    },
    {
      cmp: 'input',
      label: '开户地',
      prop: `${propName}accountAddresses`,
      itemConfig: {
        rules: [{ required: true, message: '请输入开户地', trigger: 'blur' }]
      },
      show: `!!form.${propName}channelId&&form.${propName}channelId!="000"&&form._supplierType${stype}&&form.${propName}openMode==4&&form.${propName}isRemitAccount`
    },
    {
      cmp: 'input',
      label: '联行号',
      prop: `${propName}accountLines`,
      itemConfig: {
        rules: [{ required: true, message: '请输入联行号', trigger: 'blur' }]
      },
      show: `!!form.${propName}channelId&&form.${propName}channelId!="000"&&form._supplierType${stype}&&form.${propName}openMode==4&&form.${propName}isRemitAccount`
    },
    {
      cmp: 'radio',
      label: '充值记录',
      prop: `${propName}generationMode`,
      options: [
        { key: 1, label: '自动生成' },
        { key: 2, label: '手动提交' }
      ],
      itemConfig: {
        rules: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      show: `!!form.${propName}channelId&&form.${propName}channelId!="000"&&form._supplierType${stype}&&form.${propName}openMode`
    }
  ]
  return channel
}
// channelform数据转换成提交数据结构channel
export const channelSubmit = (form, type) => {
  const propName = type + '_'
  const result = {
    channelId: form[`${propName}channelId`],
    openMode: form[`${propName}openMode`],
    generationMode: form[`${propName}generationMode`]
  }
  if (form[`${propName}channelId`] != '000') {
    switch (form[`${propName}openMode`]) {
      case 1:
        result.autoBankChannelDto = {
          supplierAccountId: form[`${propName}supplierAccountId`]
        }
        break
      case 3:
        result.manualBankChannelDto = {
          accountName: form[`${propName}accountName`],
          accountBank: form[`${propName}accountBank`],
          accountNumber: form[`${propName}accountNumber`],
          accountBankCode: form[`${propName}accountBankCode`]
        }
        break
      case 4:
        if (form[`${propName}isRemitAccount`]) {
          result.manualAlipayChannelDto = {
            alipayAccountNumber: form[`${propName}alipayAccountNumber`],
            alipayAccountName: form[`${propName}alipayAccountName`],
            isRemitAccount: form[`${propName}isRemitAccount`],
            accountName: form[`${propName}accountName`],
            accountNumber: form[`${propName}accountNumber`],
            accountBank: form[`${propName}accountBank`],
            accountBranch: form[`${propName}accountBranch`],
            accountAddresses: form[`${propName}accountAddresses`],
            accountLines: form[`${propName}accountLines`]
          }
        } else {
          result.manualAlipayChannelDto = {
            alipayAccountNumber: form[`${propName}alipayAccountNumber`],
            alipayAccountName: form[`${propName}alipayAccountName`],
            isRemitAccount: form[`${propName}isRemitAccount`]
          }
        }
        break
      default:
        break
    }
    return result
  }
  return false
}
