<template>
  <el-dialog title="审核" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" custom-class="dialog-full-hasfoot" fullscreen>
    <!-- 内容区域 -->
    <div class="dialog-wrap-full" v-if="show">
      <el-descriptions title="基本信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="申请编号">{{ detail.applyNumber || '--' }}</el-descriptions-item>
        <el-descriptions-item label="申请时间">{{ detail.createTime || '--' }}</el-descriptions-item>
        <el-descriptions-item label="商户名称">{{ detail.merchantName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="商户号">{{ detail.memberCode || '无' }}</el-descriptions-item>
        <el-descriptions-item :span="1" label="认证企业">{{ detail.mainName || '无' }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="发票内容" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="发票类型">{{ detail.invoiceType || '无' }}</el-descriptions-item>
        <el-descriptions-item label="开票税目">{{ detail.invoiceItem || '无' }}</el-descriptions-item>
        <el-descriptions-item label="申请金额"
          ><span v-format="'#,##0.00'">{{ detail.applyAmount || 0 }}</span> 元</el-descriptions-item
        >
        <el-descriptions-item label="合并笔数"
          >{{ detail.invoicingSource != 2 ? detail.planCount || '无' : '无' }}
          <el-link  v-if="detail.planCount && detail.invoicingSource != 2" type="primary" style="margin-left: 30px; margin-top: -2px" @click="$refs.Plan.open({ InvoiceId: detail.id, PlanType: 1, detail })"
            >查看</el-link
          ></el-descriptions-item
        >
        <el-descriptions-item label="平账结算单"
          >{{ detail.planDSOCount || '无' }}
          <el-link type="primary" style="margin-left: 30px; margin-top: -2px" @click="$refs.Plan.open({ InvoiceId: detail.id, PlanType: 2, detail })"
            >查看</el-link
          ></el-descriptions-item
        >
        <el-descriptions-item :span="1" label="备注">{{ detail.remark || '无' }}</el-descriptions-item>
        <el-descriptions-item label="退汇金额"
          ><span v-format="'#,##0.00'">{{ detail.returnAmount || 0 }}</span> 元</el-descriptions-item
        >
        <el-descriptions-item label="开票金额"
          ><span v-format="'#,##0.00'">{{ detail.invoiceAmount || 0 }}</span> 元</el-descriptions-item
        >
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="抬头信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="抬头名称">{{ detail.riseName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="纳税人识别号">{{ detail.taxPayerNumber || '无' }}</el-descriptions-item>
        <el-descriptions-item label="单位地址">{{ detail.unitAddress || '无' }}</el-descriptions-item>
        <el-descriptions-item label="电话号码">{{ detail.phone || '无' }}</el-descriptions-item>
        <el-descriptions-item label="开户银行">{{ detail.openBank || '无' }}</el-descriptions-item>
        <el-descriptions-item label="银行账户">{{ detail.bankAccount || '无' }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="收件人信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="收件人姓名">{{ detail.inboxName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="收件人电话">{{ detail.inboxPhone || '无' }}</el-descriptions-item>
        <el-descriptions-item label="选择区域">{{ detail.inboxArea || '无' }}</el-descriptions-item>
        <el-descriptions-item label="详细地址">{{ detail.inboxAddress || '无' }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <div class="el-descriptions__header">
        <div class="el-descriptions__title">退汇订单</div>
        <div class="el-descriptions__extra"></div>
      </div>
      <TableView
        ref="vTable"
        :labels="tableLabels"
        apiName="GetInvoiceDetailReturnList"
        tableMinHeight="0px"
        :tableConfig="{ height: '' }"
        :searchExt="{ InvoiceId: id }"
        :selection="false"
        @selection-change="selChange"
      ></TableView>
      <!-- <p>
        退汇金额：<span v-format="'#,##0.00'">{{ returnAmount || 0 }}</span> 元 开票金额：<span v-format="'#,##0.00'">{{
          (detail.applyAmount || 0) - (returnAmount || 0)
        }}</span>
        元
      </p> -->
    </div>
    <span slot="footer">
      <el-button @click="check(2)">审核不通过</el-button>
      <el-button type="primary" @click="check(1)">审核通过</el-button>
    </span>
    <Plan ref="Plan" />
  </el-dialog>
</template>

<script>
import { formatStatus, formatStatusHasColor, kvBillState, kvDistributionState } from '@/configs/formatters'
import { tableInvoiceReturn } from '@/configs/tables'
import Plan from './plan'
export default {
  components: { Plan },
  data() {
    return {
      id: null,
      show: false,
      detail: {},
      form: {},
      tableLabels: tableInvoiceReturn,
      returnList: []
    }
  },
  computed: {
    returnAmount() {
      const sum = this.returnList.reduce((next, it) => {
        return Number(next) + (Number(it.orderAmount) || 0)
      }, 0)
      return sum
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
      this.form = {}
      this.returnList = []
    },
    open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 查看
        this.id = obj.id
        this.show = true
        this.detail = obj
      } else {
        this.$message.error('无效的信息')
      }
    },
    selChange(val) {
      this.returnList = val
    },
    async check(state) {
        const params = {
        invoiceId: this.id,
        auditState: state,
        returnList: this.returnList.map((it) => {
          return it.id
        })
      }
      if (state == 2) {
        const { value } = await this.$prompt('请输入审核不通过理由', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^\S+$/,
          inputErrorMessage: '请输入审核不通过理由'
        })
        params.refuse = value
      }
      const res = await this.$api.AuditInvoice(params)
      if (state == 2) {
        this.$message.success('已审核不通过')
      } else {
        this.$message.success('已审核通过')
      }
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
