<template>
  <div class="flex-home">
    <div class="pageTitle" @click="$refs.DialogInfo.open({ id: '12' })">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetOperationUserPageList" :searchList="searchList" @onSwitch="onSwitch">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item" v-if="$hasAccess('002001')">
        <el-button plain type="primary" icon="el-icon-plus" @click="$refs.Edit.open()">添加</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="220" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button v-if="$hasAccess('002003')" type="text" @click="$refs.Edit.open(row)">编辑</el-button>
          <!-- <el-button type="text">角色分配</el-button> -->
          <el-button type="text" @click="resetPwd(row)">重置密码</el-button>
          <el-button v-if="$hasAccess('002004') && row.isEnable == 1" type="text" class="el-button-error" @click="delOne(row)">删除</el-button>
        </template>
      </el-table-column>
    </TableView>
    <Edit ref="Edit" @submit="$refs.vTable.refreshTable()" />
  </div>
</template>

<script>
import { tableOperationUser } from '@/configs/tables'
import { searchOperationUser } from '@/configs/searches'
import Edit from './edit'
export default {
  components: { Edit },
  data() {
    return {
      tableLabels: tableOperationUser,
      searchList: searchOperationUser
    }
  },
  methods: {
    async resetPwd(item) {
      const params = { userId: item.id }
      const res = await this.$api.ResetOperationUserPwd(params)
      this.$message.success('密码已重置为：LSY2022')
    },
    async onSwitch(val, key, row, set) {
      if (row.id == this.$store.state.user.userId) return this.$message.warning('不能禁用自己')
      if (key == 'isEnable') {
        if (!this.$hasAccess('002002')) return this.$message.error('您暂无权限禁用/启用')
        // 禁用启用
        const params = { userId: row.id }
        const res = await this.$api.DisabledOperationUser(params)
        set()
      }
    },
    async delOne(item) {
      await this.$confirm(`确认删除当前用户信息？`, '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      const res = await this.$api.DeleteOperationUser({ userId: item.id })
      this.$message.success('用户已删除')
      this.$refs.vTable.refreshTable()
    }
  }
}
</script>
