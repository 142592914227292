var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":"配置管理","visible":_vm.show,"append-to-body":true,"close-on-click-modal":false,"custom-class":"dialog-full-hasfoot","fullscreen":""},on:{"update:visible":function($event){_vm.show=$event}}},[(_vm.show)?_c('div',{staticClass:"dialog-wrap-full"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.add == 1),expression:"add == 1"}]},[_c('FormView',{ref:"vFormNew",attrs:{"labels":_vm.formNewLabels,"formConfig":_vm.formConfig},on:{"onInputChange":_vm.onInputChangeNew},scopedSlots:_vm._u([{key:"merchantSuffix",fn:function(){return [_c('el-col',[_c('el-alert',{staticStyle:{"margin-bottom":"18px"},attrs:{"title":"变更商户后，已选的税源地和通道将重置，请谨慎操作","type":"warning","show-icon":"","closable":false}})],1)]},proxy:true}],null,false,1709081634)})],1),_c('FormView',{directives:[{name:"show",rawName:"v-show",value:(_vm.add != 1),expression:"add != 1"}],ref:"vForm",attrs:{"labels":_vm.formLabels,"formConfig":_vm.formConfig},on:{"onInputChange":_vm.onInputChange},scopedSlots:_vm._u([{key:"saveMerchantTariffing",fn:function(){return [_c('el-col',[_c('el-form-item',[_c('el-button',{staticStyle:{"margin-bottom":"12px"},attrs:{"plain":"","type":"primary","icon":"el-icon-plus"},on:{"click":function($event){return _vm.addTariffing()}}},[_vm._v("添加")]),_c('el-table',{ref:"merchantTariffingListTable",attrs:{"data":_vm.merchantTariffingList,"border":""}},[_c('el-table-column',{attrs:{"label":"发放金额"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-input-number',{attrs:{"controls":false,"precision":0,"disabled":"","value":_vm.issueAmountBefore($index)}}),_vm._v(" - "),_c('el-input-number',{attrs:{"controls":false,"min":_vm.issueAmountBefore($index),"max":1000000000000,"precision":0},model:{value:(row.issueAmount),callback:function ($$v) {_vm.$set(row, "issueAmount", $$v)},expression:"row.issueAmount"}})]}}],null,false,1759834448)}),_c('el-table-column',{attrs:{"label":"服务费率"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"min":0,"precision":2,"max":100},model:{value:(row.serviceTariffing),callback:function ($$v) {_vm.$set(row, "serviceTariffing", $$v)},expression:"row.serviceTariffing"}}),_vm._v(" % ")]}}],null,false,3850723897)}),_c('el-table-column',{attrs:{"label":"成本费率"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"controls":false,"min":0,"precision":2,"max":100},model:{value:(row.costServiceTariffing),callback:function ($$v) {_vm.$set(row, "costServiceTariffing", $$v)},expression:"row.costServiceTariffing"}}),_vm._v(" % ")]}}],null,false,186908914)}),_c('el-table-column',{attrs:{"label":"操作","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-button',{staticClass:"el-button-error",attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteTariffing(row, $index)}}},[_vm._v("删除")])]}}],null,false,1496022439)})],1)],1)],1)]},proxy:true},{key:"keywordDtos",fn:function(ref){
var form = ref.form;
var idx = ref.idx;
return [_c('el-col',[_c('el-form-item',{staticClass:"is-required",attrs:{"label":"盖章关键字"}},[_c('el-table',{attrs:{"data":_vm.merchantKeywordList}},[_c('el-table-column',{attrs:{"prop":"keyword","label":"关键字","width":"120"}}),_c('el-table-column',{attrs:{"prop":"signatoryType","label":"签约方类型","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.formatStatus(_vm.stampOptions, row.signatoryType)))]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"signatoryName","label":"签约方","width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.signatoryType == 1 || row.signatoryType == 2)?_c('el-input',{attrs:{"disabled":"","placeholder":row.signatoryType == 1 ? '商户主体' : '创客'}}):_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":""},on:{"change":function($event){return _vm.onSignatoryChange($event, row)}},model:{value:(row.signatoryId),callback:function ($$v) {_vm.$set(row, "signatoryId", $$v)},expression:"row.signatoryId"}},_vm._l((_vm.signatoryOptions),function(item){return _c('el-option',{key:item.mainId,staticStyle:{"width":"300px"},attrs:{"label":item.mainCode + ' ' + item.mainName,"value":item.mainId}})}),1)]}}],null,true)}),_c('el-table-column',{attrs:{"label":"授权状态","width":"80","prop":"authorState"}})],1)],1)],1)]}},{key:"saveMerchantTax",fn:function(ref){
var idx = ref.idx;
var form = ref.form;
return [_c('el-col',[_c('el-form-item',{staticClass:"is-required",attrs:{"label":"可开票税目","error":_vm.taxError}},[_c('el-popover',{attrs:{"placement":"bottom-start","width":"600","trigger":"manual"},model:{value:(_vm.showTaxPopover),callback:function ($$v) {_vm.showTaxPopover=$$v},expression:"showTaxPopover"}},[_c('div',{attrs:{"slot":"reference"},slot:"reference"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.openTaxPopover}},[_vm._v("添加")])],1),_c('div',{staticClass:"tax-pop",staticStyle:{"max-height":"500px","overflow":"auto"}},[_c('div',{staticClass:"text"},[_vm._v("税目列表")]),_c('el-tree',{ref:"taxAllTree",attrs:{"data":form.isThirdInvoice ? _vm.taxHmItemsLevelList : _vm.taxItemsLevelList,"show-checkbox":"","default-expand-all":"","default-checked-keys":_vm.checkTaxItemsId,"node-key":"value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(data.title || ''))]),_vm._v("  "),_c('span',{staticClass:"label-num"},[_vm._v(_vm._s(data.taxCode || ''))])])}}],null,true)})],1),_c('div',{staticStyle:{"text-align":"right","margin":"0"}},[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){_vm.showTaxPopover = false}}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.getTaxTree}},[_vm._v("确定")])],1)]),(_vm.merchantTaxList.length > 0)?_c('el-tree',{ref:"taxTree",staticStyle:{"width":"400px"},attrs:{"data":_vm.merchantTaxList,"default-expand-all":"","node-key":"value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(data.title || ''))]),_vm._v("  "),_c('span',{staticClass:"label-num"},[_vm._v(_vm._s(data.taxCode || ''))])])}}],null,true)}):_vm._e()],1)],1)]}},{key:"isDeliverableAmountLimit",fn:function(ref){
var idx = ref.idx;
var form = ref.form;
return [_c('el-col',[_c('el-form-item',{attrs:{"label":"金额范围","prop":"isDeliverableAmountLimit","rules":[
              {
                required: true,
                message: '请选择金额范围',
                trigger: 'change'
              }
            ]}},[_c('el-radio-group',{model:{value:(form.isDeliverableAmountLimit),callback:function ($$v) {_vm.$set(form, "isDeliverableAmountLimit", $$v)},expression:"form.isDeliverableAmountLimit"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("不限制")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("限制")])],1),(form.isDeliverableAmountLimit)?_c('div',{staticStyle:{"display":"inline-block","margin-left":"30px"}},[_c('el-form-item',{staticStyle:{"display":"inline-block","margin-bottom":"0"},attrs:{"prop":"deliverableMinAmount","rules":[
                  {
                    required: true,
                    message: '请填写最小金额',
                    trigger: 'blur'
                  }
                ]}},[_c('el-input-number',{attrs:{"controls":false,"min":0,"precision":2},model:{value:(form.deliverableMinAmount),callback:function ($$v) {_vm.$set(form, "deliverableMinAmount", $$v)},expression:"form.deliverableMinAmount"}})],1),_c('span',[_vm._v(" - ")]),_c('el-form-item',{staticStyle:{"display":"inline-block","margin-bottom":"0"},attrs:{"prop":"deliverableMaxAmount","rules":[
                  {
                    required: true,
                    message: '请填写最大金额',
                    trigger: 'blur'
                  },
                  {
                    validator: function (rule, value, callback) {
                      if (value < form.deliverableMinAmount) {
                        callback(_vm.$error('需要大于最小金额'))
                      } else {
                        callback()
                      }
                    },
                    trigger: 'blur'
                  }
                ]}},[_c('el-input-number',{attrs:{"controls":false,"min":0,"precision":2},model:{value:(form.deliverableMaxAmount),callback:function ($$v) {_vm.$set(form, "deliverableMaxAmount", $$v)},expression:"form.deliverableMaxAmount"}})],1)],1):_vm._e()],1)],1)]}},{key:"isTimeLimt",fn:function(ref){
                var idx = ref.idx;
                var form = ref.form;
return [_c('el-col',[_c('el-form-item',{attrs:{"label":"时间范围","prop":"isProhibitGrantTimeLimit"}},[_c('el-radio-group',{model:{value:(form.isProhibitGrantTimeLimit),callback:function ($$v) {_vm.$set(form, "isProhibitGrantTimeLimit", $$v)},expression:"form.isProhibitGrantTimeLimit"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("不限制")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("限制")])],1),(form.isProhibitGrantTimeLimit)?_c('div',{staticStyle:{"display":"inline-block","margin-left":"30px"}},[_c('el-form-item',{staticStyle:{"display":"inline-block","margin-bottom":"0"},attrs:{"prop":"prohibitGrantTimeStart","rules":[
                  {
                    required: true,
                    message: '请选择开始时间',
                    trigger: 'blur'
                  }
                ]}},[_c('el-time-picker',{attrs:{"placeholder":"选择开始时间","editable":false,"value-format":"HH:mm:ss","format":"HH:mm:ss"},model:{value:(form.prohibitGrantTimeStart),callback:function ($$v) {_vm.$set(form, "prohibitGrantTimeStart", $$v)},expression:"form.prohibitGrantTimeStart"}})],1),_c('span',[_vm._v(" - ")]),_c('el-form-item',{staticStyle:{"display":"inline-block","margin-bottom":"0"},attrs:{"prop":"prohibitGrantTimeEnd","rules":[
                  {
                    required: true,
                    message: '请选择结束时间',
                    trigger: 'blur'
                  }
                ]}},[_c('el-time-picker',{attrs:{"placeholder":"选择结束时间","editable":false,"value-format":"HH:mm:ss","format":"HH:mm:ss"},model:{value:(form.prohibitGrantTimeEnd),callback:function ($$v) {_vm.$set(form, "prohibitGrantTimeEnd", $$v)},expression:"form.prohibitGrantTimeEnd"}})],1)],1):_vm._e()],1)],1)]}},{key:"limitAge",fn:function(ref){
                var idx = ref.idx;
                var form = ref.form;
return [_c('el-col',[_c('el-form-item',{attrs:{"label":"发放年龄限制","prop":"limitAgeType","rules":[
              {
                required: true,
                message: '请选择发放年龄限制方式',
                trigger: 'change'
              }
            ]}},[_c('el-radio-group',{model:{value:(form.limitAgeType),callback:function ($$v) {_vm.$set(form, "limitAgeType", $$v)},expression:"form.limitAgeType"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("系统设置")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("自定义设置")])],1),(form.limitAgeType)?_c('div',{staticStyle:{"display":"inline-block","margin-left":"30px"}},[_c('el-form-item',{staticStyle:{"display":"inline-block","margin-bottom":"0"},attrs:{"prop":"limitAgeMin","rules":[
                  {
                    required: true,
                    message: '请填写最小年龄',
                    trigger: 'blur'
                  }
                ]}},[_c('el-input-number',{attrs:{"controls":false,"min":0,"max":120,"precision":0,"placeholder":"最小年龄"},model:{value:(form.limitAgeMin),callback:function ($$v) {_vm.$set(form, "limitAgeMin", $$v)},expression:"form.limitAgeMin"}})],1),_c('span',[_vm._v(" - ")]),_c('el-form-item',{staticStyle:{"display":"inline-block","margin-bottom":"0"},attrs:{"prop":"limitAgeMax","rules":[
                  {
                    required: true,
                    message: '请填写最大年龄',
                    trigger: 'blur'
                  },
                  {
                    validator: function (rule, value, callback) {
                      if (value < form.eleMinAmount) {
                        callback(_vm.$error('需要大于最小年龄'))
                      } else {
                        callback()
                      }
                    },
                    trigger: 'blur'
                  }
                ]}},[_c('el-input-number',{attrs:{"controls":false,"min":0,"max":120,"precision":0,"placeholder":"最大年龄"},model:{value:(form.limitAgeMax),callback:function ($$v) {_vm.$set(form, "limitAgeMax", $$v)},expression:"form.limitAgeMax"}}),_vm._v(" 岁")],1)],1):_vm._e()],1)],1)]}},{key:"isRiskNotification",fn:function(ref){
                var idx = ref.idx;
                var form = ref.form;
return [_c('el-col',[_c('el-form-item',{attrs:{"label":"风控通知短信","prop":"isRiskNotification"}},[_c('el-radio-group',{model:{value:(form.isRiskNotification),callback:function ($$v) {_vm.$set(form, "isRiskNotification", $$v)},expression:"form.isRiskNotification"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("关闭")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("开启")])],1),(form.isRiskNotification)?_c('div',{staticStyle:{"display":"inline-block","margin-left":"30px"}},[_c('el-form-item',{staticStyle:{"display":"inline-block","margin-bottom":"0"},attrs:{"prop":"riskSmsTemplate","rules":[
                  {
                    required: true,
                    message: '请选择短信模板',
                    trigger: 'blur'
                  }
                ]}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":""},on:{"change":function($event){return _vm.getSmsContent($event)}},model:{value:(form.riskSmsTemplate),callback:function ($$v) {_vm.$set(form, "riskSmsTemplate", $$v)},expression:"form.riskSmsTemplate"}},_vm._l((_vm.smsOptions),function(item){return _c('el-option',{key:item.id,staticStyle:{"width":"300px"},attrs:{"label":item.smsName + ' ' + item.smsCode,"value":item.id}})}),1),(_vm.smsContent != '')?_c('el-tooltip',{staticStyle:{"margin-left":"10px"},attrs:{"effect":"dark","content":_vm.smsContent}},[_c('i',{staticClass:"el-icon-warning-outline"})]):_vm._e()],1)])],1):_vm._e()],1)],1)]}},{key:"isReturnNotify",fn:function(ref){
                var idx = ref.idx;
                var form = ref.form;
return [_c('el-col',[_c('el-form-item',{attrs:{"label":"退汇短信通知","prop":"isReturnNotify"}},[_c('el-radio-group',{model:{value:(form.isReturnNotify),callback:function ($$v) {_vm.$set(form, "isReturnNotify", $$v)},expression:"form.isReturnNotify"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("关闭")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("开启")])],1),(form.isReturnNotify)?_c('div',{staticStyle:{"display":"inline-block","margin-left":"30px"}},[_c('el-form-item',{staticStyle:{"display":"inline-block","margin-bottom":"0"},attrs:{"prop":"returnSmsTemplate","rules":[
                  {
                    required: true,
                    message: '请选择短信模板',
                    trigger: 'blur'
                  }
                ]}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":""},on:{"change":function($event){return _vm.getReturnSmsContent($event)}},model:{value:(form.returnSmsTemplate),callback:function ($$v) {_vm.$set(form, "returnSmsTemplate", $$v)},expression:"form.returnSmsTemplate"}},_vm._l((_vm.smsReturnOptions),function(item){return _c('el-option',{key:item.id,staticStyle:{"width":"300px"},attrs:{"label":item.smsName + ' ' + item.smsCode,"value":item.id}})}),1),(_vm.smsReturnContent != '')?_c('el-tooltip',{staticStyle:{"margin-left":"10px"},attrs:{"effect":"dark","content":_vm.smsReturnContent}},[_c('i',{staticClass:"el-icon-warning-outline"})]):_vm._e()],1)])],1):_vm._e()],1)],1)]}},{key:"isTransferRemark",fn:function(ref){
                var idx = ref.idx;
                var form = ref.form;
return [_c('el-col',[_c('el-form-item',{attrs:{"label":"打款附言","prop":"isTransferRemark"}},[_c('template',{slot:"label"},[_c('el-tooltip',{attrs:{"effect":"dark","content":"使用支付宝账号收款才能展示附言"}},[_c('i',{staticClass:"el-icon-warning-outline"})]),_vm._v(" 打款附言： ")],1),_c('el-radio-group',{model:{value:(form.isTransferRemark),callback:function ($$v) {_vm.$set(form, "isTransferRemark", $$v)},expression:"form.isTransferRemark"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("关闭")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("开启")])],1),(form.isTransferRemark)?_c('div',{staticStyle:{"display":"inline-block","margin-left":"30px"}},[_c('el-form-item',{staticStyle:{"display":"inline-block","margin-bottom":"0"},attrs:{"prop":"transferRemark","rules":[
                  {
                    required: true,
                    message: '请填写打款附言',
                    trigger: 'blur'
                  }
                ]}},[_c('el-input',{attrs:{"placeholder":"不得超过20字"},model:{value:(form.transferRemark),callback:function ($$v) {_vm.$set(form, "transferRemark", $$v)},expression:"form.transferRemark"}})],1)],1):_vm._e()],2)],1)]}},{key:"isPunchRemark",fn:function(ref){
                var idx = ref.idx;
                var form = ref.form;
return [_c('el-col',[_c('el-form-item',{attrs:{"label":"打款备注","prop":"isPunchRemark"}},[_c('template',{slot:"label"},[_c('el-tooltip',{attrs:{"effect":"dark","content":"目前是争对使用招商银行打款的回单摘要配置"}},[_c('i',{staticClass:"el-icon-warning-outline"})]),_vm._v(" 打款备注： ")],1),_c('el-radio-group',{model:{value:(form.isPunchRemark),callback:function ($$v) {_vm.$set(form, "isPunchRemark", $$v)},expression:"form.isPunchRemark"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("无")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("自定义")]),_c('el-radio',{attrs:{"label":2}},[_vm._v("使用结算单备注")])],1),(form.isPunchRemark == 1)?_c('div',{staticStyle:{"display":"inline-block","margin-left":"30px"}},[_c('el-form-item',{staticStyle:{"display":"inline-block","margin-bottom":"0"},attrs:{"prop":"punchRemark","rules":[
                  {
                    required: true,
                    message: '请填写打款备注',
                    trigger: 'blur'
                  }
                ]}},[_c('el-input',{attrs:{"maxlength":"20","placeholder":"不得超过20字"},model:{value:(form.punchRemark),callback:function ($$v) {_vm.$set(form, "punchRemark", $$v)},expression:"form.punchRemark"}})],1)],1):_vm._e(),(form.isPunchRemark == 2)?_c('div',{staticStyle:{"display":"inline-block","margin-left":"30px"}},[_c('span',[_vm._v("结算单备注超过20个字会被自动截断，建议告知客户")])]):_vm._e()],2)],1)]}}],null,false,3514096072)})],1):_vm._e(),_c('span',{attrs:{"slot":"footer"},slot:"footer"},[(_vm.add == 2)?_c('el-button',{on:{"click":_vm.prevStep}},[_vm._v("上一步")]):_c('el-button',{on:{"click":function($event){_vm.show = false}}},[_vm._v("取 消")]),(_vm.add == 1)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.nextStep}},[_vm._v("下一步")]):_c('el-button',{attrs:{"type":"primary","loading":_vm.btnloadSubmit},on:{"click":_vm.submit}},[_vm._v("保 存")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }