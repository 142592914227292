<template>
  <el-dialog title="要素验证" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="600px">
    <!-- 内容区域 -->
    <FormView ref="vForm" :labels="formLabels" :formConfig="{ labelWidth: '150px' }" @onInputChange="onChange"></FormView>
    <el-alert
      v-if="!loading && result.msg"
      :title="result.msg"
      :type="result.msgCode == 200 || result.msgCode == 0 ? 'success' : 'error'"
      center
      show-icon
      :closable="false"
    >
    </el-alert>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">验 证</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { regular } from '@/configs/validate'
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      loading: false,
      formLabels: [
        {
          cmp: 'select',
          label: '验证类型',
          prop: 'IngredientType',
          options: [
            { key: 1, label: '二要素验证（姓名+身份证）' },
            { key: 2, label: '手机三要素验证（姓名+身份证+手机号）' },
            { key: 3, label: '银行三要素验证（姓名+身份证+银行卡号）' },
            { key: 4, label: '银行四要素验证（姓名+身份证+银行卡号+银行卡预留手机号）' }
          ],
          itemConfig: {
            rules: [{ required: true, message: '请选择验证类型', trigger: 'change' }]
          },
          config: { style: { width: '100%' } }
        },
        {
          cmp: 'input',
          label: '姓名',
          prop: 'UserName',
          itemConfig: {
            rules: [{ required: true, message: '请输入姓名', trigger: 'bulr' }]
          }
        },
        {
          cmp: 'input',
          label: '身份证号',
          prop: 'IdCard',
          itemConfig: {
            rules: [
              { required: true, message: '请输入身份证号', trigger: 'bulr' },
              { pattern: regular.IDCard, message: regular.IDCardMsg, trigger: 'blur' }
            ]
          },
          config: { maxLength: 18 }
        },
        {
          cmp: 'input',
          label: '手机号',
          prop: 'TelNo',
          itemConfig: {
            rules: [
              { required: true, message: '请输入手机号', trigger: 'bulr' },
              { pattern: regular.mobile, message: regular.mobileMsg, trigger: 'blur' }
            ]
          },
          config: { maxLength: 11 },
          show: 'form.IngredientType==2'
        },
        {
          cmp: 'input',
          label: '银行卡号',
          prop: 'CardNo',
          itemConfig: {
            rules: [
              { required: true, message: '请输入银行卡号', trigger: 'bulr' },
              { pattern: regular.bankCard, message: regular.bankCardMsg, trigger: 'blur' }
            ]
          },
          config: { maxLength: 19 },
          show: 'form.IngredientType==3||form.IngredientType==4'
        },
        {
          cmp: 'input',
          label: '银行预留手机号',
          prop: 'TelNo',
          itemConfig: {
            rules: [
              { required: true, message: '请输入银行预留手机号', trigger: 'bulr' },
              { pattern: regular.mobile, message: regular.mobileMsg, trigger: 'blur' }
            ]
          },
          config: { maxLength: 11 },
          show: 'form.IngredientType==4'
        }
      ],
      result: {}
    }
  },
  methods: {
    // 数据清空
    clear() {},
    open(obj, add = false) {
      this.show = true
    },
    onChange() {
      if (this.result.msg) {
        this.result = {}
      }
    },
    // 提交
    async submit() {
      const params = await this.$refs.vForm.getForm()
      if (this.loading) return false
      this.btnloadSubmit = true
      this.loading = true
      // 结果更新
      const res = await this.$api.VerifyIngredient(params).finally(() => {
        this.btnloadSubmit = false
      })
      this.result = res
      this.loading = false
    }
  }
}
</script>
