<template>
  <div class="table-wrap">
    <!-- 搜索框 -->
    <el-card class="search" v-if="!noSearch && searchList.length" shadow="never">
      <!--搜索框的自定义内容-->
      <slot name="ext-search"></slot>
      <el-form :inline="true" :model="searchForm" label-suffix="：" class="search-form" @submit.native.prevent>
        <!-- 参数型搜索项组件 -->
        <template v-for="(item, index) in searchList">
          <ComponentSearchItem
            :key="index"
            v-if="!item.more || searchMore"
            :model="searchForm"
            :value="item.prop"
            v-bind="item"
            @input="updateModelData(item.prop, $event)"
          />
        </template>
        <!-- 搜索按钮 -->
        <div class="search-btn">
          <el-form-item>
            <el-button plain type="primary" icon="el-icon-search" @click="searchTable">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button plain icon="el-icon-refresh-left" @click="resetTable">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button plain icon="el-icon-refresh" @click="refreshTable">刷新</el-button>
          </el-form-item>
          <el-form-item v-if="hasMore">
            <el-button type="text" :icon="!searchMore ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" @click="switchSearch"
              >{{ !searchMore ? '高级' : '简单' }}搜索</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </el-card>
    <!-- 自定义的附加表格 -->
    <slot name="ext-table"></slot>
    <!-- 表格相关按钮 -->
    <div class="btnline">
      <el-form inline>
        <!--按钮列的自定义表格内容-->
        <slot name="btnline-item-left"></slot>
      </el-form>
      <el-form inline>
        <!--按钮列的自定义表格内容-->
        <slot name="btnline-item"></slot>
      </el-form>
    </div>
    <!-- 表格 -->
    <div class="table" :style="{ minHeight: tableMinHeight || '500px' }">
      <el-table ref="xTable" :data="datas" v-bind="tableCfg" v-on="$listeners" v-loading="loading">
        <!-- 多选框 -->
        <el-table-column v-if="selection" type="selection" align="center" width="55" fixed v-bind="selConfig"></el-table-column>
        <!-- 序号列 -->
        <el-table-column v-if="seq" type="index" :label="seqLabel" align="center" :width="seqWidth" fixed> </el-table-column>
        <!-- 参数型表格项组件 -->
        <ComponentTableColumn v-for="(item, idx) in labels" :key="idx" v-bind="item" v-on="$listeners" />
        <!--后置的自定义表格内容-->
        <slot name="table-item"></slot>
      </el-table>
    </div>
    <!-- 页码 -->
    <div class="pagination" v-if="!noPage">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="pageSizeOpts"
        :page-size.sync="pageSize"
        :current-page.sync="currentPage"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
// 表格基础配置项
const DefaultTableCfg = {
  border: true,
  height: '100%'
}
import ComponentTableColumn from './column'
import ComponentSearchItem from './searchItem'
import { excludeEmpty, isArray, deepClone } from '@/utils'
export default {
  name: 'ComponentTable',
  components: { ComponentTableColumn, ComponentSearchItem },
  props: {
    // 数据头列表
    labels: {
      type: Array,
      required: true
    },
    // api数据名
    apiName: {
      type: String,
      required: true
    },
    // 非必填项，有默认值
    // 向下一层的表格数据字段
    resChildName: String,
    // 选择
    selection: {
      type: Boolean,
      default: false
    },
    // 序号部分
    // 是否有序号
    seq: {
      type: Boolean,
      default: true
    },
    // 序号标示，默认#
    seqLabel: {
      type: String,
      default: '#'
    },
    // 序号格子宽度，默认80
    seqWidth: {
      type: Number,
      default: 65
    },
    // 分页部分
    // 取消分页
    noPage: {
      type: Boolean,
      default: false
    },
    // 搜索部分
    // 取消搜索
    noSearch: {
      type: Boolean,
      default: false
    },
    searchList: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 搜索的附加值
    searchExt: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 搜索初始化
    initSearch: {
      type: Object
    },
    // 表格其他配置项
    tableConfig: Object,
    // 表格选框的设置
    selConfig: Object,
    // 禁止首次加载
    disInitLoad: false,
    // 表格
    tableMinHeight: String
  },
  data() {
    return {
      loading: true,
      // 搜索参数
      searchForm: {},
      searchMore: false,
      // 表格参数
      // 数据列表
      datas: [],
      // 分页参数
      currentPage: 1,
      total: 1,
      pageSize: 10,
      pageSizeOpts: [10, 20, 50, 100, 200]
    }
  },
  computed: {
    // 序号标序
    seqIndex() {
      return this.pageSize * (this.currentPage - 1) + 1
    },
    // 表格配置项格式化
    tableCfg() {
      return Object.assign({}, DefaultTableCfg, this.tableConfig)
    },
    // 是否有高级搜索
    hasMore() {
      return !!this.searchList.find((item) => {
        return item.more
      })
    }
  },
  mounted() {
    if (this.initSearch) this.searchForm = deepClone(this.initSearch)
    // 首次加载数据
    if (!this.disInitLoad) this.getTableList()
    else this.loading = false
  },
  methods: {
    isArray,
    // 获取表格列表 reset重返第一页
    async getTableList(reset = false) {
      let params = this.getParams(reset)
      if (!params) return false
      this.$emit('get', params)
      this.loading = true
      const response = await this.$api[this.apiName](params)
      let res = response
      if (this.resChildName) res = response[this.resChildName]
      if (!res) {
        this.datas = []
        this.updatePage({
          current_page: 1,
          total: 0
        })
      } else {
        const { current: current_page, total, list: data } = res
        this.updatePage({
          current_page,
          total
        })
        this.datas = data
      }
      this.loading = false
      this.$emit('geted', response, params)
    },
    // 获取当前搜索参数
    getParams(reset = false) {
      const searchForm = deepClone(this.searchForm)
      // 清除more
      if (!this.searchMore) {
        this.searchList.forEach((item) => {
          if (item.more) {
            if (isArray(item.prop)) {
              searchForm[item.prop[0]] = undefined
              searchForm[item.prop[1]] = undefined
            } else {
              searchForm[item.prop] = undefined
            }
          }
        })
      }
      this.searchList.map((item) => {
        if (item.cmp == 'rangeInput') {
          if (this.searchForm[item.prop[1]] < this.searchForm[item.prop[0]]) {
            this.$message.error(`${item.label}输入范围无效，请重新输入`)
            throw new Error()
          }
        }
      })
      const params = excludeEmpty(Object.assign({ pageIndex: reset ? 1 : this.currentPage, pageSize: this.pageSize }, searchForm, this.searchExt))
      return params
    },
    // 改变当前页码状态
    updatePage(obj) {
      this.currentPage = parseInt(obj.current_page) || 1
      this.total = parseInt(obj.total)
    },
    // 页码尺寸发生变化
    handleSizeChange() {
      this.getTableList()
    },
    // 当前页发生变化
    handleCurrentChange() {
      this.getTableList()
    },
    // 切换搜索
    switchSearch() {
      this.searchMore = !this.searchMore
    },
    // 表单项更新
    updateModelData(key, val) {
      if (isArray(key) && isArray(val)) {
        for (let i in key) {
          this.$set(this.searchForm, key[i], val[i])
        }
      } else {
        this.$set(this.searchForm, key, val)
      }
    },
    // 重置
    async resetTable(refPrams) {
      this.$emit('reset')
      if (this.initSearch) this.searchForm = deepClone(Object.assign({}, this.initSearch, refPrams))
      else this.searchForm = Object.assign({}, refPrams)
      await this.getTableList(true)
      this.$emit('reseted')
    },
    // 刷新
    async refreshTable() {
      this.$emit('refresh')
      await this.getTableList()
      this.$emit('refreshed')
    },
    // 查询
    async searchTable() {
      this.$emit('search')
      await this.getTableList(true)
      this.$emit('searched')
    },
    // 获取当前datas
    getListDatas() {
      return this.datas
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.search {
  margin-bottom: 18px;
  &-form {
    margin-bottom: -18px;
  }
  &-btn {
    display: inline-block;
    vertical-align: top;
  }
}
.btnline {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
</style>
