<template>
  <el-dialog :title="title" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" fullscreen>
    <!-- 内容区域 -->
    <div class="dialog-wrap-full">
      <div class="flex-home" v-if="show">
        <el-col :span="24" style="margin-bottom: 18px">
          <el-button
            @click="
              $refs.AddSign.open({
                mainId: detail.mainId,
                merchantId: detail.merchantId
              })
            "
            >添加</el-button
          >
        </el-col>
        <TableView
          ref="vTable"
          :labels="tableLabels"
          :searchList="searchList"
          apiName="GetMainSupplierInfoPageList"
          :searchExt="{ merchantId: merchantId }"
        >
          <!-- 左侧按钮 -->
          <!-- <el-form-item slot="btnline-item-left">
            <el-button type="primary" @click="uploadContractHandle">上传合同</el-button>
          </el-form-item> -->
          <!-- 操作栏 -->
          <el-table-column slot="table-item" label="操作" width="220" fixed="right">
            <template v-slot="{ row, $index }">
              <el-button type="text" v-if="row.effectiveStatus != 2" @click="invalidHanle(row)">作废</el-button>
              <el-button type="text" v-if="row.effectiveStatus == 2" @click="deleteHandle(row)">删除</el-button>
              <el-button
                type="text"
                v-if="row.signType == 2 && row.effectiveStatus == 1 && row.signState == 2 && row.archivedStatus != 1"
                @click="archiveHandle(row)"
                >归档</el-button
              >
              <el-button
                type="text"
                v-if="row.signType == 2 && row.signState == 2 && row.archivedStatus != 1"
                @click="
                  uploadContractHandle({
                    id: row.id,
                    signType: row.signType,
                    contractName: row.contractUrl,
                    contractName: row.contractTemplateName,
                    againUpload: 1
                  })
                "
                >重新上传</el-button
              >
              <el-button type="text" @click="downContract(row)">下载合同</el-button>
              <el-button
                type="text"
                v-if="row.signType == 2 && row.signState == 1"
                @click="uploadContractHandle({ id: row.id, signType: row.signType })"
                >上传合同</el-button
              >
              <el-button type="text" v-if="row.signState == 1" @click="copyLink(row.signUrl)">复制链接</el-button>
            </template>
          </el-table-column>
        </TableView>
      </div>
    </div>
    <!-- 弹窗 -->
    <!-- <DialogSelectPark ref="DialogSelectPark" @submit="$refs.vTable.refreshTable()"></DialogSelectPark> -->
    <UploadContract ref="UploadContract" @uploaded="$refs.vTable.getTableList()"></UploadContract>
    <AddSign ref="AddSign" @updateList="$refs.vTable.refreshTable()"></AddSign>
    <button ref="CopyBtn" class="copyBtn" :data-clipboard-text="copyText"></button>
  </el-dialog>
</template>

<script>
import { tableMerchantSign } from '@/configs/tables'
import { searchSignManagement } from '@/configs/searches'
import DialogSelectPark from './selectpark'
import UploadContract from './uploadContract.vue'
import AddSign from './addSign.vue'
import Clipboard from 'clipboard'
import { downloadUrl } from '@/utils'
export default {
  name: 'DialogSign',
  components: { DialogSelectPark, UploadContract, AddSign },
  data() {
    return {
      tableLabels: tableMerchantSign,
      searchList: searchSignManagement,
      show: false,
      id: null,
      merchantId: null,
      title: '签约管理',
      detail: {},
      copyText: ''
    }
  },
  methods: {
    clear() {
      this.id = null
      this.merchantId = null
    },
    open(obj) {
      this.detail = Object.assign({}, obj)
      this.clear()
      if (obj && obj.mainId) {
        this.id = obj.mainId
        this.merchantId = obj.merchantId
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    },
    // 上传合同
    uploadContractHandle(data) {
      this.$refs.UploadContract.open(data)
    },
    // 下载合同
    async downContract(row) {
      if(row.contractUrl){
        downloadUrl(row.contractUrl)
      } else {
        this.$message.warning('合同正在生成，请稍后刷新重试')
      }
    },
    // 发送短信
    async sendMsg(row) {
      const res = await this.$api.SendMainSupperSignMsg({
        merchantId: this.merchantId,
        supplierId: row.supplierId
      })
      this.$message.success('发送成功')
    },
    // 删除
    deleteHandle(row) {
      this.$confirm('确认删除当前数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.DeleteBusinessMainSupplierInfo({ id: row.id }).then((res) => {
          if (res) {
            this.$message.success('操作成功')
            this.$refs.vTable.refreshTable()
          }
        })
      })
    },
    // 归档
    archiveHandle(row) {
      this.$confirm('确认将当前合同归档?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.PigeonholeBusinessMainSupplierInfo({ id: row.id }).then((res) => {
          if (res) {
            this.$message.success('操作成功')
            this.$refs.vTable.refreshTable()
          }
        })
      })
    },
    // 作废
    invalidHanle(row) {
      this.$confirm('确认作废当前协议?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.DisuseBusinessMainSupplierInfo({ id: row.id }).then((res) => {
          if (res) {
            this.$message.success('操作成功')
            this.$refs.vTable.refreshTable()
          }
        })
      })
    },
    // 复制
    copy(text, name) {
      const clipboard = new Clipboard('.copyBtn')
      clipboard.on('success', () => {
        this.$notify({
          title: name + '链接已复制到剪切板',
          message: text,
          type: 'success'
        })
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        this.$notify({
          title: '请手动复制' + name + '链接',
          message: text,
          type: 'success'
        })
        // 释放内存
        clipboard.destroy()
      })
      this.$refs.CopyBtn.click()
    },
    copyLink(url, handleName = '签约') {
      if (url) {
        this.copyText = url
        setTimeout(() => {
          this.copy(url, handleName)
        }, 0)
      } else {
        this.$message.warning('链接不存在')
      }
    }
  }
}
</script>
