<template>
  <div>
    <el-dialog :title="'添加' + title" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" top="3vh" width="1200px">
      <el-steps :active="step" align-center style="margin-bottom: 32px">
        <el-step title="客户基本信息"></el-step>
        <el-step title="税源地&账户"></el-step>
      </el-steps>
      <!-- 内容区域 -->
      <div class="dialog-wrap" style="height: 600px">
        <FormView v-show="step == 1" ref="vForm" :labels="fromLabels" :formConfig="{ labelWidth: '200px' }" @onInputChange="onInputChange"></FormView>
        <el-form v-if="step == 2" ref="supplierForm" :model="supplierForm" label-suffix="：" label-width="300px">
          <el-form-item label="业务类型" prop="businessType" :rules="[{ required: true, message: '请选择业务类型', trigger: 'change' }]">
            <el-select v-model="supplierForm.businessType" style="width: 400px" @change="onBusinessChange" disabled>
              <el-option v-for="item in kvBM" :key="item.key" :label="item.label" :value="item.key"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="签约税源地" prop="supplierId" :rules="[{ required: true, message: '请选择所属税源地', trigger: 'change' }]">
            <el-select
              v-model="supplierForm.supplierId"
              :disabled="!supplierForm.businessType"
              style="width: 400px"
              @change="onSupplierChange"
              filterable
              remote
              :remote-method="getOptions"
              :loading="loading"
            >
              <el-option v-for="item in supplierOptions" :key="item.id" :label="'(' + item.supplierCode + ') ' + item.supplierName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="税源地类型">
            <!-- {{supplierForm.supplierType == 1?'自营税源地':'供应商'}} -->
            {{ formatStatus(parkOptions, supplierForm.supplierType) }}
          </el-form-item>
          <el-form-item
            label="支付宝通道"
            style="margin-bottom: 0"
            v-if="supplierForm.supplierType != 2"
            :rules="[{ required: true, message: '请选择支付宝通道', trigger: 'change' }]"
          >
            <div style="display: flex; width: 600px">
              <el-form-item prop="alipayChannelId" :rules="[{ required: true, message: '请选择所支付宝通道', trigger: 'change' }]">
                <el-select
                  v-model="supplierForm.alipayChannelId"
                  :disabled="!supplierForm.supplierId"
                  placeholder="请选择"
                  style="width: 300px"
                  popper-append-to-body
                >
                  <el-option label="不开启" value="000"></el-option>
                  <el-option
                    v-for="item in alipayChannels"
                    :key="item.channelId"
                    :label="supplierName + ' - ' + item.channelName"
                    :value="item.channelId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="supplierForm.alipayChannelId && supplierForm.alipayChannelId != '000'"
                prop="alipayAccount"
                label-width="100px"
                label="充值账户"
                :rules="[{ required: true, message: '请选择支付宝充值账户', trigger: 'change' }]"
              >
                <el-select v-model="supplierForm.alipayAccount" placeholder="请选择" style="width: 300px" popper-append-to-body>
                  <el-option
                    v-for="item in bankChannelOptions"
                    :key="item.channelId"
                    :label="supplierName + ' - ' + item.channelName"
                    :value="item.channelId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item
            label="银行卡通道"
            v-if="supplierForm.supplierType != 2"
            :rules="[{ required: true, message: '请选择银行卡通道', trigger: 'change' }]"
          >
            <div style="display: flex; width: 600px">
              <el-form-item prop="bankChannelId" :rules="[{ required: true, message: '请选择银行卡通道', trigger: 'change' }]">
                <el-select
                  v-model="supplierForm.bankChannelId"
                  :disabled="!supplierForm.supplierId"
                  placeholder="请选择"
                  style="width: 300px"
                  popper-append-to-body
                >
                  <el-option label="不开启" value="000"></el-option>
                  <el-option
                    v-for="item in bankChannels"
                    :key="item.channelId"
                    :label="supplierName + ' - ' + item.channelName"
                    :value="item.channelId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="supplierForm.bankChannelId && supplierForm.bankChannelId != '000'"
                prop="bankAccount"
                label-width="100px"
                label="充值账户"
                :rules="[{ required: true, message: '请选择银行卡充值账户', trigger: 'change' }]"
              >
                <el-select v-model="supplierForm.bankAccount" placeholder="请选择" style="width: 300px" popper-append-to-body>
                  <el-option
                    v-for="item in bankChannelOptions"
                    :key="item.channelId"
                    :label="supplierName + ' - ' + item.channelName"
                    :value="item.channelId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item
            label="供应商通道"
            v-if="supplierForm.supplierType == 2"
            :rules="[{ required: true, message: '请选择供应商通道', trigger: 'change' }]"
          >
            <div style="display: flex; width: 600px">
              <el-form-item prop="providerChannelId" :rules="[{ required: true, message: '请选择供应商通道', trigger: 'change' }]">
                <el-select
                  v-model="supplierForm.providerChannelId"
                  :disabled="!supplierForm.supplierId"
                  placeholder="请选择"
                  style="width: 300px"
                  popper-append-to-body
                >
                  <el-option
                    v-for="item in providerChannels"
                    :key="item.channelId"
                    :label="supplierName + ' - ' + item.channelName"
                    :value="item.channelId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="supplierForm.providerChannelId"
                prop="providerAccount"
                label-width="100px"
                label="充值账户"
                :rules="[{ required: true, message: '请选择供应商充值账户', trigger: 'change' }]"
              >
                <el-select v-model="supplierForm.providerAccount" placeholder="请选择" style="width: 300px" popper-append-to-body>
                  <el-option
                    v-for="item in bankChannelOptions"
                    :key="item.channelId"
                    :label="supplierName + ' - ' + item.channelName"
                    :value="item.channelId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <template v-if="step == 1">
          <el-button type="primary" @click="next">下一步</el-button>
        </template>
        <template v-if="step == 2">
          <el-button @click="step = 1">上一步</el-button>
          <el-button type="primary" @click="submit()" :loading="btnloadSubmit">提交</el-button>
        </template>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { formCreatSelfMerchant } from '@/configs/forms'
import { formatStatus, kvASignState, parkOptions, kvBM } from '@/configs/formatters'
export default {
  data() {
    return {
      btnloadSubmit: false,
      title: '商户',
      step: 1,
      fromLabels: formCreatSelfMerchant,
      show: false,
      form: {},
      supplierOptions: [],
      supplierForm: {},
      supplierName: '',
      sign: 0,
      bankChannels: [],
      alipayChannels: [],
      providerChannels: [],
      bankChannelOptions: [],
      kvASignState,
      parkOptions,
      kvBM,
      loading: false,
      businessType: null
    }
  },
  methods: {
    formatStatus,
    clear() {
      this.step = 1
      this.form = {}
      this.supplierOptions = []
      this.supplierForm = {}
      this.sign = 0
    },
    open(businessType) {
      this.clear()
      this.businessType = businessType
      // 添加
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData({})
      })
    },
    async next() {
      // step1Form更新
      this.form = await this.$refs.vForm.getForm()
      let res = await this.$api.CheckMerchantInfo({
        mobile: this.form.mobile,
        loginName: this.form.loginName,
        contract: this.form.contract,
        attributionChannel: this.form.attributionChannel
      })
      const result = await this.$refs.vForm.validate()
      if (result && res) {
        this.step = 2
        this.supplierForm = { businessType: this.businessType }
        // this.onSupplierChange(null)
        this.getOptions(null)
      }
    },
    onInputChange(val, key) {
      if (!this.id && key == 'creditCode') {
        this.creditCodeChange(val)
      }
    },
    async creditCodeChange(val) {
      const res = await this.$api.GetBusinessMain({ creditCode: val })
      const initObj = {
        isAuthentication: 0,
        mainName: null,
        businessLicense: null,
        remark: null
      }
      // 空处理
      if (!res.id) return this.$refs.vForm.upData(initObj)

      const { isAuthentication, mainName, businessLicense, remark } = res
      const mainId = res.id
      const organizationType = parseInt(res.organizationType)
      const isEntrust = parseInt(res.isEntrust)
      const baseObj = {
        mainId,
        isAuthentication,
        organizationType,
        mainName,
        businessLicense,
        isEntrust,
        remark
      }
      this.$refs.vForm.upData(Object.assign(initObj, baseObj))
    },
    // 根据关键词获取税源地列表
    async getOptions(val) {
      this.loading = true
      const res = await this.$api.GetSupplierInfoByKeyWord({ BusinessType: this.supplierForm.businessType || 0, SupplierName: val })
      this.supplierOptions = res.list
      this.loading = false
    },

    // 改变业务模式
    async onBusinessChange(val) {
      this.getOptions(null)
      this.$set(this.supplierForm, 'supplierId', null)
      this.$set(this.supplierForm, 'supplierType', null)
      this.onSupplierChange(null)
    },

    // 变更税源地
    async onSupplierChange(val) {
      this.$set(this.supplierForm, 'bankChannelId', undefined)
      this.$set(this.supplierForm, 'alipayChannelId', undefined)
      this.$set(this.supplierForm, 'providerChannelId', undefined)
      this.$set(this.supplierForm, 'bankAccount', undefined)
      this.$set(this.supplierForm, 'alipayAccount', undefined)
      this.$set(this.supplierForm, 'providerAccount', undefined)
      this.supplierOptions.forEach((item) => {
        if (item.id == val) {
          this.supplierForm.supplierType = item.supplierType
          this.supplierName = item.supplierName
        }
      })

      if (val) {
        // 获取其通道信息,GrantType 发放类型,1:支付宝,2:银行卡,3:微信,4供应商
        const res = await this.$api.GetAddMerchantBySupplierChannelInfo({
          merchantId: '',
          mainId: this.form.mainId,
          supplierId: val
        })
        this.bankChannels = res.bankChannelList
        this.alipayChannels = res.alipayChannelList
        this.providerChannels = res.providerChannelList
        this.bankChannelOptions = res.rechargeBankChannelList
      } else {
        this.bankChannels = []
        this.alipayChannels = []
        this.providerChannels = []
        this.bankChannelOptions = []
      }
    },
    async submit() {
      this.$refs.supplierForm.validate(async (valid) => {
        if (valid) {
          // 判断通道至少开启一个
          if (this.supplierForm.supplierType != 2 && this.supplierForm.alipayChannelId == '000' && this.supplierForm.bankChannelId == '000')
            return this.$message.warning('至少需要开启一个通道')
          this.btnloadSubmit = true
          // 处理通道信息到channels
          const channels = []
          if (this.supplierForm.supplierType != 2) {
            // 双通道（支付宝、银行卡）转换信息到channels
            if (this.supplierForm.alipayChannelId != '000') {
              channels.push({
                channelId: this.supplierForm.alipayChannelId,
                bankId: this.supplierForm.alipayAccount,
                type: 2
              })
            }
            if (this.supplierForm.bankChannelId != '000') {
              channels.push({
                channelId: this.supplierForm.bankChannelId,
                bankId: this.supplierForm.bankAccount,
                type: 1
              })
            }
          } else {
            // 单通道（供应商）转换信息到channels
            channels.push({
              channelId: this.supplierForm.providerChannelId,
              bankId: this.supplierForm.providerAccount,
              type: 4
            })
          }
          // 获取businessType&&supplierId
          const { businessType, supplierId } = this.supplierForm
          // return
          const res = await this.$api
            .AddMerchantInfo(
              Object.assign(
                {
                  businessType,
                  supplierId,
                  channels
                },
                this.form
              )
            )
            .finally(() => {
              this.btnloadSubmit = false
            })
          if (res) {
            this.$message.success('添加成功')
            this.$emit('submit')
            this.show = false
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.fillTemplate {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 3fr;
  margin-bottom: 10px;
}
</style>
