<template>
  <el-dialog title="查看" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="600px">
    <!-- 内容区域 -->
    <el-descriptions :column="1" size="medium" :labelStyle="{ width: '120px', textAlign: 'right' }">
      <el-descriptions-item label="通道编号">{{ detail.channelCode || '--' }}</el-descriptions-item>
      <el-descriptions-item label="通道名称">{{ detail.channelName || '--' }}</el-descriptions-item>
      <el-descriptions-item label="所属税源地">{{ detail.supplierName || '--' }}</el-descriptions-item>
      <el-descriptions-item label="发放方式"><span v-html="formatStatus(kvGrantType, detail.grantType)"></span></el-descriptions-item>
      <el-descriptions-item label="额度限制">
        <div v-if="!detail.isLimit">关闭</div>
        <template v-else>
          <div style="margin-bottom: 10px" :class="{ delme: !detail.sigleLimit }">
            单笔发放金额不得超过 <span v-format="'#,##0.00'">{{ detail.sigleLimit || 0 }}</span> 元
          </div>
          <div style="margin-bottom: 10px" :class="{ delme: !detail.monthLimit }">
            每人每月发放金额不得超过 <span v-format="'#,##0.00'">{{ detail.monthLimit || 0 }}</span> 元
          </div>
          <div :class="{ delme: !detail.merchantLimit }">
            单个商户发放金额不得超过 <span v-format="'#,##0.00'">{{ detail.merchantLimit || 0 }}</span> 元
          </div>
        </template>
      </el-descriptions-item>
      <el-descriptions-item label="是否拆单">{{ detail.isSplit ? '是' : '否' }}</el-descriptions-item>
      <el-descriptions-item v-if="detail.isSplit" label="拆单金额">{{ detail.splitLimit }}</el-descriptions-item>
      <el-descriptions-item label="配置信息"><el-input v-model="detail.channelConfig" type="textarea" disabled rows="4" :style="{width:'400px'}"></el-input></el-descriptions-item>
      <el-descriptions-item label="备注">{{ detail.channelRemark || '无' }}</el-descriptions-item>
    </el-descriptions>
  </el-dialog>
</template>

<script>
import { formatStatus, kvGrantType } from '@/configs/formatters'
export default {
  data() {
    return {
      id: null,
      show: false,
      detail: {},
      kvGrantType
    }
  },
  methods: {
    formatStatus,
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    async open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 编辑
        this.id = obj.id
        await this.getDetail()
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    },
    async getDetail() {
      const res = await this.$api.GetWorkChannelDetaliInfo({ id: this.id })
      this.detail = res
    }
  }
}
</script>

<style lang="scss" scoped>
.delme {
  text-decoration: line-through;
  opacity: 0.5;
}
</style>
