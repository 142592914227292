<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <tableView ref="onTables" :searchList="searchList" :labels="labels" seqLabel="序号" apiName="GetContractList" @onSwitch="onSwitch">
      <el-form-item slot="btnline-item-left" v-if="$hasAccess('001501')">
        <el-button type="primary" @click="$refs.Detail.open()">添加</el-button>
      </el-form-item>
      <el-table-column label="操作" slot="table-item" width="200px">
        <template v-slot="{ row, $index }">
          <el-button v-if="$hasAccess('001502')" type="text" style="color: #6493fb" @click="$refs.ViewDetail.open(row)">查看</el-button>
          <el-button v-if="$hasAccess('001503') && row.templateType == 1" type="text" style="color: #6493fb" @click="$refs.Detail.open(row)"
            >编辑</el-button
          >
          <el-button v-if="$hasAccess('001504')" type="text" style="color: red" @click="delItem(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </tableView>
    <!-- 弹窗 -->
    <Detail ref="Detail" @updateList="$refs.onTables.refreshTable()"></Detail>
    <ViewDetail ref="ViewDetail"></ViewDetail>
  </div>
</template>

<script>
import { searchContract } from '@/configs/searches.js'
import { tableContract } from '@/configs/tables.js'
import Detail from './detail.vue'
import ViewDetail from './viewDetail.vue'
export default {
  components: { Detail, ViewDetail },
  data() {
    return {
      searchList: searchContract,
      labels: tableContract
    }
  },
  methods: {
    // 表格内的切换事件
    async onSwitch(val, key, row, set) {
      if (key == 'isShow') {
        // if (!this.$hasAccess('007500')) return this.$message.error('您暂无权限禁用/启用')
        // 禁用启用
        const params = { templateId: row.id }
        const res = await this.$api.ShowAndHideContractTemplate(params)
        set()
      }
    },
    // 删除项
    delItem(id) {
      this.$confirm('确认删除此条模板吗?', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.delConteactItem({ templateId: id }).then((res) => {
          if (res) {
            this.$message.success('删除成功')
            this.$refs.onTables.refreshTable()
          }
        })
      })
    }
  }
}
</script>
