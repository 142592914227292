<template>
  <el-dialog title="查看" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
    <!-- 内容区域 -->
    <el-descriptions title="基本信息" border :column="2" :labelStyle="{ width: '120px', textAlign: 'right' }">
      <el-descriptions-item label="添加时间">{{ detail.createTime || '--' }}</el-descriptions-item>
      <el-descriptions-item label="添加人">{{ detail.addPeople || '--' }}</el-descriptions-item>
      <el-descriptions-item label="商户名称">{{ detail.merchantName || '--' }}</el-descriptions-item>
      <el-descriptions-item label="商户编号">{{ detail.merchantCode || '--' }}</el-descriptions-item>
      <el-descriptions-item label="创客姓名">{{ detail.userName || '--' }}<WpView v-if="detail.userName" :id="id" :type="1" /></el-descriptions-item>
      <el-descriptions-item label="手机号">{{ detail.mobile || '--' }}<WpView v-if="detail.mobile" :id="id" :type="1" /></el-descriptions-item>
      <el-descriptions-item label="证件号">{{ detail.idCard || '--' }}<WpView v-if="detail.idCard" :id="id" :type="1" /></el-descriptions-item>
      <el-descriptions-item label="银行卡号">{{ detail.bankNumber || '--' }}</el-descriptions-item>
      <el-descriptions-item label="支付宝号">{{ detail.alipayNumber || '--' }}</el-descriptions-item>
      <el-descriptions-item label="身份证正面照片"><ImageViewer :path="detail.idFontPhoto" /></el-descriptions-item>
      <el-descriptions-item label="身份证反面照片"><ImageViewer :path="detail.idBackPhoto" /></el-descriptions-item>
      <el-descriptions-item v-if="detail.otherFile" label="其他文件" :span="2">
        <span v-if="detail.attachList.length > 0">
          <template v-for="item in detail.attachList">
            <ImageViewer style="padding: 0 8px" v-if="isType(item.fileName)" :key="item.fileName" :path="item.fileUrl" />
            <el-link style="padding: 0 8px" v-else :key="item.fileName" type="primary" :href="item.fileUrl">下载</el-link>
          </template>
        </span>
        <span v-else>无</span>
      </el-descriptions-item>
      <el-descriptions-item label="备注" :span="2">{{ detail.remark || '无' }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions style="margintop: 18px" title="审核信息" :column="2" border :labelStyle="{ width: '120px', textAlign: 'right' }">
      <el-descriptions-item label="审核时间">{{ detail.auditTime || '--' }}</el-descriptions-item>
      <el-descriptions-item label="审核人">{{ detail.auditPeople || '--' }}</el-descriptions-item>
      <el-descriptions-item label="状态">{{ detail.auditState || '--' }}</el-descriptions-item>
      <el-descriptions-item label="理由">{{ detail.refuse || '--' }}</el-descriptions-item>
    </el-descriptions>
  </el-dialog>
</template>

<script>
import { formatStatus, kvOrganizationType, kvIsEntrust, kvIsAuthentication } from '@/configs/formatters'
import ImageViewer from '@/components/tableView/imageViewer'
import WpView from '@/components/wpView'
export default {
  components: { ImageViewer, WpView },
  data() {
    return {
      id: null,
      show: false,
      detail: {},
      kvOrganizationType,
      kvIsEntrust,
      kvIsAuthentication
    }
  },
  methods: {
    formatStatus,
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    async open(obj) {
      this.clear()
      if (obj && obj.merchantId) {
        // 编辑
        this.id = obj.id
        await this.getDetail()
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    },
    async getDetail() {
      const res = await this.$api.GetSystemWhiteInfo({
        id: this.id
      })
      let v = res.attachList.map((item) => {
        return item.fileUrl
      })
      res.otherFile = v
      this.detail = res
    },
    isType(val) {
      let idx = val.indexOf('.')
      const type = val.substring(idx + 1).toLocaleLowerCase()
      return type == 'jpg' || type == 'png'
    }
  }
}
</script>
