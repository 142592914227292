<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <el-alert
      title="温馨提示：转入到支付宝账号单笔不要超过100w元，若一天有多笔转账，每次转入到支付宝额度尽量不要是同一金额"
      type="warning"
      style="margin-bottom:18px"
      :closable="false"
    >
    </el-alert>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetTransferAlipayPageList" :searchList="searchList" :initSearch="{ SearchTxt: fundNo }">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item-left">
        <el-button plain type="primary" icon="el-icon-plus" @click="$refs.Edit.open()">转账到支付宝</el-button>
      </el-form-item>
      <el-form-item slot="btnline-item">
        <el-button plain type="primary" icon="el-icon-download" @click="download" :loading="btnloadDownload">导出</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="100" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button v-if="row.transferSubmitState == 0" type="text" @click="submit(row)">确认到账</el-button>
        </template>
      </el-table-column>
    </TableView>
    <!-- 弹窗 -->
    <Edit ref="Edit" @submit="$refs.vTable.refreshTable()"></Edit>
  </div>
</template>

<script>
import { formatStatus, kvAlipayTransferState, kvAlipayTransferSubmitState } from '@/configs/formatters'
import { downloadExcel } from '@/utils'
import Edit from './edit'
const searchList = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入商户编号/商户名称',
      style: { width: '250px' }
    }
  },
  {
    cmp: 'select',
    label: '状态',
    prop: 'TransferState',
    options: kvAlipayTransferState
  },
  {
    cmp: 'dtpickers',
    label: '申请时间',
    prop: ['TransferTimeBegin', 'TransferTimeEnd']
  },
  {
    cmp: 'select',
    label: '到账确认',
    prop: 'SubmitState',
    options: kvAlipayTransferSubmitState
  },
  {
    cmp: 'input',
    label: '付款账户',
    prop: 'AccountNumber',
    config: {
      placeholder: '请输入付款账户',
      style: { width: '250px' }
    }
  },
]
const tableLabels = [
  { label: '申请时间', prop: 'transferTime', config: { width: 180 } },
  { label: '申请单号', prop: 'transferNo'},
  { label: '税源地', prop: 'supplierName'},
  { label: '商户名称', prop: 'merchantName'},
  { label: '商户号', prop: 'memberCode'},
  { label: '付款账户', prop: 'accountNumber'},
  { label: '收款账户', prop: 'transferAccountNumber'},
  { label: '转账金额', prop: 'transferAmount', cmp: 'money' },
  { label: '备注', prop: 'transferRemark', config: {'show-overflow-tooltip': true}},
  { label: '操作人', prop: 'transferPerson'},
  {
    label: '状态',
    prop: 'transferState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvAlipayTransferState, cellValue)
    }
  },
  {label: '失败原因', prop: 'transferDesc',config: {'show-overflow-tooltip': true}},
  {
    label: '到账确认',
    prop: 'transferSubmitState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvAlipayTransferSubmitState, cellValue)
    }
  },
]
export default {
  components: { Edit },
  data() {
    return {
      btnloadDownload: false,
      searchList: searchList,
      tableLabels: tableLabels
    }
  },
  computed: {
    fundNo() {
      return this.$route.query.fundNo
    }
  },
  methods: {
    async download() {
      this.btnloadDownload = true
      const params = this.$refs.vTable.getParams()
      const res = await this.$api.ExportTransferAlipay(params).finally(() => {
        this.btnloadDownload = false
      })
      downloadExcel(res, `支付宝汇款`)
    },
    //查看
    routeLs(row) {
      switch (row.fundType) {
        case 10:
          this.$router.push(`/financeRecharge?fundNo=${row.fundNo}`)
          break
        case 20:
          this.$router.push(`/financeDisburse?fundNo=${row.fundNo}`)
          break
        case 30:
          this.$router.push(`/financeWithdrawal?fundNo=${row.fundNo}`)
          break
        default:
          break
      }
    },
    // 确认到账
    async submit(row) {
      this.$confirm(`确定当前资金已经到账？`, '提示', {
        confirmButtonText: '已到账',
        cancelButtonText: '未到账',
        type: 'warning',
        distinguishCancelAndClose: true
      }).then(async () => {
        const res = await this.$api.SubmitTransferAlipay({ id: row.id, submitState: 1 })
        this.$message.success('已确认已到账')
        this.$refs.vTable.refreshTable()
      }).catch(async action => {
        if (action == 'cancel') {
          const res = await this.$api.SubmitTransferAlipay({ id: row.id, submitState: 2 })
          this.$message.success('已确认未到账')
          this.$refs.vTable.refreshTable()
        }
      })
    }
  }
}
</script>
