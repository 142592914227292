<template>
  <div v-if="!hidden(item)">
    <template v-if="hasOneShowingChild(item.children, item) && (!onlyOneChild.children || onlyOneChild.noShowingChildren) && !item.alwaysShow">
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)" :noto="onlyOneChild.showInSide">
        <el-menu-item v-if="onlyOneChild.meta" :index="resolvePath(onlyOneChild.path)" :class="{ 'submenu-title-noDropdown': !isNest }">
          <item :icon="onlyOneChild.meta.icon || (item.meta && item.meta.icon)" :title="onlyOneChild.meta.title" />
        </el-menu-item>
      </app-link>
    </template>
    <el-submenu v-else ref="subMenu" :index="resolvePath(item.path)">
      <template slot="title">
        <item v-if="item.meta" :icon="item.meta && item.meta.icon" :title="item.meta.title" />
      </template>
      <side-item
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
        class="nest-menu"
      />
    </el-submenu>
  </div>
</template>

<script>
import path from 'path'
import AppLink from './link'
import Item from './item'
import { isExternal } from '@/utils/validate'
import { hasAccess } from '@/utils'
export default {
  name: 'SideItem',
  components: { AppLink, Item },
  props: {
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    this.onlyOneChild = null
    return {
      tenant: process.env.VUE_APP_BASE_TENANT
    }
  },
  computed: {
    activeName() {
      const route = this.$route
      const { name } = route
      return name
    }
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (this.hidden(item)) {
          return false
        } else {
          this.onlyOneChild = item
          return true
        }
      })
      if (showingChildren.length === 1) {
        return true
      }
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
        return true
      }
      return false
    },
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    },
    hidden(item) {
      // 租户单独判断
      if (this.tenant) {
        return this.tenantHidden(item)
      }
      // 寻找子节点有没有显示的
      if (
        !item.children ||
        item.children.find((it) => {
          return !it.hideInSide && !(it.meta && !hasAccess(it.meta.ruleName))
        })
      ) {
        // 有子显示
        if (item.meta && !hasAccess(item.meta.ruleName)) return true
        if (item.showInSide) return item.name != this.activeName
        return item.hideInSide
      } else {
        // 无子显示
        return true
      }
    },
    // 租户显示判断
    tenantHidden(item) {
      // 寻找子节点有没有显示的
      if (
        !item.children ||
        item.children.find((it) => {
          return !it.hideInSide && !(it.meta && !it.meta.tenantShow)
        })
      ) {
        // 有子显示
        if (item.meta && !item.meta.tenantShow) return true
        if (item.showInSide) return item.name != this.activeName
        return item.hideInSide
      } else {
        // 无子显示
        return true
      }
    }
  }
}
</script>
