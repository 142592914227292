<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView
      ref="vTable"
      :labels="tableLabels"
      apiName="GetWorkPlanPageList"
      :searchList="searchList"
      :searchExt="{ AuditState }"
      :selection="true"
      @selection-change="selChange"
      :selConfig="{ selectable }"
      @geted="onTableGeted"
    >
      <!-- 附加栏 -->
      <div slot="ext-search" style="margin-bottom: 18px">
        <el-radio-group v-model="AuditState" @change="onAuditStateChange">
          <el-radio-button :label="null">全部</el-radio-button>
          <el-radio-button :label="1">未发起（{{ searchCounts.notStartCount || 0 }}）</el-radio-button>
          <el-radio-button :label="2">审核中（{{ searchCounts.auditingCount || 0 }}）</el-radio-button>
          <el-radio-button :label="3">审核通过（{{ searchCounts.auditPassCount || 0 }}）</el-radio-button>
          <el-radio-button :label="4">审核未通过（{{ searchCounts.auditNotPassCount || 0 }}）</el-radio-button>
        </el-radio-group>
      </div>
      <!-- 附加栏 -->
      <!-- <el-card slot="ext-table" shadow="never" style="margin-bottom: 18px; flex-shrink: 0">
        <el-descriptions :column="4" :colon="false" size="medium" style="margin-bottom: -10px">
          <el-descriptions-item label="累计提交金额"
            ><b v-format="'#,##0.00'">{{ summary.accuIssueAmount || 0 }}</b></el-descriptions-item
          >
          <el-descriptions-item label="累计人数"
            ><b v-format="'#,##0.00'">{{ summary.accuPersonCount || 0 }}</b></el-descriptions-item
          >
          <el-descriptions-item label="累计结算金额"
            ><b v-format="'#,##0.00'">{{ summary.accuSettleAmount || 0 }}</b></el-descriptions-item
          >
        </el-descriptions>
      </el-card> -->
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item-left" v-if="$hasAccess('000704')">
        <span class="v-sel-info">已选择 {{ multipleSelection.length || 0 }} 项</span>
        <el-button plain type="primary" @click="multipleCheck">批量审核</el-button>
      </el-form-item>
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="100" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button v-if="$hasAccess('000701')" type="text" @click="$refs.DialogInfo.open(row)">查看</el-button>
          <el-button v-if="$hasAccess('000704') && row.auditState == 2" type="text" @click="$refs.DialogCheck.open(row)">审核</el-button>
        </template>
      </el-table-column>
    </TableView>
    <DialogInfo ref="DialogInfo" />
    <DialogBatchCheck ref="DialogBatchCheck" @submit="$refs.vTable.refreshTable()" />
    <DialogCheck ref="DialogCheck" @submit="$refs.vTable.refreshTable()" />
  </div>
</template>

<script>
import { tableWorkPlanCheck } from '@/configs/tables'
import { searchWorkPlanCheck } from '@/configs/searches'
import DialogInfo from './info'
import DialogBatchCheck from './batchCheck'
import DialogCheck from './check'
export default {
  components: { DialogInfo, DialogBatchCheck, DialogCheck },
  data() {
    return {
      tableLabels: tableWorkPlanCheck,
      searchList: searchWorkPlanCheck,
      multipleSelection: [],
      summary: {},
      searchCounts: {},
      AuditState: null
    }
  },
  methods: {
    selectable(row) {
      // return true
      if (row.auditState == 2) return true
      return false
    },
    selChange(val) {
      this.multipleSelection = val
    },
    onAuditStateChange() {
      this.$refs.vTable.searchTable()
    },
    async onTableGeted(response, params) {
      const res = await this.$api.GetWorkPlanPageListSummaryData(params)
      const { accuIssueAmount, accuPersonCount, accuSettleAmount, ...searchCounts } = res
      if (!params.AuditState) this.searchCounts = searchCounts
      this.summary = { accuIssueAmount, accuPersonCount, accuSettleAmount }
    },
    multipleCheck() {
      if (!this.multipleSelection.length) return this.$message.warning('请选择需要批量处理的数据')
      // 判断商户一致性
      const mainCode = this.multipleSelection[0].mainCode
      const otherOne = this.multipleSelection.find((item) => {
        return item.mainCode != mainCode
      })
      if (otherOne) return this.$message.warning('请选择同一商户')
      this.$refs.DialogBatchCheck.open(this.multipleSelection)
    }
  }
}
</script>
