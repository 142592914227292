const title = process.env.VUE_APP_TITLE

/**
 * 设置title
 */
export const setTitle = (name, hasConfig = false) => {
  let resTitle = name
  if (hasConfig) {
    resTitle = title + '-' + name
  }
  document.title = resTitle
}

/**
 * 获取title
 */
export const getTitle = (disConfig = false) => {
  if (disConfig) {
    return document.title
  }
  return document.title
}
