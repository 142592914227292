<template>
  <el-dialog title="隐私设置" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="700px">
    <div v-if="show">
      <FormView ref="vForm" :labels="fromLabels" :formConfig="{ labelWidth: '200px' }"></FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="submit()" :loading="btnloadSubmit">提交</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      fromLabels: [
        {
          cmp: 'radio',
          label: '创客列表照片信息',
          prop: 'isOpen',
          options: [
            { key: false, label: '不可查看' },
            { key: true, label: '可查看' }
          ],
          itemConfig: {
            rules: [{ required: true, message: '请选择是否可查看', trigger: 'change' }]
          }
        }
      ]
    }
  },
  methods: {
    clear() {},
    async open(obj) {
      if (obj && obj.merchantId) {
        this.id = obj.merchantId
        const res = await this.$api.MerchantSwitchQuery({ merchantId: obj.merchantId, swichType: 1 })
        this.show = true
        this.$nextTick(() => {
          this.$refs.vForm.fillData({
            isOpen: res.isOpen
          })
        })
      } else {
        this.$message.error('无效的信息')
      }
    },
    async submit() {
      const form = await this.$refs.vForm.getForm()
      this.btnloadSubmit = true
      const res = await this.$api.MerchantSwitchSave({ merchantId: this.id, type: 1, ...form }).finally(() => {
        this.btnloadSubmit = false
      })
      this.$message.success('隐私设置成功')
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
