<template>
  <el-dialog title="查看" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" fullscreen>
    <!-- 内容区域 -->
    <div class="dialog-wrap-full" v-if="show">
      <el-descriptions title="基本信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="审核状态">{{ detail.verifyState || '无' }}</el-descriptions-item>
        <el-descriptions-item label="审核时间">{{ detail.verifyTime || '无' }}</el-descriptions-item>
        <el-descriptions-item label="申请编号">{{ detail.applyNumber || '--' }}</el-descriptions-item>
        <el-descriptions-item label="申请时间">{{ detail.createTime || '--' }}</el-descriptions-item>
        <el-descriptions-item label="商户名称">{{ detail.merchantName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="商户号">{{ detail.memberCode || '无' }}</el-descriptions-item>
        <el-descriptions-item label="认证企业">{{ detail.mainName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="开票类型">{{ invoicingSourceList[detail.invoicingSource] || '无'}}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="发票内容" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="发票类型">{{ detail.invoiceType || '无' }}</el-descriptions-item>
        <el-descriptions-item label="开票税目">{{ detail.invoiceItem || '无' }}</el-descriptions-item>
        <el-descriptions-item label="申请金额"
          ><span v-format="'#,##0.00'">{{ detail.applyAmount || 0 }}</span> 元</el-descriptions-item
        >
        <el-descriptions-item label="合并笔数"
          >{{ detail.invoicingSource != 2 ? detail.planCount || '无' : '无' }}
          <el-link type="primary" v-if="detail.planCount && detail.invoicingSource != 2" style="margin-left: 30px; margin-top: -2px" @click="$refs.Plan.open({ InvoiceId: detail.id, PlanType: 1, detail })"
            >查看</el-link
          ></el-descriptions-item
        >
        <el-descriptions-item label="平账结算单"
          >{{ detail.planDSOCount || '无' }}
          <el-link type="primary" style="margin-left: 30px; margin-top: -2px" @click="$refs.Plan.open({ InvoiceId: detail.id, PlanType: 2, detail })"
            >查看</el-link
          ></el-descriptions-item
        >
        <el-descriptions-item :span="1" label="备注">{{ detail.remark || '无' }}</el-descriptions-item>
        <el-descriptions-item label="退汇金额"
          ><span v-format="'#,##0.00'">{{ detail.returnAmount || 0 }}</span> 元</el-descriptions-item
        >
        <el-descriptions-item label="税后金额">
          <span v-format="'#,##0.00'">{{ detail.finalPayingAmount || 0 }}</span> 元
          <el-tooltip effect="dark" placement="top" content="税后金额-已开票的退汇税后金额">
            <i class="el-icon-question"></i>
          </el-tooltip>
        </el-descriptions-item>
        <el-descriptions-item label="服务费">
          <span v-format="'#,##0.00'">{{ detail.serviceFee || 0 }}</span> 元
          <el-tooltip effect="dark" placement="top" content="服务费-已开票的退汇服务费">
            <i class="el-icon-question"></i>
          </el-tooltip>
        </el-descriptions-item>
        <el-descriptions-item label="开票金额"
          ><span v-format="'#,##0.00'">{{ detail.invoiceAmount || 0 }}</span> 元</el-descriptions-item
        >
      </el-descriptions>
        
      <el-divider></el-divider>
      <el-descriptions title="抬头信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="抬头名称">{{ detail.riseName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="纳税人识别号">{{ detail.taxPayerNumber || '无' }}</el-descriptions-item>
        <el-descriptions-item label="单位地址">{{ detail.unitAddress || '无' }}</el-descriptions-item>
        <el-descriptions-item label="电话号码">{{ detail.phone || '无' }}</el-descriptions-item>
        <el-descriptions-item label="开户银行">{{ detail.openBank || '无' }}</el-descriptions-item>
        <el-descriptions-item label="银行账户">{{ detail.bankAccount || '无' }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="收件人信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="收件人姓名">{{ detail.inboxName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="收件人电话">{{ detail.inboxPhone || '无' }}</el-descriptions-item>
        <el-descriptions-item label="选择区域">{{ detail.inboxArea || '无' }}</el-descriptions-item>
        <el-descriptions-item label="详细地址">{{ detail.inboxAddress || '无' }}</el-descriptions-item>
        <el-descriptions-item label="邮箱">{{ detail.inboxEmail || '无' }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="快递信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="快递名称">{{ detail.expressName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="快递单号">{{ detail.expressNo || '无' }}</el-descriptions-item>
        <el-descriptions-item label="快递附件">
          <el-link v-if="detail.filePath" type="primary" :href="detail.filePath" target="_blank"
            >{{ detail.fileName || '查看文件' }}<i class="el-icon-view el-icon--right"></i>
          </el-link>
          <span v-else>无</span>
        </el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <div class="el-descriptions__header">
        <div class="el-descriptions__title">退汇订单</div>
        <div class="el-descriptions__extra"></div>
      </div>
      <TableView
        ref="vTable"
        :labels="tableLabels"
        apiName="GetInvoiceDetailReturnList"
        :searchExt="{ InvoiceId: id }"
        tableMinHeight="0px"
        :tableConfig="{ height: '' }"
      ></TableView>
    </div>
    <Plan ref="Plan" />
  </el-dialog>
</template>

<script>
import { formatStatus, formatStatusHasColor, kvBillState, kvDistributionState } from '@/configs/formatters'
import { tableInvoiceReturn } from '@/configs/tables'
import Plan from './plan'

let invoicingSourceList = {
  0: '按结算单开票',
  1: '按结算明细开票',
  2: '按金额开票',
}
export default {
  components: { Plan },
  data() {
    return {
      id: null,
      show: false,
      detail: {},
      tableLabels: tableInvoiceReturn,
      invoicingSourceList
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 查看
        this.id = obj.id
        this.show = true
        this.detail = obj
      } else {
        this.$message.error('无效的信息')
      }
    }
    // async getDetail() {
    //   const res = await this.$api.GetBillingInfoDetail({ billId: this.id })
    //   this.detail = res
    // }
  }
}
</script>
