<template>
  <el-dialog title="新建校验批次" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" top="8vh" width="600px">
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 300px">
      <FormView ref="vForm" :labels="fromLabels" :formConfig="{ labelWidth: '140px' }" @onInputChange="selectChange">
        <template v-slot:operation>
          <el-col>
            <el-form-item>
              <el-button plain type="primary" @click="spotCheckClick">抽查</el-button>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item>
              <span slot="label"><span style="color: #f56c6c"> * </span>抽查人数：</span>
              {{ count }} 人
            </el-form-item>
          </el-col>
        </template>
      </FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :disabled="count <= 0" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      fromLabels: formPeolple,
      count: 0
    }
  },

  mounted() {},
  methods: {
    async open() {
      this.show = true
      this.getMerchantOptions()
      this.$nextTick(() => {
        this.$refs.vForm.fillData({})
      })
    },

    // 获取税源地列表
    async getMerchantOptions() {
      let v = []
      let result = await this.$api.GetMerchantInfoCodeAndName()
      if (result.list.length < 0) {
        return false
      }
      result.list.map((item) => {
        v.push({
          key: item.merchantId,
          label: `【${item.memberCode}】${item.merchantName}`
        })
      })
      this.fromLabels.map((item) => {
        if (item.prop === 'merchantId') {
          item.options = v
        }
      })
    },

    async spotCheckClick() {
      const result = await this.$refs.vForm.validate()
      if (result) {
        let form = await this.$refs.vForm.getForm()
        let res = await this.$api.GetCheckCount(form)
        if (res) {
          if (res.verifyCount == 0) {
            this.$message.warning('抽查人数为0，无法新建')
          }
          this.count = res.verifyCount
        }
      }
    },

    selectChange(key, prop) {
      this.count = 0
      if (prop === 'payTimeLimit') {
        if (key == 0) this.$refs.vForm.upData({ payTimeEnd: null, payTimeBegin: null }, true)
        for (let item of this.fromLabels) {
          if (item.cmp === 'dtpickers') {
            item.itemConfig.rules[0].required = Boolean(parseInt(key))
            break
          }
        }
      }
    },

    // 提交
    async submit() {
      // 结果更新
      const result = await this.$refs.vForm.validate()
      if (result) {
        let form = await this.$refs.vForm.getForm()
        form.verifyCount = this.count
        this.btnloadSubmit = true
        let res = await this.$api.CreatedVerifyPlan(form).finally(() => {
          this.btnloadSubmit = false
        })
        if (res) {
          this.$message.success('新建成功')
          this.$emit('submit')
          this.show = false
        }
      }
    }
  }
}

const formPeolple = [
  {
    cmp: 'select',
    label: '商户名称',
    prop: 'merchantId',
    col: 22,
    options: [],
    itemConfig: {
      rules: [{ required: true, message: '请选择商户', trigger: 'blur' }]
    },
    config: {
      placeholder: '请选择',
      filterable: true,
      style: { width: '100%' }
    }
  },
  {
    cmp: 'radio',
    label: '打款完成时间',
    prop: 'payTimeLimit',
    col: 22,
    itemConfig: {
      rules: [{ required: true, message: '请选择打款完成时间', trigger: 'blur' }]
    },
    options: [
      {
        key: '0',
        label: '不限制'
      },
      {
        key: '99',
        label: '自定义'
      }
    ]
  },
  {
    cmp: 'dtpickers',
    label: '',
    show: 'form.payTimeLimit == 99',
    prop: ['payTimeBegin', 'payTimeEnd'],
    col: 22,
    itemConfig: {
      rules: [{ required: true, message: '请选择自定义时间', trigger: 'blur' }]
    }
  },
  {
    cmp: 'slot',
    name: 'operation'
  }
]
</script>
