// 通用formatStatus
export function formatStatus(kvs, value, not = '未知') {
  const result = kvs.find((item) => {
    return item.key == parseInt(value)
  })
  if (result) return result.label
  return not
}
// 带颜色的formatStatus
export function formatStatusHasColor(kvs, value, not = '未知') {
  const result = kvs.find((item) => {
    return item.key == parseInt(value)
  })
  if (result) return `<span${result.color ? ' style="color:' + result.color + ';"' : ''}>${result.label}</span>`
  return not
}
//时间戳转换
export function timestampToTime(timestamp, type) {
  var date = new Date(parseInt(timestamp) * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear()
  var M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  switch (type) {
    case 'hm':
      return h + ':' + m
      break
    case 'YMD':
      return Y + '.' + M + '.' + D
    case 'Y-M-D':
      return Y + '-' + M + '-' + D
    case 'MD':
      return M + '.' + D
    case 'NewYMD':
      return '' + Y + M + D
    default:
      return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
      break
  }
}
// TableTemplate状态
export const kvTableTemplateStatus = [
  { key: 0, label: '状态0', color: '#f56c6c' },
  { key: 1, label: '状态1' }
]

// 业务类型
export const kvFundType = [
  { key: 10, label: '充值' },
  { key: 20, label: '交易支出' },
  { key: 30, label: '提现' },
  { key: 40, label: '内部划入' },
  { key: 50, label: '内部划出' },
  { key: 60, label: '订单退汇' }
]

// 充值状态
export const kvRechargeStatus = [
  { key: 1, label: '待上账', color: '#67c23a' },
  { key: 2, label: '失败', color: '#f56c6c' },
  { key: 3, label: '上账中' },
  { key: 4, label: '已上账', color: '#D9B300' }
]

// 充值类型
export const kvRechargeType = [
  { key: 1, label: '充值' },
  { key: 2, label: '内部划入' }
]

// 异名汇款状态
export const kvExceptionState = [
  { key: 0, label: '未处理' },
  { key: 1, label: '已处理' }
]

// 异名汇款结果
export const kvExceptionResult = [
  { key: 1, label: '订单退汇' },
  { key: 2, label: '充值退回' },
  { key: 3, label: '内部划入' },
  { key: 4, label: '确认充值' },
  { key: 99, label: '无需处理' }
]

// 支付宝汇款状态
export const kvAlipayTransferState = [
  { key: 0, label: '待打款' },
  { key: 1, label: '打款中' },
  { key: 2, label: '成功' },
  { key: 3, label: '失败' }
]
// 支付宝汇款到账确认
export const kvAlipayTransferSubmitState = [
  { key: 0, label: '待确认' },
  { key: 1, label: '已到账' },
  { key: 2, label: '未到账' }
]

// 划转状态
export const kvDepositState = [
  { key: 0, label: '未划出' },
  { key: 1, label: '调账中' },
  { key: 2, label: '成功' },
  { key: 3, label: '失败' }
]

// 打款状态
export const kvPayState = [
  { key: 1, label: '待打款' },
  { key: 2, label: '打款中' },
  { key: 3, label: '打款成功' },
  { key: 4, label: '打款失败' },
  { key: 5, label: '拒绝打款', color: '#f56c6c' },
  { key: 99, label: '挂起', color: '#e6a23c' },
  { key: 98, label: '待确认', color: '#f56c6c' }
]

// 提现状态
export const kvWithdrawState = [
  { key: 0, label: '待审核' },
  { key: 1, label: '审核通过' },
  { key: 2, label: '审核不通过' },
  { key: 3, label: '未提现' },
  { key: 4, label: '提现中' },
  { key: 5, label: '提现成功' },
  { key: 6, label: '提现失败' }
]
// 提现记录搜索
export const kvWithdrawSearchState = [
  { key: 0, label: '待审核' },
  { key: 2, label: '审核不通过' },
  { key: 4, label: '提现中' },
  { key: 5, label: '提现成功' },
  { key: 6, label: '提现失败' }
]

// 创客列表
export const kvTableWorkPeopleContract = [
  { key: 0, label: '待签约' },
  { key: 1, label: '已签约' },
  { key: 2, label: '签约失败' }
]

// 结算单打款状态
export const kvWorkPlanPayState = [
  { key: 1, label: '未打款' },
  { key: 2, label: '打款中' },
  { key: 3, label: '打款成功' },
  { key: 4, label: '打款失败' },
  { key: 5, label: '拒绝打款' },
  { key: 98, label: '待确认' },
  { key: 99, label: '挂起' }
]

// 认证状态
export const kvAuthState = [
  { key: 0, label: '未认证' },
  { key: 1, label: '已认证' }
]

// 签约类型
export const kvContractMode = [
  { key: 10, label: '静默签约' },
  { key: 20, label: '活体电签' },
  { key: 30, label: '快捷签约' }
]

// 静默签约方案
export const kvContractScheme10 = [{ key: 10, label: '创客信息校验' }]
// 活体签约方案
export const kvContractScheme20 = [
  { key: 10, label: '三要素标准方案' },
  { key: 20, label: '三要素补充方案' }
]
// 快捷签约方案
export const kvContractScheme30 = [
  { key: 10, label: '三要素+身份证照' },
  { key: 20, label: '三要素' }
]

// 结算单列表审核状态
export const kvWorkPlanAuditState = [
  { key: 1, label: '未审核' },
  { key: 2, label: '审核中' },
  { key: 3, label: '审核通过', color: '#67c23a' },
  { key: 4, label: '审核不通过', color: '#f56c6c' }
]

// 结算单列表发放状态
export const kvWorkPlanGrantState = [
  { key: 1, label: '未发放' },
  { key: 2, label: '待发放' },
  { key: 3, label: '发放中' },
  { key: 4, label: '发放完成' },
  { key: 5, label: '拒绝发放' }
]

export const kvPaymentChannel = [
  { key: '银行/银行卡', label: '银行/银行卡' },
  { key: '支付宝', label: '支付宝' },
  { key: '微信', label: '微信' }
]

// 发票审核状态
export const kvVerifyState = [
  { key: 1, label: '未审核' },
  { key: 2, label: '审核中' },
  { key: 3, label: '审核通过', color: '#67c23a' },
  { key: 4, label: '审核不通过', color: '#f56c6c' }
]

// 开票状态
export const kvBillState = [
  { key: 1, label: '未开票' },
  { key: 2, label: '待开票' },
  { key: 3, label: '开票中' },
  { key: 4, label: '已开票', color: '#67c23a' },
  { key: 5, label: '开票失败', color: '#f56c6c' }
]

// 配送状态
export const kvDistributionState = [
  { key: 1, label: '未配送' },
  { key: 2, label: '已配送' }
]

// 发放类型
export const kvGrantType = [
  { key: 1, label: '支付宝' },
  { key: 2, label: '银行卡' },
  { key: 3, label: '微信' },
  { key: 4, label: '供应商' }
  // { key: 5, label: '供应商银行卡' },
  // { key: 6, label: '供应商支付宝' }
]

// 账户类型
export const kvAccountType = [
  { key: 1, label: '银行卡' },
  { key: 2, label: '支付宝' },
  { key: 3, label: '微信' }
]

// 签约状态
export const kvASignState = [
  { key: 0, label: '未生成' },
  { key: 1, label: '未签约' },
  { key: 2, label: '已签约' }
]

// 组织类型
export const kvOrganizationType = [
  { key: 0, label: '企业' },
  { key: 1, label: '政府/事业单位' },
  { key: 2, label: '其他组织' },
  { key: 3, label: '个体工商户' }
]

// 实际操作人
export const kvIsEntrust = [
  { key: 0, label: '法定代表人' },
  { key: 1, label: '委托代理人' }
]

// 类型
export const signType = [
  { key: 1, label: '自营税源地' },
  { key: 2, label: '供应商' }
]

// 税源地认证状态
export const kvIsAuthentication = [
  { key: 0, label: '未认证' },
  { key: 1, label: '已认证' }
]
// 税源地认证状态
export const parkOptions = [
  { key: null, label: '全部' },
  { key: 1, label: '自营税源地' },
  { key: 2, label: '供应商' }
]
// 税源地业务模式
export const parkBM = [
  { key: 1, label: '委托代征' },
  { key: 2, label: '个体户' }
]
// 业务类型
export const kvBM = [
  { key: 1, label: '自然人业务' },
  { key: 2, label: '个体户业务' }
]
// 税源地可开票类型
export const kvSyncInvoiceType = [
  { key: 1, label: '增值税专用发票' },
  { key: 2, label: '增值税普通发票' },
  { key: 3, label: '增值税专用电子发票' },
  { key: 4, label: '增值税普通电子发票' }
]

// 要素效验状态
export const charmStatus = [
  { key: '', label: '全部' },
  { key: 1, label: '二要素效验' },
  { key: 2, label: '三要素效验' },
  { key: 3, label: '银行卡三素效验' },
  { key: 4, label: '银行卡四素效验' }
]

// 模板类型
export const kvTemplateType = [
  { key: 1, label: '主协议' },
  { key: 2, label: '补充协议' },
  { key: 3, label: '电签协议' },
  { key: 99, label: '其他协议' }
]
// 来源
export const sourceType = [
  { key: '系统商务', label: '系统商务' },
  { key: '开放平台', label: '开放平台' }
]

// 结算单列表审核状态
export const kvPeopleListStatus = [
  { key: 1, label: '全部' },
  { key: 2, label: '待审核' },
  { key: 3, label: '审核通过' },
  { key: 4, label: '审核不通过' }
]
// 税源地签约状态
export const kvSignType = [
  { key: 0, label: '未签约' },
  { key: 1, label: '线上签约' },
  { key: 2, label: '线下签约' }
]
// 税源地签约状态
export const kvSignOptions = [
  { key: null, label: '全部' },
  { key: false, label: '未签约' },
  { key: true, label: '已签约' }
]
export const taxState = [
  { label: '未导入', key: 0 },
  { label: '已导入', key: 1 }
]
// 角色状态
export const kvRoleEenable = [
  { key: 0, label: '启用' },
  { key: 1, label: '禁用' }
]

// 授权状态
export const authorization = [
  { key: 0, label: '未授权' },
  { key: 1, label: '已授权' }
]

// 公告类型
export const kvNoticeType = [
  { key: 1, label: '系统更新' },
  { key: 2, label: '放假通知' },
  { key: 3, label: '打款时间通知' },
  { key: 99, label: '其他通知' }
]

// 公告发布方式
export const kvNoticeIssueMode = [
  { key: 1, label: '立即发送' },
  { key: 2, label: '定时发送' }
]

// 公告发布状态
export const kvNoticeIssueState = [
  { key: 0, label: '草稿' },
  { key: 1, label: '未发布' },
  { key: 2, label: '已发布' }
]

// 生效状态
export const effectiveState = [
  { key: 0, label: '未生效' },
  { key: 1, label: '已生效' },
  { key: 2, label: '已作废' }
]
// 协议类型
export const agreementType = [
  { key: 1, label: '主协议' },
  { key: 2, label: '补充协议' },
  { key: 3, label: '其他' }
]
// 归档
export const archivedStatus = [
  { key: 0, label: '未归档' },
  { key: 1, label: '已归档' }
]
// 商户管理 - 签约状态
// 税源地签约状态
export const merchantsSignState = [
  { key: 0, label: '未生成' },
  { key: 1, label: '未签约' },
  { key: 2, label: '已签约' }
]

// 白名单列表 - 审核状态
// 发票审核状态
export const whiteAuditState = [
  { key: 0, label: '待审核' },
  { key: 1, label: '已生效' },
  { key: 2, label: '审核不通过' }
]

// 接单列表
export const orderAuditState = [
  { key: null, label: '全部' },
  { key: 0, label: '待审核' },
  { key: 1, label: '进行中' },
  { key: 2, label: '已完成' },
  { key: 3, label: '审核不通过' }
]
// 审核状态
export const kvRechargeAuditStatus = [
  { key: 0, label: '待审核' },
  { key: 1, label: '审核通过' },
  { key: 2, label: '审核不通过' }
]
//交付物审核
export const kvAuditState = [
  { key: 0, label: '待审核' },
  { key: 1, label: '审核通过' },
  { key: 2, label: '审核不通过' }
]
//交付物上传状态
export const kvAuditStatus = [
  { key: 0, label: '待上传' },
  { key: 1, label: '已上传' },
  { key: 2, label: '已退回' }
]
//交付物/验收单上传状态
export const kvSearchUploadStatus = [
  { key: 0, label: '待上传' },
  { key: 1, label: '已上传' }
]
//交付物/验收单审核状态
export const kvSearchAuditStatus = [
  { key: 0, label: '待审核' },
  { key: 1, label: '审核中' },
  { key: 2, label: '审核通过' },
  { key: 3, label: '已退回' }
]

//接单状态
export const kvState = [
  { key: 0, label: '待确认' },
  { key: 1, label: '进行中' },
  { key: 2, label: '已完成' },
  { key: 3, label: '已拒绝' }
]
//业务类型
export const kvBussinessType = [
  { key: null, label: '全部' },
  { key: 0, label: '充值' },
  { key: 1, label: '交易发放' },
  { key: 2, label: '提现发放' }
]
//签约类型
export const kvSignContractType = [
  { label: '商户', key: 1 },
  { label: '创客', key: 2 },
  { label: '税源地', key: 3 },
  { label: '其他', key: 99 }
]
// 开户方式
// type:bank aplipay provider
export const kvOpenMode = [
  { key: 1, label: '自动开户-银行卡' },
  // { key: 2, label: '自动开户-支付宝' },
  { key: 3, label: '手动开户-银行卡' },
  { key: 4, label: '手动开户-支付宝' }
]
// 业务确认单-上传状态
export const kvBusinessUploadState = [
  { key: 0, label: '待上传' },
  { key: 1, label: '已上传' }
]
// 业务确认单-审核状态
export const kvBusinessAuditState = [
  { key: 0, label: '待审核' },
  { key: 1, label: '审核中' },
  { key: 2, label: '审核通过' },
  { key: 3, label: '退回' }
]
// 业务确认单-状态
export const kvBusinessState = [
  { key: null, label: '全部' },
  { key: 0, label: '待确认' },
  { key: 1, label: '已确认' },
  { key: 2, label: '已通过' },
  { key: 3, label: '已驳回' }
]
// 业务确认单-确认方式
export const kvBusinessWayState = [
  { key: 0, label: '线上确认' },
  { key: 1, label: '盖章确认' }
]

// 商务状态
export const kvEmployeeJobState = [
  { key: 0, label: '在职' },
  { key: 1, label: '离职' }
]

// 渠道类型
export const kcChannelType = [
  { key: 0, label: '企业' },
  { key: 1, label: '个人' }
]

// 渠道状态
export const kvChannelState = [
  { key: 0, label: '合作中' },
  { key: 1, label: '终止合作' }
]
