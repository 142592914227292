<template>
  <el-dialog :title="isEdit ? '上账' : '查看'" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 450px">
      <el-descriptions border :column="2" :labelStyle="{ width: '120px', textAlign: 'right' }">
        <el-descriptions-item label="申请时间">{{ detail.rechargeTime || '无' }}</el-descriptions-item>
        <el-descriptions-item label="充值单号">{{ detail.rechargeNo || '无' }}</el-descriptions-item>
        <el-descriptions-item label="审核状态">{{ formatStatus(kvRechargeAuditStatus, detail.auditState) }}</el-descriptions-item>
        <el-descriptions-item label="充值状态">{{ formatStatus(kvRechargeStatus, detail.rechargeState) }}</el-descriptions-item>
        <el-descriptions-item label="审核时间">{{ detail.auditTime || '无' }}</el-descriptions-item>
        <!-- <el-descriptions-item label="打款时间">{{ detail.rechargeTime || '无' }}</el-descriptions-item>
        <el-descriptions-item label="流水号">{{ detail.rechargeNo || '无' }}</el-descriptions-item> -->
        <el-descriptions-item label="商户名称">{{ detail.merchantName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="商户编号">{{ detail.memberCode || '无' }}</el-descriptions-item>
        <el-descriptions-item label="打款账户名称">{{ detail.payAccountName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="打款账户号码">{{ detail.payAccountNumber || '无' }}</el-descriptions-item>
        <!-- <el-descriptions-item label="打款开户银行">{{ detail.payAccountBank || '无' }}</el-descriptions-item> -->
        <el-descriptions-item label="充值金额"
          ><span v-format="'#,##0.00'">{{ detail.rechargeAmount || 0 }}</span></el-descriptions-item
        >
        <el-descriptions-item label="打款凭证"><ImageViewer :path="detail.payVoucher" /></el-descriptions-item>
        <el-descriptions-item label="备注">{{ detail.remark || '无' }}</el-descriptions-item>
        <el-descriptions-item label="收款账户名称">{{ detail.rechargeAccountName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="收款账户号码">{{ detail.rechargeAccountNumber || '无' }}</el-descriptions-item>
        <el-descriptions-item label="收款开户银行" :span="2">{{ detail.rechargeAccountBank || '无' }}</el-descriptions-item>
        <el-descriptions-item v-if="detail.rechargeState == 2" label="失败原因" :span="2">{{ detail.failCause || '无' }}</el-descriptions-item>
      </el-descriptions>
      <el-form style="margin-top: 20px" v-if="isEdit" ref="vForm" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="流水单号" prop="bankRemitNo">
          <el-input style="width: 300px" v-model="form.bankRemitNo" placeholder="请输入流水单号"></el-input>
        </el-form-item>
      </el-form>
      <div v-if="isEdit" style="text-align: right">
        <el-button @click="check(2)">失败</el-button>
        <el-button type="primary" @click="check(1)">确认上帐</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { formatStatusHasColor, formatStatus, kvRechargeAuditStatus, kvRechargeStatus } from '@/configs/formatters.js'
import ImageViewer from '@/components/tableView/imageViewer'
export default {
  data() {
    return {
      id: null,
      show: false,
      detail: {},
      rules: {
        bankRemitNo: [{ required: true, message: '请输入流水单号', trigger: 'blur' }]
      },
      form: {},
      isEdit: false,
      kvRechargeAuditStatus,
      kvRechargeStatus
    }
  },
  components: {
    ImageViewer
  },
  methods: {
    formatStatus,
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
      this.form = {}
    },
    open(obj, isEdit) {
      console.log(isEdit)
      this.clear()
      this.isEdit = isEdit
      if (obj && obj.id) {
        // 查看
        this.id = obj.id
        this.show = true
        this.getDetail()
      } else {
        this.$message.error('无效的信息')
      }
    },
    async getDetail() {
      const res = await this.$api.GetRechargeLogInfo({ rechargeId: this.id })
      this.detail = res
    },
    // 状态
    status(val) {
      if (val) return formatStatusHasColor(kvRechargeStatus, val)
      return '无'
    },
    async check(state) {
      const params = {
        FinanceRechargeId: this.id,
        bankRemitNo: this.form.bankRemitNo
      }
      if (state == 2) {
        const { value } = await this.$prompt('请输入失败过理由', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputType: 'textarea',
          inputPlaceholder: '请输入审失败理由,不超过200字',
          inputPattern: /^\S+$/,
          inputErrorMessage: '请输入失败理由'
        })
        params.failCause = value
      }
      let res
      if (state == 2) {
        res = await this.$api.InAlipayAccountBalanceFail(params)
        this.$message.success('已失败')
      } else {
        if (this.form.bankRemitNo == '') {
          this.$message.error('请输入流水单号')
          return false
        }
        res = await this.$api.InAlipayAccountBalance(params)
        this.$message.success('上账完成')
      }
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
