<template>
  <div>
    <el-dialog title="更新" :visible.sync="show">
      <div style="width: 80%; margin: 0 auto">
        <el-form :model="formData">
          <el-form-item label="姓名" prop="userName" :rules="[{ required: true, message: '请填写姓名', trigger: 'blur' }]">
            <el-input v-model="formData.userName"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="idCard" :rules="[{ validator: isIdCard, trigger: 'blur' }]">
            <el-input v-model="formData.idCard"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile" :rules="[{ validator: isMobile, trigger: 'blur' }]" v-if="isShow(formData.vierfyLevel, [2, 4])">
            <el-input v-model="formData.mobile"></el-input>
          </el-form-item>
          <el-form-item label="账号" v-if="isShow(formData.vierfyLevel, [3, 4])">
            <el-input v-model="formData.accountNumber"></el-input>
          </el-form-item>
          <el-form-item label="效验等级">
            <span>{{ formData.vierfyLevel | formatter }}</span>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" style="display: flex; justify-content: space-around; margin-bottom: 18px">
        <el-button @click="show = false">取消</el-button>
        <el-button type="primary" @click="submit" :loading="btnloadSubmit">确认并效验</el-button>
      </div>
    </el-dialog>
    <WpView ref="Wp" :visible="false" :type="5" :id="detail.id" @geted="wpGeted" @need="wpNeed" />
  </div>
</template>

<script>
import { charmStatus } from '@/configs/formatters'
import { regular } from '@/configs/validate'
import WpView from '@/components/wpView'
export default {
  components: { WpView },
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      formData: {},
      detail: {},
      people: {}
    }
  },
  methods: {
    open(row) {
      this.detail = row
      this.$nextTick(() => {
        this.$refs.Wp.handlerClick()
      })
    },
    // 需要验证码
    wpNeed() {
      setTimeout(() => {
        this.$message.warning('输入短信验证码后才能进行编辑')
      }, 10)
    },
    // 确认成功F
    wpGeted(res) {
      const { name, mobile, idCard } = res
      this.people = { userName: name, mobile, idCard }
      this.formData = Object.assign({}, this.detail, this.people)
      this.show = true
    },
    // 提交效验数据
    async submit() {
      this.btnloadSubmit = true
      const { id, idCard, mobile, userName, accountNumber } = this.formData
      const result = await this.$api.PutWorkPeopleVerify({ id, idCard, mobile, userName, accountNumber }).finally(() => {
        this.btnloadSubmit = false
      })
      if (result) {
        this.$message.success('效验成功')
        this.show = false
      }
    },
    // 该项是否展示
    isShow(idx, list) {
      return list.includes(idx)
    },
    isIdCard(rule, value, callback) {
      if (value && regular.IDCard.test(value)) {
        callback()
      } else {
        callback(new Error(regular.IDCardMsg))
      }
    },
    isMobile(rule, value, callback) {
      if (value && regular.mobile.test(value)) {
        callback()
      } else {
        callback(new Error(regular.mobileMsg))
      }
    }
  },
  filters: {
    formatter(val) {
      let result = charmStatus.find((item) => {
        return item.key == val
      })
      if (result) {
        return result.label
      }
    }
  }
}
</script>
