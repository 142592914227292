<template>
  <el-header class="head-wrap">
    <div class="head-logo">
      <div class="logo-wrap">
        <i class="icon iconfont icon-icon_logo"></i>
        <i class="icont iconfont icon-icon_lingshiyun"></i>
        <i class="icont iconfont icon-a-icon_dian"></i>
        <i class="icont iconfont icon-icon_word_pingtai"></i>
      </div>
      <!-- <h1 class="title">{{ title }}</h1> -->
    </div>

    <div>
      <span class="sub-word">小工具：</span>
      <el-tooltip effect="dark" content="认证查询" placement="bottom">
        <el-button class="sub-btn" size="mini" circle @click="$refs.Fdd.open()">认</el-button>
      </el-tooltip>
      <el-tooltip effect="dark" content="要素验证" placement="bottom">
        <el-button class="sub-btn" size="mini" circle @click="$refs.VerifyIngredient.open()">验</el-button>
      </el-tooltip>
      <el-tooltip effect="dark" content="充值结果查询" placement="bottom">
        <el-button class="sub-btn" size="mini" circle @click="$refs.FinanceQuery.open()">充</el-button>
      </el-tooltip>
      <el-tooltip effect="dark" content="回单上传" placement="bottom">
        <el-button class="sub-btn" size="mini" circle @click="$refs.Receipt.open()">回</el-button>
      </el-tooltip>
      <el-tooltip effect="dark" content="提成计算器" placement="bottom">
        <el-button class="sub-btn" size="mini" circle @click="$refs.Commission.open()">提</el-button>
      </el-tooltip>
      <!-- <el-tooltip effect="dark" content="茂新同步" placement="bottom">
        <el-button class="sub-btn" size="mini" circle @click="$refs.VerifyIngredient.open()">同</el-button>
      </el-tooltip> -->
      <el-divider direction="vertical"></el-divider>
      <el-dropdown class="user-dropdown" trigger="hover">
        <div class="head-user">
          <p class="username">{{ username }}<i class="el-icon-caret-bottom"></i></p>
          <!-- <el-image class="avatar" :src="avatar" /> -->
        </div>
        <el-dropdown-menu slot="dropdown" class="menu">
          <el-dropdown-item @click.native="$refs.ResetPwd.open()">
            <div><i class="el-icon-setting" z></i>修改密码</div>
          </el-dropdown-item>
          <el-dropdown-item @click.native="logout">
            <div><i class="el-icon-switch-button"></i>退出登录</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 弹窗 -->
    <ResetPwd ref="ResetPwd" />
    <Fdd ref="Fdd" />
    <VerifyIngredient ref="VerifyIngredient" />
    <FinanceQuery ref="FinanceQuery" />
    <Receipt ref="Receipt" />
    <Commission ref="Commission" />
  </el-header>
</template>

<script>
import { getUsername, getAvatar } from '@/utils/auth'
import ResetPwd from '@/components/dialog/resetPwd'
import Fdd from '@/views/tools/fdd'
import VerifyIngredient from '@/views/tools/verifyIngredient'
import FinanceQuery from '@/views/tools/financeQuery'
import Receipt from '@/views/tools/receipt'
import Commission from '@/views/tools/commission'
export default {
  name: 'Head',
  components: { ResetPwd, Fdd, VerifyIngredient, FinanceQuery, Receipt,Commission },
  data() {
    return {
      avatarSrc: require('@/assets/logo.png'),
      title: process.env.VUE_APP_TITLE
    }
  },
  computed: {
    avatar() {
      return getAvatar() || this.avatarSrc
    },
    username() {
      return getUsername() || '用户'
    }
  },
  mounted() {},
  methods: {
    async logout() {
      await this.$store.dispatch('user/logOut')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.head-wrap {
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: $--border-base;
  .head-logo {
    display: flex;
    align-items: center;
    .logo {
      height: 42px;
      width: 143px;
      flex-shrink: 0;
    }
    .logo-wrap {
      color: #2739d8;
      display: flex;
      align-items: center;
      .icon {
        font-size: 26px;
        margin-right: 10px;
      }
      .icont {
        font-size: 14px;
      }
    }
    .title {
      flex-grow: 1;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      margin: 0;
      color: $--color-text-primary;
      padding: 0 8px;
    }
  }

  .user-dropdown {
    cursor: pointer;
  }

  .head-user {
    display: flex;
    align-items: center;
    .avatar {
      width: 32px;
      border-radius: 100%;
      border: $--border-base;
      flex-shrink: 0;
    }
    .username {
      flex-grow: 1;
      text-align: center;
      font-size: 16px;
      margin: 0;
      color: $--color-text-primary;
      padding: 0 8px;
    }
  }
}
.sub-word {
  font-size: 12px;
  color: $--color-text-secondary;
}
</style>
