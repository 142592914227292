<template>
  <el-dialog title="查看" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" fullscreen>
    <!-- 内容区域 -->
    <div class="dialog-wrap-full" v-if="show">
      <el-descriptions title="基本信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="审核状态">{{ detail.verifyState || '无' }}</el-descriptions-item>
        <el-descriptions-item label="审核时间">{{ detail.verifyTime || '无' }}</el-descriptions-item>
        <el-descriptions-item label="申请编号">{{ detail.applyNumber || '--' }}</el-descriptions-item>
        <el-descriptions-item label="申请时间">{{ detail.createTime || '--' }}</el-descriptions-item>
        <el-descriptions-item label="开票状态">{{ detail.billState }}</el-descriptions-item>
        <el-descriptions-item label="配送状态">{{ detail.distributionState }}</el-descriptions-item>
        <el-descriptions-item label="商户名称">{{ detail.merchantName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="商户号">{{ detail.memberCode || '无' }}</el-descriptions-item>
        <el-descriptions-item label="认证企业">{{ detail.mainName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="开票类型">{{ invoicingSourceList[detail.invoicingSource] || '无' }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="发票内容" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="发票类型">{{ detail.invoiceType || '无' }}</el-descriptions-item>
        <el-descriptions-item label="开票税目">{{ detail.invoiceItem || '无' }}</el-descriptions-item>
        <el-descriptions-item label="申请金额"
          ><span v-format="'#,##0.00'">{{ detail.applyAmount || 0 }}</span> 元</el-descriptions-item
        >
        <el-descriptions-item label="合并笔数"
          >{{ detail.invoicingSource != 2 ? detail.planCount || '无' : '无' }}
          <el-link
            v-if="detail.planCount && detail.invoicingSource != 2"
            type="primary"
            style="margin-left: 30px; margin-top: -2px"
            @click="$refs.Plan.open({ InvoiceId: id, PlanType: 1, detail })"
            >查看</el-link
          ></el-descriptions-item
        >
        <el-descriptions-item label="平账结算单"
          >{{ detail.planDSOCount || 0 }}
          <el-link
            v-if="detail.planDSOCount"
            type="primary"
            style="margin-left: 30px; margin-top: -2px"
            @click="$refs.Plan.open({ InvoiceId: id, PlanType: 2, detail })"
            >查看</el-link
          ></el-descriptions-item
        >
        <el-descriptions-item :span="1" label="备注">{{ detail.remark || '无' }}</el-descriptions-item>
        <el-descriptions-item label="退汇金额"
          ><span v-format="'#,##0.00'">{{ detail.returnAmount || 0 }}</span> 元</el-descriptions-item
        >
        <el-descriptions-item label="开票金额"
          ><span v-format="'#,##0.00'">{{ detail.invoiceAmount || 0 }}</span> 元</el-descriptions-item
        >
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="发票信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="已开票金额"
          ><span v-format="'#,##0.00'">{{ detail.invoicedAmount || 0 }}</span>
          元
          <el-link
            v-if="detail.invoicedAmount"
            type="primary"
            style="margin-left: 30px; margin-top: -2px"
            @click="$refs.Receipt.open({ InvoiceId: id })"
            >查看</el-link
          ></el-descriptions-item
        >
        <el-descriptions-item label="已上传发票数">{{ detail.receiptCount || 0 }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="抬头信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="抬头名称">{{ detail.riseName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="纳税人识别号">{{ detail.taxPayerNumber || '无' }}</el-descriptions-item>
        <el-descriptions-item label="单位地址">{{ detail.unitAddress || '无' }}</el-descriptions-item>
        <el-descriptions-item label="电话号码">{{ detail.phone || '无' }}</el-descriptions-item>
        <el-descriptions-item label="开户银行">{{ detail.openBank || '无' }}</el-descriptions-item>
        <el-descriptions-item label="银行账户">{{ detail.bankAccount || '无' }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="收件人信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="收件人姓名">{{ detail.inboxName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="收件人电话">{{ detail.inboxPhone || '无' }}</el-descriptions-item>
        <el-descriptions-item label="选择区域">{{ detail.inboxArea || '无' }}</el-descriptions-item>
        <el-descriptions-item label="详细地址">{{ detail.inboxAddress || '无' }}</el-descriptions-item>
        <el-descriptions-item label="邮箱">{{ detail.inboxEmail || '无' }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="快递信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="快递名称">{{ detail.expressName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="快递单号">{{ detail.expressNo || '无' }}</el-descriptions-item>
        <el-descriptions-item label="快递附件">
          <ImageViewer v-if="detail.filePath" :path="detail.filePath" />
          <span v-else>无</span>
        </el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
    </div>
    <Plan ref="Plan" />
    <Receipt ref="Receipt" />
  </el-dialog>
</template>

<script>
import { formatStatus, formatStatusHasColor, kvBillState, kvDistributionState } from '@/configs/formatters'
import ImageViewer from '@/components/tableView/imageViewer'
import Plan from '../invoice/plan'
import Receipt from './receipt'

let invoicingSourceList = {
  0: '按结算单开票',
  1: '按结算明细开票',
  2: '按金额开票',
}
export default {
  components: { ImageViewer, Plan, Receipt },
  data() {
    return {
      id: null,
      show: false,
      detail: {},
      kvBillState,
      kvDistributionState,
      invoicingSourceList
    }
  },
  methods: {
    formatStatus,
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 查看
        this.id = obj.id
        this.detail = obj
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    }
  }
}
</script>
