<template>
  <el-dialog :title="(!id ? '添加' : '编辑') + title" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="800px">
    <!-- 内容区域 -->
    <div>
      <FormView ref="vForm" :labels="fromLabels" :slotIdxList="[4]" :formConfig="{ labelWidth: '140px' }">
        <template #form-items="{ idx, form }">
          <el-col>
            <el-form-item
              :rules="[
                { required: true, message: '请填写短信模板内容', trigger: 'blur' },
              ]"
              v-if="idx === 4"
              label="短信模板内容"
            >
              <div class="sms-textarea">
                <div
                  class="__inner"
                  :class="{ placeholder: smsPlaceholder }"
                  ref="smsTextarea"
                  contenteditable
                  @blur="smsTextareaBlur"
                  @input="smsTextareaChange"
                ></div>
                <span class="count">{{ smsNum }}/{{ smsMaxNum }}</span>
              </div>
              <div v-if="smsError" class="el-form-item__error">请填写短信模板内容</div>
              <div v-else-if="smsNum > smsMaxNum" class="el-form-item__error">短信模板内容限制在200个字以内</div>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item>
              <div>可用参数如下：（可点击插入短信模板内容中）</div>
              <div class="sms-textarea-tags">
                <el-tag
                  class="__tag"
                  v-for="item in temOptions"
                  :key="item.code"
                  :ref="'tag' + item.parameterCode"
                  @click="insertTagToTextarea(item)"
                  >{{ item.parameterName + '（' + item.parameterCode + '）' }}</el-tag
                >
              </div>
            </el-form-item>
          </el-col>
        </template>
      </FormView>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import html2canvas from 'html2canvas'
import { formSMS } from '@/configs/forms'
import { kvGrantType } from '@/configs/formatters'
export default {
  name: 'DialogChannel',
  data() {
    return {
      smsCursorPos: 0, // 光标位置
      smsMaxNum: 200,
      smsNum: 0,
      smsError: false,
      smsPlaceholder: false,
      btnloadSubmit: false,
      id: null,
      show: false,
      title: '短信模板',
      temOptions: [],
      fromLabels: formSMS,
      isSigleLimitDisabled: false,
      isMonthLimitDisabled: false,
      isMerchantLimitDisabled: false,
      detail: {},
      temMap: {},
      loading: false,
      grantOptions: kvGrantType
    }
  },
  methods: {
    async submit() {
      if (this.smsNum > 200) {
        this.$message.error('短信模板内容超过200字')
        return false
      }
      const result = await this.$refs.vForm.validate()
      // 结果更新
      if (result) {
        const form = await this.$refs.vForm.getForm()
        this.btnloadSubmit = true
        if (this.id) {
          await this.$api.EditSmsTemplate(Object.assign({ id: this.id }, form)).finally(() => {
            this.btnloadSubmit = false
          })
          this.$message.success('修改成功')
        } else {
          await this.$api.AddSmsTemplate(Object.assign({}, form)).finally(() => {
            this.btnloadSubmit = false
          })
          this.$message.success('添加成功')
        }
        this.$emit('submit')
        this.show = false
      }
    },
    clear() {
      this.id = null
      this.smsError = false
      this.smsPlaceholder = true
      this.smsNum = 0
      this.detail = {}
    },
    async open(obj) {
      this.clear()
      this.getOtionps()
      this.getSource()
      if (obj && obj.id) {
        // 编辑
        this.id = obj.id
        await this.getDetail()
      } else {
        // 新建
      }
      this.show = true
      this.$nextTick(() => {
        this.$refs.vForm.fillData(this.detail)
        // 请在dom结构渲染完成后执行smsImport方法
        this.smsImport(this.detail.smsContent || '')
      })
    },
    async getSource() {
      const res = await this.$api.GetSystemDataDicList({ DataCategory: 'SMSSignature' })
      const options = (res.list || []).map((it) => {
        return {
          key: it.dataValue,
          label: it.dataName
        }
      })
      this.fromLabels.find((item) => item.prop == 'smsSignature').options = options
    },
    async getDetail() {
      const res = await this.$api.GetSmsTemplate({ id: this.id })
      const { id, smsCategory, smsName, smsRecipients, smsSignature, smsContent, smsRemark, smsParameterId } = res
      this.detail = {
        id,
        smsCategory,
        smsName,
        smsRecipients,
        smsSignature,
        smsContent,
        smsRemark,
        smsParameterId
      }
    },
    async getOtionps() {
      const resOptions = await this.$api.GetSmsParameterAllList()
      resOptions.list.map((item) => {
        this.temMap[item.parameterCode] = item
      })
      this.temOptions = resOptions.list
    },
    // 标签插入短信模板内容事件
    smsTextareaBlur() {
      const dom = this.$refs.smsTextarea
      let cursorPos = 0
      const range = window.getSelection().getRangeAt(0)
      cursorPos = range.endOffset
      let commonContainer = range.commonAncestorContainer
      if (commonContainer.nodeName == 'DIV' && cursorPos && commonContainer.outerHTML) {
        commonContainer = commonContainer.childNodes[cursorPos]
        if (commonContainer.outerHTML) cursorPos = commonContainer.outerHTML.length
      }
      let previousSibling = commonContainer.previousSibling
      while (previousSibling) {
        if (previousSibling.nodeName != '#text') {
          cursorPos += previousSibling.outerHTML.length
        } else {
          cursorPos += previousSibling.textContent.length
        }
        previousSibling = previousSibling.previousSibling
      }
      this.smsCursorPos = cursorPos
      this.smsTextareaChange()
      this.checkSmsTextarea()
      // 选择时机转换文本，不用每次blur后转换
      this.smsExport()
    },
    smsTextareaChange() {
      if (this.smsError) this.smsError = false
      const dom = this.$refs.smsTextarea
      this.smsNum = dom.innerText.length
      if (dom.innerHTML.length) this.smsPlaceholder = false
      else this.smsPlaceholder = true
    },
    // 检查sms是否有输入内容
    checkSmsTextarea() {
      if (!this.smsNum) this.smsError = true
    },
    // sms结果转换成文本
    smsExport() {
      const dom = this.$refs.smsTextarea
      let content = ''
      let smsParameterId = []
      for (let i = 0; i < dom.childNodes.length; i++) {
        const one = dom.childNodes[i]
        if (one.nodeName == '#text') {
          content += one.textContent
        } else if (one.nodeName == 'IMG') {
          content += `{${one.name}}`
          smsParameterId.push(one.id)
        }
      }
      // 请将content赋值给提交函数
      this.$refs.vForm.upData({ smsContent: content, smsParameterId })
    },
    // sms文本导入成结构，请在tags部分dom结构渲染完成后执行smsImport方法
    async smsImport(content) {
      const regex = /(\{[^}]+\})/g
      const resList = content.split(regex)
      const regimg = /^\{([^}]+)\}$/
      let result = ''
      for (let i = 0; i < resList.length; i++) {
        const one = resList[i]
        const match = one.match(regimg)
        if (match) {
          const tags = this.$refs['tag' + match[1]]
          if (tags) {
            // 绘制tag图片
            const canvas = await html2canvas(tags[0].$el)
            result += `<img name="${match[1]}" id="${this.temMap[match[1]].id}" src="${canvas.toDataURL('image/png')}">`
          }
        } else {
          result += one
        }
      }
      this.$refs.smsTextarea.innerHTML = result
      this.smsTextareaChange()
    },
    async insertTagToTextarea(one) {
      this.smsPlaceholder = false
      // 绘制tag图片
      const canvas = await html2canvas(this.$refs['tag' + one.parameterCode][0].$el)
      // 插入tag
      const tagdom = `<img name="${one.parameterCode}" id='${one.id}' src="${canvas.toDataURL('image/png')}">`
      const dom = this.$refs.smsTextarea
      const html = dom.innerHTML
      const result = html.slice(0, this.smsCursorPos) + tagdom + html.slice(this.smsCursorPos)
      dom.innerHTML = result
      this.$refs.smsTextarea.focus()
      // 设置焦点
      const pos = this.smsCursorPos + tagdom.length
      // 轮询计算光标在dom的哪个子内
      const rangeDom = window.getSelection().getRangeAt(0)
      let residuePos = pos
      let childIdx = 0
      let nextSibling = rangeDom.startContainer
      if (nextSibling.nodeName == 'DIV') {
        nextSibling = nextSibling.childNodes[0]
      }
      let flag = true
      while (flag) {
        let _len = 0
        if (nextSibling.nodeName != '#text') {
          _len = nextSibling.outerHTML.length
        } else {
          _len = nextSibling.textContent.length
        }
        if (residuePos >= _len) {
          residuePos -= _len
          childIdx += 1
          nextSibling = nextSibling.nextSibling
          if (!nextSibling) {
            const textNode = document.createTextNode('')
            dom.appendChild(textNode)
            break
          }
        } else {
          flag = false
        }
      }
      const range = document.createRange()
      const selection = window.getSelection()
      range.setStart(dom.childNodes[childIdx], residuePos)
      range.setEnd(dom.childNodes[childIdx], residuePos)
      selection.removeAllRanges()
      selection.addRange(range)
      dom.focus()
    }
  }
}
</script>
<style lang="scss" scoped>
.btnline {
  text-align: right;
}
.el-col-12 {
  height: 51px;
}
// 短信模板内容样式
.sms-textarea {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: bottom;
  font-size: 13px;
  .__inner {
    display: block;
    resize: vertical;
    padding: 5px 15px;
    line-height: 1.5;
    box-sizing: border-box;
    width: 100%;
    font-size: inherit;
    color: #606266;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    min-height: 51px;
    line-height: 28px;
    &::before {
      content: '请编辑短信模板（可点击插入参数），限制200字以内';
      display: none;
      opacity: 0.5;
      pointer-events: none;
    }
    &.placeholder::before {
      display: block;
    }
    ::v-deep img {
      vertical-align: middle;
      margin: 0 5px;
    }
  }
  &-tags {
    .__tag {
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .count {
    color: #909399;
    background: #fff;
    position: absolute;
    font-size: 12px;
    line-height: 1;
    bottom: 5px;
    right: 10px;
    pointer-events: none;
  }
}
</style>
