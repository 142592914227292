<template>
  <el-dialog title="签约" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="500px">
    <!-- 内容区域 -->
    <FormView ref="vForm" :labels="fromLabels"></FormView>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
const fromLabels = [
  {
    cmp: 'txt',
    label: '个体户',
    prop: 'mermchantId'
  },
  {
    cmp: 'select',
    label: '签约公司',
    prop: 'mermchantId',
    options: [],
    itemConfig: {
      rules: [{ required: true, message: '请选择签约公司', trigger: 'change' }]
    },
    config: {
      placeholder: '请选择签约公司',
      style: { width: '100%' }
    }
  },
  {
    cmp: 'select',
    label: '签约模板',
    prop: 'mermchantId',
    options: [],
    itemConfig: {
      rules: [{ required: true, message: '请选择签约模板', trigger: 'change' }]
    },
    config: {
      placeholder: '请选择签约模板',
      style: { width: '100%' }
    }
  }
]
export default {
  data() {
    return {
      id: null,
      show: false,
      fromLabels: fromLabels,
      detail: {}
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.detail = {}
    },
    open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 编辑
        this.id = obj.id
        this.detail = obj
        this.show = true
        this.$nextTick(() => {
          this.$refs.vForm.fillData({})
        })
      } else {
      }
    },
    // 提交
    async submit() {
      // 结果更新
      const params = await this.$refs.vForm.getForm()
      // const res = await this.$api.EditSystemRole(Object.assign({ systemRoleMenuFunEntities }, params))
      this.$message.success('小程序签约已发送')
      this.$emit('submit')
      this.show = false
    }
  }
}
</script>
