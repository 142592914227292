<template>
  <el-upload
    :action="uploadUrl"
    :show-file-list="false"
    v-loading="loading"
    class="file-uploader"
    :headers="{
      Authorization: 'Bearer ' + $store.state.user.token
    }"
    :before-upload="onBeforeUpload"
    :on-error="onUploadError"
    :on-success="onUpload"
  >
    <el-button slot="trigger" icon="el-icon-upload" plain type="primary">{{ button || '导入' }}</el-button>
  </el-upload>
</template>

<script>
export default {
  name: 'ComponentFormImport',
  props: {
    url: String,
    button: String,
    size: {
      type: Number,
      default: 10
    },
    //文件格式
    fileSuffix: {
      type: Array,
      default: () => ['jpg', 'png', 'jpeg', 'pdf', 'zip']
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    uploadUrl() {
      return process.env.VUE_APP_BASE_HOST + process.env.VUE_APP_BASE_API + this.url
    }
  },
  methods: {
    onBeforeUpload(file, fileList) {
      // 文件大小限制
      if (file.size / (1024 * 1024) > this.size) {
        this.$message.warning(`当前限制文件大小不能大于${this.size}M`)
        return false
      }
      // 格式限制
      let suffix = this.getFileType(file.name)
      if (this.fileSuffix.indexOf(suffix) === -1) {
        this.$message.warning(`只能上传${this.fileSuffix.toString()}`)
        return false
      }
      this.loading = true
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf('.')
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase()
      } else {
        return ''
      }
    },
    onUploadError(err) {
      this.loading = false
      this.$message.error(err.message || '上传失败')
    },
    onUpload(res) {
      if (!res || res.code !== '0' || !res.data) return this.onUploadError(res)
      this.loading = false
      this.$emit('uploaded', res.data)
    }
  }
}
</script>
