<template>
  <el-dialog title="明细" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="1000px">
    <div class="dialog-wrap" style="height: 600px" v-if="show">
      <div class="flex-home">
        <TableView ref="vTable" :labels="tableLabels" apiName="GetInvoiceDetailPlanPageList" :searchExt="searchExt"></TableView>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { tableBillPlan } from '@/configs/tables'
import { deepClone } from '@/utils'
export default {
  data() {
    return {
      show: false,
      tableLabels: deepClone(tableBillPlan),
      searchExt: {}
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.searchExt = {}
    },
    open(obj) {
      this.clear()
      this.searchExt = obj
      this.tableLabels = deepClone(tableBillPlan);
      if(obj.PlanType == 1) {
        if(obj.detail.invoicingSource != 1) {
          this.tableLabels.splice(0,1)
        } else {
          this.tableLabels[2].prop = 'workDetailAmount'
        }
      } else {
        this.tableLabels.splice(0,1)
      }
      this.show = true
    }
  }
}
</script>
