<template>
  <el-dialog :title="'服务费返还单号：' + detail.serialNo" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false">
    <!-- 内容区域 -->
    <div class="dialog-wrap">
      <el-descriptions border title="结算概览" :column="1" :labelStyle="{ width: '140px', textAlign: 'right' }">
        <el-descriptions-item label="商户名称">{{ detail.merchantName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="结算年月">{{ detail.settleMonth || '无' }}</el-descriptions-item>
        <el-descriptions-item label="服务费金额变更总计"
          ><span v-format="'#,##0.00'">{{ detail.diffPre }}</span
          >元 <i class="el-icon-right"></i> <span v-format="'#,##0.00'">{{ detail.diffAfter }}</span
          >元</el-descriptions-item
        >
        <el-descriptions-item label="服务费变更差额总计"
          ><span v-format="'#,##0.00'">{{ detail.diffServicefee || 0 }}</span
          >元</el-descriptions-item
        >
        <el-descriptions-item label="抵扣金状态">{{ detail.state == 0 ? '待入账' : '已入账' || '无' }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions border title="服务费月结返还明细" :column="1" :labelStyle="{ width: '140px', textAlign: 'right' }">
        <template slot="extra"
          >GMV总计：<span v-format="'#,##0.00'">{{ detail.payAmount || 0 }}</span
          >元</template
        >
        <el-descriptions-item label="当前服务费总计"
          ><span v-format="'#,##0.00'">{{ detail.diffPre || 0 }}</span
          >元</el-descriptions-item
        >
        <el-descriptions-item label="变更服务费率">{{ detail.diffAfterRate || 0 }}%</el-descriptions-item>
        <el-descriptions-item label="变更后服务费总计"
          ><span v-format="'#,##0.00'">{{ detail.diffAfter || 0 }}</span
          >元</el-descriptions-item
        >
        <el-descriptions-item label="服务费变更差额总计"
          ><span v-format="'#,##0.00'">{{ detail.diffServicefee || 0 }}</span
          >元</el-descriptions-item
        >
      </el-descriptions>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      id: null,
      show: false,
      showContent: false,
      detail: {},
      tabName: ''
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.showContent = false
      this.detail = {}
    },
    open(obj) {
      this.clear()
      this.detail = obj
      this.show = true
      console.log(obj)
      // if (obj && obj.id) {
      //   // 查看
      //   // const { id, taskName, taskDescribe, taskNumber, taskState, auditStatus,taskCreateTime,taskType } = obj
      //   // this.detail = { id, taskName, taskDescribe, taskNumber, taskState, auditStatus,taskCreateTime,taskType }
      //   this.id = obj.taskNumber

      //   this.tabName = 'one'
      //   // this.getDetail()
      // } else {
      //   this.$message.error('无效的信息')
      // }
    },
    async getDetail() {
      const res = await this.$api.GetTaskManage({ id: this.id })
      this.detail = res
    }
  }
}
</script>
