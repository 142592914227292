<template>
  <el-dialog title="查看" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="1000px">
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 600px">
      <p>【订单编号：{{ detail.customerOrderNo || '无' }}】</p>
      <el-descriptions border title="基本信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right' }">
        <el-descriptions-item label="姓名">{{ detail.userName || '无' }}<WpView v-if="detail.userName" :id="id" :type="4" /></el-descriptions-item>
        <el-descriptions-item label="证件号">{{ detail.idCard || '无' }}<WpView v-if="detail.idCard" :id="id" :type="4" /></el-descriptions-item>
        <el-descriptions-item label="签约状态">{{ formatStatus(kvTableWorkPeopleContract, detail.isSign) }}</el-descriptions-item>
        <el-descriptions-item label="下发类型">{{ detail.grantType || '无' }}</el-descriptions-item>
        <el-descriptions-item label="账号名">{{ detail.accountName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="账号">{{ detail.accountNumber || '无' }}</el-descriptions-item>
        <el-descriptions-item label="个体户名称">{{ detail.companyName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="提交金额"
          ><span v-format="'#,##0.00'">{{ detail.afterTaxAmount || 0 }}</span></el-descriptions-item
        >
        <el-descriptions-item label="创建时间">{{ detail.createTime || '无' }}</el-descriptions-item>
        <el-descriptions-item label="提交审核时间">{{ detail.auditStartTime || '无' }}</el-descriptions-item>
        <el-descriptions-item label="审核通过时间">{{ detail.auditFinishTime || '无' }}</el-descriptions-item>
        <el-descriptions-item label="确认发放时间">{{ detail.grantConfirmTime || '无' }}</el-descriptions-item>
        <el-descriptions-item label="打款完成时间">{{ detail.grantFinishTime || '无' }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions border title="任务信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right' }">
        <el-descriptions-item label="商户名称">{{ detail.merchantName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="商户编号">{{ detail.memberCode || '无' }}</el-descriptions-item>
        <el-descriptions-item label="认证企业">{{ detail.mainName || '无' }}</el-descriptions-item
        ><el-descriptions-item label="所属任务">{{ detail.taskName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="任务编号">{{ detail.taskNo || '无' }}</el-descriptions-item>
        <el-descriptions-item label="结算编号">{{ detail.planNo || '无' }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions border title="订单信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right' }">
        <el-descriptions-item label="审核状态"
          ><span v-html="formatStatusHasColor(kvWorkPlanAuditState, detail.auditState)"></span
        ></el-descriptions-item>
        <el-descriptions-item label="打款状态"><span v-html="formatStatusHasColor(kvWorkPlanPayState, detail.payState)"></span></el-descriptions-item>
        <el-descriptions-item label="电子回单">
          <el-button v-if="detail.payState == 3 && detail.receiptInfo" type="text" @click="downReceipt()">下载回单</el-button>
          <span v-else>--</span>
        </el-descriptions-item>
        <el-descriptions-item label="失败原因">{{ detail.failCause || '无' }}</el-descriptions-item>
        <el-descriptions-item label="订单金额"
          ><span v-format="'#,##0.00'">{{ detail.orderAmount || 0 }}</span></el-descriptions-item
        >
        <el-descriptions-item label="利润"
          ><span v-format="'#,##0.00'">{{ detail.profitAmount || 0 }}</span></el-descriptions-item
        >
        <el-descriptions-item label="服务费"
          ><span v-format="'#,##0.00'">{{ detail.serviceFee || 0 }}</span
          >（服务费率：{{ detail.serviceTariffing || 0 }}%）</el-descriptions-item
        >
        <el-descriptions-item label="服务费抵扣金"
          ><span v-format="'#,##0.00'">{{ detail.deductionAmount || 0 }}</span></el-descriptions-item
        >
        <el-descriptions-item label="支付金额"
          ><span v-format="'#,##0.00'">{{ detail.balanceAmount || 0 }}</span></el-descriptions-item
        >
        <el-descriptions-item label="差额服务费"
          ><span v-format="'#,##0.00'">{{ detail.serviceDiffFee || 0 }}</span></el-descriptions-item
        >
        <el-descriptions-item label="个税"
          ><span v-format="'#,##0.00'">{{ detail.individualTax || 0 }}</span> 元</el-descriptions-item
        >
        <el-descriptions-item label="增值税"
          ><span v-format="'#,##0.00'">{{ detail.addedTax || 0 }}</span> 元</el-descriptions-item
        >
        <el-descriptions-item label="税金及附加"
          ><span v-format="'#,##0.00'">{{ detail.surchargesTax || 0 }}</span> 元</el-descriptions-item
        >
        <el-descriptions-item label="实发金额"
          ><span v-format="'#,##0.00'">{{ detail.finalPayingAmount || 0 }}</span> 元</el-descriptions-item
        >
        <el-descriptions-item label="成本费"
          ><span v-format="'#,##0.00'">{{ detail.costServiceFee || 0 }}</span
          >（成本费率：{{ detail.costServiceTariffing || 0 }}%）</el-descriptions-item
        >
        <el-descriptions-item label="差额成本"
          ><span v-format="'#,##0.00'">{{ detail.costServiceDiffFee || 0 }}</span></el-descriptions-item
        >
        <el-descriptions-item label="发放税源地">{{ detail.supplierName || '--' }}</el-descriptions-item>
        <el-descriptions-item label="发放通道">{{ detail.channelName || '--' }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <!-- 转账记录(拆单) -->
      <div class="el-descriptions__header">
        <div class="el-descriptions__title">转账记录</div>
        <div class="el-descriptions__extra"></div>
      </div>
      <div>
        <TableView
          v-if="show"
          ref="vTable"
          :labels="tableLabels"
          apiName="GetInidvidualPlanDetailRemit"
          :noPage="true"
          tableMinHeight="0px"
          :tableConfig="{ height: '' }"
          :searchExt="{ detailid: id }"
        >
          <el-table-column slot="table-item" label="操作" width="100" fixed="right">
            <!-- <template v-slot="{ row }">
              <el-button v-if="row.remitStateLabel == '打款成功'" type="text" @click="downReceipt(row.id)">下载回单</el-button>
              <span v-else>--</span>
            </template> -->
          </el-table-column>
        </TableView>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { formatStatus, formatStatusHasColor, kvWorkPlanAuditState, kvWorkPlanPayState, kvTableWorkPeopleContract } from '@/configs/formatters'
import { downloadUrl } from '@/utils'
import WpView from '@/components/wpView'
export default {
  components: { WpView },
  data() {
    return {
      id: null,
      show: false,
      detail: {},
      kvWorkPlanAuditState,
      kvWorkPlanPayState,
      kvTableWorkPeopleContract,
      tableLabels: [
        { label: '流水号', prop: 'customerOrderNo' },
        { label: '提交金额', prop: 'afterTaxAmount', cmp: 'money' },
        { label: '服务费', prop: 'remitServiceFee', cmp: 'money' },
        { label: '差额服务费', prop: 'remitServiceDiffFee', cmp: 'money' },
        { label: '打款状态', prop: 'remitStateLabel' },
        {
          label: '失败原因',
          prop: 'remitDesc',
          formatter: (row, column, cellValue, index) => {
            if (row.remitStateLabel == '打款成功') return '--'
            return cellValue || '--'
          }
        }
      ]
    }
  },
  methods: {
    formatStatus,
    formatStatusHasColor,
    // 数据清空
    clear() {
      this.detail = {}
    },
    open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 编辑
        this.id = obj.id
        this.detail = obj
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    },
    // 下载回执
    async downReceipt(id = this.id) {
      if (id == this.id && this.detail.receiptInfo) return downloadUrl(this.detail.receiptInfo)
      // const res = await this.$api.GetReceiptInfo({ DetailId: id })
      // if (!res.receiptInfo) return this.$message.warning('回单暂未生成，请稍后重试')
      // if (id == this.id) this.detail.receiptInfo = res.receiptInfo
      // downloadUrl(res.receiptInfo)
    }
  }
}
</script>
