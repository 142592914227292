<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetFinanceReturnPageList" :searchList="searchList">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item-left" v-if="$hasAccess('007800')">
        <el-button plain type="primary" icon="el-icon-plus" @click="$refs.Edit.open()">添加</el-button>
      </el-form-item>
    </TableView>
    <Edit @submit="$refs.vTable.refreshTable()" ref="Edit" />
  </div>
</template>

<script>
import Edit from './edit'
import { formatStatus, kvGrantType, kvPayState, kvBillState } from '@/configs/formatters'
const searchList = [
  {
    cmp: 'input',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入订单号/结算单编号/商户编号/商户名称',
      style: { width: '350px' }
    }
  },
  {
    cmp: 'dtpickers',
    label: '添加时间',
    prop: ['CreateTimeBegin', 'CreateTimeEnd']
  }
]
const tableLabels = [
  {
    label: '订单号',
    prop: 'customerOrderNo'
  },
  {
    label: '结算单编号',
    prop: 'planNo'
  },
  {
    label: '商户名称',
    prop: 'merchantName'
  },
  {
    label: '商户号',
    prop: 'memberCode'
  },
  {
    label: '下发类型',
    prop: 'grantType'
    // formatter: (row, column, cellValue, index) => {
    //   return formatStatus(kvGrantType, cellValue)
    // }
  },
  { label: '税后金额', prop: 'afterTaxAmount', cmp: 'money' },
  { label: '服务费', prop: 'serviceFee', cmp: 'money' },
  { label: '差额服务费', prop: 'serviceDiffFee', cmp: 'money' },
  { label: '抵扣金', prop: 'deductionAmount', cmp: 'money' },
  { label: '订单金额', prop: 'orderAmount', cmp: 'money' },
  {
    label: '打款状态',
    prop: 'payState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvPayState, cellValue)
    }
  },
  { label: '添加时间', prop: 'createTime', config: { width: 180 } },
  {
    label: '开票状态',
    prop: 'isClose',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(
        [
          { key: 0, label: '未开票' },
          { key: 1, label: '已开票' }
        ],
        cellValue
      )
    }
  }
]
export default {
  components: { Edit },
  data() {
    return {
      searchList: searchList,
      tableLabels: tableLabels
    }
  }
}
</script>
