<template>
  <el-dialog top="9vh" title="发布任务" :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" width="700px">
    <!-- 内容区域 -->
    <div class="dialog-wrap" style="height: 630px">
      <el-form :rules="rules" ref="detail" :model="detail" label-width="120px">
        <el-form-item prop="merchantId" label="所属商户:">
          <el-select filterable style="width: 100%" v-model="detail.merchantId" placeholder="请选择">
            <el-option v-for="item in merchantOptions" :key="item.key" :label="item.label" :value="item.key"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="taskName" label="任务名称:">
          <el-input placeholder="请输入任务名称" v-model="detail.taskName"></el-input>
        </el-form-item>
        <el-form-item prop="taskType" label="任务类型:">
          <el-col :span="8">
            <el-select style="width: 100%" v-model="detail.taskType" placeholder="请选择">
              <el-option label="结算任务" :value="0"></el-option>
              <el-option label="普通任务" :value="1"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item prop="taskDescribe" label="任务内容:">
          <el-input rows="3" type="textarea" v-model="detail.taskDescribe"></el-input>
        </el-form-item>
        <el-form-item prop="acceptCriteria" label="验收标准:">
          <el-input rows="3" type="textarea" v-model="detail.acceptCriteria"></el-input>
        </el-form-item>
        <el-form-item prop="countLimit" label="需求人数:">
          <el-col :span="8">
            <el-select style="width: 100%" v-model="detail.countLimit" placeholder="需求人数">
              <el-option label="不限制人数" :value="0"></el-option>
              <el-option label="限制人数" :value="1"></el-option>
            </el-select>
          </el-col>
          <el-col v-if="detail.countLimit == 1" :offset="1" :span="15">
            <el-form-item class="otherItem" prop="limitCount">
              <el-input v-model.number="detail.limitCount"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item prop="timeLimit" label="工作时间:">
          <el-col :span="8">
            <el-select style="width: 100%" v-model="detail.timeLimit" placeholder="工作时间">
              <el-option label="不限制时间" :value="0"></el-option>
              <el-option label="限制时间" :value="1"></el-option>
            </el-select>
          </el-col>
          <el-col v-if="detail.timeLimit == 1" :offset="1" :span="15">
            <el-col>
              <el-form-item class="otherItem" prop="timeRange">
                <el-date-picker
                  style="width: 100%"
                  v-model="detail.timeRange"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
                <!-- <el-date-picker type="date" placeholder="选择日期" v-model="detail.limitTimeStart" style="width: 100%"></el-date-picker> -->
              </el-form-item>
            </el-col>
            <!-- <el-col style="text-align: center" :span="2">-</el-col>
            <el-col :span="11">
              <el-form-item class="otherItem" prop="limitTimeEnd">
                <el-date-picker type="date" placeholder="选择日期" v-model="detail.limitTimeEnd" style="width: 100%"></el-date-picker>
              </el-form-item>
            </el-col> -->
          </el-col>
        </el-form-item>
        <el-form-item prop="addressLimit" label="工作地点:">
          <el-col :span="8">
            <el-select style="width: 100%" v-model="detail.addressLimit" placeholder="工作地点">
              <el-option label="不限制地点" :value="0"></el-option>
              <el-option label="限制地点" :value="1"></el-option>
            </el-select>
          </el-col>
          <el-col v-if="detail.addressLimit == 1" :offset="1" :span="15">
            <el-form-item class="otherItem" prop="limitAddress">
              <el-input v-model.number="detail.limitAddress"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item prop="commissionLimit" label="指定佣金范围:">
          <el-col :span="8">
            <el-select style="width: 100%" v-model="detail.commissionLimit" placeholder="请选择">
              <el-option label="不指定佣金范围" :value="0"></el-option>
              <el-option label="指定佣金范围" :value="1"></el-option>
            </el-select>
          </el-col>
          <el-col v-if="detail.commissionLimit == 1" :offset="1" :span="15">
            <el-col :span="11">
              <el-form-item class="otherItem" prop="taskMinAmount">
                <el-input @blur="vilMinAmount(detail.taskMinAmount)" v-model.number="detail.taskMinAmount"></el-input>
              </el-form-item>
            </el-col>
            <el-col style="text-align: center" :span="2">-</el-col>
            <el-col :span="11">
              <el-form-item class="otherItem" prop="taskMaxAmount">
                <el-input @blur="vilMaxAmount(detail.taskMaxAmount)" v-model.number="detail.taskMaxAmount"></el-input>
              </el-form-item>
            </el-col>
          </el-col>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input rows="3" type="textarea" v-model="detail.remark"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="limtButton" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { timestampToTime } from '@/configs/formatters'
import { debounce } from '@/utils'
export default {
  data() {
    var checkTaskMinAmount = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入佣金范围'))
      } else if (this.detail.taskMaxAmount < value) {
        return callback(new Error('该佣金范围不大于最大佣金'))
      } else {
        callback()
      }
    }
    var checkTaskMaxAmount = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入佣金范围'))
      } else if (this.detail.taskMinAmount > value) {
        return callback(new Error('该佣金范围不小于最小佣金'))
      } else {
        callback()
      }
    }
    return {
      btnloadSubmit: false,
      id: null,
      show: false,
      title: '任务',
      detail: {
        countLimit: 0,
        timeLimit: 0,
        addressLimit: 0,
        commissionLimit: 0
      },
      merchantOptions: [],
      rules: {
        taskName: [
          { required: true, message: '请输入任务名称', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        taskType: [{ required: true, message: '请选择任务类型', trigger: 'blur' }],
        taskDescribe: [
          { required: true, message: '请输入任务内容', trigger: 'blur' },
          { min: 1, max: 500, message: '长度在 1 到 500 个字', trigger: 'blur' }
        ],
        acceptCriteria: [
          { required: true, message: '请输入验收标准', trigger: 'blur' },
          { min: 1, max: 500, message: '长度在 1 到 500 个字', trigger: 'blur' }
        ],
        countLimit: [{ required: true, message: '请选择需求人数', trigger: 'blur' }],
        merchantId: [{ required: true, message: '请选择商户', trigger: 'blur' }],
        timeLimit: [{ required: true, message: '请选择工作时间', trigger: 'blur' }],
        addressLimit: [{ required: true, message: '请选择工作地点', trigger: 'blur' }],
        remark: [{ min: 1, max: 500, message: '长度在 1 到 500 个字', trigger: 'blur' }],
        commissionLimit: [{ required: true, message: '请选择指定佣金范围', trigger: 'blur' }],
        limitCount: [
          { required: true, message: '请输入需求人数', trigger: 'blur' },
          { type: 'number', message: '人数必须为数字值', trigger: 'blur' }
        ],
        limitAddress: [
          { required: true, message: '请输入工作地点', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字', trigger: 'blur' }
        ],
        timeRange: [{ required: true, message: '请选择日期', trigger: 'blur' }],
        taskMinAmount: [{ validator: checkTaskMinAmount, trigger: 'blur' }],
        taskMaxAmount: [{ validator: checkTaskMaxAmount, trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.detail = { countLimit: 0, timeLimit: 0, addressLimit: 0, commissionLimit: 0 }
    },
    async open(obj) {
      this.clear()
      let taskRes = await this.$api.GetMerchantInfoCodeAndName()
      this.merchantOptions = taskRes.list.map((item) => {
        return {
          key: item.merchantId,
          label: `(${item.memberCode}) ${item.merchantName}`
        }
      })
      this.show = true
    },
    // 提交
    limtButton: debounce(function (val) {
      this.submit()
    }, 500),
    async submit() {
      // 结果更新
      this.$refs.detail.validate(async (valid) => {
        if (valid) {
          this.btnloadSubmit = true
          if (this.detail.timeLimit) {
            this.detail.limitTimeStart = timestampToTime(parseInt(this.detail.timeRange[0].getTime() / 1000), 'Y-M-D')
            this.detail.limitTimeEnd = timestampToTime(parseInt(this.detail.timeRange[1].getTime() / 1000), 'Y-M-D')
          }
          if (this.detail.commissionLimit) {
            this.detail.taskMinAmount = Number(this.detail.taskMinAmount)
            this.detail.taskMaxAmount = Number(this.detail.taskMaxAmount)
          }
          const res = await this.$api.AddTaskManage(this.detail).finally(() => {
            this.btnloadSubmit = false
          })
          if (res) {
            this.$message.success('发布成功')
            // 编辑
            this.$emit('submit')
            this.show = false
          }
        }
      })
    },
    vilMinAmount(e) {
      if (e) {
        this.detail.taskMinAmount = Number(e).toFixed(2)
      }
    },
    vilMaxAmount(e) {
      if (e) {
        this.detail.taskMaxAmount = Number(e).toFixed(2)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.otherItem {
  margin-bottom: 0;
}
</style>
