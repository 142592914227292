<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <TableView
      ref="vTable"
      :labels="tableLabels"
      apiName="GetInidvidualPlanPageList"
      :searchList="searchList"
      :searchExt="{ AuditState }"
      :selection="false"
      @selection-change="selChange"
      :selConfig="{ selectable }"
      @geted="onTableGeted"
    >
      <!-- 附加栏 -->
      <div slot="ext-search" style="margin-bottom: 18px">
        <el-radio-group v-model="AuditState" @change="onAuditStateChange">
          <el-radio-button :label="null">全部</el-radio-button>
          <el-radio-button :label="1">未发起（{{ searchCounts.notStartCount || 0 }}）</el-radio-button>
          <el-radio-button :label="2">审核中（{{ searchCounts.auditingCount || 0 }}）</el-radio-button>
          <el-radio-button :label="3">审核通过（{{ searchCounts.auditPassCount || 0 }}）</el-radio-button>
          <el-radio-button :label="4">审核未通过（{{ searchCounts.auditNotPassCount || 0 }}）</el-radio-button>
        </el-radio-group>
      </div>
      <!-- 附加栏 -->
      <!-- <el-card slot="ext-table" shadow="never" style="margin-bottom: 18px; flex-shrink: 0">
        <el-descriptions :column="4" :colon="false" size="medium" style="margin-bottom: -10px">
          <el-descriptions-item label="累计提交金额"
            ><b v-format="'#,##0.00'">{{ summary.accuIssueAmount || 0 }}</b></el-descriptions-item
          >
          <el-descriptions-item label="累计人数"
            ><b v-format="'#,##0.00'">{{ summary.accuPersonCount || 0 }}</b></el-descriptions-item
          >
          <el-descriptions-item label="累计结算金额"
            ><b v-format="'#,##0.00'">{{ summary.accuSettleAmount || 0 }}</b></el-descriptions-item
          >
        </el-descriptions>
      </el-card> -->
      <!-- 按钮栏 -->
      <!-- <el-form-item slot="btnline-item-left" v-if="$hasAccess('006302')">
        <span class="v-sel-info">已选择 {{ multipleSelection.length || 0 }} 项</span>
        <el-button plain type="primary" @click="multipleCheck">批量审核</el-button>
      </el-form-item> -->
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="120" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button v-if="$hasAccess('006300')" type="text" @click="$refs.DialogInfo.open(row)">查看</el-button>
          <el-button v-if="$hasAccess('006301') && row.auditState == 2" type="text" @click="$refs.DialogCheck.open(row)">审核</el-button>
          <!-- <el-button v-if="row.auditState == 3" type="text" @click="confirm(row)">确认发放</el-button> -->
        </template>
      </el-table-column>
    </TableView>
    <DialogInfo ref="DialogInfo" />
    <DialogBatchCheck ref="DialogBatchCheck" @submit="$refs.vTable.refreshTable()" />
    <DialogCheck ref="DialogCheck" @submit="$refs.vTable.refreshTable()" />
  </div>
</template>

<script>
// 常量设置
import { formatStatus, formatStatusHasColor, kvWorkPlanAuditState, kvWorkPlanGrantState } from '@/configs/formatters'
const tableLabels = [
  { label: '结算单号', prop: 'planNo', config: { width: 140 } },
  {
    label: '所属任务',
    prop: 'taskName',
    config: { showOverflowTooltip: true }
  },
  { label: '商户名称', prop: 'merchantName', config: { width: 150 } },
  { label: '商户号', prop: 'memberCode' },
  { label: '认证企业', prop: 'mainName', config: { width: 150 } },
  { label: '个税', prop: 'individualTax', cmp: 'money' },
  { label: '增值税', prop: 'addedTax', cmp: 'money' },
  { label: '税金及附加', prop: 'surchargesTax', cmp: 'money' },
  { label: '实发金额', prop: 'finalPayingAmount', cmp: 'money' },
  { label: '付款通道', prop: 'paymentChannel' },
  { label: '提交金额', prop: 'issueAmount', cmp: 'money' },
  { label: '人数', prop: 'totalPeopleNum' },
  {
    label: '税源地-通道',
    prop: 'supplierName',
    config: { width: 150 },
    formatter: (row, column, cellValue, index) => {
      return (cellValue || '--') + '-' + (row.channelName || '--')
    }
  },
  {
    label: '审核状态',
    prop: 'auditState',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return formatStatusHasColor(kvWorkPlanAuditState, cellValue)
    }
  },
  {
    label: '发放状态',
    prop: 'grantState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvWorkPlanGrantState, cellValue)
    }
  },
  {
    label: '发放进度',
    prop: 'grantFinishCount',
    formatter: (row, column, cellValue, index) => {
      return (cellValue || 0) + '/' + (row.grantRejectCount || 0) + '/' + (row.totalPeopleNum || 0)
    }
  },
  { label: '成功金额', prop: 'successAmount', cmp: 'money' },
  {
    label: '申请时间',
    prop: 'auditStartTime',
    formatter: (row, column, cellValue, index) => {
      return cellValue || '--'
    },
    config: { width: 140 }
  },
  {
    label: '备注',
    prop: 'remark',
    config: {
      'show-overflow-tooltip': true
    }
  }
]
import { searchWorkPlanCheck } from '@/configs/searches'
import DialogInfo from './info'
import DialogBatchCheck from './batchCheck'
import DialogCheck from './check'
export default {
  components: { DialogInfo, DialogBatchCheck, DialogCheck },
  data() {
    return {
      tableLabels: tableLabels,
      searchList: searchWorkPlanCheck,
      multipleSelection: [],
      summary: {},
      searchCounts: {},
      AuditState: null
    }
  },
  methods: {
    selectable(row) {
      if (row.auditState == 2) return true
      return false
    },
    selChange(val) {
      this.multipleSelection = val
    },
    onAuditStateChange() {
      this.$refs.vTable.searchTable()
    },
    async onTableGeted(response, params) {
      const res = await this.$api.GetInidvidualPlanPageListSummaryData(params)
      const { accuIssueAmount, accuPersonCount, accuSettleAmount, ...searchCounts } = res
      if (!params.AuditState) this.searchCounts = searchCounts
      this.summary = { accuIssueAmount, accuPersonCount, accuSettleAmount }
    },
    multipleCheck() {
      if (!this.multipleSelection.length) return this.$message.warning('请选择需要批量处理的数据')
      // 判断商户一致性
      const mainCode = this.multipleSelection[0].mainCode
      const otherOne = this.multipleSelection.find((item) => {
        return item.mainCode != mainCode
      })
      if (otherOne) return this.$message.warning('请选择同一商户')
      this.$refs.DialogBatchCheck.open(this.multipleSelection)
    },
    // confirm(item) {
    //   // 确认发放
    // }
  }
}
</script>
