<template>
  <div class="flex-home">
    <div class="pageTitle">{{ $route.meta.title }}</div>
    <el-alert
      title="温馨提示：此模块只支持同一商户下，同一银行下，各记账子单元互转，不支持跨行、跨商户调拨资金"
      type="warning"
      style="margin-bottom:18px"
      :closable="false"
    >
    </el-alert>
    <TableView ref="vTable" :labels="tableLabels" apiName="GetDepositInsidePageList" :searchList="searchList" :initSearch="{ SearchTxt: fundNo }">
      <!-- 按钮栏 -->
      <el-form-item slot="btnline-item-left">
        <el-button plain type="primary" icon="el-icon-plus" @click="$refs.Edit.open()">内部划转</el-button>
      </el-form-item>
      <el-form-item slot="btnline-item">
        <el-button plain type="primary" icon="el-icon-download" @click="download" :loading="btnloadDownload">导出</el-button>
      </el-form-item>
    </TableView>
    <!-- 弹窗 -->
    <Edit ref="Edit" @submit="$refs.vTable.refreshTable()"></Edit>
  </div>
</template>

<script>
import { formatStatus, kvDepositState } from '@/configs/formatters'
import { downloadExcel } from '@/utils'
import Edit from './edit'
const searchList = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入商户编号/商户名称/流水号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '状态',
    prop: 'DepositState',
    options: kvDepositState
  },
  {
    cmp: 'dtpickers',
    label: '申请时间',
    prop: ['DepositTimeBegin', 'DepositTimeEnd']
  }
]
const tableLabels = [
  { label: '申请时间', prop: 'depositTime', config: { width: 180 } },
  { label: '申请单号', prop: 'depositNo'},
  { label: '商户号', prop: 'memberCode'},
  { label: '商户名称', prop: 'merchantName'},
  { label: '付款账户', prop: 'payerAccountNumber'},
  { label: '收款账户', prop: 'payeeAccountNumber'},
  { label: '调账金额', prop: 'depositAmount', cmp: 'money' },
  { label: '备注', prop: 'depositRemark', config: {'show-overflow-tooltip': true}},
  {
    label: '状态',
    prop: 'depositState',
    formatter: (row, column, cellValue, index) => {
      return formatStatus(kvDepositState, cellValue)
    }
  },
  {label: '失败原因', prop: 'depositDesc',config: {'show-overflow-tooltip': true}}
]
export default {
  components: { Edit },
  data() {
    return {
      btnloadDownload: false,
      searchList: searchList,
      tableLabels: tableLabels
    }
  },
  computed: {
    fundNo() {
      return this.$route.query.fundNo
    }
  },
  methods: {
    async download() {
      this.btnloadDownload = true
      const params = this.$refs.vTable.getParams()
      const res = await this.$api.ExportDepositInside(params).finally(() => {
        this.btnloadDownload = false
      })
      downloadExcel(res, `内部划转记录`)
    },
    //查看
    routeLs(row) {
      switch (row.fundType) {
        case 10:
          this.$router.push(`/financeRecharge?fundNo=${row.fundNo}`)
          break
        case 20:
          this.$router.push(`/financeDisburse?fundNo=${row.fundNo}`)
          break
        case 30:
          this.$router.push(`/financeWithdrawal?fundNo=${row.fundNo}`)
          break
        default:
          break
      }
    }
  }
}
</script>
