import Layout from '@/layout'

/** meta
 * hideInSide: true 在Side中不展示
 * showInSide: true 当页面访问时才在Side中展示，优先级高于hideInSide
 * icon: 'el-xxx' Side中的icon
 * title: '' 标题
 * ruleName：权限
 * tenantShow：租户开放
 */

export const currencyRoutes = [
  {
    path: '/login',
    name: 'login',
    hideInSide: true,
    meta: {
      tenantShow: true,
      title: '登录'
    },
    component: () => import('@/views/login')
  },
  {
    path: '/error-530',
    name: 'error-530',
    hideInSide: true,
    meta: {
      tenantShow: true,
      title: '登录失效'
    },
    component: () => import('@/views/error/530')
  },
  {
    path: '/error-404',
    name: 'error-404',
    hideInSide: true,
    meta: {
      tenantShow: true,
      title: '页面不存在'
    },
    component: () => import('@/views/error/404')
  },
  {
    path: '/error-403',
    name: 'error-403',
    hideInSide: true,
    meta: {
      tenantShow: true,
      title: '无权访问'
    },
    component: () => import('@/views/error/403')
  },
  {
    path: '/replace',
    name: 'replace',
    component: Layout
  }
]

export const asyncRoutes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        name: 'home',
        hideInSide: true,
        meta: {
          title: '首页',
          tenantShow: true
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/home')
      }
    ]
  },
  // {
  //   path: '/template',
  //   meta: {
  //     title: '模版管理',
  //     // icon: 'el-icon-house'
  //   },
  //   hideInSide: true,
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/templateTable',
  //       name: 'templateTable',
  //       meta: {
  //         title: '表格模版',
  //         // icon: 'el-icon-house'
  //       },
  //       component: () => import('@/views/template/table')
  //     },
  //     {
  //       path: '/templateForm',
  //       name: 'templateForm',
  //       meta: {
  //         title: '表单模版',
  //         // icon: 'el-icon-house'
  //       },
  //       component: () => import('@/views/template/form')
  //     }
  //   ]
  // },
  {
    path: '/merchant',
    meta: {
      title: '商户管理',
      tenantShow: true,
      icon: 'icon-shanghuguanli'
    },
    component: Layout,
    children: [
      {
        path: '/merchant/list',
        name: 'merchant',
        meta: {
          title: '委托代征商户',
          tenantShow: true,
          ruleName: '010-010'
        },
        component: () => import('@/views/merchant/index')
      },
      {
        path: '/merchant/self',
        name: 'merchant',
        meta: {
          title: '个体户商户',
          ruleName: '010-020'
        },
        component: () => import('@/views/merchant/selfIndex')
      },
      {
        path: '/merchant/configList',
        name: 'merchant',
        meta: {
          title: '配置管理',
          tenantShow: true,
          ruleName: '010-030'
        },
        component: () => import('@/views/merchant/configList')
      }
    ]
  },
  {
    path: '/Park',
    meta: {
      title: '税源地管理',
      icon: 'icon-yuanquguanli'
    },
    component: Layout,
    children: [
      {
        path: '/Park/index',
        name: 'ParkIndex',
        meta: {
          title: '税源地列表',
          ruleName: '020-010'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/Park/index')
      },
      {
        path: '/channel/index',
        name: 'channelIndex',
        meta: {
          title: '通道列表',
          ruleName: '020-020'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/channel/index')
      },
      {
        path: '/Park/mainList/index',
        name: 'mainList',
        meta: {
          title: '主体列表',
          ruleName: '010-040'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/Park/mainList/index')
      }
    ]
  },
  {
    path: '/workPeo',
    meta: {
      title: '创客管理',
      tenantShow: true,
      icon: 'icon-duoren-renqun'
    },
    component: Layout,
    children: [
      {
        path: '/workPeople',
        name: 'workPeople',
        meta: {
          title: '创客列表',
          tenantShow: true,
          ruleName: '030-010'
          // icon: 'icon-duoren-renqun'
        },
        component: () => import('@/views/workPeople')
      },
      {
        path: '/personnel',
        name: 'personnel',
        meta: {
          title: '人员库',
          ruleName: '030-020'
          // icon: 'icon-renyuanku'
        },
        component: () => import('@/views/personnel/information')
      }
      // {
      //   path: '/electricitySign',
      //   name: 'electricitySign',
      //   meta: {
      //     title: '活体电签',
      //     ruleName: '030-020'
      //     // icon: 'icon-renyuanku'
      //   },
      //   component: () => import('@/views/electricitySign/index')
      // }
    ]
  },
  {
    path: '/selfEmployed',
    component: Layout,
    meta: {
      title: '个体户管理'
    },
    children: [
      {
        path: '',
        name: 'selfEmployed',
        meta: {
          title: '个体户列表',
          ruleName: '420',
          icon: 'icon-renyuanku'
        },
        component: () => import('@/views/selfEmployed')
      }
    ]
  },
  {
    path: '/taskMange',
    meta: {
      title: '任务管理',
      tenantShow: true,
      icon: 'icon-renwujihua'
    },
    component: Layout,
    children: [
      {
        path: '/taskMange/taskList/index',
        name: 'TarkIndex',
        meta: {
          title: '任务列表',
          tenantShow: true,
          ruleName: '050-010'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/taskMange/taskList/index')
      },
      {
        path: '/taskMange/orderReceiving/index',
        name: 'orderReceiving',
        meta: {
          title: '接单列表',
          ruleName: '050-020'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/taskMange/orderReceiving/index')
      }
    ]
  },
  {
    path: '/workPlan',
    meta: {
      title: '结算管理',
      tenantShow: true,
      icon: 'icon-jiesuanzhanghubaobiao'
    },
    component: Layout,
    children: [
      {
        path: '/workPlanCheck',
        name: 'workPlanCheck',
        meta: {
          title: '结算单审核',
          tenantShow: true,
          ruleName: '060-010'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/workPlan/check')
      },
      {
        path: '/workPlanDetail',
        name: 'workPlanDetail',
        meta: {
          title: '结算明细',
          tenantShow: true,
          ruleName: '060-020'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/workPlan/detail')
      },
      {
        path: '/workPlanSelfCheck',
        name: 'workPlanSelfCheck',
        meta: {
          title: '个体户结算审核',
          ruleName: '060-030'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/workPlan/selfCheck')
      },
      {
        path: '/workPlanSelfDetail',
        name: 'workPlanSelfDetail',
        meta: {
          title: '个体户结算明细',
          ruleName: '060-040'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/workPlan/selfDetail')
      }
    ]
  },
  {
    path: '/statistics',
    meta: {
      title: '数据统计',
      icon: 'el-icon-data-line'
    },
    component: Layout,
    children: [
      {
        path: '/terrace',
        name: 'terrace',
        meta: {
          title: '平台数据汇总',
          ruleName: '400-010'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/statistics/terrace')
      },
      {
        path: '/merchants',
        name: 'merchants',
        meta: {
          title: '商户数据汇总',
          ruleName: '400-020'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/statistics/merchants')
      },
      {
        path: '/mergeAlipayAccount',
        name: 'mergeAlipayAccount',
        meta: {
          title: '合并账户支付宝统计'
          // ruleName: '400-020'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/statistics/mergeAlipayAccount')
      }
    ]
  },
  {
    path: '/tradingCenter',
    meta: {
      title: '交易中心',
      tenantShow: true,
      icon: 'icon-jiesuanzhanghubaobiao'
    },
    component: Layout,
    children: [
      {
        path: '/discountMangerment',
        name: 'discountMangerment',
        meta: {
          title: '抵扣金管理',
          ruleName: '065'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/tradingCenter/discountMangerment')
      }
    ]
  },
  {
    path: '/billApply',
    meta: {
      title: '票税管理',
      tenantShow: true,
      icon: 'icon-heguishuipiao'
    },
    component: Layout,
    children: [
      {
        path: '/invoice',
        name: 'invoice',
        meta: {
          title: '发票审核',
          tenantShow: true,
          ruleName: '070-010'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/billApply/invoice')
      },
      {
        path: '/billInfo',
        name: 'billInfo',
        meta: {
          title: '开票记录',
          tenantShow: true,
          ruleName: '070-020'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/billApply/billInfo')
      },
      {
        path: '/billRegister',
        name: 'billRegister',
        meta: {
          title: '开票登记',
          tenantShow: true,
          ruleName: '070-050'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/billApply/billRegister')
      },
      {
        path: '/taxItems',
        name: 'taxItems',
        meta: {
          title: '税目管理',
          ruleName: '070-030'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/billApply/taxItems')
      },
      {
        path: '/taxCredentials',
        name: 'taxCredentials',
        meta: {
          title: '完税凭证',
          tenantShow: true,
          ruleName: '070-040'
          // icon: 'icon-yonghuguanli'
        },
        component: () => import('@/views/taxManagement/credentials')
      }
      // {
      //   path: '/taxVoucher',
      //   name: 'taxVoucher',
      //   hideInSide: true,
      //   meta: {
      //     title: '完税凭证',
      //     ruleName: ''
      //     // icon: 'el-icon-house'
      //   },
      //   component: () => import('@/views/billApply/taxVoucher')
      // }
    ]
  },
  {
    path: '/finance',
    meta: {
      title: '财务管理',
      tenantShow: true,
      icon: 'icon-caiwuliushui'
    },
    component: Layout,
    children: [
      {
        path: '/accountCapital',
        name: 'accountCapital',
        meta: {
          title: '资金流水',
          ruleName: '080-005'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/finance/accountCapital')
      },
      {
        path: '/financeRecharge',
        name: 'financeRecharge',
        meta: {
          title: '充值记录',
          tenantShow: true,
          ruleName: '080-010'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/finance/recharge')
      },
      {
        path: '/financeException',
        name: 'financeException',
        meta: {
          title: '异名汇款',
          tenantShow: true
          // ruleName: '080-010'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/finance/exception')
      },
      {
        path: '/financeReturn',
        name: 'financeReturn',
        meta: {
          title: '退汇订单',
          tenantShow: true,
          ruleName: '080-050'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/finance/return')
      },
      {
        path: '/financeReturnLog',
        name: 'financeReturnLog',
        meta: {
          title: '退汇记录',
          tenantShow: true
          // ruleName: '080-050'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/finance/returnLog')
      },
      {
        path: '/depositInside',
        name: 'depositInside',
        meta: {
          title: '内部划转记录',
          tenantShow: true
          // ruleName: '080-020'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/finance/depositInside')
      },
      {
        path: '/alipayTransfer',
        name: 'alipayTransfer',
        meta: {
          title: '支付宝汇款',
          tenantShow: true
          // ruleName: '080-020'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/finance/alipayTransfer')
      },
      {
        path: '/financeDisburse',
        name: 'financeDisburse',
        meta: {
          title: '支出记录',
          tenantShow: true,
          ruleName: '080-020'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/finance/disburse')
      },
      {
        path: '/financeWithdrawal',
        name: 'financeWithdrawal',
        meta: {
          title: '提现记录',
          tenantShow: true,
          ruleName: '080-030'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/finance/withdrawal')
      },
      {
        path: '/fpWithdrawal',
        name: 'fpWithdrawal',
        meta: {
          title: 'FP提现待办',
          ruleName: '080-040'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/finance/fpWithdrawal')
      },
      {
        path: '/financeReceipt',
        name: 'financeReceipt',
        meta: {
          title: '电子回单导入',
          ruleName: '080-060'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/finance/receipt')
      },
      {
        path: '/financeReceiptDownload',
        name: 'financeReceiptDownload',
        meta: {
          title: '批量下载回单'
          // ruleName: '080-060'
          // icon: 'el-icon-house'
        },
        component: () => import('@/views/finance/receiptDownload')
      }
    ]
  },
  {
    path: '/contract',
    meta: {
      title: '合同管理',
      icon: 'icon-hetongxinxi'
    },
    component: Layout,
    children: [
      {
        path: '/contract/template',
        name: 'contractTemplate',
        meta: {
          title: '合同模板',
          ruleName: '090'
        },
        component: () => import('@/views/contract')
      }
    ]
  },
  {
    path: '/smsManger',
    meta: {
      title: '短信模板管理',
      icon: 'icon-renwujihua'
    },
    component: Layout,
    children: [
      {
        path: '/smsManger/smsTemplate',
        name: 'smsTemplate',
        meta: {
          title: '短信模板',
          ruleName: '090'
        },
        component: () => import('@/views/smsManger/smsTemplate')
      }
    ]
  },
  {
    path: '/riskMange',
    meta: {
      title: '风控管理',
      tenantShow: true,
      icon: 'icon-fengkongguanli'
    },
    component: Layout,
    children: [
      {
        path: '/factorVerify',
        name: 'factorVerify',
        meta: {
          title: '人员要素校验',
          // tenantShow: true,
          ruleName: '100-050'
        },
        component: () => import('@/views/riskMange/factorVerify')
      },
      // {
      //   path: '/deliverable',
      //   name: 'deliverable',
      //   meta: {
      //     title: '交付物管理',
      //     ruleName: '100-010'
      //   },
      //   component: () => import('@/views/riskMange/deliverable')
      // },
      {
        path: '/newDeliverable',
        name: 'newDeliverable',
        meta: {
          title: '交付物管理',
          // tenantShow: true,
          ruleName: '100-010'
        },
        component: () => import('@/views/riskMange/newDeliverable')
      },
      {
        path: '/personList',
        name: 'personList',
        hideInSide: true,
        meta: {
          title: '人员名单'
          // tenantShow: true
        },
        component: () => import('@/views/riskMange/personList')
      },
      {
        path: '/business',
        name: 'business',
        meta: {
          title: '业务确认单管理',
          // tenantShow: true,
          ruleName: '100-020'
        },
        component: () => import('@/views/riskMange/business')
      },
      // {
      //   path: '/phoneReview',
      //   name: 'phoneReview',
      //   meta: {
      //     title: '电话复核'
      //     // tenantShow: true,
      //     // ruleName: '100-050'
      //   },
      //   component: () => import('@/views/riskMange/phoneReview')
      // },
      {
        path: '/whiteList',
        name: 'whiteList',
        meta: {
          title: '签约白名单',
          tenantShow: true,
          ruleName: '100-030'
        },
        component: () => import('@/views/riskMange/whiteList')
      },
      {
        path: '/blackList',
        name: 'blackList',
        meta: {
          title: '发放黑名单',
          tenantShow: true,
          ruleName: '100-040'
        },
        component: () => import('@/views/riskMange/blackList')
      }
    ]
  },
  {
    path: '/notice',
    meta: {
      title: '公告管理',
      icon: 'icon-gonggaoguanli'
    },
    component: Layout,
    children: [
      {
        path: '',
        name: 'notice',
        meta: {
          title: '公告管理',
          ruleName: '390'
        },
        component: () => import('@/views/notice')
      }
    ]
  },
  {
    path: '/system',
    meta: {
      title: '系统管理',
      icon: 'el-icon-setting'
    },
    component: Layout,
    children: [
      {
        path: '/operationUser',
        name: 'operationUser',
        meta: {
          title: '用户管理',
          ruleName: '110-010'
          // icon: 'icon-yonghuguanli'
        },
        component: () => import('@/views/system/operationUser')
      },
      {
        path: '/role',
        name: 'role',
        meta: {
          title: '角色管理',
          ruleName: '110-020'
          // icon: 'icon-yonghuguanli'
        },
        component: () => import('@/views/system/role')
      },
      {
        path: '/employee',
        name: 'employee',
        meta: {
          title: '商务管理'
          // ruleName: '110-020'
          // icon: 'icon-yonghuguanli'
        },
        component: () => import('@/views/system/employee')
      },
      {
        path: '/channel',
        name: 'channel',
        meta: {
          title: '渠道管理'
          // ruleName: '110-020'
          // icon: 'icon-yonghuguanli'
        },
        component: () => import('@/views/system/channel')
      },
      {
        path: '/merchantRole',
        name: 'merchantRole',
        meta: {
          title: '商户角色配置',
          ruleName: '110-040'
          // icon: 'icon-yonghuguanli'
        },
        component: () => import('@/views/system/merchantRole')
      },
      {
        path: '/setSystem',
        name: 'setSystem',
        meta: {
          title: '系统设置',
          ruleName: '110-030'
          // icon: 'icon-xitongshezhi'
        },
        component: () => import('@/views/setSystem')
      }
    ]
  },
  {
    path: '/tenantWrap',
    meta: {
      title: '运营商管理',
      icon: 'el-icon-setting'
    },
    component: Layout,
    children: [
      {
        path: '/tenant',
        name: 'tenant',
        meta: {
          title: '运营商管理',
          ruleName: '440'
          // icon: 'icon-yonghuguanli'
        },
        component: () => import('@/views/tenant')
      }
    ]
  }
]
