<template>
  <el-link v-if="path" type="primary" @click="lookFile" style="max-height: 18px">
    {{ name || '查看文件' }} <i v-if="isIcon" class="el-icon-view el-icon--right"></i>
    <el-image-viewer
      v-if="showViewer"
      :zIndex="9999"
      :url-list="viewerlList"
      :on-close="
        () => {
          showViewer = false
        }
      "
    />
  </el-link>
  <span v-else>无</span>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { isArray, downloadUrl } from '@/utils'
export default {
  components: {
    ElImageViewer
  },
  props: {
    path: String || Array,
    name: String,
    isIcon: true
  },
  data() {
    return {
      showViewer: false,
      viewerlList: []
    }
  },
  methods: {
    isArray,
    lookFile() {
      const regImg = /\.(jpg|jpeg|png|JPG)$/
      if (isArray(this.path) || regImg.test(this.path)) {
        this.viewerlList = isArray(this.path) ? this.path : [this.path]
        this.showViewer = true
      } else {
        downloadUrl(this.path)
      }
    }
  }
}
</script>
